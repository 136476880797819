function dateRangeFilters(value: string, filter: Date, dateFilters: Date[]): boolean {
    if ((filter === undefined) || (filter === null) || (dateFilters === null)) {
      return true;
    }
    if ((value === undefined) || (value === null) || (value.length === 0)) {
      return false;
    }
    let s = dateFilters[0].getTime();
    let e;
    if (dateFilters[1]) {
      e = dateFilters[1].getTime() + 86400000 - 1;
    } else {
      e = s + 86400000 - 1;
    }
    // let vDate = (new Date(value).getTime());
    let vDate = (new Date(value).getTime());

    if (vDate >= s && vDate <= e) {
      return true;
    }
    return false;
}

export {
    dateRangeFilters
}