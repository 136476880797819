import {Component, OnInit, OnDestroy} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Subscription} from 'rxjs';
import {InitialOrderDetails, OrderItem, OrderRoot} from '../../models/order.model';
import {RawVariation, VariationAttribute} from '../../models/variation.model';
import {PostOrderSection} from '../post-order-section';
import {PostOrderService} from '../post-order.service';
import {NameAndValue} from '../../models/nameAndValue.model';
import {ShopifyVariantId} from '../../models/mongoose-populated-ids/shopifyVariantId.model';

@Component({
  selector: 'app-initial-order-details-section[pageSection][minimizeSectionMethod]',
  templateUrl: './initial-order-details-section.component.html',
  styleUrls: ['../post-order-sections.scss', './initial-order-details-section.component.scss']
})
export class InitialOrderDetailsSectionComponent extends PostOrderSection implements OnInit, OnDestroy {
  initialOrderDetailsSubscription: Subscription;
  itemsSubscription: Subscription;
  orderRootSubscription: Subscription;
  formLoaded: boolean;
  webOrderId: string;
  initialOrderDetails: InitialOrderDetails;
  items: OrderItem[];

  constructor(
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    private postOrderService: PostOrderService,
  ) {
    super(confirmationService, messageService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.formLoaded = false;
    this.webOrderId = '';
    this.items = [];
    this.initialOrderDetailsSubscription = 
      this.postOrderService.initialOrderDetailsSubject.subscribe((initialDetails: InitialOrderDetails) => {
        this.formLoaded = true;
        this.initialOrderDetails = initialDetails;
      });
    this.itemsSubscription =
      this.postOrderService.itemsSubject.subscribe((initialItems: OrderItem[]) => {
        this.items = initialItems;
      });
    this.orderRootSubscription =
      this.postOrderService.orderRootSubject.subscribe((orderRoot: OrderRoot) => {
        if (orderRoot.orderId) {
          this.webOrderId = orderRoot.orderId;
        }
      });
  }

  ngOnDestroy(): void {
    this.initialOrderDetailsSubscription.unsubscribe();
    this.itemsSubscription.unsubscribe();
    this.orderRootSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  getItemAttributes(item: OrderItem): VariationAttribute[] {
    return (item.subProductId as RawVariation).attributes;
  }

  getShopifyAttributes(item: OrderItem): NameAndValue<string>[] {
    if (!item.shopifyVariant || (typeof item.shopifyVariant == 'string')) {
      return [];
    }
    return (item.shopifyVariant as ShopifyVariantId).selectedOptions.filter((option: NameAndValue<string>) =>
      option.value != 'Default Title'
    );
  }

  getShopifyTitle(item: OrderItem): string {
    if (!item.shopifyVariant || (typeof item.shopifyVariant == 'string')) {
      return '';
    }
    return (item.shopifyVariant as ShopifyVariantId).crmVariantTitle;
  }

}
