import {SelectItem} from 'primeng/api';
import {Website} from '../models/website.model';

interface BrandCfg {
  [brandCode: string]: Website
}

let brandConfigs: BrandCfg;
function getBrandConfigs(): BrandCfg {
  if (!brandConfigs) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('brandConfigs')) {
      return {};
    }
    brandConfigs = JSON.parse(localStorage.getItem('brandConfigs')) || [];
  }
  return brandConfigs;
}

let brandCodes: string[];
function getBrandCodes(): string[] {
  if (!brandCodes) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('brandConfigs')) {
      return [];
    }
    brandCodes = Object.keys(getBrandConfigs());
  }
  return brandCodes;
}

let brandSelectItems: SelectItem<string>[];
function getBrandSelectItems(): SelectItem<string>[] {
  if (!brandSelectItems) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('brandConfigs')) {
      return [];
    }
    brandSelectItems = getBrandCodes().map((brandCode: string) => {
      return {
        label: brandCode,
        value: brandCode,
      }
    });
  }
  return brandSelectItems;
}

let websiteSelectItems: SelectItem<string>[];
function getWebsiteSelectItems(): SelectItem<string>[] {
  if (!websiteSelectItems) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('brandConfigs')) {
      return [];
    }
    const brandConfigs: BrandCfg = getBrandConfigs();
    websiteSelectItems = getBrandCodes().map((brandCode: string) => {
      return {
        label: brandCode,
        value: brandConfigs[brandCode]._id,
      }
    });
  }
  return websiteSelectItems;
}

function getBrandColour(brand: string, defaultColour: string = '#ffffff'): string {
  const config = getBrandConfigs()[brand];
  if (config) {
    return config.color;
  }
  return defaultColour;
}

function getBrandBackgroundColour(brand: string, defaultColour: string = '#000000'): string {
  const config = getBrandConfigs()[brand];
  if (config) {
    return config.background;
  }
  return defaultColour;
}

function getBrandTitleBarColour(brand: string, defaultColour: string = '#000000'): string {
  const config = getBrandConfigs()[brand];
  if (config) {
    return config.titleBarColor;
  }
  return defaultColour;
}

export {
  BrandCfg,
  getBrandConfigs,
  getBrandCodes,
  // This one's value is the brand code
  getBrandSelectItems,
  // This one's value is the website _id (string version of ObjectId)
  getWebsiteSelectItems,
  getBrandColour,
  getBrandTitleBarColour,
  getBrandBackgroundColour,
}
