export class Result {
  constructor(isSuccess) {
    this.isSuccess = isSuccess;
  }
}
export class Success extends Result {
  constructor() {
    super(true);
  }
}
export class AutocompleteSuccess extends Success {
  constructor(suggestions) {
    super();
    this.suggestions = suggestions;
  }
  toSuccess() {
    return this;
  }
  toFailed() {
    throw new Error('Did not fail');
  }
}
export class LocationSuccess extends Success {
  constructor(suggestions) {
    super();
    this.suggestions = suggestions;
  }
  toSuccess() {
    return this;
  }
  toFailed() {
    throw new Error('Did not fail');
  }
}
export class GetSuccess extends Success {
  constructor(address) {
    super();
    this.address = address;
  }
  toSuccess() {
    return this;
  }
  toFailed() {
    throw new Error('Did not fail');
  }
}
export class GetLocationSuccess extends Success {
  constructor(location) {
    super();
    this.location = location;
  }
  toSuccess() {
    return this;
  }
  toFailed() {
    throw new Error('Did not fail');
  }
}
export class GetLocationFailed extends Result {
  constructor(status, message) {
    super(false);
    this.status = status;
    this.message = message;
  }
  toSuccess() {
    throw new Error('Not a success');
  }
  toFailed() {
    return this;
  }
}
export class AutocompleteFailed extends Result {
  constructor(status, message) {
    super(false);
    this.status = status;
    this.message = message;
  }
  toSuccess() {
    throw new Error('Not a success');
  }
  toFailed() {
    return this;
  }
}
export class LocationFailed extends Result {
  constructor(status, message) {
    super(false);
    this.status = status;
    this.message = message;
  }
  toSuccess() {
    throw new Error('Not a success');
  }
  toFailed() {
    return this;
  }
}
export class GetFailed extends Result {
  constructor(status, message) {
    super(false);
    this.status = status;
    this.message = message;
  }
  toSuccess() {
    throw new Error('Not a success');
  }
  toFailed() {
    return this;
  }
}
export class FindSuccess extends Success {
  constructor(addresses) {
    super();
    this.addresses = addresses;
  }
  toSuccess() {
    return this;
  }
  toFailed() {
    throw new Error('failed');
  }
}
export class FindFailed extends Result {
  constructor(status, message) {
    super(false);
    this.status = status;
    this.message = message;
  }
  toSuccess() {
    throw new Error('failed');
  }
  toFailed() {
    return this;
  }
}
export class TypeaheadSuccess extends Success {
  constructor(results) {
    super();
    this.results = results;
  }
  toSuccess() {
    return this;
  }
  toFailed() {
    throw new Error('failed');
  }
}
export class TypeaheadFailed extends Result {
  constructor(status, message) {
    super(false);
    this.status = status;
    this.message = message;
  }
  toSuccess() {
    throw new Error('failed');
  }
  toFailed() {
    return this;
  }
}