<section id="profile">
  <div class="container p-5">
    <h4 class="display-4">My Profile</h4>
    <hr />
    <div *ngIf="!user" class="m-auto">
      <h1 class="text-center display-3 mt-5">
        <i class="fa fa-spinner fa-spin"></i>
      </h1>
    </div>
    <ng-container *ngIf="user">
    <div class="row mt-2">
      <div class="col-3">
        <img [src]="user.picture" alt="userimage" class="rounded-circle image">
      </div>
      <div class="col-7">
        <p id="name" class="lead">{{user.name}}</p>
      </div>
      <div class="col-2">
        <a routerLink="/profile/settings" class="btn btn-warning">Change Password</a>
        <button type="button" class="btn btn-warning" [hidden]="user.qrcode" (click)="setup2fa()" style="margin-top: 5px;">Setup 2FA</button>
      </div>
      <div *ngIf="user.qrcode" class="col-12 row" style="padding-top: 10px;">
        <div class="col-3" style="align-self: center;">
          <img *ngIf="user.qrcode" src="{{user.qrcode}}">
        </div>
        <div class="col-4" >
          <div class="row" style="margin-top: 20px;">
            <button type="button" class="btn btn-warning" (click)="generateQrCheck()">Generate new 2 Factor Authentication code</button>
          </div>
          <div class="row" style="margin-top: 20px;">
            <input *ngIf="user.qrcode"  pInputText [(ngModel)]="authCode" style="width: 160px;">
          </div>
          <div class="row" style="margin-top: 20px;">
            <button type="button" class="btn btn-primary" *ngIf="user.qrcode" (click)="validate2fa()" style="width: 100px">Validate</button>
          </div>
        </div>
        <div class="col-5" style="margin-top: 20px;">
          Generating a new code will stop all previous devices set up to stop working.
          This option is meant to be used if you are changing devices and would like to remove older ones no longer in use.
          Otherwise this is not neccesary.  
        </div>
      </div>
    </div>
    <ng-container *ngIf="(pendantTestNumberAvailable > -1)">
      <hr class="my-4"/>
      <div class="row mt-2">
        <div class="col-12">
          <h2>Pendant Test Emails</h2>
          Releasing a batch of emails will queue them for processing. There will be a slight delay between the release being triggered and the numbers updating.<br/>
          The queue processed every 2 hours between 0840 and 2040. The number available count assumes that all have a valid external email address.
        </div>
        <ng-container *ngIf="showSendPendantTests">
          <div class="col-6">
            <label for="pendantTestNumberToRelease">Number of Pendant Test emails to release:</label>
          </div>
          <div class="col-4">
            <input id="pendantTestNumberToRelease" pInputText [(ngModel)]="pendantTestNumberToRelease" (keypress)="numberOnly($event, false)"
              max="pendantTestNumberAvailable"/>
          </div>
          <div class="col-2">
            <button type="button" class="btn btn-primary" (click)="releasePendantTestEmails()"
                [disabled]="pendantTestReleaseDisabled || pendantTestNumberToRelease <= 0">
              Release Emails
            </button>
          </div>
        </ng-container>
        <div class="col-6">
          <label>Number of Pendant Test emails available to release:</label>
          {{pendantTestNumberAvailable}}
        </div>
        <div class="col-6">
          <label>Number of Pendant Test emails released today:</label>
          {{pendantTestsReleasedToday}}
        </div>
        <div class="col-12">
          <button type="button" class="btn btn-primary" (click)="downloadMessageLogSummary()">
            Download Message Log Summary
          </button>
        </div>
      </div>
    </ng-container>
    <hr class="my-4"/>
    <div class="row">
      <div class="form-group col-12">
        <h2>Default Note Categories</h2>
        Below you can change the default note categories set when adding a note to an order
      </div>
      <div class="col-3">
        <label>Default Note Categories</label>
      </div>
      <div class="col-8">
        <p-multiSelect [style]="{width:'100%'}" class="noteCategories" [options]="noteCategories"
          [(ngModel)]="defaultNoteCategories" scrollHeight="$1" [panelStyle]="{minWidth:'12em'}"
          placeholder="Choose Categories" appendTo="body">
        </p-multiSelect>
      </div>
      <div class="col-1">
        <button type="button" class="icon-btn" (click)="saveNoteCategory()" title="Save 'Default' note categories"
          [disabled]="noteCategoryOptionsDisabled">
          <i class="fas fa-save fa-2x"></i>
        </button>
      </div>
    </div>
    <hr class="my-4"/>
    <div class="row" [formGroup]="noteTemplateForm">
      <div class="form-group col-12">
        <h2>Note Templates</h2>
        Below you can define templates for notes that you use regularly to save time in adding a note to an order.
        The categories you define on the template will override your default note categories for that note.
      </div>
      <div class="form-group col-12">
        <label>Template To Edit</label>
        <p-dropdown [options]="noteTemplates" name="noteTemplates" [showClear]="true" placeholder="Select a template" [style]="{width:'100%'}"
          (onChange)="changeNoteTemplate($event)" [filter]=true [resetFilterOnHide]="true" [optionLabel]="'label'" #templateDropdown>
        </p-dropdown>
      </div>
      <div class="form-group col-12">
        <label>Template Name</label>
        <input type="text" name="templateName" class="form-control" formControlName="label" [required]="true" />
      </div>
      <div class="form-group col-12">
        <label>Note Categories</label>
        <p-multiSelect [style]="{width:'100%'}" [options]="noteCategories"
          formControlName="noteCategories" scrollHeight="$1" [panelStyle]="{minWidth:'12em'}"
          placeholder="Choose Categories" appendTo="body" [required]="true">
        </p-multiSelect>
      </div>
      <div class="form-group col-12">
        <label>Note Content</label>
        <textarea type="text" name="noteContent" class="form-control" formControlName="value" [required]="true"></textarea>
      </div>
      <div class="form-group col-12">
        <button type="button" class="icon-btn" (click)="saveNoteTemplate()" title="Save note template '{{templateName.value}}'"
          [disabled]="noteTemplateOptionsDisabled || !noteTemplateForm.valid ">
          <i class="fas fa-save fa-2x"></i>
        </button>
        <button type="button" class="icon-btn" (click)="confirmNoteTemplateDelete()" title="Delete note template '{{templateName.value}}'"
          [disabled]="noteTemplateOptionsDisabled || !noteTemplateForm.valid || templateName.dirty ">
          <i class="fas fa-trash fa-2x"></i>
        </button>
      </div>
    </div>
    <hr class="my-4"/>
    <div class="row">
      <div class="col-12">
        <h2>Saved Column Sets</h2>
        Below you can change the order of the columns in your saved sets by dragging column names up or down the list,
        or remove sets you no longer need
      </div>
    </div>
    <hr />
    <div class="row my-2" *ngIf="defaultColumns.length > 0">
      <div class="col-3">
        <label>Set name:</label>&nbsp;Default
      </div>
      <div class="col-6">
        <label>Columns (click +/- button to toggle display):</label>
        <div *ngIf="isColumnListExpanded(-1)" cdkDropList [cdkDropListData]="defaultColumns"
          (cdkDropListDropped)="dropColumn($event)">
          <div *ngFor="let column of defaultColumns" cdkDrag>{{column.header}}</div>
        </div>
      </div>
      <div class="col-1">
        <button (click)="toggleColumnList(-1)" type="button" class="btn btn-primary minMax"
          title="Toggle Column display for 'Default' column set">
          <i *ngIf="isColumnListExpanded(-1)" class="pi pi-minus"></i>
          <i *ngIf="!isColumnListExpanded(-1)" class="pi pi-plus"></i>
        </button>
      </div>
      <div class="col-2">
        <button type="button" class="icon-btn" (click)="saveColumnSet(-1)" title="Save 'Default' column set"
          [disabled]="columnSetOptionsDisabled">
          <i class="fas fa-save fa-2x"></i>
        </button>
      </div>
    </div>
    <hr />
    <ng-container *ngFor="let columnSet of savedColumnSets; let setIndex = index;">
      <div class="row my-2">
        <div class="col-3"><label>Set name:</label>&nbsp;{{columnSet.label}}</div>
        <div class="col-6">
          <label>Columns (click +/- button to toggle display):</label>
          <div *ngIf="isColumnListExpanded(setIndex)" cdkDropList [cdkDropListData]="columnSet.value"
            (cdkDropListDropped)="dropColumn($event)">
            <div *ngFor="let column of columnSet.value" cdkDrag>{{column.header}}</div>
          </div>
        </div>
        <div class="col-1">
          <button (click)="toggleColumnList(setIndex)" type="button" class="btn btn-primary minMax"
            title="Toggle Column display for '{{columnSet.label}}' column set">
            <i *ngIf="isColumnListExpanded(setIndex)" class="pi pi-minus"></i>
            <i *ngIf="!isColumnListExpanded(setIndex)" class="pi pi-plus"></i>
          </button>
        </div>
        <div class="col-2">
          <button type="button" class="icon-btn" (click)="saveColumnSet(setIndex)"
            title="Save '{{columnSet.label}}' column set" [disabled]="columnSetOptionsDisabled">
            <i class="fas fa-save fa-2x"></i>
          </button>
          <button type="button" class="icon-btn" (click)="confirmColumnSetDelete(setIndex)"
            title="Delete '{{columnSet.label}}' column set" [disabled]="columnSetOptionsDisabled">
            <i class="fas fa-trash fa-2x"></i>
          </button>
        </div>
      </div>
      <hr/>
    </ng-container>
  </ng-container>
  </div>
</section>
<p-confirmDialog key="general"></p-confirmDialog>
<p-toast></p-toast>