import {TooltipService} from '../setup/tooltips/tooltip.service';
import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../data.service';
import {Website} from '../models/website.model';
import {Column} from '../models/column.model';
import {ConfigDataResponse, Lookup} from '../models/responses/configDataResponse.model';
import {getHomepageColumns} from '../lookups/spreadsheetColumns';
import {PageSection} from '../models/page-section.model';
import {defaultLeftColumn, defaultRightColumn} from '../lookups/defaultPageSections';
import {BrandCfg} from '../lookups/brands';
import {ActionCfg, ActionConfig} from '../lookups/actions';
import {Title} from '@angular/platform-browser';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {UsersService} from '../setup/users/users.service';
import {CrmLoginResponse} from '../models/responses/crmLoginResponse.model';
import {clearLocalStorage, getErrorMessage} from '../helpers/helperFunctions';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {Tooltip} from '../models/tooltip.model';
import {CRM_CLIENT_VERSION} from '../models/environment.model';
import {numberOnly} from '../helpers/keyboardHelpers';
import {Output, EventEmitter} from '@angular/core';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ConfirmationService],
})
export class LoginComponent {
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  email: string = '';
  password: string = '';
  btnDisabled: boolean = false;
  crmClientVersion: string = CRM_CLIENT_VERSION;
  authCode: string;
  numberOnly = numberOnly;
  show2fa: boolean;
  token: string;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private confirmationService: ConfirmationService,
    private data: DataService,
    private route: ActivatedRoute,
    private tooltipService: TooltipService,
    private title: Title,
  ) {}

  validate(): boolean {
    if (this.email) {
      if (this.password) {
        return true;
      } else {
        this.data.error('Password is not entered');
      }
    } else {
      this.data.error('Email is not entered.');
    }
    return false;
  }

  login(): void {
    clearLocalStorage();
    this.btnDisabled = true;
    if (!this.validate()) {
      this.btnDisabled = false;
      return;
    }
    this.usersService.login({
      email: this.email,
      password: this.password,
      deviceId: localStorage.getItem('deviceId')
    }).subscribe((response: CrmLoginResponse) => {
      clearLocalStorage();
      this.btnDisabled = false;
      if (!response.success) {
        this.data.error(response.message);
        return;
      }
      // check for 2fa response response.user doesn't exist then 2fa response everything below is successsful login attempt
      if (response.user) {
        this.getLoginDetails(response);
      } else {
        this.token = response.token;
        this.showAuthPrompt();
      }
    }, (error) => {
      this.data.error(getErrorMessage(error));
      this.btnDisabled = false;
    }); 
  }

  showAuthPrompt(): void {
    this.show2fa = true;
  }

  validate2fa() {
    this.usersService.login2fa(this.token, this.authCode, localStorage.getItem('deviceId')).subscribe((response: CrmLoginResponse) => {
      if (!response.success) {
        this.showErrorPrompt('Error', response.message);
      } else {
        this.getLoginDetails(response);
      }
    })
  }

  getLoginDetails(response: CrmLoginResponse) {
    localStorage.setItem('email', this.email);
    localStorage.setItem('token', response.token);
    localStorage.setItem('userId', response.userInfo._id);
    localStorage.setItem('userName', response.user);
    localStorage.setItem('defaultNoteCategories', JSON.stringify(response.userInfo.defaultNoteCategories));
    localStorage.setItem('noteTemplates', JSON.stringify(response.userInfo.noteTemplates));
    localStorage.setItem('userPreferences', JSON.stringify(response.userInfo.userPreferences));
    const homepageColumns: Column[] = getHomepageColumns();
    this.setColumns(response.userInfo.defaultColumns, homepageColumns);
    this.setColumnsSet(response.userInfo.savedColumnSets, homepageColumns);

    this.getTooltips();
    if ((response.userInfo.orderPageLeftColumn.length > 0) || (response.userInfo.orderPageRightColumn.length > 0)) {
      // Check if there are any page sections in the default list the user does not have in their saved columns
      let defaultPageSections: PageSection[] = [];
      defaultPageSections = defaultPageSections.concat(defaultLeftColumn);
      defaultPageSections = defaultPageSections.concat(defaultRightColumn);

      localStorage.setItem('orderPageLeftColumn', JSON.stringify(response.userInfo.orderPageLeftColumn));
      response.userInfo.orderPageLeftColumn.forEach((savedPageSection: PageSection) => {
        defaultPageSections = defaultPageSections.filter((defaultPageSection: PageSection) => 
          defaultPageSection.template != savedPageSection.template
        );
      });

      response.userInfo.orderPageRightColumn.forEach((savedPageSection: PageSection) => {
        defaultPageSections = defaultPageSections.filter((defaultPageSection: PageSection) => 
          defaultPageSection.template != savedPageSection.template
        );
      });

    // If they are missing new sections add them to the bottom of the right column, else store what they have saved
      if (defaultPageSections.length == 0) { 
        localStorage.setItem('orderPageRightColumn', JSON.stringify(response.userInfo.orderPageRightColumn));
      } else {
        const newRightColumn: PageSection[] = response.userInfo.orderPageRightColumn.concat(defaultPageSections);
        localStorage.setItem('orderPageRightColumn', JSON.stringify(newRightColumn));
      }
    } 
    this.loadData(response.userInfo._id);
  } 

  loadData(userId: string): void {
    this.usersService.getOnLoginData(userId).subscribe((loadedData: ConfigDataResponse) => {
      if (!loadedData.success) {
        this.data.error(loadedData.message);
        return;
      }

      const actionConfigs: ActionCfg = {};
      if (loadedData.actions) {
        loadedData.actions.forEach((action: ActionConfig) => {
          const actionName = action.actionName;
          delete action.actionName;
          actionConfigs[actionName] = action;
        });
      }
      localStorage.setItem('actionConfigs', JSON.stringify(actionConfigs));

      const brandConfigs: BrandCfg = {};
      if (loadedData.brands) {
        loadedData.brands.forEach((website: Website) => {
          brandConfigs[website.title] = website
        });
      }
      localStorage.setItem('brandConfigs', JSON.stringify(brandConfigs));

      localStorage.setItem('outstandingNotificationCount', `${loadedData.notificationCount}`);

      localStorage.setItem('manualSendMessageTypes', JSON.stringify(loadedData.manualSendMessageTypes));
      if (loadedData.lookups) {
        loadedData.lookups.forEach((lookup: Lookup) => {
          localStorage.setItem(lookup.lookupName, JSON.stringify(lookup.lookupValues));
        });
      }
      const redirectTo: string = (this.route.snapshot.queryParams.redirectURL as string);
      if (redirectTo) {
        void this.router.navigateByUrl(redirectTo);
      } else {
        void this.router.navigate(['/']);
      }
    });
  }

  getTooltips(): void {
    this.tooltipService.getTooltips().subscribe((response: MultiRecordResponse<Tooltip>) => {
      if (response.success && response.data) {
        response.data.forEach(element => {
          const key = `${element.page}.${element.section}.${element.label}`
          localStorage.setItem(key, element.tooltip);
        });
      }
    }, (err: Error) => {
      console.log('Error while getting tooltips to store in localStorage :: ', err.message);
    })
  }

  setColumns(savedColumns: Column[], homepageColumns: Column[]) {
    if (!savedColumns || (savedColumns.length === 0)) {
      const defaultColumns: Column[] = homepageColumns.filter((col: Column) => col.hide != true);
      localStorage.setItem('defaultColumns', JSON.stringify(defaultColumns));
    } else {
      // Use the default's definitions else they do not show as checked in the p-multiSelect.
      // This also filters out saved columns no longer in the available list. 
      const colsToSave: Column[] = savedColumns.reduce<Column[]>((resultArray: Column[], savedColCfg: Column) => {
        const cfgToSave: Column|undefined = homepageColumns.find((defaultCfg: Column) => defaultCfg.field == savedColCfg.field);
        if (cfgToSave) {
          resultArray.push(cfgToSave);
        }
        return resultArray;
      }, []);
      localStorage.setItem('defaultColumns', JSON.stringify(colsToSave));
    }
  }

  setColumnsSet(savedColumnSets: SelectItem<string[]>[], homepageColumns: Column[]) {
    if (!savedColumnSets || (savedColumnSets.length === 0)) {
      localStorage.setItem('savedColumnSets', JSON.stringify([]));
    } else {
      // Use the default's definitions else they do not show as checked in the p-multiSelect.
      // This also filters out saved columns no longer in the available list. 
      const colSetsToSave: SelectItem<Column[]>[] = savedColumnSets.map<SelectItem<Column[]>>((colSet: SelectItem<string[]>) => {
        const colsForSet: Column[] = colSet.value.reduce<Column[]>((resultArray: Column[], savedColName: string) => {
          const cfgToSave: Column|undefined = homepageColumns.find((defaultCfg: Column) => defaultCfg.field == savedColName);
          if (cfgToSave) {
            resultArray.push(cfgToSave);
          }
          return resultArray;
        }, []);
        return {
          label: colSet.label,
          value: colsForSet,
        }
      }).sort((setA: SelectItem<Column[]>, setB: SelectItem<Column[]>) => 
        setA.label.localeCompare(setB.label)
      );
      localStorage.setItem('savedColumnSets', JSON.stringify(colSetsToSave));
    }
  }

  ngOnInIt() {
    this.title.setTitle('CRM Login');
    this.show2fa = false
  }

  showErrorPrompt(header: string, message: string) {
    this.confirmationService.confirm({
      key: 'general',
      header: header,
      message: message,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'OK',
      rejectVisible: false,
      accept: () => {},
    });
  }

  closeDialog() {
    this.show2fa = false
  }
  
}