<p-dialog (onHide)="closeDialog()" header="Email Note" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '75vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100">
  <datalist id="emailList">
    <option *ngFor="let emailAddress of emailAddresses" [value]="emailAddress.value">{{emailAddress.label}}</option>
  </datalist>
  <form [formGroup]="emailForm">
    <div class="row">
      <div *ngIf="resultMessage" class="col-12 form-group">
        <div class="alert" [ngClass]="resultSuccess? 'alert-success' : 'alert-danger'">
          {{resultMessage}}
        </div>
      </div>
      <div class="col-12 form-group">
        In addition to the text in the "Body" field the TD Code, customer name, a link to the order and your name will be sent.
      </div>
      <div class="col-6 form-group">
        <label for="emailAddress">Email Address:</label>
        <input type="search" list="emailList" class="form-control" name="emailAddress" id="emailAddress" formControlName="emailAddress" required="true"/>
      </div>
      <div class="col-6 form-group">
        <label for="emailSubject">Subject:</label>
        <input type="text" class="form-control" name="emailSubject" id="emailSubject" formControlName="emailSubject" required="true"/>
      </div>
      <div class="col-12 form-group">
        <label for="emailBody">Body (changes to the body will not update the note, just what is sent in the email):</label>
        <textarea name="emailBody" class="form-control" rows="15" formControlName="emailBody" required="true">
        </textarea>
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <button type="button" class="btn btn-warning" (click)="closeDialog()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary mr-2" [disabled]="!isOkToSend()" (click)="sendEmail()">
      Submit
    </button>
  </ng-template>
</p-dialog>