import {PageSection} from '../models/page-section.model';

const defaultLeftColumn: PageSection[] = [
  {
    'template': 'alarmUserDetailsSection',
    'minimized': false,
  },
  {
    'template': 'accountContactsSection',
    'minimized': false,
  },
  {
    'template': 'orderLinksSection',
    'minimized': false, // Own component
  },
  {
    'template': 'accountDetailsSection',
    'minimized': false,
  },
  {
    'template': 'renewalSection',
    'minimized': false,
  },
  {
    'template': 'initialOrderSection',
    'minimized': false, // Own component
  },
  {
    'template': 'initialDespatchSection',
    'minimized': false, // Own component
  },
];
const defaultRightColumn: PageSection[] = [
  {
    'template': 'statusSection',
    'minimized': false,
  },
  {
    'template': 'actionsSection',
    'minimized': false,
  },
  {
    'template': 'cancellationSection',
    'minimized': false, // Own component
  },
  {
    'template': 'notesSection',
    'minimized': false,
  },
  {
    'template': 'tagsSection',
    'minimized': false,
  },
  {
    'template': 'reviewsSection',
    'minimized': false, // Own component
  },
  {
    'template': 'jontekContactsSection',
    'minimized': false, // Own component
  },
  {
    'template': 'customerFeedbackSection',
    'minimized': false, // Own component
  },
];

export {
  defaultLeftColumn,
  defaultRightColumn
}
