import {Table} from 'primeng/table';
import {SelectItem} from 'primeng/api';
import {ConfirmationService} from 'primeng/api';
import {Group} from '../../../models/group.model';
import {Validators} from '@angular/forms';
import {UntypedFormBuilder} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {UsersService} from '../../users/users.service';
import {Component, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {filteredUsersByRole, sortByLabel} from '../../../helpers/helperFunctions';
import {User} from '../../../models/user.model';
import {UsersAndRolesResponse} from '../../../models/responses/usersAndRolesResponse.model';
import {MultiRecordResponse} from '../../../models/responses/multiRecordResponse.model';
import {SimpleResponse} from '../../../models/responses/simpleResponse.model';
import {SingleRecordResponse} from '../../../models/responses/singleRecordResponse.model';

@Component({
  selector: 'app-maintain-groups',
  templateUrl: './maintain-groups.component.html',
  styleUrls: ['./maintain-groups.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class MaintainGroupsComponent implements OnInit {

  constructor(
    private userService: UsersService,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private confirmationService: ConfirmationService,
  ) { }
  @Output() closeDialog = new EventEmitter();
  @ViewChild('dt', {static: true})
  table: Table;
  groups: Group[] = [];
  tableData: Group[] = [];
  display: boolean = true;
  newTag = {};
  isGroupAddButtonDisabled = false;
  users:SelectItem<string>[] = []
  displayMaintainUsersDialog = false;
  groupDataToMaintainUsers: Group = null;
  usersForMaintainUserDialog: string[] = [];
  addGroupForm = this.fb.group({
    name: ['', [Validators.required]],
    description:['',[Validators.required]]
  })
  cols = [
    { field: 'name', header: 'Group Name' },
    { field: 'description', header: 'Group Description' },
    { field: 'createdBy', header: 'Added By' },
    { field: 'createdAt', header: 'Added Timestamp' },
    { field: 'changedAt', header: 'Changed Timestamp' },
    { field: 'autoNotificationCount', header: 'Auto Notification' },
    { field: 'actions', header: 'Actions' }
  ];

  ngOnInit() {
    this.getGroups();
    this.getUsers();
  }

  get showMaintainUsers(): boolean {
    return (this.groupDataToMaintainUsers != null);
  }

  addGroup() {
    this.isGroupAddButtonDisabled = true;
    this.userService.addGroup({
      name: this.addGroupForm.get('name').value,
      description: this.addGroupForm.get('description').value,
      createdBy: localStorage.getItem('userName'),
    }).subscribe((response: SingleRecordResponse<Group>) => {
      this.isGroupAddButtonDisabled = false
      if (response.success) {
        this.addGroupForm.reset();
        this.getGroups();
        this.showSuccess();
      } else if (!response.success && response.errorCode == 11000) {
        this.showPopUp(
          'Duplicate group name',
          'A group already exists with this name. Please try another name'
        )
      } else {
        this.showErrorPopUp('Error adding group', response.message);
      }
    }, (err: Error) => {
      this.isGroupAddButtonDisabled = false;
      this.showErrorPopUp('Error adding group', err.message);
      this.addGroupForm.reset();
      console.log('An Exception is occured :: ', err);
    })
  }

  updateGroup(group: Group, index: number): void {
    const params: any = {
      description: group.description,
      name: group.name,
      groupId: group._id
    };

    if (group.name == this.groups[index].name && group.description == this.groups[index].description) {
      this.showPopUp(
        'You did not make any change',
        'Please change the value of group name or group description then update information'
      );
      return;
    };
    if (group.name != this.groups[index].name && group.users.length > 0) {
      const acceptCallback = () => {
        this.tableData[index].name = this.groups[index].name;
      }
      this.showPopUp(
        'Group name can not be changed',
        'Users are associated with this group so group name cannot be changed. If you wish to change the group name, first remove all users from it ',
        acceptCallback
      );
      return;
    }
    this.userService.updateGroup(params).subscribe((response: SimpleResponse) => {
      if (response.success) {
        this.getGroups();
        this.showSuccess();
      } else if (!response.success && response.errorCode == 11000) {
        this.tableData[index].name = this.groups[index].name;
        this.tableData[index].description = this.groups[index].description;
        this.showPopUp(
          'Duplicate group name',
          'A group already exists with this name. Please try another name'
        )
      } else {
        this.showErrorPopUp('Error updating group', response.message);
      }
    }, (err: Error) => {
      console.log('Error on :: ', err);
      this.tableData[index].name = this.groups[index].name;
      this.tableData[index].description = this.groups[index].description;
      this.showErrorPopUp('Error updating group', err.message);
    });
  }

  doesUserExist(group: Group, index: number): void {
    if (group.users.length == 0) {
      return;
    }
    const acceptCallback = () => {
      this.tableData[index].name = this.groups[index].name;
    };
    this.showPopUp(
      'Group name can not be changed',
      'Users are associated with this group so group name cannot be changed. If you wish to change the group name, first remove all users from it ',
      acceptCallback
    );
  }

  deleteGroup(group: Group): void {
    if (group.users.length > 0) {
      this.showPopUp(
        'Group can not be deleted.',
        'Users are associated with this group so it cannot be deleted. If you wish to delete the group, first remove all users from it'
      );
    } else if (group.users.length === 0) {
      const acceptCallback = () => {
        this.userService.deleteGroup(group._id).subscribe((response: SimpleResponse) => {
          if (response.success) {
            this.showSuccess();
            this.getGroups();
          } else {
            this.showErrorPopUp('Error deleting group', response.message);
          }
        }, (err: Error) => {
          this.showErrorPopUp('Error deleting group', err.message);
        });
      }
      this.showPopUp(
        'Delete Group',
        'Are you sure, you want to delete this group',
        acceptCallback,
        true,
        'Yes',
      );
    }
  }

  maintainUsers(group: Group) {
    this.groupDataToMaintainUsers = group;
    this.usersForMaintainUserDialog = [...this.groupDataToMaintainUsers.users.map((e: (string|User)) => (e as User)._id)];
  }

  saveUsers() {
    if (this.groupDataToMaintainUsers.autoNotificationCount> 0 && this.usersForMaintainUserDialog.length == 0) {
      this.showPopUp(
        'Group must have at least one user',
        'This group is associated to auto-notification table, it should have atleast one user. Please select a user then update'
      );
      return;
    }
    const params = {
      groupId:this.groupDataToMaintainUsers._id,
      userIds:this.usersForMaintainUserDialog,
    };
    console.log('Params on addUsersToGroup :: ', params);
    this.userService.addUsersToGroup(params).subscribe((response: SimpleResponse) => {
      // console.log('response :: ', response);
      if (response.success) {
        this.getGroups();
        this.showSuccess();
        this.groupDataToMaintainUsers = null;
        this.usersForMaintainUserDialog = null;
      } else {
        this.showErrorPopUp('Error saving users', response.message);
      }
    }, (err: Error) => {
      console.log('ERROR :: ', err);
      this.showErrorPopUp('Error saving users', err.message);
    })
  }

  closeMaintainUsersDialog() {
    this.groupDataToMaintainUsers = null;
    this.usersForMaintainUserDialog = null;
  }

  getGroups() {
    this.userService.getGroups().subscribe((response: MultiRecordResponse<Group>) => {
      if (response.success) {
        this.groups = response.data.map((group: Group) => {
          return {
            ...group
          }
        });
        this.tableData = response.data.map((group: Group) => {
          return {
            ...group
          }
        });
      }
    }, (err: Error) => {
      console.log('ERROR :: ', err);
    })
  }

  getUsers() {
    this.userService.getUsers().subscribe((response: UsersAndRolesResponse) => {
      if (response.success) {
        response.users = filteredUsersByRole(response.users);
        this.users = response.users.map((user: User) => {
          return {
            value: user._id,
            label: user.name
          }
        });
        this.users = sortByLabel(this.users);
      }
    }, (err: Error) => {
      console.log('Error while getting users in maintain group component :: ',);
    })
  }
  
  hideDialog() {
    this.closeDialog.emit();
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success',
      detail: 'Changes Successfuly Applied'
    });
  }

  showPopUp(header: string, message: string, acceptCallback?: () => void, rejectVisible: boolean = false, acceptLabel: string = 'OK') {
    this.confirmationService.confirm({
      message: message,
      header: header,
      icon: 'pi pi-info-circle',
      rejectVisible: rejectVisible,
      acceptLabel: acceptLabel,
      accept: () => {
        if (acceptCallback) {
          acceptCallback();
        }
      },
      reject: () => {
          
      }
    });
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp(header, message);
  }
}
