<div class="row m-0">
  <div class="col-1">
    <button type="button" id="exportSelectedRange" class="btn btn-primary m-3" (click)="exportPostInfoPackReport()" [disabled]="!exportButtonEnabled">
      Export
    </button>
  </div>
  <div class="col-11 text-right pt-2">
    <div class="dateInputs">
      <label class="load-logs" for="startDate">Added Between:</label>
      <input id="startDate" type="date" autocomplete="off" max="{{maxDateStr}}" [(ngModel)]="startDate" class="mx-2"/>
      <label class="load-logs" for="endDate">and:</label>
      <input id="endDate" type="date" autocomplete="off" max="{{maxDateStr}}" [(ngModel)]="endDate" class="mx-2"/>
    </div>
    <ng-container *ngIf="!loading else loadingBlock">
      <button type="button" (click)="loadReport()" class="btn btn-primary m-2" [disabled]="!(startDate && endDate)">
        Load Report
      </button>
      <button type="button" (click)="showChartDialog()" class="btn btn-primary m-2" [disabled]="!chartButtonEnabled">
        Show Chart
      </button>
    </ng-container>
  </div>
  <ng-template #loadingBlock>
    <div class="m-2 spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </ng-template>
  <div class="col-12">
    <p-table [value]="reportData" [columns]="cols">
      <ng-template pTemplate="header" let-columns>
          <tr>
            <ng-container *ngFor="let col of columns">
              <th>
                <div>{{col.header}}</div>
              </th>
            </ng-container>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <div [ngStyle]="{'width': col.field === 'spacer'? '25px': ''}">
              {{getRowData(rowData, col.field)}}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<app-post-sheet-infopack-chart *ngIf="showChart" [reportData]="reportData" (closeChart)="closeChart()"></app-post-sheet-infopack-chart>