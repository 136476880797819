import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {OverlayOptions, SelectItem} from 'primeng/api';
import {getMessageConfig} from '../../lookups/manualSendMessageTypes';
import {ProposedMessageService} from '../../messages-list/proposed-message.service';
import {ContactDetails} from '../../models/contactDetails.model';
import {Order} from '../../models/order.model';
import {OutstandingAction} from '../../models/outstandingAction.model';
import {ProposedMessage} from '../../models/proposedMessage.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {ukMobileNumberValidator} from '../../validators/phone-number.validator';
import {getContactFlags} from '../../helpers/helperFunctions';
import {OrderFormTag} from '../../models/orderFormTag.model';
import {PRICE_REGEX} from '../../helpers/getPlanCodeAndPrice';
import {DEFAULT_OVERLAY} from '../../lookups/primeng/overlayOptions';

@Component({
  selector: 'app-sms-send[order][tags][smsTypes][closeModal]',
  templateUrl: './sms-send.component.html',
  styleUrls: ['./sms-send.component.scss']
})
export class SmsSendComponent implements OnInit {
  @Input() order: Order; // Tags on definition don't match actual format, so have to send in separately
  @Input() tags: OrderFormTag[];
  @Input() smsTypes: SelectItem<string>[];
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  availableContactDetails: SelectItem<ContactDetails>[] = [];
  contactDetailsToPickFrom: SelectItem<ContactDetails>[] = [];
  contactForm: UntypedFormGroup;
  sendDisabled: boolean;
  selectedContactDetails: ContactDetails;
  resultMessage: string;
  resultSuccess: boolean;
  dialogVisible: boolean;
  hasBlockAlarmComms: boolean;
  overlayOptions: OverlayOptions = DEFAULT_OVERLAY;

  constructor(
    private proposedMessageService: ProposedMessageService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.sendDisabled = true;
    this.dialogVisible = true;
    this.prepareContacts();
    this.prepareCancellationTexts();
    this.hasBlockAlarmComms = this.tags.some((tag: OrderFormTag) => 
      tag.content == 'Block Alarm Correspondence'
    );
    
    this.contactForm = this.formBuilder.group({
      'smsTypeToSend': [null, Validators.required],
      'source': ['Manually Entered'],
      'firstName': [null, Validators.required],
      'lastName': [null, Validators.required],
      'reFirstName': [null],
      'reLastName': [null],
      'mobile': [null, ukMobileNumberValidator],
    });
    // Select the first type automatically
    if (this.smsTypes.length > 0) {
      this.contactForm.controls['smsTypeToSend'].setValue(this.smsTypes[0].value);
    }
    this.selectedTypeChange();
    if (this.contactDetailsToPickFrom.length > 1) {
      // Select the first contact on loading to save staff time if they want to use the default (most likely)
      this.selectedContactDetails = this.contactDetailsToPickFrom[0].value;
      this.selectedContactChange();
    }
    this.sendDisabled = false;
  }

  get reFirstName(): AbstractControl {
    return this.contactForm.get('reFirstName');
  }

  get reLastName(): AbstractControl {
    return this.contactForm.get('reLastName');
  }

  get areRegardingFieldsRequired(): boolean {
    return (getMessageConfig(this.contactForm.controls['smsTypeToSend'].value)?.regardingFields == 'required');
  }

  get areRegardingFieldsAllowed(): boolean {
    return ['optional', 'required'].includes(getMessageConfig(this.contactForm.controls['smsTypeToSend'].value)?.regardingFields);
  }

  get hasRegardingFirstName(): boolean {
    return !!this.reFirstName.value;
  }

  get hasRegardingLastName(): boolean {
    return !!this.reLastName.value;
  }

  get toUsersOnly(): boolean {
    return getMessageConfig(this.contactForm.controls['smsTypeToSend'].value)?.toUsersOnly;
  }

  get isRenewalWithNoPrice(): boolean {
    const emailType: string = this.contactForm.controls['smsTypeToSend'].value;
    if (emailType && emailType.toLocaleLowerCase().includes('renewal')) {
      const renewalPriceString: string = this.order.renewalInformation.renewalPrice;
      if (!renewalPriceString || !PRICE_REGEX.test(renewalPriceString)) {
        return true;
      }
      const renewalPrice: number = Number(renewalPriceString);
      return (renewalPrice <= 0);
    }
    return false;
  }

  prepareContacts(): void {
    // We don't need email or address for sending text messages
    if (this.order.alarmUserDetails.correspondenceDetails?.firstName || this.order.alarmUserDetails.correspondenceDetails?.lastName) {
      this.availableContactDetails.push({
        label: `Correspondent: ${this.order.alarmUserDetails.correspondenceDetails.firstName}` +
          `${this.order.alarmUserDetails.correspondenceDetails.lastName}; ${this.order.alarmUserDetails.correspondenceDetails.mobile}`,
        value: {
          'source': 'Correspondent',
          'firstName': this.order.alarmUserDetails.correspondenceDetails.firstName,
          'lastName': this.order.alarmUserDetails.correspondenceDetails.lastName,
          'email': '',
          'mobile': this.order.alarmUserDetails.correspondenceDetails.mobile,
          'address': [],
        }
      });
      // Don't allow user only types if there's a correspondent
      this.smsTypes = this.smsTypes.filter((smsType: SelectItem<string>) => 
        !getMessageConfig(smsType.value).toUsersOnly
      );
    } else {
      // Don't allow letters to alarm users if correspondent filled in
      this.availableContactDetails.push({
        label: `Alarm User: ${this.order.alarmUserDetails.firstName} ${this.order.alarmUserDetails.lastName}; ` +
          `${this.order.alarmUserDetails.mobile} ${getContactFlags(this.order.alarmUserDetails)}`,
        value: {
          'source': 'Alarm User',
          'firstName': this.order.alarmUserDetails.firstName,
          'lastName': this.order.alarmUserDetails.lastName,
          'email': '',
          'mobile': this.order.alarmUserDetails.mobile,
          'address': [],
        }
      });
      let userNumber: number = 1;
      this.order.alarmUserDetails.users.forEach(user => {
        this.availableContactDetails.push({
          label: `Additional User ${userNumber++}: ${user.firstName} ${user.lastName}; ${user.mobile} ${getContactFlags(user)}`,
          value: {
            'source': `Additional User ${userNumber++}`,
            'firstName': user.firstName,
            'lastName': user.lastName,
            'email': '',
            'mobile': user.mobile,
            'address': [],
          }
        });
      });
    }
    let contactNumber: number = 1;
    this.order.accountContacts.forEach(accountContact => {
      this.availableContactDetails.push({
        label: `Account Contact ${contactNumber++}: ${accountContact.accFirstName} ${accountContact.accLastName}; ` +
          `${accountContact.accMobile} ${getContactFlags(accountContact)}`,
        value: {
          'source': `Account Contact ${contactNumber++}`,
          'firstName': accountContact.accFirstName,
          'lastName': accountContact.accLastName,
          'email': '',
          'mobile': accountContact.accMobile,
          'address': [],
        }
      });
    });
    if ((this.order.status.status == 'cancelling') && this.order.cancellation && this.order.cancellation.personReturning) {
      this.availableContactDetails.push({
        label: `Cancellation Contact: ${this.order.cancellation.personReturning}`,
        value: {
          'source': 'Cancellation Contact',
          'firstName': this.order.cancellation.personReturning,
          'lastName': '',
          'email': '',
          'mobile': this.order.cancellation.telephoneNumber,
          'address': [],
        }
      });
    }
    const returningEquipAction: OutstandingAction = this.order.outstandingActions.find((action: OutstandingAction) => 
      action.outstandingName == 'Returning Equipment'
    );
    if (returningEquipAction && returningEquipAction.personReturning) {
      this.availableContactDetails.push({
        label: `Returning Equip Contact: ${returningEquipAction.personReturning}`,
        value: {
          'source': 'Returning Equip Contact',
          'firstName': returningEquipAction.personReturning,
          'lastName': '',
          'email': '',
          'mobile': returningEquipAction.contactNumber,
          'address': [],
        }
      });
    }
  }

  selectedContactChange(): void {
    if (this.selectedContactDetails) {
      this.contactForm.patchValue({
        'source': this.selectedContactDetails.source,
        'firstName': this.selectedContactDetails.firstName,
        'lastName': this.selectedContactDetails.lastName,
        'mobile': this.selectedContactDetails.mobile,
      });
    } else {
      this.contactForm.patchValue({
        'source': 'Manually Entered',
        'firstName': '',
        'lastName': '',
        'mobile': '',
      });
    }
  }

  selectedTypeChange(): void {
    let selectFirstContact: boolean = false;
    if (this.areRegardingFieldsAllowed) {
      this.contactForm.patchValue({
        'reFirstName': this.order.alarmUserDetails.firstName,
        'reLastName': this.order.alarmUserDetails.lastName,
      });
      this.contactDetailsToPickFrom = this.availableContactDetails.filter((contact: SelectItem<ContactDetails>) => 
        !contact.value.source.includes('User')
      );
      if (this.selectedContactDetails?.source.includes('User')) {
        selectFirstContact = true;
      }
    } else {
      this.contactForm.patchValue({
        'reFirstName': '',
        'reLastName': '',
      });
      if (this.toUsersOnly) {
        this.contactDetailsToPickFrom = this.availableContactDetails.filter((contact: SelectItem<ContactDetails>) => 
          contact.value.source.includes('User')
        );
        if (!this.selectedContactDetails?.source.includes('User')) {
          selectFirstContact = true;
        }
      } else {
        this.contactDetailsToPickFrom = this.availableContactDetails;
      }
    }
    // Have to do this rather than enable/disable fields as might be optional
    if (this.areRegardingFieldsRequired) {
      this.reFirstName.setValidators(Validators.required);
      this.reLastName.setValidators(Validators.required);
    } else {
      this.reFirstName.clearValidators();
      this.reLastName.clearValidators();
    }
    if (selectFirstContact && (this.contactDetailsToPickFrom.length == 0)) {
      this.selectedContactDetails = undefined;
      this.contactForm.patchValue({
        'source': 'Manually Entered',
        'firstName': '',
        'lastName': '',
        'mobile': '',
      });
    } else if (selectFirstContact || (this.contactDetailsToPickFrom.length == 1)) {
      this.selectedContactDetails = this.contactDetailsToPickFrom[0].value;
      this.contactForm.patchValue({
        'source': this.selectedContactDetails.source,
        'firstName': this.selectedContactDetails.firstName,
        'lastName': this.selectedContactDetails.lastName,
        'mobile': this.selectedContactDetails.mobile,
      });
    }
    this.reFirstName.updateValueAndValidity();
    this.reLastName.updateValueAndValidity();
  }

  sendSms() {
    this.resultMessage = '';
    this.sendDisabled = true;
    const params: ProposedMessage = {
      'brand': this.order.website.title,
      'messageType': this.contactForm.controls['smsTypeToSend'].value,
      'methods': ['text'],
      'tdCode': this.order.alarmUserDetails.tdCode,
      'orderId': this.order._id,
      'status': 'Ready to Send',
      'renewalDate': this.order.renewalInformation.renewalDate,
      'contactDetails': {
        'source': this.contactForm.controls['source'].value,
        'firstName': this.contactForm.controls['firstName'].value,
        'lastName': this.contactForm.controls['lastName'].value,
        'reFirstName': this.contactForm.controls['reFirstName'].value,
        'reLastName': this.contactForm.controls['reLastName'].value,
        'email': '',
        'mobile': this.contactForm.controls['mobile'].value,
        'address': [],
      },
    };
    this.proposedMessageService.addMessage(params).subscribe((response: SingleRecordResponse<ProposedMessage>) => {
      this.sendDisabled = false;
      if (response.success) {
        this.resultMessage = 'SMS queued for sending';
        this.resultSuccess = true;
      } else {
        this.resultMessage = `Error queuing SMS for sending ${response.message}`;
        this.resultSuccess = false;
      }
      
    }, (err: Error) => {
      this.sendDisabled = false;
      this.resultMessage = `Error queuing SMS for sending ${err.message}`;
      this.resultSuccess = false;
    });
  }

  isOkToSend(): boolean {
    return this.contactForm.valid && !this.sendDisabled &&
      // This part makes sure if one provided both are when regarding is optional
      (this.hasRegardingFirstName == this.hasRegardingLastName) &&
      !this.isRenewalWithNoPrice;
  }

  closeDialog() {
    this.resultMessage = '';
    this.closeModal.emit();
  }

  prepareCancellationTexts(): void {
    if (this.order.status.status != 'cancelling') {
      this.smsTypes = this.smsTypes.filter((smsType: SelectItem<string>) => 
        !smsType.value.startsWith('Cancellation')
      );
    }
  }
}
