<p-dialog (onHide)="closeDialog()" header="Send Email" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '90vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100">
  <div id="emailDialogBody">
    <form [formGroup]="contactForm">
      <div class="row">
        <div *ngIf="resultMessage" class="col-12 form-group">
          <div class="alert" [ngClass]="resultSuccess? 'alert-success' : 'alert-danger'">
            {{resultMessage}}
          </div>
        </div>
        <div *ngIf="hasBlockAlarmComms" class="col-12 form-group">
          <div class="alert alert-warning">
            This order has a "Block Alarm Correspondence" tag, which might indicate the customer is currently away.
          </div>
        </div>
        <div *ngIf="isRenewalWithNoPrice" class="col-12 form-group">
          <div class="alert alert-danger">
            This order has no renewal price, you must set a renewal price before you can send a renewal message.
          </div>
        </div>
        <div class="col-12 form-group">
          This form is for sending emails using one of the predefined templates, it cannot be used for general email communication.
        </div>
        <div class="col-6 form-group">
          <ng-container *ngIf="emailTypes.length === 1">
            <label for="emailType">Email Type:</label>
            <input type="text" class="form-control" name="emailType" id="emailType" formControlName="emailTypeToSend" readonly/>
          </ng-container>
          <ng-container *ngIf="emailTypes.length > 1">
            <label for="emailType">Email Type:</label>
            <p-dropdown [options]="emailTypes" name="emailType" id="emailType" formControlName="emailTypeToSend" 
              placeholder="Select an email type to send" [required]="true" (onChange)="selectedTypeChange()"
              [overlayOptions]="overlayOptions" [filter]="true">
            </p-dropdown>
          </ng-container>
        </div>
        <div class="col-6 form-group">
          <label for="contactDetails">Select contact to send email to, or enter details manually below</label>
          <p-dropdown [options]="contactDetailsToPickFrom" name="contactDetails" id="contactDetails" 
              [(ngModel)]="selectedContactDetails" [showClear]="true" [overlayOptions]="overlayOptions"
              (onChange)="selectedContactChange()" [ngModelOptions]="{standalone: true}" [filter]="true"
              placeholder="Optionally select an existing contact">
          </p-dropdown>
        </div>
        <div class="col-12 form-group">
          If you have selected an existing contact you can override some of their details below -
          this will not update the order and is just the details to use for this email.
        </div>
        <div class="col-6 form-group">
          <label for="firstName">First Name</label>
          <input type="text" class="form-control" name="firstName" id="firstName" formControlName="firstName" required/>
        </div>
        <div class="col-6 form-group">
          <label for="lastName">Last Name</label>
          <input type="text" class="form-control" name="lastName" id="lastName" formControlName="lastName" required/>
        </div>
        <ng-container *ngIf="areRegardingFieldsAllowed">
          <div class="col-6 form-group">
            <label for="reFirstName">Regarding First Name</label>
            <input type="text" class="form-control" name="reFirstName" id="reFirstName" formControlName="reFirstName"
              [required]="areRegardingFieldsRequired || hasRegardingLastName" />
          </div>
          <div class="col-6 form-group">
            <label for="reLastName">Regarding Last Name</label>
            <input type="text" class="form-control" name="reLastName" id="reLastName" formControlName="reLastName"
              [required]="areRegardingFieldsRequired || hasRegardingFirstName" />
          </div>
        </ng-container>
        <div class="col-12 form-group">
          <label for="email">Email Address</label>
          <input type="email" class="form-control" name="email" id="email" formControlName="email" required/>
        </div>
        <div *ngIf="isCancellationMessage" class="col-12 form-group">
          <label for="link">Cancellation Link</label>
          <input type="link" class="form-control" name="link" id="link" formControlName="link"/>
        </div>
        <div *ngIf="hasVariations" class="col-12 form-group">
          <label for="emailVariation">Select which type of email to send</label>
          <p-dropdown [options]="contactForm.controls['emailTypeToSend'].value | messageVariations" 
              name="emailVariation" id="emailVariation" formControlName="emailVariation" placeholder="Select email type"
              [required]="true" [overlayOptions]="overlayOptions" [filter]="true">
          </p-dropdown>
        </div>
        <ng-container *ngIf="arePaymentFieldsRequired">
          <div class="col-6 form-group">
            <label for="paymentFor">Payment For</label>
            <input type="text" class="form-control" name="paymentFor" id="paymentFor" formControlName="paymentFor" required/>
          </div>
          <div class="col-6 form-group">
            <label for="paymentAmount">Payment Amount</label>
            <input type="number" class="form-control" name="paymentAmount" id="paymentAmount" formControlName="paymentAmount"
                min="0" step="0.01" (keypress)="numberOnly($event, true)" required/>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-12 form-group text-right">
          <button type="button" class="btn btn-primary" (click)="sendEmail()" [disabled]="!isOkToSend()">Send</button>
          <button type="button" class="btn btn-warning ml-2" (click)="closeDialog()">Close</button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>