
<div class="container">
  <hr>
  <h1>List of Websites</h1>
  <hr>
  <app-message></app-message>

  <div class="row">
    <div class="col-1" *ngFor="let website of websites; let index = index;">
      <a routerLink="/websites/{{website._id}}">{{website.title}}</a>
    </div>
  </div>

  <hr>

  <div>
    <form [formGroup]="websiteForm" novalidate>
      <h2>{{formHeading}}</h2>
      <hr>
      <div>
        <div class="form-row">
          <div class="form-group col-12">
            <label>Website's Domain</label>
            <input type="text" name="newWebsite" class="form-control" formControlName="domain" >
          </div>
          <div class="form-group col-4">
            <label>Background</label>
            <input type="color" style="height: 33px;"  name="background" class="form-control" formControlName="background" >
          </div>
          <div class="form-group col-4">
            <label>Color</label>
            <input type="color" style="height: 33px;" name="color" class="form-control" formControlName="color" >
          </div>
          <div class="form-group col-4">
            <label>Title Bar Color</label>
            <input type="color" style="height: 33px;" name="titleBarColor" class="form-control" formControlName="titleBarColor">
          </div>
          <div class="form-group col-4">
            <label>Website's Title</label>
            <input type="text" name="newTitle" class="form-control" placeholder="The title is very important for the rest of the CRM functionality"
              formControlName="title" [readonly]="!allowBrandCodeChange">
          </div>
          <div class="form-group col-4">
            <label>Board Sort Order</label>
            <input type="number" style="height: 33px;" name="order" class="form-control" formControlName="order">
          </div>
          <div class="form-group col-4">
            <label>Accounting Code</label>
            <input type="text" style="height: 33px;" name="accountingCode" class="form-control" formControlName="accountingCode">
          </div>
          <div class="form-group col-12">
            <label>Website Backend</label>
            <p-dropdown [options]="websiteBackends" name="usingWebBackend" id="usingWebBackend" formControlName="usingWebBackend"
              placeholder="Website Backend" [required]="true" [overlayOptions]="overlayOptions">
            </p-dropdown>
          </div>
          <div class="form-group col-4">
            <label>Currency Code</label>
            <input type="text" style="height: 33px;" name="currencyCode" class="form-control" formControlName="currencyCode">
          </div>
          <div class="form-group col-4">
            <label>Business Central Lifeline24 Brand Code</label>
            <input type="text" style="height: 33px;" name="businessCentralLLBrandCode" class="form-control" formControlName="businessCentralLLBrandCode">
          </div>
          <div class="form-group col-4">
            <label>Business Central Careline365 Brand Code</label>
            <input type="text" style="height: 33px;" name="businessCentralCLBrandCode" class="form-control" formControlName="businessCentralCLBrandCode">
          </div>
        </div>
            
        <div class="formsect" formGroupName="api">
          <div class="form-row">
            <div class="form-group col-12"><h3>WooCommerce Settings:</h3></div>
            <div class="form-group col-12">
              <label>Website's URL</label>
              <input type="text" name="url" class="form-control" formControlName="url" >
            </div>
            <div class="form-group col-6">
              <label>Secret Code</label>
              <input type="text" name="secret" class="form-control" formControlName="secret" >
            </div>
            <div class="form-group col-6">
              <label>Api Key</label>
              <input type="text" name="key" class="form-control" formControlName="key" >
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">Gravity Forms API Settings:</div>
            <div class="form-group col-6">
              <label>Public API Key</label>
              <input type="text" name="apiKeyGF" class="form-control" formControlName="apiKeyGF" >
            </div>
            <div class="form-group col-6">
              <label>Private API Key</label>
              <input type="text" name="privateKeyGF" class="form-control" formControlName="privateKeyGF" >
            </div>
          </div>
        </div>
        <div class="formsect" formGroupName="shopify">
          <div class="form-row">
            <div class="form-group col-12"><h3>Shopify Settings:</h3></div>
            <div class="form-group col-12">
              <label>Shopify Store Domain</label>
              <input type="text" name="storeDomain" class="form-control" formControlName="storeDomain" >
            </div>
            <div class="form-group col-4">
              <label>Shopify Access Token</label>
              <input type="text" name="accessToken" class="form-control" formControlName="accessToken" >
            </div>
            <div class="form-group col-4">
              <label>Shopify Api Version</label>
              <input type="text" name="apiVersion" class="form-control" formControlName="apiVersion" >
            </div>
            <div class="form-group col-4">
              <label>Shopify API Secret</label>
              <input type="text" name="apiSecret" class="form-control" formControlName="apiSecret" >
            </div>
          </div>
        </div>

        <button class="btn btn-primary" type="submit" name="action" (click)="submitForm()" [disabled]="!isOkToSubmit">
          {{action}}
        </button>
      </div>
    </form>

    <ng-container *ngIf="!!id">
      <hr>
      <h2>Import Products</h2>
      <div>
        <label for="skus">SKUS to Import (one SKU per line)</label>
        <textarea [(ngModel)]="skus" rows="5" id="skus"  class="form-control">
        </textarea>
        <div class="mt-1">
          <button type="button" class="btn btn-primary" (click)="importSpecificProductsForWebsite()" [disabled]="skus.trim() === ''">
            Import Specific SKUs
          </button>
        </div>
      </div>
      <hr>
      <div>
        Please note that Import New Products should only be used in exceptional circumstances. 
        It will import all products for the site that do not already exist in the CRM. 
        Items in the "Renewal" category or are zero priced items with no variations will be
        excluded. Depending on what is on the alarm site this could bring in a lot of unncessary products.
      </div>
      <div class="mt-1">
        <button type="button" class="btn btn-primary" (click)="importNewProducts()">
          Import New Products
        </button>
      </div>
    </ng-container>
  </div>
</div>
<p-toast></p-toast>