<div class="formbar row no-gutters">
  <div class="col-12">
    Order Links
    <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
      <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
      <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
    </button>
  </div>
</div>
<div class="formsect">
  <div [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
    <div class="row">
      <div class="col-4 col-lg-4 col-xl-4 form-group">
        <label>Enter td code to link order</label>
        <input type="text" class="noAutoSave" id="tdCode" name="tdCodeToLinkOrder" [(ngModel)]="tdCodeToLinkOrder" [ngModelOptions]="{standalone:true}">
      </div>
      <div class="col-6 col-lg-4 col-xl-6 form-group">
        <label>Reason</label>
        <input type="text" class="noAutoSave" id="linkReason" name="linkReason" [(ngModel)]="linkReason" [ngModelOptions]="{standalone:true}">
      </div>
      <div class="col-2 col-lg-4 col-xl-2 form-group">
        <label></label>
        <button type="button" class="noAutoSave btn btn-primary btn-sm" (click)="addOrderLink()" [disabled]="isAddLinkDisabled">
          Link Orders
        </button>
      </div>
    </div>
    <div class="subsectionHeading">
      Linked Orders
    </div>
    <div *ngIf="tdCode" class="row">
      <div class="col-12">
        <div class="row form-group linkHeader">
          <div class="col-4 col-lg-4 col-xl-4">TD Code</div>
          <div class="col-6 col-lg-4 col-xl-6">Reason</div>
        </div>
        <div class="row form-group orderLink" *ngFor="let orderLink of orderLinks">
          <div class="col-4 col-lg-4 col-xl-4">
            <ng-container *ngIf="orderLink.fromTdCode === tdCode; else reverseLink">
              <a routerLink="/order/{{orderLink.to}}" target="_blank">{{orderLink.toTdCode}}</a>
            </ng-container>
            <ng-template #reverseLink>
              <a routerLink="/order/{{orderLink.from}}" target="_blank">{{orderLink.fromTdCode}}</a>
            </ng-template>
          </div>
          <div class="col-6 col-lg-4 col-xl-6">
            {{orderLink.reason}}
          </div>
          <div class="col-2 col-lg-4 col-xl-2">
            <button type="button" class="btn btn-danger btn-sm" (click)="deleteLinkedOrder(orderLink._id)">
              Remove Link
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
