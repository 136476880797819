<section id="settings">
  <div class="container p-5">
    <h4 class="display-4">My Account Settings</h4>
    <hr>
    <app-message></app-message>
    <div *ngIf="!currentSettings" class="m-auto">
      <h1 class="text-center display-3 mt-5">
        <i class="fa fa-spinner fa-spin"></i>
      </h1>
    </div>
    <div *ngIf="currentSettings" class="card bg-light">
      <div class="card-body">
        <div id="errrorpass" *ngIf="unsafePassword">
          <h3>Your Password is not safe!</h3>
          <ul>
            <li>[a-z]	The password must contain at least 1 lowercase alphabetical character</li>
            <li>[A-Z]	The password must contain at least 1 uppercase alphabetical character</li>
            <li>[0-9]	The password must contain at least 1 numeric character</li>
            <li>[&#64;!$%^&*] The password must contain at least one special character</li>
            <li>The password must be a minimum of 12 characters</li>
          </ul>
        </div>
        <form>
          <div class="form-group">
            <label class="form-control-label">Name</label>
            <input type="text" name="name" class="form-control" [(ngModel)]="currentSettings.name" readonly>
          </div>
          <div class="form-group">
            <label class="form-control-label">Email</label>
            <input type="email" name="email" class="form-control" [(ngModel)]="currentSettings.email" readonly>
          </div>
          <div class="form-group">
            <label class="form-control-label">New Password</label>
            <input type="password" name="newPwd" class="form-control" [(ngModel)]="currentSettings.password">
          </div>
          <div class="form-group">
            <label class="form-control-label">Confirm Password</label>
            <input type="password" name="pwdConfirm" class="form-control" [(ngModel)]="currentSettings.confirmPassword">
          </div>
          <div class="buttons">
            <button type="button" class="btn btn-warning ml-2" (click)="cancel()">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="update()" [disabled]="btnDisabled">Update</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
