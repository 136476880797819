import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {PostOrderComponent} from './post-order.component';

@Injectable()
export class PostOrderDeactivateGuard  {

  constructor() { }
    canDeactivate(component: PostOrderComponent, _currentRoute: ActivatedRouteSnapshot, _currentState: RouterStateSnapshot, 
      nextState?: RouterStateSnapshot): boolean {
    return component.isOkToDeactivate(false, nextState? nextState.url: '');
  }

}

export const PostOrderDeactivateGuardFn: CanDeactivateFn<PostOrderComponent> = (component: PostOrderComponent, next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PostOrderDeactivateGuard).canDeactivate(component, next, state);
}
