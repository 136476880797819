import {Component, OnInit, ViewChild} from '@angular/core';
import {WebsiteService} from "../websites/website.service";
import {ActivatedRoute, Params} from "@angular/router";
import {CouponsService} from "./coupons.service";
import {WebsitesResponse} from '../../models/responses/websitesResponse.model';
import {Website} from '../../models/website.model';
import {Title} from '@angular/platform-browser';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {ConfirmationService} from 'primeng/api';
import {getErrorMessage, isValidObjectId} from '../../helpers/helperFunctions';
import {Coupon} from '../../models/coupon.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {Column} from '../../models/column.model';
import {ShopifyDiscount} from '../../models/shopifyDiscount.model';
import {RefreshStatus} from '../../models/responses/refreshStatus.model';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
  providers: [ConfirmationService]
})
export class CouponsComponent implements OnInit {
  websites: Website[];
  webId: string;
  selectedWebsite: Website
  coupons: Coupon[] = [];
  couponColumns: Column[] = [{
    'field': 'code',
    'header': 'Code',
  }, {
    'field': 'type',
    'header': 'Type',
  }, {
    'field': 'amount',
    'header': 'Amount',
  }, {
    'field': 'description',
    'header': 'Description',
  }];
  codes: string = '';
  shopifyDiscounts: ShopifyDiscount[] = [];
  discountColumns: Column[] = [{
    'field': 'code',
    'header': 'Code',
  }, {
    'field': 'valueType',
    'header': 'Amount Type',
  }, {
    'field': 'discount',
    'header': 'Amount',
  }, {
    'field': 'discountType',
    'header': 'Discount Type',
  }, {
    'field': 'shortSummary',
    'header': 'Description',
  }, {
    'field': 'oncePerOrder',
    'header': 'Once Per Order',
  }, {
    'field': 'minimumOrderValue',
    'header': 'Minimum Order Value',
  }, {
    'field': 'startDate',
    'header': 'Start Date',
  }, {
    'field': 'expiryDate',
    'header': 'Expiry Date',
  }];
  showRefreshResponse: boolean;
  refreshResponses: RefreshStatus[] = [];
  refreshColumns: Column[] = [{
    'field': 'identifier',
    'header': 'Code',
  }, {
    'field': 'status',
    'header': 'Status',
  }];
  @ViewChild('couponTable', {static: false})
  couponTable: Table;
  @ViewChild('discountTable', {static: false})
  discountTable: Table;
  @ViewChild('discountRefreshTable', {static: false})
  discountRefreshTable: Table;

  constructor(
    private websiteService: WebsiteService,
    private couponsService: CouponsService,
    private route: ActivatedRoute,
    private title: Title,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit() {
    this.showRefreshResponse = false;
    this.websiteService
      .getWebsites()
      .subscribe({
        next: (websiteResponse: WebsitesResponse) => {
          this.websites = websiteResponse.websites;
          if (this.webId) {
            this.selectedWebsite = this.websites.find((website: Website) => website._id == this.webId);
            this.loadCouponsOrDiscounts();
          }
        }, 
        error: (err: any) => {
          console.log(err);
        }
      });
    this.route.params.subscribe((params: Params) => {
      this.webId = params.id;
      if (!this.webId || !isValidObjectId(this.webId)) {
        this.selectedWebsite = undefined;
      } else {
        if (this.websites && (this.websites.length > 0)) {
          this.selectedWebsite = this.websites.find((website: Website) => website._id == this.webId);
          this.loadCouponsOrDiscounts();
        }
      }
    });
    this.title.setTitle('CRM Coupons');
  }

  loadCouponsOrDiscounts() {
    if (!this.selectedWebsite) {
      return;
    }
    this.coupons = [];
    this.shopifyDiscounts = [];
    if (this.selectedWebsite.usingWebBackend == 'WooCommerce') {
      this.couponsService.getCouponsByWebsite(this.webId).subscribe({
        next: (couponResponse: MultiRecordResponse<Coupon>) => {
          this.coupons = couponResponse.data;
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    } else {
      this.couponsService.getShopifyDiscounts(this.webId).subscribe({
        next: (shopifyDiscountResponse: MultiRecordResponse<ShopifyDiscount>) => {
          this.shopifyDiscounts = shopifyDiscountResponse.data;
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    }
  }

  updateCoupons(id: string) {
    this.websiteService.updateCouponsWebsite(id).subscribe({
      next: (response: SimpleResponse) => {
        if (response.success) {
          this.showInfoPopUp('Refreshing Coupons...', response.message);
        } else {
          this.showErrorPopUp('Error Refreshing Coupons', 
            'There was an error refreshing coupons, please try again. Error: ' + 
            response.message);
        }
      }, 
      error: (err: any) => {
        this.showErrorPopUp('Error Refreshing Coupons', 
          'There was an error refreshing coupons, please try again. Error: ' + 
          getErrorMessage(err));
        console.log('Error refreshing coupons', err);
      }
    });
  }

  refreshDiscounts() {
    this.showRefreshResponse = false;
    if (!isValidObjectId(this.webId)) {
      return;
    }
    if (!this.codes && !this.codes.trim()) {
      this.showErrorPopUp('No Codes Supplied', 'You must specify the codes to import/refresh.');
    }
    this.couponsService.refreshShopifyDiscounts(this.webId, this.codes.split('\n')).subscribe({
      next: (response: MultiRecordResponse<RefreshStatus>) => {
        if (!response.success) {
          this.showErrorPopUp('Error Importing/Refreshing Discounts', `Error importing/refreshing discounts ${response.message}`);
        } else {
          this.refreshResponses = response.data;
          this.showRefreshResponse = true;
          this.loadCouponsOrDiscounts();
        }
      },
      error: (err: any) => {
        this.showErrorPopUp('Error Importing/Refreshing Discounts', `Error importing/refreshing discounts ${err.message}`);
      }
    });
  }

  showInfoPopUp(header: string, message: string) {
    this.showPopUp(header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string) {
    this.showPopUp(header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(header: string, message: string, icon: string) {
    this.confirmationService.confirm({
      key: 'general',
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  applyCouponFilter($event: Event, field: string, filterType: string): void {
    this.couponTable.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  applyDiscountFilter($event: Event, field: string, filterType: string): void {
    this.discountTable.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  applyDiscountRefreshFilter($event: Event, field: string, filterType: string): void {
    this.discountRefreshTable.filter(($event.target as HTMLInputElement).value, field, filterType);
  }
}
