import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {getBrandBackgroundColour, getBrandCodes} from '../../lookups/brands';
import {getInfoPackWhatToSendOptions, WhatToSend} from '../../lookups/postSheet';
import {PostsheetReportData} from '../../models/reporting/postsheetReportData.model';

@Component({
  selector: 'app-post-sheet-infopack-chart',
  templateUrl: './post-sheet-infopack-chart.component.html',
  styleUrls: ['./post-sheet-infopack-chart.component.scss']
})
export class PostSheetInfopackChartComponent implements OnInit {

  constructor() { }
  @Input() reportData: PostsheetReportData[];
  @Output() closeChart: EventEmitter<void> = new EventEmitter<void>();
  indexedReportData: {[what: string]: PostsheetReportData} = {};
  data: any;
  displayModal: boolean = true;
  whatToSendOptions: WhatToSend[]; 
  
  ngOnInit() {
    this.displayModal = true;
    this.reportData.forEach((dataItem: PostsheetReportData) => {
      this.indexedReportData[dataItem.whatToSend] = dataItem;
    });

    this.data = {
      labels: [],
      datasets: [],
    };
    this.whatToSendOptions = getInfoPackWhatToSendOptions();
    /* const totalDataSet = {
      label: 'Total',
      //borderColor: '#',
      borderWidth: 0,
      data: [],
    };*/
    this.whatToSendOptions.forEach((what: WhatToSend) => { 
      this.data.labels.push(
        what.label,
      );
      /*if (this.indexedReportData[what.label]) {
        totalDataSet.data.push(this.indexedReportData[what.label].total);
      } else {
        totalDataSet.data.push(0);
      }*/
    });
    // this.data.datasets.push(totalDataSet);
    
    getBrandCodes().forEach((brandCode: string) => {
      const infopackDataSet = {
        label: brandCode,
        backgroundColor: getBrandBackgroundColour(brandCode),
        borderWidth: 0,
        data: [],
      };
      this.whatToSendOptions.forEach((what: WhatToSend) => {
        if (this.whatToSendOptions && this.indexedReportData[what.label] && this.indexedReportData[what.label].breakdownByBrand[brandCode]) {
          infopackDataSet.data.push(this.indexedReportData[what.label].breakdownByBrand[brandCode]);
        } else {
          infopackDataSet.data.push(0);
        }
      });
      this.data.datasets.push(infopackDataSet);
    });
  };


  closeDialog(): void {
    this.displayModal = false;
    this.closeChart.emit();
  }

}
