<div class="mb-xl-2"></div>
<div class="container-fluid" id="hardwareAuditTable">
  <p-table #auditTable [value]="hardwareAuditRecords" [paginator]="true" [rows]="30"
      [sortField]="'createdAt'" [sortOrder]=-1 [sortMode]="'single'"
      (sortFunction)="customSort($event)" [customSort]="true"
  >
    <ng-template pTemplate="caption">
      <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
      <label class="load-audit" for="startDate">Changes Between:</label>
      <input id="startDate" type="date" autocomplete="off" #startDate min="{{minDateStr}}" max="{{maxDateStr}}" value="{{defaultStartDateStr}}"/>
      <label class="load-audit" for="endDate">and:</label>
      <input id="endDate" type="date" autocomplete="off" #endDate min="{{minDateStr}}" max="{{maxDateStr}}" value="{{maxDateStr}}"/>
      <span class="load-audit" *ngIf="!recordsLoading else recordsLoadingBlock">
        <button type="button" class="btn btn-primary" (click)="loadAuditRecords(startDate.value, endDate.value)" >Load Changes</button>
      </span>
      <ng-template #recordsLoadingBlock>
        <div class="load-audit spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </ng-template>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of cols">
          <th [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" 
              ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
        </ng-container>
      </tr>
      <tr>
        <th *ngFor="let col of cols">
          <ng-container [ngSwitch]="col.field">
            <ng-container *ngSwitchCase="'createdAt'">
              <div>
                <p-calendar [appendTo]="'body'" [selectionMode]="'range'" [readonlyInput]=true [dateFormat]="'dd/mm/yy'"
                    [(ngModel)]="dateFilters" (onSelect)="auditTable.filter(dateFilters, col.field, 'date-range')"
                    (onClearClick)="auditTable.filter(dateFilters, col.field, 'date-range')" [yearRange]=filterYearRange
                    [monthNavigator]=true [yearNavigator]=true [showButtonBar]=true [keepInvalid]=false
                    [minDate]=filterMinDate [maxDate]=filterMaxDate [numberOfMonths]="1"
                    [showOtherMonths]=true [selectOtherMonths]=true [showIcon]=true 
                    [style]="{width: '100%'}" [inputStyle]="{width: '100%'}">
                </p-calendar>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'websiteId'">
              <p-multiSelect [options]="brands" [(ngModel)]="selectedBrand" placeholder="Brand"
                  filterPlaceHolder="brand" scrollHeight="600px" [maxSelectedLabels]=0
                  selectedItemsLabel="{0} selected" [style]="{'display':'flex'}" 
                  (onChange)="auditTable.filter($event.value, col.field, 'brand-website')" >
                <ng-template let-Pbrand let-i="index" pTemplate="item">
                  <div class="brandBar" [ngStyle]="{'background-color': brandConfigs[Pbrand.value].background, 'color':brandConfigs[Pbrand.value].color}">
                    &nbsp;{{Pbrand.value}}
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-container>
            <ng-container *ngSwitchCase="'hardwareOrSet'">
              <input pInputText type="search" (input)="applyFilter($event, col.field, 'hardware-or-set-title')" style="width: 100%;" />
            </ng-container>
            <ng-container *ngSwitchCase="'changedBy'">
              <input pInputText type="search" (input)="applyFilter($event, col.field, 'startsWith')" style="width: 100%;" />
            </ng-container>
            <ng-container *ngSwitchDefault>
              <input pInputText type="search" (input)="applyFilter($event, col.field, 'contains')" style="width: 100%;" />
            </ng-container>
          </ng-container>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-auditRecord>
      <tr>
        <td>{{auditRecord.createdAt | date:'dd/MM/yyyy HH:mm'}}</td>
        <td>
          <div class="brandBar" [ngStyle]="{
            'background-color': brandConfigs[auditRecord.websiteId.title].background, 'color':brandConfigs[auditRecord.websiteId.title].color
          }">
            {{auditRecord.websiteId.title}}
          </div>
        </td>
        <td>{{auditRecord.hardwareOrSet.title}}</td>
        <td>{{auditRecord.fieldChanged}}</td>
        <td>{{auditRecord.changedFrom}}</td>
        <td>{{auditRecord.changedTo}}</td>
        <td>{{auditRecord.changedBy}}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="summary">
      There are {{hardwareAuditRecords?.length}} changes
    </ng-template>

  </p-table>
</div>
<p-toast></p-toast>