import {SelectItem} from 'primeng/api';

const cancellationCategoriesRequiringPrompt: string[] = [
  'RIP', 'Care'
];

interface CancellationCategorySelectItem extends SelectItem<string> {
  'requiresFreeText': boolean;
  'emailTemplate': string;
}

interface CancellationReasonSelectItem extends CancellationCategorySelectItem {
  'requiresContactDetails': boolean;
}

let cancellationCategorySelectItems: CancellationCategorySelectItem[];
function getCancellationReasonCategories(activeOnly: boolean): CancellationCategorySelectItem[] {
  if (!cancellationCategorySelectItems) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('cancellation reason: categories')) {
      return [];
    }
    cancellationCategorySelectItems = JSON.parse(localStorage.getItem('cancellation reason: categories')) || [];
  }
  if (activeOnly) {
    return cancellationCategorySelectItems.filter((categorySelect: CancellationCategorySelectItem) =>
      !categorySelect.disabled
    );
  }
  return cancellationCategorySelectItems;
}

let cancellationReasonsByCategory: {[category: string]: CancellationReasonSelectItem[]} = {};
function getCancellationReasonsForCatgory(category: string): CancellationReasonSelectItem[] {
  if (!category) {
    return [];
  }
  if (!cancellationReasonsByCategory[category]) {
    const lookupKey: string = `cancellation reason: ${category}`;
    // Handle requests to get details before login is complete
    if (!localStorage.getItem(lookupKey)) {
      return [];
    }
    cancellationReasonsByCategory[category] = JSON.parse(localStorage.getItem(lookupKey)) || [];
  }
  return cancellationReasonsByCategory[category];
}

export {
  CancellationCategorySelectItem,
  CancellationReasonSelectItem,
  cancellationCategoriesRequiringPrompt,
  getCancellationReasonCategories,
  getCancellationReasonsForCatgory,

}
