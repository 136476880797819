<div class="outstanding-list">
  <p-table 
  #outstandingActionsTableRef *ngIf="!loading" 
  [value]="outstandingActions" 
  [columns]="cols" 
  [globalFilterFields]="searchableFields" 
  [paginator]="true" 
  [rows]="15" (onFilter)="onFilter($event)"
  sortMode="multiple" [multiSortMeta]="multiSortMeta" [showCurrentPageReport]="true" 
  currentPageReportTemplate="Page {currentPage} of {totalPages} - Feedback {first} to {last} of {totalRecords}"
  >
    <ng-template pTemplate="caption">
      <div class="container-fluid py-2">
        <div class="row px-4 text-center">
          <div *ngIf="outstandingActions.length > 0" class="col-3 py-2 actionTitle"
              [style.background-color]="getActionBackgroundColour(actionType, outstandingActions[0].outstandingColor)">
            <span [style.color]="getActionColour(actionType)">
              {{actionType}}
            </span>
          </div>
          <div class="col-6 py-2 filter">
            <i class="fa fa-search"></i>
            <input type="search" pInputText size="50" placeholder="Global Filter" (input)="globalFilter($event, 'contains')" style="width:90%">
          </div>
          <div class="col-3 py-2">
            <div *ngIf="!loading && (outstandingActions.length > 0)" ngbDropdown class="d-inline-flex">
              <button type="button" id="exportMessages" ngbDropdownToggle class="ml-2 btn btn-primary" aria-controls="exportMessageControl">
                Export
              </button>
              <div ngbDropdownMenu aria-labelledby="exportFeedback" id="exportFeedbackOptions">
                <button ngbDropdownItem type="button" (click)="export(ExportOptions.ALL)">
                  All Loaded Records
                </button>
                <button ngbDropdownItem type="button" (click)="export(ExportOptions.FILTERED)">
                  Filtered Records
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="!col.hide" [ngSwitch]="col.field">
            <div *ngSwitchCase="'brand'">
              <!-- {{col.header}} -->
              <p-multiSelect 
                [options]="brands" 
                [(ngModel)]="selectedBrands" 
                placeholder="BRANDS"
                styleClass="brandcss"
                [panelStyle]="{minWidth:'12em'}"
                scrollHeight="600px"
                [maxSelectedLabels]="0"
                selectedItemsLabel="{0} selected"
                (onChange)="outstandingActionsTableRef.filter($event.value, 'brand', 'in')" 
                [style]="{'display':'flex'}"
                filterPlaceHolder="brand"
                >
              <ng-template let-Pbrand let-i="index" pTemplate="item">
                <div class="brandBar" [ngStyle]="{'background-color': brandConfigs[Pbrand.label].background, 'color':brandConfigs[Pbrand.label].color}">
                  &nbsp;{{Pbrand.label}}
                </div>
              </ng-template>
              </p-multiSelect>
            </div>
            <div *ngSwitchCase="'status'">
              <!-- {{col.header}} -->
              <p-multiSelect
              [options]="statuses"
              [(ngModel)]="selectedStatus"
              placeholder="STATUS"
              [panelStyle]="{minWidth:'12em'}"
              scrollHeight="600px"
              [maxSelectedLabels]=0
              selectedItemsLabel="{0} selected"
              [style]="{'display':'flex'}"
              filterPlaceHolder="status"
              (onChange)="outstandingActionsTableRef.filter($event.value,'status','in')"
              >
              <ng-template let-PStatus let-i="index" pTemplate="item">
                <div class="brandBar" [style.background-color]="statusConfigs[PStatus.value].background" 
                    [style.color]="statusConfigs[PStatus.value].color">
                  &nbsp;{{PStatus.label}}
                </div>
              </ng-template>
              </p-multiSelect>
            </div>
            <div *ngSwitchCase="'actionDueDate'">
              {{col.header}}
              <p-calendar *ngIf="dueDateFilterAllowed" [(ngModel)]="dueDateFilters" appendTo="body" selectionMode="range"
                [readonlyInput]="true" dateFormat="dd/mm/yy" (onSelect)="outstandingActionsTableRef.filter($event, col.field, col.field)"
                [monthNavigator]=true [yearNavigator]=true [showButtonBar]=true [keepInvalid]=true
                [yearRange]="filterYearRange" [numberOfMonths]="1" [showOtherMonths]=true [selectOtherMonths]=true [showIcon]=true
                [inputStyle]="{maxWidth:'100px'}" [hideTransitionOptions]="'0ms'"
                (onClearClick)="clearDueDateFilters()">
              </p-calendar>
            </div>

            <div *ngSwitchDefault>{{col.header}}</div>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData" >
        <ng-container *ngFor="let col of columns">
          <td *ngIf="!col.hide" [ngSwitch]="col.field">
            <div *ngSwitchCase="'actionDueDate'">
              {{rowData[col.field] | date:'dd/MM/y HH:mm'}}
              <ng-container *ngIf="showStatus">
                <br/>
                {{rowData['actionStatus']}}
              </ng-container>
            </div>
            <div *ngSwitchCase="'actionInitiatedDate'">
              {{rowData[col.field] | date:'dd/MM/y HH:mm'}}
            </div>
            <div *ngSwitchCase="'brand'">
              <div [ngStyle]="{'width':'100%' ,'background-color': getBrandBackgroundColour(rowData['brand']), 'color': getBrandColour(rowData['brand']) }">
                {{rowData['brand']}}
              </div>
            </div>
            <div *ngSwitchCase="'orderDate'">
              {{rowData[col.field] | date:'dd/MM/y'}}
            </div>
            <div *ngSwitchCase="'tdCode'">
              <a (click)="getOtherOutstandingActions(rowData)" target="_blank" class="linkcolour">
                {{rowData[col.field]}}
                <div *ngIf="isOrderLocked(rowData.orderId)">
                  {{ orderLockedBy(rowData.orderId) }}
                </div>
              </a>
            </div>
            <div *ngSwitchCase="'backgroundAutoTestCall'" [ngStyle]="{'background-color':rowData['backgroundAutoTestCallColor']}">
              {{rowData[col.field] | date:'dd/MM/y HH:mm'}}
            </div>
            <div *ngSwitchCase="'lastManualActivationTime'" [ngStyle]="{'background-color':rowData['lastManualActivationTimeColor']}">
              {{rowData[col.field] | date:'dd/MM/y HH:mm'}}
            </div>
            <div *ngSwitchCase="'status'"
                [style.background-color]="getStatusBackgroundColour(rowData[col.field])" 
                [style.color]="getStatusColour(rowData[col.field])">
              {{rowData[col.field]}}
            </div>
            <span *ngSwitchCase="'tagNames'">
              <div *ngFor="let tag of rowData['tags'] ; let i = index " [ngStyle]="{'background-color': tag.color, 'font-weight':'bold'}">
                <p> * {{tag.tagName}}</p>
              </div>
            </span>
            <div *ngSwitchDefault>
              {{rowData[col.field]}}
            </div>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>
<div class="d-flex justify-content-center align-items-center" *ngIf="loading">
  <p-progressSpinner></p-progressSpinner>
</div>

<p-dialog *ngIf="showOtherOutstandingActions" class="other-outstanding-actions" header="Other Outstanding actions on this order"
  [(visible)]="showOtherOutstandingActions" [modal]="true" [style]="{width: '50vw'}" [draggable]="false"
  [resizable]="false" [closable]="false">
  <div class="row">
    <div class="col-md-6">
      <span class="other-actions-font">TD Code: {{displayOtherOutstandingActions.tdCode}}</span>
    </div>
    <div class="col-md-6">
      <span class="other-actions-font">Customer Name: {{displayOtherOutstandingActions.customerName}}</span>
    </div>
    <div class="col-md-6 mt-2 text-center">
      <div [style.background-color]="getActionBackgroundColour(actionType, displayOtherOutstandingActions.outstandingColor)">
        <span class="other-actions-font" [style.color]="getActionColour(actionType)">
          {{actionType}}
        </span>
      </div>
    </div>
    <div class="col-md-6 mt-2">
      <span class="other-actions-font">Due: {{displayOtherOutstandingActions.actionDueDate | date:'dd/MM/y HH:mm'}}</span>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-12">
      <h2>Additional Actions:</h2>
    </div>
  </div>
  <div *ngFor="let action of otherOutstandingActions" class="row mt-2">
    <div class="col-md-6 text-center">
      <div [style.background-color]="getActionBackgroundColour(action.outstandingName, action.outstandingColor)">
        <span class="other-actions-font" [style.color]="getActionColour(actionType)">
          {{action.outstandingName}}
        </span>
      </div>
    </div>
    <div class="col-md-6">
      <span>Due: {{action.renewalDateTaken?(action.renewalDateTaken | date:'dd/MM/y HH:mm'):'Date is missing'}}</span>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="navigateToPostOrder()" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>