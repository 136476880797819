<div class="tooltips-list">
    <p-table #dt [value]="tooltips" [columns]="cols" [globalFilterFields]="searchableFields"
        [paginator]="true" [rows]="10" [sortOrder]="1" sortMode="single"
        >
        <ng-template pTemplate="caption">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input type="search" pInputText size="50" placeholder="Global Filter"
                (input)="globalFilter($event, 'contains')" style="width:auto">
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field">
                    <div *ngSwitchCase="'page'">
                        <p-multiSelect [options]="pages" [(ngModel)]="selectedPage" placeholder="Page"
                            styleClass="brandcss" [panelStyle]="{minWidth:'12em'}" scrollHeight="600px"
                            [maxSelectedLabels]=0 selectedItemsLabel="{0} selected"
                            (onChange)="dt.filter($event.value,col.field,'in');onSelectPage($event)" [style]="{'display':'flex'}">
                            <ng-template let-Ppage let-i="index" pTemplate="item">
                                <span class="brandBar">
                                    &nbsp;{{Ppage.label}}
                                </span>
                            </ng-template>
                        </p-multiSelect>
                    </div>
                    <div *ngSwitchCase="'section'">
                        <p-multiSelect [options]="sectionOptions" placeholder="Section" [panelStyle]="{minWidth:'12em'}"
                            scrollHeight="600px" [maxSelectedLabels]=0 selectedItemsLabel="{0} selected"
                            [style]="{'display':'flex'}" (onChange)="dt.filter($event.value,col.field,'in')">
                        </p-multiSelect>
                    </div>
                    <div *ngSwitchCase="'label'" [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order">
                        </p-sortIcon>
                    </div>
                    <div *ngSwitchDefault>
                        {{col.header}}
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns" [ngSwitch]="col.field">
                    <div *ngSwitchCase="'tooltip'">
                        <textarea class="form-control" [cols]="30" pInputTextarea name="tooltip"
                            [(ngModel)]="rowData[col.field]" placeholder="Enter tooltip" [disabled]="!userCanEditTooltips()"
                            appAutoResizeTextarea>
                        </textarea>
                    </div>
                    <div *ngSwitchCase="'action'">
                        <button *ngIf="userCanEditTooltips()" type="button" class="btn btn-primary" (click)="updateTooltip(rowData)" >Update</button>
                    </div>
                    <div *ngSwitchDefault>
                        {{rowData[col.field]}}
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<!-- <div *ngIf="loading" class="loading-indicator d-flex align-items-center justify-content-center" style="height: 200px;">
    <p-progressSpinner></p-progressSpinner>
</div> -->
<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>