import {SelectItem} from 'primeng/api';

let monitoringSelectItems: SelectItem<string>[];
function getMonitoringOptions(): SelectItem<string>[] {
  if (!monitoringSelectItems) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('order: monitoring')) {
      return [];
    }
    monitoringSelectItems = JSON.parse(localStorage.getItem('order: monitoring')) || [];
  }
  return monitoringSelectItems;
}

// This is intended for validating monitoring options on imports and converting to the correct format for the DB
const monitoringDBLookup: {[lookupName: string]: string} = {};

function getMonitoringDBLookup(): {[lookupName: string]: string} {
  if (!monitoringDBLookup) {
    getMonitoringOptions().forEach((monitoringOption: SelectItem<string>) => {
      monitoringDBLookup[monitoringOption.label.toLocaleLowerCase().replace(/\s/g, '')] = monitoringOption.value;
    });
  }
  return monitoringDBLookup;
}

export {
  getMonitoringOptions,
  getMonitoringDBLookup,
}