import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PostSheet} from '../models/postSheet.model';

@Injectable()
export class PrintMailingLabelsService {
  isPrinting: boolean = false;
  selectedPost: PostSheet[];

  constructor(private router: Router) { }

  printLabels(postToPrintLabelsFor: PostSheet[]) {
    this.isPrinting = true;
    this.selectedPost = postToPrintLabelsFor;
    this.router.navigate(['/post-sheets', {
      'outlets': {
        'print': ['mailing-labels']
      }
    }]);
  }

  get postToPrintLabelsFor(): PostSheet[] {
    return this.selectedPost;
  }

  onDataReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate(['/post-sheets', {
        'outlets': {
          'print': null
        }
      }]);
    })
  }
}
