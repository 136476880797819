<div class="container">
  <div class="row">
    <div class="col-12">GoCardless Response</div>
    <div *ngIf="generalErrorMsg" class="col-12">
      <div class="alert alert-danger">
        {{generalErrorMsg}}
      </div>
    </div>
    <div class="col-12" *ngIf='gcStatus !== ""'>
      <ng-container *ngIf='gcStatus === "accepted"; else gcFailure'>
        <div class="alert alert-success">
          GoCardless Direct Debit set up successfully
        </div>
      </ng-container>
      <ng-template #gcFailure>
        <div class="alert alert-danger">
          GoCardless Direct Debit set up failed or was cancelled.
        </div>
      </ng-template>
    </div>
    <div class="col-6" *ngIf='shopifyOrderLink !== ""'>
      <a href="{{shopifyOrderLink}}" target="_blank" class="alert-link">View this order on Shopify.</a>
    </div>
    <div *ngIf="shopifyUpdateErrorMsg" class="col-12">
      <div class="alert alert-danger">
        {{shopifyUpdateErrorMsg}}
      </div>
      <button type="button" class="btn btn-primary ml-2" (click)="updateShopifyOrder()">
        Retry Shopify Update
      </button>
    </div>
    <div class="col-6" *ngIf='crmOrderErrorMsg !== ""'>
      <div class="alert alert-danger">
        {{crmOrderErrorMsg}}
      </div>
      <button type="button" class="btn btn-primary ml-2" (click)="findCrmOrder()">
        Re-run search for CRM Order
      </button>
    </div>
    <div class="col-6" *ngIf='crmOrderLink !== ""'>
      <a href="{{crmOrderLink}}" target="_blank" class="alert-link">Open CRM Order.</a>
    </div>
  </div>
</div>
