import {MessageService} from 'primeng/api';
import {NotificationService} from './../notification.service';
import {SelectItem} from 'primeng/api';
import {UsersService} from './../../setup/users/users.service';
import {Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit} from '@angular/core';
import {filteredUsersByRole, sortByLabel} from '../../helpers/helperFunctions';
import {CrmNotification} from '../../models/notifications/crmNotification.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {UserNotification} from '../../models/notifications/userNotification.model';
import {UsersAndRolesResponse} from '../../models/responses/usersAndRolesResponse.model';
import {User} from '../../models/user.model';
import {Group} from '../../models/group.model';

@Component({
  selector: 'app-create-notification',
  templateUrl: './create-notification.component.html',
  styleUrls: ['./create-notification.component.scss'],
  providers: [MessageService]
})
export class CreateNotificationComponent implements OnInit, AfterViewInit {
  @Output() closeCreateNotificationDialog = new EventEmitter();
  constructor(
    private userService: UsersService,
    private notificationService: NotificationService,
    private messageService: MessageService,
  ) {

  }
  display: boolean = true;
  notificationSummaryDisplay: boolean = false;
  sendToForMessageSummary: string[] = [];
  notificationSummary: string = '';
  notificationText: string = '';
  isAllUsers: boolean = false;
  users: SelectItem<string>[] = [];
  groups: SelectItem<string>[] = [];
  selectedGroups: string[] = [];
  selectedUsers: string[] = [];
  isConfirmDisabled: boolean = false;
  @ViewChild('summaryInput')
  summaryInput: ElementRef;

  ngOnInit() {
    this.getUsers();
    this.getGroups();
  }

  ngAfterViewInit() {
    this.summaryInput.nativeElement.focus();
  }

  createNotification() {
    this.sendToForMessageSummary = [];
    if (this.isAllUsers) {
      this.sendToForMessageSummary = ['All Users'];
    }
    
    if (this.selectedGroups.length > 0 && !this.isAllUsers) {
      const groupsForSummaryDisplay: string[] = this.selectedGroups.map(e => {
        return this.groups.find(group => group.value.toString() == e.toString()).label;
      });
      this.sendToForMessageSummary.push(...groupsForSummaryDisplay);
    }
    if (this.selectedUsers.length > 0 && !this.isAllUsers) {
      const usersForSummaryDisplay: string[] = this.selectedUsers.map(e => {
        return this.users.find(user => user.value.toString() == e.toString()).label;
      });
      this.sendToForMessageSummary.push(...usersForSummaryDisplay);
    }
    // console.log("sendToForMessageSummary :: ", this.sendToForMessageSummary);
    this.notificationSummaryDisplay = true;
  }

  finalSubmit() {
    this.isConfirmDisabled = true;
    const params: CrmNotification = {
      summary: this.notificationSummary,
      notificationText: this.notificationText,
      createdBy:localStorage.getItem('userName'),
      sendToAllUsers: this.isAllUsers,
      sendToGroups: [],
      sendToUsers: [],
    };
    
    if (this.selectedGroups.length > 0 && !this.isAllUsers) {
      params['sendToGroups'] = this.selectedGroups;
    }
    if (this.selectedUsers.length > 0 && !this.isAllUsers) {
      params['sendToUsers'] = this.selectedUsers;
    }
    this.notificationService.createNotification(params)
      .subscribe((response: MultiRecordResponse<UserNotification>) => {
        this.isConfirmDisabled = false
        // console.log("Response on creating notification", response);
        if (response.success) {
          this.notificationSummaryDisplay = false;
          this.showSuccess();
          setTimeout(() => {
            this.closeCreateNotificationDialog.emit();
          },600)
        } else {
          this.showError();
        }
      }, (err: Error) => {
        this.isConfirmDisabled = false;
        this.showError();
      });
  }

  getUsers() {
    this.userService.getUsers().subscribe((response: UsersAndRolesResponse) => {
      if (response.success) {
        response.users = filteredUsersByRole(response.users);
        this.users = response.users.map((user: User) => {
          return {
            label: user.name,
            value: user._id
          }
        });
        this.users = sortByLabel(this.users);
      }
    }, (err: Error) => {
      console.log("Error :: ", err);
    })
  }

  getGroups() {
    this.userService.getGroups().subscribe((response: MultiRecordResponse<Group>) => {
      if (response.success) {
        this.groups = response.data.map(e => {
          return {
            label: e.name,
            value: e._id
          }
        });
        this.groups = sortByLabel(this.groups);
      }
    }, (err: Error) => {
      
    })
  }

  onHideDialog() {
    this.closeCreateNotificationDialog.emit();
  }

  onHideNotificationSummaryDialog() {
    this.notificationSummaryDisplay = false;
  }


  showSuccess() {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success',
      detail: 'Changes successfuly applied'
    });
  }

  showError() {
    this.messageService.add({
      severity: 'error',
      life: 300000,
      summary: 'Error',
      detail: 'Something ent wrong, Try again'
    });
  }

}
