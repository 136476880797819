import { Pipe, PipeTransform } from '@angular/core';
import {getEquipmentManualFilename} from '../lookups/equipmentSerial';

@Pipe({
  name: 'serialToManual'
})
export class SerialToManualPipe implements PipeTransform {

  transform(serial: string): string {
    return getEquipmentManualFilename(serial);
  }

}
