import {SelectItem} from 'primeng/api';

enum CalculationMethod {
  ADDITIONAL = 'Additional',
  DIFFERENCE = 'Difference',
  FREE = 'Free',
  FULL = 'Full',
  LOST = 'Lost',
}

interface OrderType {
  'title': string;
  'isNewOrder': boolean;
  'calculationMethod': CalculationMethod;
  'genProdPostingGroup': string;
}

let cseOrderTypes: SelectItem<OrderType>[];

function getCseOrderTypes(): SelectItem<OrderType>[] {
  if (!cseOrderTypes) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('CSE Order: order type')) {
      return [];
    }
    cseOrderTypes = JSON.parse(localStorage.getItem('CSE Order: order type')) || [];
  }
  return cseOrderTypes;
}

export {
  CalculationMethod,
  OrderType,
  getCseOrderTypes,
}