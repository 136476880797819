<p-dialog  [(visible)]="showAddCategory" [modal]="true" [style]="{width: '600px', minWidth: '500px'}" [minY]="700"
           [maximizable]="true" [baseZIndex]=1000>
  <p-header>Please Provide the Name</p-header>
  <p>
    The category name should reflect what the customer has, not the type of ad/blog
    e.g. Digital means the customer has a digital alarm, not that the ads/blogs in that
    category are for/about digital alarms.<br/>
    <strong>Note:</strong>new categories will not have any affect without development work to assign them 
    to appropriate customers.
  </p>
  <label>New Category Name:</label><input type="text" [(ngModel)]="categoryName" pInputText class="w-100"/>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="closeCategoryDialog()" label="OK" [disabled]="!categoryName">
    </button>
  </p-footer>
</p-dialog>
<div class="row no-gutters">
  <div class="col-6 col-md-6 col-lg-6">
    <button type="button" class="btn btn-primary m-2" (click)="contentToEdit=undefined;showMaintainContent=true">Add New Content</button>
    <button type="button" class="btn btn-primary m-2" (click)="showAddCategory=true" [disabled]="!categories">Add New Category</button>
  </div>
</div>
<div class="row no-gutters">
  <div class="actions-list">
    <p-table #fAndFContent [value]="applicationContents" [columns]="columns" [paginator]="true" [rows]="15" tableStyleClass="contentTable"
        [sortOrder]="1" sortMode="single" sortField="sbr_sortorder" (sortFunction)="customSort($event)" [customSort]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <div *ngSwitchCase="'sbr_imageurl'" [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon>
              <p class='hint'>Image not displayed actual size</p>
            </div>
            <div *ngSwitchDefault [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon>
            </div>
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of columns" [ngSwitch]="col.field">
            <ng-container *ngSwitchCase="'_sbr_applicationcategoryid_value'">
              <p-multiSelect [options]="categories" placeholder="{{col.header}}" appendTo="body" 
                  [filter]=true (onChange)="fAndFContent.filter($event.value, col.field, 'in')"
                  [maxSelectedLabels]=1 selectedItemsLabel="{0} selected">
              </p-multiSelect>
            </ng-container>
            <ng-container *ngSwitchCase="'sbr_contenttype'">
              <p-multiSelect [options]="contentTypeSelects" placeholder="{{col.header}}" appendTo="body" 
                  (onChange)="fAndFContent.filter($event.value, col.field, 'in')"
                  [maxSelectedLabels]=1 selectedItemsLabel="{0} selected">
              </p-multiSelect>
            </ng-container>
            <ng-container *ngSwitchCase="'sbr_name'">
              <input pInputText type="search" (input)="applyFilter($event, col.field, 'contains')" />
            </ng-container>
            <ng-container *ngSwitchCase="'sbr_title'">
              <input pInputText type="search" (input)="applyFilter($event, col.field, 'contains')" />
            </ng-container>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns" [ngSwitch]="col['field']">
            <div *ngSwitchCase="'actions'">
              <button type="button" class="btn btn-danger" (click)="deleteContent(rowData['sbr_applicationcontentid'])"
                  [disabled]="updateDisabled">
                delete
              </button>
              <button type="button" class="btn btn-primary ml-2" (click)="editContent(rowData)" [disabled]="updateDisabled">
                Edit
              </button>
            </div>
            <div *ngSwitchCase="'sbr_contenttype'">
              {{contentTypeToDisplayName[rowData["sbr_contenttype"]]}}
            </div>
            <div *ngSwitchCase="'modifiedon'">
              {{rowData["modifiedon"] | date:'dd/MM/y HH:mm'}}
            </div>
            <div *ngSwitchCase="'sbr_targeturl'">
              <a href='{{rowData["sbr_targeturl"]}}' target='_blank'>
                {{getDisplayUrl(rowData["sbr_targeturl"])}}
              </a>
            </div>
            <div *ngSwitchCase="'sbr_imageurl'">
              <a href='{{rowData["sbr_imageurl"]}}' target='_blank'>
                <img *ngIf='rowData["sbr_imageurl"]' src='{{rowData["sbr_imageurl"]}}' style='max-width: 200px'/>
              </a>
            </div>
            <div *ngSwitchCase="'_sbr_applicationcategoryid_value'">
              {{rowData["_sbr_applicationcategoryid_value"] | catidToName:categories}}
            </div>
            <div *ngSwitchDefault>
              {{rowData[col.field]}}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<p-toast></p-toast>
<app-maintain-f-and-f-content *ngIf="showMaintainContent" [fAndFContent]="contentToEdit" [fAndFCategories]="categories"
  (closeMaintainContent)="closeMaintainContent($event)">
</app-maintain-f-and-f-content>
<p-confirmDialog key="general" [baseZIndex]=2000></p-confirmDialog>
<p-confirmDialog key="error" [baseZIndex]=3000></p-confirmDialog>
