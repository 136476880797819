import { Pipe, PipeTransform } from '@angular/core';
import {RawShopifyProduct} from '../../models/shopifyProduct.model';

@Pipe({
  name: 'shopifyProductToType',
})
export class ShopifyProductToTypePipe implements PipeTransform {

  transform(shopifyProduct: RawShopifyProduct): string {
    if (shopifyProduct.crmCategory == 'Discontinued') {
      // Need to work out which type it is as discontinued masks
      if (shopifyProduct.tags.includes('subscription')) {
        return 'Subscriptions';
      }
      if (shopifyProduct.shopifyCollectionNames.includes('Key Safes')) {
        return 'Key Safes';
      }
      if (shopifyProduct.tags.includes('setup_fee')) {
        return 'Setup Fees';
      }
      if (shopifyProduct.shopifyCollectionNames.includes('Installations')) {
        return 'Installations';
      }
      if (shopifyProduct.shopifyCollectionNames.includes('Cables/Chargers')) {
        return 'Cables/Chargers';
      }
      if (shopifyProduct.shopifyCollectionNames.includes('Base Unit')) {
        return 'Base Unit';
      }
      if (shopifyProduct.shopifyCollectionNames.includes('GPS Alarm')) {
        return 'GPS Alarm';
      }
      if (shopifyProduct.shopifyCollectionNames.includes('Plans')) {
        return 'Plans';
      }
    }
    return shopifyProduct.crmCategory;
  }

}
