import { Pipe, PipeTransform } from '@angular/core';
import {PaymentAccount} from '../../models/payments/paymentAccount.model';
import {SMART_DEBIT_ACCOUNTS} from '../../lookups/smartDebitAccounts';

@Pipe({
  name: 'smartDebitAccountName'
})
export class SmartDebitAccountNamePipe implements PipeTransform {

  transform(legalCompany: string, brand: string): string {
    const account: PaymentAccount = SMART_DEBIT_ACCOUNTS[legalCompany][brand];
    return account? account.accountName: '';
  }

}
