// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import {Environment} from '../app/models/environment.model';

export const environment: Environment = {
  production: false,
  IPAddress: 'crm-test.careline.co.uk',
  ioAddress: 'crm-test.careline.co.uk',
  webName: 'CRM Staging',
  navBarColour: '#007bff',
  protocol: 'https://',
  stripeKeys: {
    LL: 'pk_test_51LTN6gD1XjPt1RYUzN5UfFUNh7uPe8DWjnVvQNZsVy4ufhJ1y62FaruWrk2PN5W27FjofM5siJyTkSZglyCo2v6X00fqw4pAev',
    CL: 'pk_test_51LTMpcA3SG7uUDhVtt4KxDegLwe69c20NNeBGuspRLA8mkBaqXsyWQgImSmfBOUif5jsjxOoItN3TXdNtCF3UgR400HB2njx2l',
    CK: 'pk_test_51LNDrXKhCxrkk8k7glZdkczPDAmY1b5RjJXLQ2pzqPF13NAB6QBRVxUlLotfsM0d4g1pVon7zRKESkcmreCGfaoc00U6rjobCE',
    TC: 'pk_test_51LQq8cCCeD0NTuVygocOUc2l2Xqhmh7EtQ4OJgxAf485HSBdZrQG9bXiTmhGDNFESND9vv5QXlx7ztncsOAgOONl001RC2pYVn',
    LLIE: 'pk_test_51LTNLeDxFrpV80kBquz0h7lQGfEj2y8Haon6lVq2EECsFjNZrNcOSjH3spUtY7fCcIOEXgpUuWOvbKOcfXfaXky800vwWkSI4t',
    LL_OLD: 'pk_test_PyO8DaaJjQYu7ZFOZzWxjtni',
    CL_OLD: 'pk_test_9OVstJxMBMuCF034RUkOYpT8',
    CK_OLD: 'pk_test_0WI3m9bCNhSBCstgxWlBuUtt',
    TC_OLD: 'pk_test_LRPqk5ZF2NXN0GPYPn2g0TLL',
    LLIE_OLD: 'pk_test_nNWkH2VwMR4BA6bKnMdIz70W',
  },
  getAddressDomainToken: 'dtoken_hEDzcyiWMr0dhwkRFTVay3iui9Cl8dkXuhSMGOW4WNGBpCeX_5pV141eyhADhPZynvKrSYEvJvmn2NcjdC7EpWLsTAlTYANROeAy3_Lp-gQYFN8AOt_vA0vsvz8x4TIJyq2f8fAByc6oAs5CE3Sp6vsCjrSOJT7FdJie1HMyJZfi9rR8qvO9fJb2pZZ0nT6eegjeI1rEQfrrUXm_iqXInw',
  evoBaseUrl: 'https://appellosbrtest.crm4.dynamics.com/main.aspx?appid=fb9a4e2b-380c-4e5c-9c2a-05cfa2444850&forceUCI=1',
};
