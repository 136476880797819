import {SelectItem} from 'primeng/api';

interface ActionConfig {
  '_id': string;
  'actionName': string;
  'background': string;
  'color': string;
  'usersCanAdd': boolean;
  'defaultDueInDays'?: number;
  'attention'?: boolean;
  'owedPaymentLabel'?: string;
  'returnedEquipLabel'?: string;
  'returnedEquipTwoLabel'?: string;
  'returnedEquipThreeLabel'?: string;
  'personReturningLabel'?: string;
  'returnLabelNumbersLabel'?: string;
  'cancellationEmailLabel'?: string;
  'holdUntilLabel'?: string;
  'statusLabel'?: string;
  'dateLabel'?: string;
  'noteLabel'?: string;
  'countLabel'?: string;
  'contactNumberLabel'?: string;
  'responsiblePersonNameLabel'?: string;
  'emailAddressLabel'?: string;
  'reasonLabel'?: string;
  'board'?: string;
  'boardSortOrder'?: number;
  'allowDueDateFilterOnCC'?: boolean;
}

interface ActionCfg {
  [actionName: string]: ActionConfig
}

let actionConfigs: ActionCfg;
function getActionConfigs(): ActionCfg {
  if (!actionConfigs) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('actionConfigs')) {
      return {};
    }
    actionConfigs = JSON.parse(localStorage.getItem('actionConfigs')) || {};
  }
  return actionConfigs;
}

let actionNames: string[];
function getActionNames(): string[] {
  if (!actionNames) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('actionConfigs')) {
      return [];
    }
    actionNames = Object.keys(getActionConfigs());
  }
  return actionNames;
}

// This is intended for validating action names on imports and converting to the correct format
let actionNameLookup: {[lookupName: string]: string};
function getActionNameLookup(): {[lookupName: string]: string} {
  if (!actionNameLookup) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('actionConfigs')) {
      return {};
    }
    actionNameLookup = {};
    getActionNames().forEach((actionName: string) => {
      actionNameLookup[actionName.toLocaleLowerCase().replace(/\s/g, '')] = actionName;
    });
  }
  return actionNameLookup;
}

// This is for primeng dropdowns
let actionSelectItems: SelectItem<string>[];
function getActionSelectItems(): SelectItem<string>[] {
  if (!actionSelectItems) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('actionConfigs')) {
      return [];
    }
    actionSelectItems = [];
    getActionNames().forEach((actionName: string) => {
      actionSelectItems.push({'label': actionName, 'value': actionName});
    });
  }
  return actionSelectItems;
}

let boardNames: string[];
function getBoardNames(): string[] {
  if (!boardNames) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('actionConfigs')) {
      return [];
    }
    boardNames = [];
    Object.values(getActionConfigs()).forEach((actionConfig: ActionConfig) => {
      if (actionConfig.board && !boardNames.includes(actionConfig.board)) {
        boardNames.push(actionConfig.board);
      }
    });
    boardNames.sort();
  }
  return boardNames;
}

interface ActionReasonLookups {
  [actionName: string]: SelectItem<string>[];
}

let actionReasonLookups: ActionReasonLookups;

function getActionReasonLookups(): ActionReasonLookups {
  if (!actionReasonLookups) {
    const actionNames: string[] = getActionNames();
    // Handle requests to get the lookups before login is complete
    if (actionNames.length == 0) {
      return {};
    }
    actionReasonLookups = {};
    actionNames.forEach((actionName: string) => {
      if (localStorage.getItem(`action reasons: ${actionName}`)) {
        actionReasonLookups[actionName] = JSON.parse(localStorage.getItem(`action reasons: ${actionName}`));
      }
    });
  }
  return actionReasonLookups;
}

function getActionColour(actionName: string, defaultColour: string = '#000000'): string {
  const configs: ActionCfg = getActionConfigs();
  const config = configs[actionName];
  if (config) {
    return config.color;
  }
  return defaultColour;
}

function getActionBackgroundColour(actionName: string, defaultColour: string = '#FFFFFF'): string {
  const configs: ActionCfg = getActionConfigs();
  const config = configs[actionName];
  if (config) {
    return config.background;
  }
  return defaultColour;
}

function getAllowDueDateFilterOnCC(actionName: string): boolean {
  const configs: ActionCfg = getActionConfigs();
  const config = configs[actionName];
  if (config) {
    return config.allowDueDateFilterOnCC;
  }
  return false;
}

export {
  ActionCfg,
  ActionConfig,
  ActionReasonLookups,
  getActionConfigs,
  getActionNames,
  getActionNameLookup,
  getActionSelectItems,
  getActionColour,
  getActionBackgroundColour,
  getActionReasonLookups,
  getAllowDueDateFilterOnCC,
  getBoardNames,
}
