import {SelectItem} from 'primeng/api';

const vatStatuses: SelectItem<string>[] = [
  { label: 'Not Exempt', value: 'not exempt' },
  { label: 'Exempt', value: 'exempt' }
];

const shopifyVatStatuses: SelectItem<boolean>[] = [
  { label: 'Not Exempt', value: true },
  { label: 'Exempt', value: false }
];

export {
  vatStatuses,
  shopifyVatStatuses,
}