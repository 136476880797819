<div class="row mx-0 my-2 messageDetails">
  <div class="col-6 col-lg-2 offset-0 offset-lg-1">
    <div>
      Order Status:
      <span class="statusBlock" [style.background-color]="getStatusBackgroundColour(order.status.status)" 
          [style.color]="getStatusColour(order.status.status)">
        {{order.status.status}}
      </span>
    </div>
  </div>
  <div class="col-6 col-lg-3">
    Order Date: {{order.created | date:'dd/MM/y'}}
  </div>
  <div class="col-6 col-lg-2">
    Plan: {{order.accountDetails.plan}}
  </div>
  <div class="col-6 col-lg-4">
    No. of Consecutive High Use Reports: {{order.status.highUseCount}}
  </div>
</div>
<div *ngIf="message.messageType === 'High User'" class="row mx-0 my-2 messageDetails">
  <div class="col-12" [formGroup]="messageForm">
    <label for="messageToSend">Message To Send</label>
    <select class="form-control" name="messageToSend" formControlName="messageToSend" required>
      <option> </option>
      <option *ngFor="let highUserMessageType of highUserMessageTypes" value="{{highUserMessageType.value}}">
        {{highUserMessageType.label}}
      </option>
    </select>
  </div>
</div>

