import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {numberOnly} from '../helpers/keyboardHelpers';
import {ProposedMessageService} from '../messages-list/proposed-message.service';
import {Column} from '../models/column.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {UserResponse} from '../models/responses/userResponse.model';
import {User} from '../models/user.model';
import {UsersService} from '../setup/users/users.service';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import {MessageLogReportResponse} from '../models/responses/messageLogReportResponse.model';
import {getNoteCategories, NoteCategory} from '../lookups/noteCategory';
import {getNoteTemplates, NoteTemplate} from '../lookups/noteTemplate';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Dropdown} from 'primeng/dropdown';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {DropDownChangeEvent} from '../models/primeng/dropdownChangeEvent.model';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import { sortByLabel } from '../helpers/helperFunctions';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class ProfileComponent implements OnInit {

  user: User;
  defaultColumns: Column[];
  defaultNoteCategories: string[];
  noteTemplates: NoteTemplate[] = getNoteTemplates();
  noteTemplate: {
    templateName: string,
    noteContent: string,
    noteCategories: string[],
  }[];
  savedColumnSets: SelectItem<Column[]>[];
  savedNoteTemplates: NoteTemplate[];
  columnListExpanded: {[index: number]: boolean} = {};
  noteTemplateExpanded: {[index: number]: boolean} = {};
  showNewNoteTemplateDialog: boolean = false;
  noteCategoryListExpanded: {[index: number]: boolean} = {};
  columnSetOptionsDisabled: boolean;
  noteCategoryOptionsDisabled: boolean;
  noteTemplateOptionsDisabled: boolean;
  showSendPendantTests: boolean;
  noteCategories: NoteCategory[]=getNoteCategories(true);
  pendantTestReleaseDisabled: boolean;
  pendantTestNumberToRelease: number;
  pendantTestNumberAvailable: number;
  pendantTestsReleasedToday: number;
  newQrcode: string;
  authCode: string;
  numberOnly = numberOnly;
  displayModal = true;
  header: string = "Add/Edit Note Templates";
  loading: boolean = true;
  noteTemplateForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private title: Title,
    private usersService: UsersService,
    private proposedMsgService: ProposedMessageService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
  ) { }

  @Output() closeNoteTemplateDialog: EventEmitter<NoteTemplate|string|undefined> = new EventEmitter<NoteTemplate|string|undefined>();
  @Input() openMode: string;
  @ViewChild('templateDropdown') templateDropdown: Dropdown;

  ngOnInit(): void {
    this.pendantTestNumberToRelease = 0;
    this.pendantTestNumberAvailable = -1
    this.columnSetOptionsDisabled = true;
    this.showSendPendantTests = this.usersService.userHasPermission('Send Pendant Test Emails');
    this.title.setTitle('CRM Profile');
    this.noteTemplateForm = this.fb.group({
      label: ['', Validators.required],
      value: ['', Validators.required],
      noteCategories: [[], Validators.required],
    });
    this.usersService.getUser(localStorage.getItem('userId'))
      .subscribe((response: UserResponse) => {
        if (response.success) {
          this.user = response.user;
          this.defaultNoteCategories = this.user.defaultNoteCategories;
          this.noteTemplate = this.user.noteTemplates
        } else {
          this.showConfirmationPopup('Error', `Error loading user profile. Error: ${response.message}`, 'OK', '');
        }
      }, (err: Error) => {
        this.showConfirmationPopup('Error', `Error loading user profile. ${err.message}`, 'OK', '');
      });
    this.updatePendantTestCount();
    this.defaultColumns = JSON.parse(localStorage.getItem('defaultColumns'));
    this.savedColumnSets = JSON.parse(localStorage.getItem('savedColumnSets'));
    this.noteTemplates = JSON.parse(localStorage.getItem('noteTemplates'));
    sortByLabel(this.noteTemplates);
    this.noteTemplateOptionsDisabled = false;
    this.columnSetOptionsDisabled = false;
  }

  setup2fa(): void {
    this.usersService.setup2fa().subscribe((response: SingleRecordResponse<string>) => {
      this.user.qrcode = response.data
    });
  }

  generateQrCheck() {
    this.showConfirmationPopup('Are you sure you want to generate a new code?', 
      'Generating a new code will cause all previously set devices to stop working. You will need to set up all devices that you wish to use with the new QRcode',
      'Continue' ,'Cancel', this.generateNewQr
    );
  }

  generateNewQr(): void {
    this.usersService.setup2fa().subscribe((response: SingleRecordResponse<string>) => {
      this.newQrcode = response.data;
      this.newQrcode = this.user.qrcode;
    });
  }

  validate2fa(): void {
    this.usersService.validate2fa(this.authCode).subscribe((response: SimpleResponse) => {
      if (!response.success) {
       this.showErrorPrompt('Error', response.message);
      } else {
        this.showConfirmationPopup('Success', response.message , 'OK', '');
      }
    });
  }

  updatePendantTestCount(): void {
    this.proposedMsgService.getPendantTestCount().subscribe((countRsp: MultiRecordResponse<number>) => {
      if (countRsp.success) {
        this.pendantTestNumberAvailable = countRsp.data[0];
        this.pendantTestsReleasedToday = countRsp.data[1];
      } else {
        this.showConfirmationPopup('Error', countRsp.message, 'OK', '');
      }
    }, (err: Error) => {
      this.showConfirmationPopup('Error', `Error loading pendant test count. Error: ${err.message}`, 'OK', '');
    });
  }

  releasePendantTestEmails(): void {
    this.pendantTestReleaseDisabled = true;
    this.proposedMsgService.releasePendantTestEmails(this.pendantTestNumberToRelease).subscribe((releaseRsp: SimpleResponse) => {
      if (releaseRsp.success) {
        this.showSuccess('Release triggered, emails will be queued for sending.');
        setTimeout(() => {
          this.updatePendantTestCount();
        }, 10000);
        
      } else {
        this.showConfirmationPopup('Error', releaseRsp.message, 'OK', '');
      }
    }, (err: Error) => {
      this.showConfirmationPopup('Error', `Error releasing pendant test emails. Error: ${err.message}`, 'OK', '');
    });
  }

  saveNoteCategory(): void {
    this.noteCategoryOptionsDisabled = true;
    // Default Categories to be saved 
    localStorage.setItem('defaultNoteCategories', JSON.stringify(this.defaultNoteCategories));
    this.saveUserNoteCategories(this.defaultNoteCategories);
  }

  saveUserNoteCategories(defaultNoteCategories: string[],): void {
    this.usersService.saveNoteCategories({
      userId: localStorage.getItem('userId'),
      defaultNoteCategories: defaultNoteCategories,
    }).subscribe((response: SimpleResponse) => {
      this.noteCategoryOptionsDisabled = false;
      if (response.success) {
        this.showSuccess();
      } else {
        this.showConfirmationPopup('Error', `Error saving changes to note categories. Error: ${response.message}`, 'OK', '');
      }
    }, (err: Error) => {
      this.noteCategoryOptionsDisabled = false;
      this.showConfirmationPopup('Error', `Error saving changes to note categories. Error: ${err.message}`, 'OK', '');
      console.log('Error on saving note categories:', err);
    });
  }

  saveNoteTemplate(): void { 
    this.noteTemplateOptionsDisabled = true;
    const noteTemplateIndex: number = this.noteTemplates.findIndex((template: NoteTemplate) => 
      template.label == this.noteTemplateForm.value.label
    );
    if (noteTemplateIndex == -1) {
      this.noteTemplates.push(this.noteTemplateForm.value);
      localStorage.setItem('noteTemplates', JSON.stringify(this.noteTemplates));
      this.saveUserNoteTemplates(this.noteTemplates);
    } else {
      this.noteTemplates[noteTemplateIndex] = this.noteTemplateForm.value;
      localStorage.setItem('noteTemplates', JSON.stringify(this.noteTemplates));
      this.saveUserNoteTemplates(this.noteTemplates);
    }
  } 

  saveUserNoteTemplates(noteTemplates: NoteTemplate[]): void {
    this.usersService.saveNoteTemplate({
      userId: localStorage.getItem('userId'),
      noteTemplates: noteTemplates
    }).subscribe((response: SimpleResponse)=> {
      this.noteTemplateOptionsDisabled = false;
      if (response.success) {
        this.showSuccess();
        this.noteTemplateForm.reset();
        this.templateDropdown.clear(undefined);
      } else {
        this.showConfirmationPopup('Error', `Error saving note Template. Error: ${response.message}`, 'OK', '');
      }
    }, (err: Error) => {
      this.noteTemplateOptionsDisabled = false;
      this.showConfirmationPopup('Error', `Error saving note Template. Error: ${err.message}`, 'OK', '')
      console.log('Error on saving note templates:', err);
    })
  }

  confirmNoteTemplateDelete(): void {
    const noteTemplateIndex: number = this.noteTemplates.findIndex((template: NoteTemplate) => 
      template.label == this.noteTemplateForm.value.label 
    );
    const setTemplateName: string = this.noteTemplates[noteTemplateIndex].label;
    this.showConfirmationPopup('Delete Confirmation', `Are you sure you wish to delete note template '${setTemplateName}'?`, 'Yes', 'No', () => {
      this.deleteNoteTemplate(noteTemplateIndex);
    });
  }

  deleteNoteTemplate(noteTemplateIndex: number): void {
    this.noteTemplateOptionsDisabled = true;
    this.noteTemplates.splice(noteTemplateIndex, 1);
    localStorage.setItem('noteTemplates', JSON.stringify(this.noteTemplates));
    this.saveUserNoteTemplates(this.noteTemplates);
  }

  toggleNoteTemplate(templateIndex: number): void {
    if (!this.isNoteTemplateExpanded(templateIndex)) {
      this.columnListExpanded[templateIndex] = true;
    } else {
      this.columnListExpanded[templateIndex] = false;
    }
  }

  isNoteTemplateExpanded(templateIndex: number): boolean {
    return this.noteTemplateExpanded[templateIndex];
  }

  changeNoteTemplate(event: DropDownChangeEvent<NoteTemplate>) {
    if (event.value) {
      this.noteTemplateForm.patchValue(event.value);
    } else {
      this.noteTemplateForm.reset();
    }
    this.noteTemplateForm.markAsPristine();
  }

  get templateName(): AbstractControl {
    return this.noteTemplateForm.controls['label'];
  }

  confirmColumnSetDelete(setIndex: number): void {
    const setName: string = this.savedColumnSets[setIndex].label;
    this.showConfirmationPopup('Delete Confirmation', `Are you sure you wish to delete column set '${setName}'?`, 'Yes', 'No', () => {
      this.deleteColumnSet(setIndex);
    });
  }

  deleteColumnSet(setIndex: number): void {
    this.columnSetOptionsDisabled = true;
    // User might have unsaved changes to other sets, so load saved values from local storage
    // and then delete the correct one
    const originalDefaultColumns: Column[]  = JSON.parse(localStorage.getItem('defaultColumns'));
    const updatedSavedColumnSets: SelectItem<Column[]>[] = JSON.parse(localStorage.getItem('savedColumnSets'));
    updatedSavedColumnSets.splice(setIndex, 1);
    this.savedColumnSets.splice(setIndex, 1);
    localStorage.setItem('savedColumnSets', JSON.stringify(updatedSavedColumnSets));
    this.saveUserColumnSets(originalDefaultColumns, updatedSavedColumnSets);
  }

  saveColumnSet(setIndex: number): void {
    this.columnSetOptionsDisabled = true;
    if (setIndex == -1) {
      // Default columns to be saved - send the original saved column sets as there might be 
      // unsaved changes to this.saveColumnSets
      localStorage.setItem('defaultColumns', JSON.stringify(this.defaultColumns));
      const originalSavedColumnSets: SelectItem<Column[]>[] = JSON.parse(localStorage.getItem('savedColumnSets'));
      this.saveUserColumnSets(this.defaultColumns, originalSavedColumnSets);
    } else {
      // User might have unsaved changes to other sets, so load saved values from local storage
      // and then update the correct one
      const originalDefaultColumns: Column[]  = JSON.parse(localStorage.getItem('defaultColumns'));
      const updatedSavedColumnSets: SelectItem<Column[]>[] = JSON.parse(localStorage.getItem('savedColumnSets'));
      updatedSavedColumnSets[setIndex] = this.savedColumnSets[setIndex];
      localStorage.setItem('savedColumnSets', JSON.stringify(updatedSavedColumnSets));
      this.saveUserColumnSets(originalDefaultColumns, updatedSavedColumnSets);
    }
  }

  saveUserColumnSets(defaultColumns: Column[], savedColumnSets: SelectItem<Column[]>[]): void {
    this.usersService.saveColumns({
      userId: localStorage.getItem('userId'),
      defaultColumns: defaultColumns,
      savedColumnSets: savedColumnSets.map<SelectItem<string[]>>((colSet: SelectItem<Column[]>) => {
        return {
          label: colSet.label,
          value: colSet.value.map((col: Column) => col.field)
        }
      }),
    }).subscribe((response: SimpleResponse) => {
      this.columnSetOptionsDisabled = false;
      if (response.success) {
        this.showSuccess();
      } else {
        this.showConfirmationPopup('Error', `Error saving changes to column set. Error: ${response.message}`, 'OK', '');
      }
    }, (err: Error) => {
      this.columnSetOptionsDisabled = false;
      this.showConfirmationPopup('Error', `Error saving changes to column set. Error: ${err.message}`, 'OK', '');
      console.log('Error on saving column sets:', err);
    });
  }

  dropColumn(event: CdkDragDrop<Column[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }
  
  toggleColumnList(index: number): void {
    if (!this.isColumnListExpanded(index)) {
      this.columnListExpanded[index] = true;
    } else {
      this.columnListExpanded[index] = false;
    }
  }

  isColumnListExpanded(index: number): boolean {
    return this.columnListExpanded[index];
  }

  getMessageLogSheetHeadings(): string[] {
    const sheetHeadings: string[] = [
      'Message Type',
    ];
    const summaryDate: moment.Moment = moment().subtract('1', 'week');
    let daysProcessed: number = 0;
    while (daysProcessed <= 7) {
      const formattedDate: string = summaryDate.format('YYYY-MM-DD');
      sheetHeadings.push(`${formattedDate} Sent`);
      sheetHeadings.push(`${formattedDate} Failed`);
      summaryDate.add(1, 'day');
      daysProcessed++;
    }
    return sheetHeadings;
  }
  
  getSuccessfulMessageLogSheetHeadings(): string[] {
    const successfulSheetHeadings: string[] =[
      'tdCode', 'dateLogged', 'messageType'
    ];
    return successfulSheetHeadings;
  }

  downloadMessageLogSummary(): void {
    this.proposedMsgService.getMessageLogReport().subscribe((msgLogSummaryRsp: MessageLogReportResponse) => {
      if (msgLogSummaryRsp.success) {
        const summarySheetName: string = 'Message Log Summary';
        const detailSheetName: string = 'Message Log Detail';
        const sheets: {[sheetName: string]: XLSX.WorkSheet} = {};
        const worksheetSummary: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
          msgLogSummaryRsp.summaryData,
          {
            header: this.getMessageLogSheetHeadings(),
          }
        );
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
          msgLogSummaryRsp.data,
          {
            header: this.getSuccessfulMessageLogSheetHeadings(),
          }
        );
        sheets[summarySheetName] = worksheetSummary;
        sheets[detailSheetName] = worksheet;
        const workbook: XLSX.WorkBook = {Sheets: sheets, SheetNames: [summarySheetName, detailSheetName]};
        const opts: XLSX.WritingOptions = {compression: true, bookType: 'xlsx'};

        XLSX.writeFile(workbook, 'Message Log.xlsx', opts);
        XLSX.write(workbook, {compression: true, bookType: 'xlsx', type: 'array'});
      } else {
        this.showConfirmationPopup('Error', msgLogSummaryRsp.message, 'OK', '');
      }
    });
  }

  showConfirmationPopup(header: string, message: string, acceptLabel: string, rejectLabel: string,
      acceptCallback?: () => void, rejectCallback?: () => void) {
    let acceptClicked: boolean = false;
    this.confirmationService.confirm({
      key: 'general',
      header: header,
      message: message,
      acceptLabel: acceptLabel,
      rejectLabel: rejectLabel,
      rejectVisible: !!rejectLabel,
      accept: () => {
        acceptClicked = true;
        if (acceptCallback) {
          acceptCallback();
        }
      },
      reject: () => {
        if (rejectCallback && !acceptClicked) {
          rejectCallback();
        }
      }
    });
  }

  showSuccess(successMsg: string = 'Changes Successfuly Applied') {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success',
      detail: successMsg,
    });
  }

  showErrorPrompt(header: string, message: string) {
    this.confirmationService.confirm({
      key: 'general',
      header: header,
      message: message,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'OK',
      rejectVisible: false,
      accept: () => {},
    });
  }

  closeDialog() {
    this.displayModal = false;
    this.closeNoteTemplateDialog.emit(undefined);
  }

}
