import {SelectItem} from 'primeng/api';

const renewalTypeSelectItems: SelectItem<string>[] = [
  {'label': '', 'value': ''},
  {'label': 'Direct Debit', 'value': 'directDebit'},
  {'label': 'Recurring Billing', 'value': 'recurringBilling'},
  {'label': 'Go Cardless', 'value': 'goCardless'},
  {'label': 'Standing Order', 'value': 'standing order'},
];

// This is intended for validating renewal types on imports and converting to the correct format for the DB
const renewalTypeDBLookup: {[lookupName: string]: string} = {};
// This is to convert the DB value to display value
const renewalTypeDisplayLookup: {[lookupName: string]: string} = {};

renewalTypeSelectItems.forEach((renewalType: SelectItem<string>) => {
  renewalTypeDBLookup[renewalType.label.toLocaleLowerCase().replace(/\s/g, '')] = renewalType.value;
  renewalTypeDisplayLookup[renewalType.value] = renewalType.label;
});

export {
  renewalTypeDBLookup,
  renewalTypeSelectItems,
  renewalTypeDisplayLookup,
}
