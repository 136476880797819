import {Component, OnInit} from '@angular/core';
import {UsersService} from './users.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {Title} from '@angular/platform-browser';
import {LogsService} from '../../reporting/logs/logs.service';
import {User} from '../../models/user.model';
import {UserResponse} from '../../models/responses/userResponse.model';
import {MultiSelectChangeEvent} from '../../models/primeng/multiSelectChangeEvent.model';
import {UsersAndRolesResponse} from '../../models/responses/usersAndRolesResponse.model';
import {Role} from '../../models/role.model';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {LogWithUpload} from '../../models/log.model';
import {permissions} from '../../lookups/rolesAndPermissions';
import {sortByLabel} from '../../helpers/helperFunctions';
import {ExcelExportService} from '../../post-order/excel-export.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [ConfirmationService, MessageService, ConfirmDialogModule]
})
export class UsersComponent implements OnInit {
  users: User[];
  filteredUsers: User[];
  selectedUser: User;
  displayDialog: boolean;
  userName: string;
  isMaintainGroupVisible: boolean = false;
  originalUsers: User[];
  roleDefinitions: Role[] = [];
  rolesContainingPermission: {[permission: string]: string[]} = {};
  userRoles: SelectItem<string>[] = [];
  sortOptions: SelectItem<string>[] = [{
    'label': 'Name Asc', 'value': 'name'
  }, {
    'label': 'Name Desc', 'value': '!name'
  }, {
    'label': 'Email Asc', 'value': 'email'
  }, {
    'label': 'Email Desc', 'value': '!email'
  }, {
    'label': 'Created Asc', 'value': 'created'
  }, {
    'label': 'Created Desc', 'value': '!created'
  }];
  availablePermissions: SelectItem<string>[] = permissions;
  sortOrder: number;
  sortField: string;
  selectedSortOption: string;
  selectedRole: string;
  selectedPermission: string;
  superAdminRoles: string[];

  constructor(private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private logsService: LogsService,
              private usersService: UsersService,
              private title: Title,
              private excelExportService: ExcelExportService,
              ) {

  }

  ngOnInit() {
    this.filteredUsers = [];
    this.users = [];
    this.userName = localStorage.getItem('userName');
    this.getUsers();
    this.title.setTitle('CRM Users');
  }

  getUsers() {
    this.usersService.getUsers().subscribe((response: UsersAndRolesResponse) => {
      if (!response.success) {
        this.showErrorPopUp('Error getting users', response.message);
        return;
      }
      this.users = response.users || [];
      this.filteredUsers = this.users;
      this.originalUsers = this.users.map((user: User) => {
        return {
          ...user
        }
      });
      this.roleDefinitions = (response.userRoles || []);
      this.userRoles = this.roleDefinitions.map((role: Role) => {
        role.permissions.forEach((permission: string) => {
          if (!this.rolesContainingPermission[permission]) {
            this.rolesContainingPermission[permission] = [];
          }
          this.rolesContainingPermission[permission].push(role._id);
        });
        this.superAdminRoles = [];
        if (this.rolesContainingPermission['Super Admin']) {
          this.superAdminRoles = this.rolesContainingPermission['Super Admin'];
        }
        return {
          value: role._id,
          label: role.roleName
        }
      });
      sortByLabel(this.userRoles);
      this.sortOrder = 1;
      this.sortField = 'name';
      this.selectedSortOption = 'name';
    }, _err => {
    });
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success Update!',
      detail: 'Changes Successfully Applied'
    });
  }

  showSuccessDelete() {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success Deleting!',
      detail: 'Changes Successfully Applied'
    });
  }
  
  showWarn() {
    this.messageService.add({
      severity: 'warn',
      life: 10000,
      summary: 'Update Cancellation!',
      detail: 'Changes Not Applied'
    });
  }

  // MultSelectChangeEvent incorrectly has value as array of strings and itemValue as SelectItem<string> both should be the same type
  confirmRoleChange(event: MultiSelectChangeEvent<any>, user: User): void {
    if (!this.usersService.userHasRole(event.itemValue.value)) {
      // If the user does not have the role, but has all the permissions this role grants, allow it.
      // This allows newly created roles to be assigned, otherwise only Super Admins can assign it.
      const roleToCheck: Role = this.roleDefinitions.find((role: Role) => role._id == event.itemValue.value);
      if (!roleToCheck || !this.usersService.userHasEveryPermission(roleToCheck.permissions)) {
        // Undo the change
        if (event.value.includes(event.itemValue.value)) {
          // The user has tried to add a role
          user.roles = user.roles.filter((roleId: string) => roleId != event.itemValue.value);
        } else {
          // The user tried to remove a role - add back in this way to refresh UI
          user.roles = [...user.roles, event.itemValue.value];
        }
        this.confirmationService.confirm({
          message: 'You are not allowed to add/remove a role that you do not have yourself.',
          header: 'Permission Denied',
          icon: 'pi pi-info-circle',
          acceptVisible: true,
          acceptLabel: 'OK',
          rejectVisible: false,
          accept: () => {
          },
        });
        return;
      }
    }
    
    let acceptClicked: boolean = false;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to update the user details?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      acceptVisible: true,
      rejectVisible: true,
      accept: () => {
        acceptClicked = true;
        this.updateUser(user);
      },
      reject: () => {
        if (!acceptClicked) {
          this.showWarn();
          // Undo the change
          if (event.value.includes(event.itemValue.value)) {
            // The user has tried to add a role
            user.roles = user.roles.filter((roleId: string) => roleId != event.itemValue.value);
          } else {
            // The user tried to remove a role - add back in this way to refresh UI
            user.roles = [...user.roles, event.itemValue.value];
          }
        }
      }
    });
  }

  confirmUpdate(user: User) {
    let acceptClicked: boolean = false;
    this.confirmationService.confirm({
      message: 'Are you sure that you want to update the user details?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle',
      acceptVisible: true,
      rejectVisible: true,
      accept: () => {
        acceptClicked = true;
        this.updateUser(user);
      },
      reject: () => {
        if (!acceptClicked) {
          this.showWarn();
          // Replace the list with the original users (which will have been updated with already applied changes)
          console.log('Update original users confirmUpdate');
          this.users = this.originalUsers.map((aUser: User) => {
            return {
              ...aUser
            }
          });
          this.onRoleOrPermissionChange();
        }
      }
    });
  }

  updateUser(user: User) {
    this.usersService.updateUser(user._id, { 'user': user }).subscribe((userRes: UserResponse) => {
      if (!userRes.success) {
        this.showErrorPopUp('Error updating users', userRes.message);
        // Replace the list with the original users (which will have been updated with already applied changes)
        this.users = this.originalUsers.map((aUser: User) => {
          return {
            ...aUser
          }
        });
        this.onRoleOrPermissionChange();
        return;
      }
      const oldUser: User = this.originalUsers.find((aUser: User) => aUser._id == user._id);
      // Swap out the user in the original list with the updated one
      this.originalUsers = this.originalUsers.map((aUser: User) => {
        if (aUser._id != user._id) {
          return aUser;
        } else {
          return userRes.user;
        }
      });
      // Update the list being worked on
      this.users = this.originalUsers.map((aUser: User) => {
        return {
          ...aUser
        }
      });
      this.onRoleOrPermissionChange();
      this.changeRightAutoNotification(oldUser, user);
      this.showSuccess();
      if (user['password']) {
        const log: LogWithUpload = {
          source: 'User',
          code: user.email,
          id: user['_id'],
          user: this.userName,
          date: (new Date()).toISOString(),
          key: 'Password',
          action: 'update'
        };
        this.logsService
          .createLog({log: log})
          .subscribe((logres: SimpleResponse) => {
            console.log('logs response :: ', logres)
          }, (err => {
            console.log('ERROR :: ', err);
          }))
      }
    }, err => {
      this.showErrorPopUp('Error updating users', err.message);
    });

  }

  confirmDelete(user: User) {
    let acceptClicked: boolean = false;
    const canDelete: boolean = this.usersService.userHasPermission('Delete Users');
    if (canDelete) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete this user?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptVisible: true,
        rejectVisible: true,
        accept: () => {
          acceptClicked = true;
          this.deleteUser(user);
        },
        reject: () => {
          if (!acceptClicked) {
            this.showWarn();
          }
        }
      });
    } else {
      this.showErrorPopUp('Unable to Delete', 'You do not have permission to delete users.');
    }
  }

  deleteUser(user: User) {
    this.usersService.deleteUser(user._id).subscribe((userResp: UserResponse) => {
      if (userResp.success) {
        this.showSuccessDelete();
        this.users = this.users.filter((aUser: User) => aUser._id != user._id);
        this.onRoleOrPermissionChange();
      } else {
        this.showErrorPopUp('Error deleting user', userResp.message);;  
      }
    }, err => {
      this.showErrorPopUp('Error deleting user', err.message);
    });
  }

  selectUser(event: Event, user: User) {
    this.selectedUser = user;
    this.displayDialog = true;
    event.preventDefault();
  }

  displayMaintainGroups() {
    this.isMaintainGroupVisible = true;
  }

  hideMaintainGroups() {
    this.isMaintainGroupVisible = false;
  }

  changeRightAutoNotification(oldUser: User, user: User) {
    let oldUserRights: string[] = this.getAccessRights(oldUser);
    oldUserRights = oldUserRights.length ? oldUserRights: ['None'];
    let newUserRights: string[] = this.getAccessRights(user);
    newUserRights = newUserRights.length ? newUserRights: ['None'];
    this.usersService.changeRightsAutoNotification({
      userName: this.userName, 
      rightsChangedForUser: user.name,
      oldUserRights: oldUserRights.join(),
      newUserRights: newUserRights.join(), 
    }).subscribe(response => {
      console.log('Response on changeAccessRight :: ', response);
    });
  }

  getAccessRights(user: User): string[] {
    const userRights: string[] = [];
    user.roles.forEach((roleId: string) => {
      const role: SelectItem<string> = this.userRoles.find((roleSelectItem: SelectItem<string>) => roleSelectItem.value == roleId);
      if (role) {
        userRights.push(role.label);
      }
    });
    return userRights;
  }

  onSortChange(): void {
    if (this.selectedSortOption.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = this.selectedSortOption.substring(1);
    } else {
      this.sortOrder = 1;
      this.sortField = this.selectedSortOption;
    }
  }

  onRoleOrPermissionChange(): void {
    if (!this.selectedRole && !this.selectedPermission) {
      this.filteredUsers = this.users;
      return;
    }
    let rolesWithSelectedPermission: string[] = [];
    if (this.selectedPermission && this.rolesContainingPermission[this.selectedPermission]) {
      rolesWithSelectedPermission = this.rolesContainingPermission[this.selectedPermission];
    }
    this.filteredUsers = this.users.filter((user: User) => {
      if ((this.selectedRole) && (!user.roles.includes(this.selectedRole))) {
        return false;
      }
      if (this.selectedPermission) {
        const hasUserRoleWithPermission: boolean = user.roles.some((roleId: string) => 
          rolesWithSelectedPermission.includes(roleId) || this.superAdminRoles.includes(roleId)
        );
        return (hasUserRoleWithPermission);
      }
      return true;
    });
  }

  exportUserPermissions() {
    const usersPermissions: {[username: string]: string}[] = [];
    this.users.forEach((user: User) => {
      const userPermissions: {[username: string]: string} = {
        'Username': user.name
      };
      permissions.forEach((permissionSelect: SelectItem<string>) => {
        const permissionName: string = permissionSelect.value;
        const rolesWithSelectedPermission: string[] =
          this.rolesContainingPermission[permissionName]? this.rolesContainingPermission[permissionName]: [];
        const hasUserRoleWithPermission: boolean = user.roles.some((roleId: string) => 
          rolesWithSelectedPermission.includes(roleId) || this.superAdminRoles.includes(roleId)
        );
        userPermissions[permissionSelect.label] = hasUserRoleWithPermission? 'Y': 'N';
      });
      usersPermissions.push(userPermissions);
    });
    this.excelExportService.exportAsExcelFile({'User Permissions': usersPermissions}, 'CRM User Permissions');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp(header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
