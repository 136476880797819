class Website {
  '_id'?: string;
  'domain': string;
  'title': string;
  'background': string;
  'color': string;
  'titleBarColor': string;
  'date': Date;
  'api'?: {
    'url': string;
    'secret':  string;
    'key': string;
  };
  'shopify'?: {
    'storeDomain': string;
    'accessToken': string;
    'apiVersion': string;
    'apiSecret': string;
  };
  'order': number;
  'accountingCode': string;
  'usingWebBackend': string;
  'currencyCode': string;
  'businessCentralLLBrandCode': string;
  'businessCentralCLBrandCode': string;
}

export {
  Website,
};