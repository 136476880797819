<p-card  [style]="{height:'300px'}">
  <ng-template pTemplate="header">
    <div class="header" 
        [style.background-color]="getActionBackgroundColour(actionData._id, actionData.backgroundColor)"
        [style.color]="getActionColour(actionData._id)">
      {{actionData._id}}
    </div>
  </ng-template>
  <div class="row" *ngIf="actionData.overdue>0">
    <div class="col-6">
      <h3>Overdue: </h3>
    </div>
    <div class="col-6">
      <h3><a [routerLink]="['/actions-listing',actionData._id,'overdue']">{{actionData.overdue}}</a></h3>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <h3>Today: </h3>
    </div>
    <div class="col-6">
      <h3><a
          [routerLink]="['/actions-listing',actionData._id,'today']">{{actionData.morning+actionData.afternoon+actionData.evening}}</a>
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="col-6" >
    <h6>Morning: </h6>
    </div>
    <div class="col-6" >
      <h6><a [routerLink]="['/actions-listing',actionData._id,'morning']"  >{{actionData.morning}}</a></h6>
    </div>
  </div>
  <div class="row" >
    <div class="col-6">
      <h6>Afternoon: </h6>
    </div>
    <div class="col-6">
      <h6><a [routerLink]="['/actions-listing',actionData._id,'afternoon']"  >{{actionData.afternoon}}</a></h6>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <h6>Evening: </h6>
    </div>
    <div class="col-6">
      <h6><a [routerLink]="['/actions-listing',actionData._id,'evening']" >{{actionData.evening}}</a></h6>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
    <h3>Total: </h3>
    </div>
    <div class="col-6">
      <h3><a [routerLink]="['/actions-listing',actionData._id,'total']" >{{actionData.total}}</a></h3>
    </div>
  </div>
</p-card>
