import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Hardware} from '../models/hardware.model';
import {HardwareAuditRecord} from '../models/hardwareAuditRecord.model';
import {HardwareSet} from '../models/hardwareSet.model';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {PageCountResponse} from '../models/responses/pageCountResponse.model';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {AccountService} from '../models/accountService.model';

@Injectable({
  providedIn: 'root'
})
export class HardwareService {
  API_URL = environment.protocol + environment.IPAddress + '/api';

  constructor(
    private http: HttpClient,
  ) { }

  getHardwareForSite(websiteId: string): Observable<MultiRecordResponse<Hardware>> {
    return this.http.get<MultiRecordResponse<Hardware>>(`${this.API_URL}/hardware/${websiteId}/all`);
  }

  updateHardware(id: string, hardwareParams: {'hardware': Hardware}): Observable<SingleRecordResponse<Hardware>> {
    return this.http.put<SingleRecordResponse<Hardware>>(`${this.API_URL}/hardware/${id}`, hardwareParams);
  }

  saveNewHardware(hardwareParams: {'hardware': Hardware}): Observable<SingleRecordResponse<Hardware>> {
    return this.http.post<SingleRecordResponse<Hardware>>(`${this.API_URL}/hardware`, hardwareParams);
  }

  getHardwareSetsForSite(websiteId: string): Observable<MultiRecordResponse<HardwareSet>> {
    return this.http.get<MultiRecordResponse<HardwareSet>>(`${this.API_URL}/hardwareSet/${websiteId}/all`);
  }

  updateHardwareSet(id: string, hardwareSetParams: {'hardwareSet': HardwareSet}): Observable<SingleRecordResponse<HardwareSet>> {
    return this.http.put<SingleRecordResponse<HardwareSet>>(`${this.API_URL}/hardwareSet/${id}`, hardwareSetParams);
  }

  saveNewHardwareSet(hardwareSetParams: {'hardwareSet': HardwareSet}): Observable<SingleRecordResponse<HardwareSet>> {
    return this.http.post<SingleRecordResponse<HardwareSet>>(`${this.API_URL}/hardwareSet`, hardwareSetParams);
  }

  getHardwareAuditPageCount(startDate: string, endDate: string): Observable<PageCountResponse> {
    return this.http.get<PageCountResponse>(`${this.API_URL}/hardwareAudit/pagecount/${startDate}/${endDate}`);
  }

  getHardwareAuditPage(startDate: string, endDate: string, page: number): Observable<MultiRecordResponse<HardwareAuditRecord>> {
    return this.http.get<MultiRecordResponse<HardwareAuditRecord>>(`${this.API_URL}/hardwareAudit/page/${startDate}/${endDate}/${page}`);
  }

  getServicesForSite(websiteId: string): Observable<MultiRecordResponse<AccountService>> {
    return this.http.get<MultiRecordResponse<AccountService>>(`${this.API_URL}/accountServices/services/${websiteId}/all`);
  }

  updateAccountService(id: string, serviceParams: {'accountService': AccountService}): Observable<SingleRecordResponse<AccountService>> {
    return this.http.put<SingleRecordResponse<AccountService>>(`${this.API_URL}/accountServices/services/${id}`, serviceParams);
  }

  saveNewAccountService(serviceParams: {'accountService': AccountService}): Observable<SingleRecordResponse<AccountService>> {
    return this.http.post<SingleRecordResponse<AccountService>>(`${this.API_URL}/accountServices/services`, serviceParams);
  }
  
}
