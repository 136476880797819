import { Component, OnInit, ViewChild } from '@angular/core';
import {Column} from '../../models/column.model';
import {ConfirmationService} from 'primeng/api';
import {AsAtOrder} from '../../models/reporting/asAtOrder.model';
import {Table} from 'primeng/table';
import {Title} from '@angular/platform-browser';
import {OrderService} from '../../post-order/order.service';
import {ExcelExportService} from '../../post-order/excel-export.service';
import {asAtOrderCols} from '../../lookups/reporting/asAtOrderReportColumns';
import moment from 'moment-timezone';
import {PageCountResponse} from '../../models/responses/pageCountResponse.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {MultiSelectChangeEvent} from '../../models/primeng/multiSelectChangeEvent.model';
import {BrandCfg, getBrandConfigs} from '../../lookups/brands';
import {crmDelay} from '../../helpers/helperFunctions';

const MAX_PARALLEL: number = 5;
const PAGE_RETRIES: number = 3;

@Component({
  selector: 'app-order-as-at',
  templateUrl: './order-as-at.component.html',
  styleUrls: ['./order-as-at.component.scss'],
  providers: [ConfirmationService]
})
export class OrderAsAtComponent implements OnInit {
  orderCols: Column[];
  selectedOrderCols: Column[];
  minDateStr: string;
  maxDateStr: string;
  recordsLoading: boolean = false;
  recordPagesToLoad: number = 0;
  asAtOrderRecords: AsAtOrder[];
  @ViewChild('asAtOrderTable', {static: false})
  asAtOrderTable: Table;
  pagesProcessing: number;
  dateRecordsLoadedFor: string;
  loadedRecordsIncludeDeleted: string;
  brandConfigs: BrandCfg;
  constructor(
    private title: Title,
    private orderService: OrderService,
    private confirmationService: ConfirmationService,
    private excelService: ExcelExportService,
  ) { }

  ngOnInit(): void {
    this.title.setTitle('As At Order Report');
    this.asAtOrderRecords = [];
    this.orderCols = asAtOrderCols;
    this.brandConfigs = getBrandConfigs();
    this.selectedOrderCols = this.orderCols.filter((col: Column) => col.hide != true);
    // How far back the data was built to
    this.minDateStr = '2023-04-01';
    this.maxDateStr = moment.tz('Europe/London').subtract(1, 'day').format('YYYY-MM-DD');
  }

  loadOrderRecords(forDate: string, includeDeleted: string) {
    this.recordsLoading = true;
    this.asAtOrderRecords = [];
    this.recordPagesToLoad = 0;
    this.dateRecordsLoadedFor = forDate;
    this.loadedRecordsIncludeDeleted = includeDeleted;
    this.orderService.getAsAtOrderReportPageCount(forDate, includeDeleted).subscribe(
      async (response: PageCountResponse) => {
        if (!response.success) {
          this.recordsLoading = false;
          this.showErrorPopUp('Error getting page count', response.message);
        } else {
          this.pagesProcessing = 0;
          this.recordPagesToLoad = response.pageCount!;
          if (this.recordPagesToLoad === 0) {
            this.recordsLoading = false;
          } else {
            for (let page: number = 1; page <= response.pageCount!; page++) {
              // Limit the max number of pages being updated in parallel which allows other work to complete
              // whilst pages are still loading and allows abort if page navigated away from
              while (this.pagesProcessing >= MAX_PARALLEL) {
                await crmDelay(250);
              }
              this.pagesProcessing++;
              this.loadAsAtOrderPage(forDate, includeDeleted, page, PAGE_RETRIES);
            }
          }
        }
      }
    );
  }

  loadAsAtOrderPage(forDate: string, includeDeleted: string, page: number, retryCount: number) {
    this.orderService
      .getAsAtOrderReportPage(forDate, includeDeleted, page)
      .subscribe((response: MultiRecordResponse<AsAtOrder>) => {
        if (!response.success || !response.data) {
          console.log(`Error loading page ${page}. Error: ${response.message}`);
          if (retryCount > 0) {
            this.loadAsAtOrderPage(forDate, includeDeleted, page, retryCount - 1);
            return;
          }
          // Only decrement count if we are not retrying page
          this.recordPagesToLoad--;
          this.pagesProcessing--;
          this.showErrorPopUp('Error loading order records', `Something went wrong try again. Error: ${response.message}`);
          return;
        }
        this.asAtOrderRecords = this.asAtOrderRecords.concat(response.data);
        this.recordPagesToLoad--;
        this.pagesProcessing--;
        if (this.recordPagesToLoad === 0) {
          this.recordsLoading = false;
        }
      }, 
      (err: any) => {
        console.log(`Error loading page ${page}. Error ${err.message}`);
        if (retryCount > 0) {
          this.loadAsAtOrderPage(forDate, includeDeleted, page, retryCount - 1);
          return;
        }
        // Only decrement count if we are not retrying page
        this.recordPagesToLoad--;
        this.pagesProcessing--;
        this.showErrorPopUp('Error loading order records', `Something went wrong try again. Error: ${err.message}`);
      });
  }

  showInfoPopUp(header: string, message: string): void {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  exportAsAtOrdersToExcel() {
    const recordsToExport: AsAtOrder[] = this.asAtOrderTable.filteredValue? this.asAtOrderTable.filteredValue: this.asAtOrderRecords;
    const ordersFormattedForExport: any[] = recordsToExport.map((asAtOrder: AsAtOrder) => {
      let formattedOrderRecord: any = {};
      this.selectedOrderCols.forEach((col: Column) => {
        if (col.field.includes('Date')) {
          let fieldMoment: moment.Moment;
          if (asAtOrder[col.field]) {
            fieldMoment = moment.tz(asAtOrder[col.field], 'Europe/London');
          }
          if (fieldMoment && fieldMoment.isValid()) {
            formattedOrderRecord[col.header] = fieldMoment.toDate();
          } else {
            formattedOrderRecord[col.header] = '';
          }
        } else {
          formattedOrderRecord[col.header] = asAtOrder[col.field];
        }
      });
      return formattedOrderRecord;
    });
    this.excelService.exportAsExcelFile({'As At Orders': ordersFormattedForExport}, `Orders As At ${this.dateRecordsLoadedFor}`);
  }

  exportAsAtOrdersAsJson() {
    const recordsToExport: AsAtOrder[] = this.asAtOrderTable.filteredValue? this.asAtOrderTable.filteredValue: this.asAtOrderRecords;
    const jsonString: string = JSON.stringify(recordsToExport);
    const blob: Blob = new Blob([jsonString], { type: 'application/json' });
    const url: string = window.URL.createObjectURL(blob);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = `Orders As At ${this.dateRecordsLoadedFor}.json`;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  columnsChanged(_event: MultiSelectChangeEvent<Column>) {
    this.selectedOrderCols.sort((a: Column, b: Column) => a.order - b.order);
  }
}
