import { Pipe, PipeTransform } from '@angular/core';
import {getEquipmentName} from '../lookups/equipmentSerial';

@Pipe({
  name: 'serialToName'
})
export class SerialToNamePipe implements PipeTransform {

  transform(serial: string): string {
    return getEquipmentName(serial);
  }

}
