import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {Feedback} from "./feedback.model";
import {environment} from '../../environments/environment';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';


@Injectable({
  providedIn: 'root'
})
export class FeedbacksService {
  private API_URL: string = environment.protocol+environment.IPAddress+'/api';

  constructor(private http: HttpClient) {
  }

  getFeedbacks(): Observable<MultiRecordResponse<Feedback>> {
    return this.http.get<MultiRecordResponse<Feedback>>(`${this.API_URL}/feedback/`);
  }
  getFeedback(id: string): Observable<SingleRecordResponse<Feedback>> {
    return this.http.get<SingleRecordResponse<Feedback>>(`${this.API_URL}/feedback/${id}`);
  }
  createFeedback(feedbackParams): Observable<SingleRecordResponse<Feedback>> {
    return this.http.post<SingleRecordResponse<Feedback>>(`${this.API_URL}/feedback`, feedbackParams);
  }

  updateFeedback(id: string, feedbackParams): Observable<SimpleResponse> {
    return this.http.put<SimpleResponse>(`${this.API_URL}/feedback/${id}`, feedbackParams);
  }

  deleteFeedback(id: string): Observable<SingleRecordResponse<Feedback>> {
    return this.http.delete<SingleRecordResponse<Feedback>>(`${this.API_URL}/feedback/${id}`);
  }

}
