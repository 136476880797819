<div class="container feedbackstop">
  <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()">
    <p-panel>
      <div class="container-fluid" style="margin: 10px 0px">
        <div class="row py-2">
          <div class="col-2">
            Category *:
          </div>
          <div class="col-8">
            <p-dropdown [options]="categories" formControlName="category"></p-dropdown>
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row py-2">
          <div class="col-2">
            Title *:
          </div>
          <div class="col-8">
            <input pInputText type="text" formControlName="title" placeholder="Required"/>
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row py-2">
          <div class="col-2">
            Description *:
          </div>
          <div class="col-8">
            <textarea pInputText type="text" rows="5" formControlName="body" placeholder="Required"></textarea>
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row py-2">
          <div class="col-2"></div>
          <div class="col-6">
            <button pButton type="submit" label="Submit" class="btn btn-primary" [disabled]="!feedbackForm.valid"></button>
          </div>
          <div class="col-2"></div>
        </div>
        <div style="text-align:center;margin-top:20px" *ngIf="submitted">
          Form Submitted
          <br>
        </div>
      </div>
    </p-panel>
  </form>

</div>
<!-- feedBacks -->
<div class="container feedbacksbody">
  <p-accordion>
    <!--<p-accordionTab *ngFor="let feedback of feedbacks"   [selected]="true">-->
    <p-accordionTab *ngFor="let feedback of feedbacks ">
      <p-header>
        <div class="row">
          <div class="col-8 issueHeading">
            {{feedback.title}} | {{feedback.category | uppercase}} | Submitted by: {{feedback.createdBy}}
          </div>
          <div class="col-4 text-right issueDate">
            {{feedback.createdAt | date:'medium'}} |  
            <p-toggleButton onLabel="done" offLabel="in progress" [(ngModel)]="feedback.checked" (click)="checkedFeedback(feedback)" ></p-toggleButton>
          </div>
        </div>
      </p-header>
      <div class="row">
        <div class="col-12">
          <div class="alert alert-danger">{{feedback.body}}</div>
        </div>
      </div>
      <div class="row" *ngFor="let response of feedback.responses">
        <div class="col-1 respnder">
          {{response.createdBy }}:
        </div>
        <div class="col-11">
          <div class="alert alert-success marginbottom">{{response.body}}</div>
          <span class="float-sm-right timemargin">{{response.createdAt | date:'medium'}}</span>
        </div>
      </div>
      <br><br>
      <div class="row" *ngIf=" ! feedback.checked">
        <div class="col-12" >
          <textarea pInputText type="text" rows="5" [(ngModel)]="feedback.newRep" placeholder="Required"></textarea>
        </div>
        <div class="col-12 text-right">
          <button pButton type="button" (click)="addrep(feedback)" label="Reply" class="btn btn-primary"></button>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>
