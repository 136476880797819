import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import {LogsService} from '../reporting/logs/logs.service';
import {environment} from '../../environments/environment';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {LogWithUpload} from '../models/log.model';
import {Column} from '../models/column.model';
import {HomepageFormatOrder} from '../models/homepageFormatOrder.model';
@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  constructor(
    private logsService: LogsService,
    private http: HttpClient,
  ) {
  }
  API_URL: string = environment.protocol + environment.IPAddress + '/api';

  static toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${moment.tz('Europe/London').format('YYYYMMDD_HHmm')}.xlsx`;
  }

  public exportAsExcelFile(json: {[tabName: string]: any[]}, excelFileName: string): { fileName: string; contents: string } {
    // let workbook: XLSX.WorkBook;
    const sheets: {[sheetName: string]: XLSX.WorkSheet} = {};
    const sheetNames: string[] = [];
    for (let key in json) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json[key]);
      sheets[key] = worksheet;
      sheetNames.push(key);
    }
    const workbook: XLSX.WorkBook = {Sheets: sheets, SheetNames: sheetNames};

    const fileName: string =  ExcelExportService.toExportFileName(excelFileName);
    const opts: XLSX.WritingOptions = {compression: true, bookType: 'xlsx'};

    XLSX.writeFile(workbook, fileName, opts);
    const contents = XLSX.write(workbook, {compression: true, bookType: 'xlsx', type: 'base64'});

    const res = {
      fileName: fileName,
      contents: contents
    }
    return res;
  }

  /**
   * Exports all data to excel
   * @param orders 
   * @param cols 
   * @param userName 
   * @param orderExportOption 
   * @return {boolean} whether the export worked, without truncating any data
   */
  exportAllColumns(orders: HomepageFormatOrder[], cols: Column[], userName: string, orderExportOption:string = '1 sheet, 1 tab total'): boolean {
    let result: boolean = true;
    const listByBrand = {};
    const orderIdAndTdCode = [];
    orders.forEach((order: HomepageFormatOrder) => {
      orderIdAndTdCode.push(
        { orderId: order._id, tdCode: order.tdCode }
      );
      const expOrder = { _id: order._id };
      for (const cole of cols) {
        const field: string = (cole['field']);
        const header: string = (cole['header']);
        expOrder[header] = order[field];

        if (['outstandingActionsName', 'renewalErrors', 'Tags', 'tagExpiryDates', 'vim'].includes(field)) {
          expOrder[header] = order[field].join(" |\n");
        } else if (field == 'statusDate') {
          expOrder[header] = moment(order['statusDate']).format('DD/MM/YYYY').replace("Invalid date", "");
        } else if (['renewalDate', 'dispatchDate'].includes(field)) {
          if (order[field] && (order[field].length == 10) && (order[field] != '01/01/1970')) {
            expOrder[header] = order[field];
          }
        } else if (['created', 'cancellationDate', 'returnDate'].includes(field)) {
          // This checks for 01/01/1970
          if (order[field] && (order[field].getTime() != 0)) {
            expOrder[header] =  moment.tz(order[field], 'Europe/London').format('DD/MM/YYYY HH:mm:ss');
          }
        } else if (field == 'outstandingActionsRenewalDateTaken') {
          expOrder[header] = order[field].map(date => {
            return moment(date).format('DD/MM/YYYY')
          }).join(" |\n").replace("Invalid date", "NO DATE");
        } else if (field == 'outstandingActionsInitiatedDate') {
          expOrder[header] = order[field].map(date => {
            return moment(date).format('DD/MM/YYYY')
          }).join(" |\n").replace("Invalid date", "NO DATE");
        } else if (field == 'notesDate') {
          expOrder[header] = order.noteDateFormatted.map((noteDate: string) => {
            return noteDate? noteDate: 'NO DATE'
          }).join("|\n");
        } else if (field == 'notesContent') {
          expOrder[header] = order.notesContent.join('|');
        } else if (field == 'noteCategory') {
          expOrder[header] = order.noteCategory.join('|');
        } else if (field == 'notesUserName') {
          expOrder[header] = order.notesUserName.join('|');
        } else if (field == 'notesWithDate') {
          expOrder[header] = order.notesUserName.map((noteUser: string, noteIdx: number) => {
            return `${noteUser? noteUser + ': ' : ''} ${order.noteDateFormatted[noteIdx]} [${order.noteCategory[noteIdx]}] ${order.notesContent[noteIdx]}`
          }).join('|');
        } else if (field == 'discounts') {
          expOrder[header] = order['discounts']? order['discounts'].join("|\n"): '';
        }
        // Excel limit is supposed to be 32,767 but an imported CSV truncated at less than that
        if (expOrder[header] && (expOrder[header].length > 32700)) {
          expOrder[header] = 'Truncated...' + expOrder[header].slice(-32700);
          result = false;
        }
      }

      let titleToUse: string = order['title'];
      if (orderExportOption === '1 sheet, 1 tab total') {
        titleToUse = 'data';
      }

      if (listByBrand[titleToUse] == undefined) {
        listByBrand[titleToUse] = [expOrder];
      } else {
        listByBrand[titleToUse].push(expOrder);
      }
      return expOrder;
    });
    let res: any={};
    if (orderExportOption === '1 sheet, 1 tab total') {
      res = this.exportAsExcelFile(listByBrand, 'Order');
    } else if (orderExportOption === '1 sheet, 1 tab/brand') {
      res = this.exportAsExcelFile(listByBrand, 'Order');
    } else if (orderExportOption === '1 sheet/brand') {
      for (let key in listByBrand) {
        res = this.exportAsExcelFile(
          { [key]: listByBrand[key] },
          key + "_Order"
        );
      }
    }
    const counts = {};
    for (let key in listByBrand) {
      listByBrand[key].map(row =>
        row['Brand']
      ).forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1;
      });
    }
    const fileName: string = res.fileName?.split('.')?.[0] ? res.fileName.split('.')[0] + '.csv' : '';
    const updateLog: LogWithUpload = {
      fileName: fileName,
      source: 'Export',
      nbRecorders: orders.length,
      fields: cols.map(col => col.header),
      recorderPerBrand: counts,
      user: userName,
      csvContent: JSON.stringify(orderIdAndTdCode),
      isFileCsv: true,
    };

    function createExportLog(excelService: ExcelExportService, exportLog: LogWithUpload, retryCount: number) {
      excelService.logsService
        .createLog({log: exportLog})
        .subscribe((logres: SimpleResponse) => {
          if (!logres.success) {
            console.error('Error creating export log', logres.message);
            if (retryCount > 0) {
              createExportLog(excelService, exportLog, retryCount-1);
            }
          } else {
            excelService.duplicateExportAutoNotification({
              userName,
              exportedCustomerRecordCount: orders.length,
              userId: localStorage.getItem('userId')
            });
          }
        }, err => {
          console.error('Error creating export log', err);
          if (retryCount > 0) {
            createExportLog(excelService, exportLog, retryCount-1);
          }
        });
    }

    createExportLog(this, updateLog, 2);
    return result;
  }

  duplicateExportAutoNotification(params) {
    this.http.post(`${this.API_URL}/notifications/duplicate-export`, params)
      .subscribe((response: any) => {
        console.debug("Response on exporting duplicate record :: ", response);
      }, (err: Error) => {
        console.error("Error creating duplicate export notification. Error: ", err);
      });
  }
}
