import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {OverlayOptions, SelectItem} from 'primeng/api';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {getMessageConfig} from '../../lookups/manualSendMessageTypes';
import {ProposedMessageService} from '../../messages-list/proposed-message.service';
import {ContactDetails} from '../../models/contactDetails.model';
import {Order} from '../../models/order.model';
import {OutstandingAction} from '../../models/outstandingAction.model';
import {ProposedMessage} from '../../models/proposedMessage.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {getExternalEmailValidator} from '../../validators/email.validator';
import {getContactFlags} from '../../helpers/helperFunctions';
import {OrderFormTag} from '../../models/orderFormTag.model';
import {PRICE_REGEX} from '../../helpers/getPlanCodeAndPrice';
import {DEFAULT_OVERLAY} from '../../lookups/primeng/overlayOptions';

@Component({
  selector: 'app-email-send[order][tags][emailTypes][fieldInitialisation][closeModal]',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.scss']
})
export class EmailSendComponent implements OnInit {
  @Input() order: Order; // Tags on definition don't match actual format, so have to send in separately
  @Input() tags: OrderFormTag[];
  @Input() emailTypes: SelectItem<string>[];
  @Input() fieldInitialisation: any;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  availableContactDetails: SelectItem<ContactDetails>[] = [];
  contactDetailsToPickFrom: SelectItem<ContactDetails>[] = [];
  contactForm: UntypedFormGroup;
  sendDisabled: boolean;
  selectedContactDetails: ContactDetails;
  resultMessage: string;
  resultSuccess: boolean;
  dialogVisible: boolean;
  links: {[linkType: string]: string} = {};
  hasBlockAlarmComms: boolean;
  numberOnly = numberOnly;
  overlayOptions: OverlayOptions = DEFAULT_OVERLAY;

  constructor(
    private proposedMessageService: ProposedMessageService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.sendDisabled = true;
    this.dialogVisible = true;
    this.prepareContacts();
    this.prepareCancellationEmails();
    this.hasBlockAlarmComms = this.tags.some((tag: OrderFormTag) => 
      tag.content == 'Block Alarm Correspondence'
    );

    this.contactForm = this.formBuilder.group({
      'emailTypeToSend': [null, Validators.required],
      'source': ['Manually Entered'],
      'firstName': [null, Validators.required],
      'lastName': [null, Validators.required],
      'reFirstName': [null],
      'reLastName': [null],
      'email': [null, [getExternalEmailValidator(true)]],
      'link': [null],
      'emailVariation': [null, Validators.required],
      'paymentFor': [null, Validators.required],
      'paymentAmount': [null, Validators.required],
    });
    // Select the first type automatically
    if (this.emailTypes.length > 0) {
      this.contactForm.controls['emailTypeToSend'].setValue(this.emailTypes[0].value);
    }
    this.selectedTypeChange();
    this.contactForm.patchValue(this.fieldInitialisation);
    if (this.contactDetailsToPickFrom.length > 1) {
      // Select the first contact on loading to save staff time if they want to use the default (most likely)
      this.selectedContactDetails = this.contactDetailsToPickFrom[0].value;
      this.selectedContactChange();
    }
    this.sendDisabled = false;
  }

  prepareContacts(): void {
    // We don't need mobile or address for emailing
    if (this.order.alarmUserDetails.correspondenceDetails?.firstName || this.order.alarmUserDetails.correspondenceDetails?.lastName) {
      this.availableContactDetails.push({
        label: `Correspondent: ${this.order.alarmUserDetails.correspondenceDetails.firstName}` +
          `${this.order.alarmUserDetails.correspondenceDetails.lastName}; ${this.order.alarmUserDetails.correspondenceDetails.email}`,
        value: {
          'source': 'Correspondent',
          'firstName': this.order.alarmUserDetails.correspondenceDetails.firstName,
          'lastName': this.order.alarmUserDetails.correspondenceDetails.lastName,
          'email': this.order.alarmUserDetails.correspondenceDetails.email,
          'mobile': '',
          'address': [],
        }
      });
      // Don't allow user only types if there's a correspondent
      this.emailTypes = this.emailTypes.filter((emailType: SelectItem<string>) => 
        !getMessageConfig(emailType.value).toUsersOnly
      );
    } else {
      // Don't allow emails to alarm users if correspondent filled in
      this.availableContactDetails.push({
        label: `Alarm User: ${this.order.alarmUserDetails.firstName} ${this.order.alarmUserDetails.lastName}; ` +
          `${this.order.alarmUserDetails.email} ${getContactFlags(this.order.alarmUserDetails)}`,
        value: {
          'source': 'Alarm User',
          'firstName': this.order.alarmUserDetails.firstName,
          'lastName': this.order.alarmUserDetails.lastName,
          'email': this.order.alarmUserDetails.email,
          'mobile': '',
          'address': [],
        }
      });
      let userNumber: number = 1;
      this.order.alarmUserDetails.users.forEach(user => {
        this.availableContactDetails.push({
          label: `Additional User ${userNumber++}: ${user.firstName} ${user.lastName}; ${user.email} ${getContactFlags(user)}`,
          value: {
            'source': `Additional User ${userNumber++}`,
            'firstName': user.firstName,
            'lastName': user.lastName,
            'email': user.email,
            'mobile': '',
            'address': [],
          }
        });
      });
    }
    let contactNumber: number = 1;
    this.order.accountContacts.forEach(accountContact => {
      this.availableContactDetails.push({
        label: `Account Contact ${contactNumber++}: ${accountContact.accFirstName} ${accountContact.accLastName}; ` +
          `${accountContact.accEmail} ${getContactFlags(accountContact)}`,
        value: {
          'source': `Account Contact ${contactNumber++}`,
          'firstName': accountContact.accFirstName,
          'lastName': accountContact.accLastName,
          'email': accountContact.accEmail,
          'mobile': '',
          'address': [],
        }
      });
    });
    if ((this.order.status.status == 'cancelling') && this.order.cancellation &&
        (this.order.cancellation.personReturning || this.order.cancellation.cancellationEmail)) {
      this.availableContactDetails.push({
        label: `Cancellation Contact: ${this.order.cancellation.personReturning}; ${this.order.cancellation.cancellationEmail}`,
        value: {
          'source': 'Cancellation Contact',
          'firstName': this.order.cancellation.personReturning,
          'lastName': '',
          'email': this.order.cancellation.cancellationEmail,
          'mobile': '',
          'address': [],
        }
      });
    }
    const returningEquipAction: OutstandingAction = this.order.outstandingActions.find((action: OutstandingAction) => 
      action.outstandingName == 'Returning Equipment'
    );
    if (returningEquipAction && (returningEquipAction.personReturning || returningEquipAction.status)) {
      this.availableContactDetails.push({
        label: `Returning Equip Contact: ${returningEquipAction.personReturning}; ${returningEquipAction.status}`,
        value: {
          'source': 'Returning Equip Contact',
          'firstName': returningEquipAction.personReturning,
          'lastName': '',
          'email': returningEquipAction.status,
          'mobile': '',
          'address': [],
        }
      });
    }
  }

  prepareCancellationEmails(): void {
    if (this.order.status.status != 'cancelling') {
      this.emailTypes = this.emailTypes.filter((emailType: SelectItem<string>) => 
        !emailType.value.startsWith('Cancellation')
      );
    } else {
      if (this.emailTypes.some((emailType: SelectItem<string>) =>
        emailType.value.startsWith('Cancellation')
      )) {
        this.proposedMessageService.getCancellationLink(this.order._id)
          .subscribe((linkResult: SingleRecordResponse<string>) => {
            if (linkResult.success && linkResult.data) {
              this.links['Cancellation'] = linkResult.data;
            }
          });
      }
    }
  }

  get areRegardingFieldsRequired(): boolean {
    return (getMessageConfig(this.contactForm.controls['emailTypeToSend'].value)?.regardingFields == 'required');
  }

  get areRegardingFieldsAllowed(): boolean {
    return ['optional', 'required'].includes(getMessageConfig(this.contactForm.controls['emailTypeToSend'].value)?.regardingFields);
  }

  get hasRegardingFirstName(): boolean {
    return !!this.reFirstName.value;
  }

  get hasRegardingLastName(): boolean {
    return !!this.reLastName.value;
  }

  get toUsersOnly(): boolean {
    return getMessageConfig(this.contactForm.controls['emailTypeToSend'].value)?.toUsersOnly;
  }

  get isCancellationMessage(): boolean {
    const emailType: string = (this.contactForm.controls['emailTypeToSend'].value as string);
    if (!emailType) {
      return false;
    }
    return emailType.startsWith('Cancellation');
  }

  get hasVariations(): boolean {
    return (getMessageConfig(this.contactForm.controls['emailTypeToSend'].value)?.variations.length > 0);
  }

  get arePaymentFieldsRequired(): boolean {
    return getMessageConfig(this.contactForm.controls['emailTypeToSend'].value)?.paymentFieldsRequired;
  }

  get isRenewalWithNoPrice(): boolean {
    const emailType: string = this.contactForm.controls['emailTypeToSend'].value;
    if (emailType && emailType.toLocaleLowerCase().includes('renewal')) {
      const renewalPriceString: string = this.order.renewalInformation.renewalPrice;
      if (!renewalPriceString || !PRICE_REGEX.test(renewalPriceString)) {
        return true;
      }
      const renewalPrice: number = Number(renewalPriceString);
      return (renewalPrice <= 0);
    }
    return false;
  }

  sendEmail() {
    this.resultMessage = '';
    this.sendDisabled = true;
    const params: ProposedMessage = {
      'brand': this.order.website.title,
      'messageType': this.contactForm.controls['emailTypeToSend'].value,
      'methods': ['email'],
      'tdCode': this.order.alarmUserDetails.tdCode,
      'orderId': this.order._id,
      'status': 'Ready to Send',
      'renewalDate': this.order.renewalInformation.renewalDate,
      'contactDetails': {
        'source': this.contactForm.controls['source'].value,
        'firstName': this.contactForm.controls['firstName'].value,
        'lastName': this.contactForm.controls['lastName'].value,
        'reFirstName': this.contactForm.controls['reFirstName'].value,
        'reLastName': this.contactForm.controls['reLastName'].value,
        'email': this.contactForm.controls['email'].value,
        'mobile': '',
        'address': [],
      },
    };
    if (this.isCancellationMessage) {
      params.cancellationInfo = {
        'derivedPlan': '',
        'link': this.contactForm.controls['link'].value,
        'unitCost': '',
        'vatCost': '',
        'equipCost': '',
        'equipName': '',
      }
    }
    if (this.contactForm.controls['emailTypeToSend'].value.startsWith('Renewal')) {
      params.renewalData = {
        'showDdOffer': (this.order.accountDetails.planType == 'annual'),
        'renewalLink': '',
      };
    }
    if (this.hasVariations) {
      params.emailVariation = this.emailVariation.value;
    }
    if (this.arePaymentFieldsRequired) {
      params.paymentFor = this.paymentFor.value;
      params.paymentAmount = this.paymentAmount.value.toFixed(2);
    }
    this.proposedMessageService.addMessage(params).subscribe((response: SingleRecordResponse<ProposedMessage>) => {
      if (response.success) {
        this.resultMessage = 'Email queued for sending';
        this.resultSuccess = true;
      } else {
        this.resultMessage = `Error queuing email for sending ${response.message}`;
        this.resultSuccess = false;
        this.sendDisabled = false;
      }
      
    }, (err: Error) => {
      this.sendDisabled = false;
      this.resultMessage = `Error queuing email for sending ${err.message}`;
      this.resultSuccess = false;
    });
  }

  isOkToSend(): boolean {
    return this.contactForm.valid && !this.sendDisabled &&
      // This part makes sure if one provided both are when regarding is optional
      (this.hasRegardingFirstName == this.hasRegardingLastName) &&
      !this.isRenewalWithNoPrice;
  }

  closeDialog() {
    this.resultMessage = '';
    this.closeModal.emit();
  }

  selectedContactChange(): void {
    if (this.selectedContactDetails) {
      this.contactForm.patchValue({
        'source': this.selectedContactDetails.source,
        'firstName': this.selectedContactDetails.firstName,
        'lastName': this.selectedContactDetails.lastName,
        'email': this.selectedContactDetails.email,
      });
    } else {
      this.contactForm.patchValue({
        'source': 'Manually Entered',
        'firstName': '',
        'lastName': '',
        'email': '',
      });
    }
  }

  get reFirstName(): AbstractControl {
    return this.contactForm.get('reFirstName');
  }

  get reLastName(): AbstractControl {
    return this.contactForm.get('reLastName');
  }

  get emailVariation(): AbstractControl {
    return this.contactForm.get('emailVariation');
  }

  get paymentFor(): AbstractControl {
    return this.contactForm.get('paymentFor');
  }

  get paymentAmount(): AbstractControl {
    return this.contactForm.get('paymentAmount');
  }

  selectedTypeChange(): void {
    let selectFirstContact: boolean = false;
    if (this.isCancellationMessage) {
      this.contactForm.patchValue({
        'link': this.links['Cancellation']? this.links['Cancellation']: '',
      });
    }
    if (this.areRegardingFieldsAllowed) {
      this.contactForm.patchValue({
        'reFirstName': this.order.alarmUserDetails.firstName,
        'reLastName': this.order.alarmUserDetails.lastName,
      });
      this.contactDetailsToPickFrom = this.availableContactDetails.filter((contact: SelectItem<ContactDetails>) => 
        !contact.value.source.includes('User')
      );
      if (this.selectedContactDetails?.source.includes('User')) {
        selectFirstContact = true;
      }
    } else {
      this.contactForm.patchValue({
        'reFirstName': '',
        'reLastName': '',
      });
      if (this.toUsersOnly) {
        this.contactDetailsToPickFrom = this.availableContactDetails.filter((contact: SelectItem<ContactDetails>) => 
          contact.value.source.includes('User')
        );
        if (!this.selectedContactDetails?.source.includes('User')) {
          selectFirstContact = true;
        }
      } else {
        this.contactDetailsToPickFrom = this.availableContactDetails;
      }
    }
    // Have to do this rather than enable/disable fields as might be optional
    if (this.areRegardingFieldsRequired) {
      this.reFirstName.setValidators(Validators.required);
      this.reLastName.setValidators(Validators.required);
    } else {
      this.reFirstName.clearValidators();
      this.reLastName.clearValidators();
    }
    if (selectFirstContact && (this.contactDetailsToPickFrom.length == 0)) {
      this.selectedContactDetails = undefined;
      this.contactForm.patchValue({
        'source': 'Manually Entered',
        'firstName': '',
        'lastName': '',
        'email': '',
      });
    } else if (selectFirstContact || (this.contactDetailsToPickFrom.length == 1)) {
      this.selectedContactDetails = this.contactDetailsToPickFrom[0].value;
      this.contactForm.patchValue({
        'source': this.selectedContactDetails.source,
        'firstName': this.selectedContactDetails.firstName,
        'lastName': this.selectedContactDetails.lastName,
        'email': this.selectedContactDetails.email,
      });
    }
    if (this.hasVariations) {
      this.emailVariation.enable();
    } else {
      this.emailVariation.disable();
    }
    if (this.arePaymentFieldsRequired) {
      this.paymentFor.enable();
      this.paymentAmount.enable();
    } else {
      this.paymentFor.disable();
      this.paymentAmount.disable();
    }
    this.emailVariation.updateValueAndValidity();
    this.reFirstName.updateValueAndValidity();
    this.reLastName.updateValueAndValidity();
  }
}
