import {FaultyEquipmentService} from './../faulty-equipment.service';
import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {FaultyEquipment} from '../../../models/faultyEquipment.model';
import {UpdateFaultyEquipmentRequest} from '../../../models/requests/updateFaultyEquipmentRequest.model';
import {SingleRecordResponse} from '../../../models/responses/singleRecordResponse.model';
import {equipmentCategories, getAlarmsBrands, getCableBrands, getAccessoryBrands, getAlarmFaults, getAccessoryFaults, 
  getCableFaults, getAlarmAndAccessoryFaultyProducts, getCableFaultyProducts, shelfLocations, getNotFixedReasons}
  from '../../../lookups/faultyEquipment';
import {OrderService} from '../../order.service';
import {FindOrderResponse} from '../../../models/responses/findOrderResponse.model';
import {UsersService} from '../../../setup/users/users.service';
import {AddFaultyEquipmentRequest} from '../../../models/requests/addFaultyEquipmentRequest.model';
@Component({
  selector: 'app-detail-faulty-equipment[faultyEquipmentDetailId][openingMode][closeFaultyEquipmentDetailDialog]',
  templateUrl: './detail-faulty-equipment.component.html',
  styleUrls: ['./detail-faulty-equipment.component.scss'],
  providers: [ConfirmationService]
})
export class DetailFaultyEquipmentComponent implements OnInit {

  @Input() faultyEquipmentDetailId: string = null;
  @Input() openingMode: string;
  @Output() closeFaultyEquipmentDetailDialog: EventEmitter<SingleRecordResponse<FaultyEquipment>> = new EventEmitter<SingleRecordResponse<FaultyEquipment>>();
  faultyEquipmentDetail: FaultyEquipment;
  faultyProductOptions: SelectItem<string>[] = [];
  isSubmitButtonDisabled: boolean = false;
  loading: boolean = true;
  visible: boolean = true;
  equipmentCategories: SelectItem<string>[];
  equipmentBrandOptions: SelectItem<string>[];
  faultOptions: SelectItem<string>[];
  equipmentBrandByCategory: {[brand: string]: SelectItem<string>[]} = {};
  equipmentFaultByCategory: {[brand: string]: SelectItem<string>[]} = {};
  unlocked: boolean;
  shelfLocations: SelectItem<number>[]  = [];
  popupHeader: string = '';
  reasonNotFixedOptions: SelectItem<string>[];

  constructor(
    private faultyEquipmentService: FaultyEquipmentService,
    private confirmationService: ConfirmationService,
    private orderService: OrderService,
    private usersService: UsersService,
  ) {
   }

  ngOnInit(): void {
    this.unlocked = false;
    this.initializeAddEquipmentVariables();
    if (this.faultyEquipmentDetailId) {
      this.getFaultyEquipmentDetail();
    } else {
      this.loading = false;
      this.faultyEquipmentDetail.reportingUser = localStorage.getItem('userName');
      this.faultyEquipmentDetail.reportedAt = new Date().toISOString();
    }
  }
  
  userCanAdministerFaults(): boolean {
    return this.usersService.userHasPermission('Fault Admin');
  }

  get tdCode(): string {
    return this.faultyEquipmentDetail.orderId?.alarmUserDetails?.tdCode;
  }

  set tdCode(code: string) {
    this.faultyEquipmentDetail.orderId = {
      'alarmUserDetails': {
        'tdCode': code,
      }
    };
  }

  getFaultyEquipmentDetail() {
    this.faultyEquipmentService.getFaultyEquipment(this.faultyEquipmentDetailId)
      .subscribe((response: SingleRecordResponse<FaultyEquipment>) => {
        if (response.success) {
          this.faultyEquipmentDetail = response.data;
          if ((this.faultyEquipmentDetail.equipmentType == 'Pendant/Accessories') ||
              (this.faultyEquipmentDetail.equipmentType == 'Alarm')) {
            this.faultyProductOptions = getAlarmAndAccessoryFaultyProducts();
          } else if (this.faultyEquipmentDetail.equipmentType == 'Cable') {
            this.faultyProductOptions = getCableFaultyProducts();
          }
          this.equipmentBrandOptions = this.equipmentBrandByCategory[this.faultyEquipmentDetail.equipmentType];
          this.faultOptions = this.equipmentFaultByCategory[this.faultyEquipmentDetail.equipmentType];
          this.loading = false;
        } else {
          this.showErrorPopUp(
            'Something went wrong',
            `Error retrieving fault details. Error: ${response.message}`
          );
        }
      }, (err: Error) => {
        this.showErrorPopUp(
          'Something went wrong',
          `Error retrieving fault details. Error: ${err.message}`
        );
      });
  }

  initializeAddEquipmentVariables() {
    // this.loading = false;
    this.faultyEquipmentDetail = {
      _id: '',
      orderId: {},
      serial: '',
      reportedFault: '',
      reportingUser: '',
      reportedAt: null,
      outcomeOfTest: '',
      tester: '',
      dateTested: null,
      troubleShootingCorrect: '',
      faultyProduct: '',
      dateSentToSupplier: null,
      dateReturnedFromSupplier: null,
      fixed: null,
      equipmentType: '',
      equipmentBrand: '',
      additionalNote: '',
      deleted: false,
      deletedAt: null,
      deletedBy: '',
      warranty: null,
      reasonNotFixed: '',
    }
    this.reasonNotFixedOptions = getNotFixedReasons();
    this.equipmentCategories = equipmentCategories;
    this.equipmentBrandByCategory['Alarm'] = getAlarmsBrands();
    this.equipmentBrandByCategory['Pendant/Accessories'] = getAccessoryBrands();
    this.equipmentBrandByCategory['Cable'] = getCableBrands();
    this.equipmentFaultByCategory['Alarm'] = getAlarmFaults();
    this.equipmentFaultByCategory['Pendant/Accessories'] = getAccessoryFaults();
    this.equipmentFaultByCategory['Cable'] = getCableFaults();
    this.shelfLocations = shelfLocations;
    if (this.openingMode == 'update')
      this.popupHeader = 'Update Faulty Equipment';
    else if (this.openingMode == 'read-only')
      this.popupHeader = 'Faulty Equipment Detail';
    else
      this.popupHeader = 'Add New Faulty Equipment';
  }

  updateDefaultEquipment() {
    this.isSubmitButtonDisabled = true;
    const params: UpdateFaultyEquipmentRequest = {
      'serial': this.faultyEquipmentDetail.serial,
      'outcomeOfTest': this.faultyEquipmentDetail.outcomeOfTest,
      'tester': this.faultyEquipmentDetail.tester,
      'dateTested': this.faultyEquipmentDetail.dateTested,
      'troubleShootingCorrect': this.faultyEquipmentDetail.troubleShootingCorrect,
      'faultyProduct': this.faultyEquipmentDetail.faultyProduct,
      'dateSentToSupplier': this.faultyEquipmentDetail.dateSentToSupplier,
      'dateReturnedFromSupplier': this.faultyEquipmentDetail.dateReturnedFromSupplier,
      'fixed': this.faultyEquipmentDetail.fixed,
      'shelfLocation': this.faultyEquipmentDetail.shelfLocation,
      'trackingId':this.faultyEquipmentDetail.trackingId,
      'warranty': this.faultyEquipmentDetail.warranty,
      'reasonNotFixed': this.faultyEquipmentDetail.reasonNotFixed,
    };
    console.debug("updateDefaultEquipment PARAMS: ", params);
    // Only set these if record is unlocked
    if (this.unlocked) {
      params.orderId = this.faultyEquipmentDetail.orderId?._id;
      params.equipmentType = this.faultyEquipmentDetail.equipmentType;
      params.equipmentBrand = this.faultyEquipmentDetail.equipmentBrand;
      params.reportedFault = this.faultyEquipmentDetail.reportedFault;
      params.additionalNote = this.faultyEquipmentDetail.additionalNote;
      params.reportingUser = this.faultyEquipmentDetail.reportingUser;
      params.reportedAt = this.faultyEquipmentDetail.reportedAt;
    }
    this.faultyEquipmentService.updateFaultyEquipment(this.faultyEquipmentDetailId, params)
      .subscribe((response: SingleRecordResponse<FaultyEquipment>) => {
        if (response.success) {
          // The update method doesn't fill in the tdCode for us, but we have it already
          response.data.orderId = this.faultyEquipmentDetail.orderId;
          this.closeFaultyEquipmentDetailDialog.emit(response);
        } else {
          this.isSubmitButtonDisabled = false;
          console.error("Error response: ", response.message);
          this.showErrorPopUp(
            'Error',
            `Error updating fault, please try again. Error: ${response.message}`,
          );
        }
      }, (err: Error) => {
        this.isSubmitButtonDisabled = false;
        console.error("Error response: ", err);
        this.showErrorPopUp(
          'Error',
          `Error updating fault, please try again. Error: ${err.message}`,
        );
      });
  }

  addNewFault() {
    const params: AddFaultyEquipmentRequest = {
      'serial': this.faultyEquipmentDetail.serial,
      'reportedFault': this.faultyEquipmentDetail.reportedFault,
      'reportingUser': this.faultyEquipmentDetail.reportingUser,
      'reportedAt': this.faultyEquipmentDetail.reportedAt,
      'outcomeOfTest': this.faultyEquipmentDetail.outcomeOfTest,
      'tester': this.faultyEquipmentDetail.tester,
      'dateTested': this.faultyEquipmentDetail.dateTested,
      'troubleShootingCorrect': this.faultyEquipmentDetail.troubleShootingCorrect,
      'faultyProduct': this.faultyEquipmentDetail.faultyProduct,
      'dateSentToSupplier': this.faultyEquipmentDetail.dateSentToSupplier,
      'dateReturnedFromSupplier': this.faultyEquipmentDetail.dateReturnedFromSupplier,
      'fixed': this.faultyEquipmentDetail.fixed,
      'equipmentType': this.faultyEquipmentDetail.equipmentType,
      'equipmentBrand': this.faultyEquipmentDetail.equipmentBrand,
      'additionalNote': this.faultyEquipmentDetail.additionalNote,
      'shelfLocation': this.faultyEquipmentDetail.shelfLocation,
      'trackingId': this.faultyEquipmentDetail.trackingId,
      'deleted': this.faultyEquipmentDetail.deleted,
      'deletedAt': this.faultyEquipmentDetail.deletedAt,
      'deletedBy': this.faultyEquipmentDetail.deletedBy,
      'warranty': this.faultyEquipmentDetail.warranty,
      'reasonNotFixed': this.faultyEquipmentDetail.reasonNotFixed,
     };
    this.faultyEquipmentService.addFaultToEquipment(params as AddFaultyEquipmentRequest).subscribe((response: any) => {
      if (response.success) {
        this.closeFaultyEquipmentDetailDialog.emit(response);
      } else {
        this.isSubmitButtonDisabled = false;
        this.showErrorPopUp(
          'Error',
          `Error adding fault, please try again. Error: ${response.message}`,
        );
      }
    }, (err: Error) => {
      console.error("Error on add fault to equipment :: ", err);
      this.showErrorPopUp(
        'Error',
        `Error adding fault, please try again. Error: ${err.message}`,
      );
    });
  }

  validateTdCode() {
    this.isSubmitButtonDisabled = true;
    this.orderService.findOrder({
      'alarmUserDetails.tdCode': this.faultyEquipmentDetail.orderId?.alarmUserDetails?.tdCode,
      'deleted': false
    }).subscribe((response: FindOrderResponse) => {
      if (!response.success || !response.orders) {
        this.showErrorPopUp(
          'Error',
          'Error looking up order id from Td Code. Please check the code and try again.',
        );
      } else if (response.orders.length != 1) {
        this.showErrorPopUp(
          'Error',
          `Error looking up order id from Td Code. There are ${response.orders.length} matches.`,
        );
      } else {
        this.faultyEquipmentDetail.orderId._id = response.orders[0]._id;
        this.isSubmitButtonDisabled = false;
      }
    }, (error) => {
      this.showErrorPopUp(
        'Error',
        `Error looking up order id from Td Code, please try again. Error: ${error.message}.`,
      );
    });
  }

  onEquipmentCategoryChange() {
    this.faultyEquipmentDetail.equipmentBrand = null;
    this.faultyEquipmentDetail.reportedFault = null;
    this.faultyEquipmentDetail.faultyProduct = null;
    this.equipmentBrandOptions = this.equipmentBrandByCategory[this.faultyEquipmentDetail.equipmentType];
    this.faultOptions = this.equipmentFaultByCategory[this.faultyEquipmentDetail.equipmentType];
    if (this.faultyEquipmentDetail.equipmentType == 'Alarm' || this.faultyEquipmentDetail.equipmentType == 'Pendant/Accessories') {
      this.faultyProductOptions = getAlarmAndAccessoryFaultyProducts();
    }
    if (this.faultyEquipmentDetail.equipmentType == 'Cable') {
      this.faultyProductOptions = getCableFaultyProducts();
    }
  }

  closeFaultyEquipmentDetail() {
    this.faultyEquipmentDetail = null;
    this.closeFaultyEquipmentDetailDialog.emit({
      'success': false,
      'message': 'closed' 
    });
  }

  outcomeUpdated() {
    if (!this.faultyEquipmentDetail.tester) {
      this.faultyEquipmentDetail.tester = localStorage.getItem('userName');
    }
    if (!this.faultyEquipmentDetail.dateTested) {
      this.faultyEquipmentDetail.dateTested = new Date().toISOString();
    }
  }

  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this Fault?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      rejectVisible: true,
      accept: () => {
        this.deleteRecord();
      },
      reject: () => {}
    });
  }

  deleteRecord() {
    this.faultyEquipmentService.updateFaultyEquipment(this.faultyEquipmentDetailId, {
      'deleted': true,
      'deletedAt': (new Date()).toISOString(),
      'deletedBy': localStorage.getItem('userName'),
    }).subscribe((response: SingleRecordResponse<FaultyEquipment>) => {
        if (response.success) {
          this.closeFaultyEquipmentDetailDialog.emit(response);
        } else {
          this.isSubmitButtonDisabled = false;
          this.showErrorPopUp(
            'Error',
            `Error deleting fault, please try again. Error: ${response.message}`,
          );
        }
      }, (err: Error) => {
        console.error("Error deleting fault. Error:", err);
        this.showErrorPopUp(
          'Error',
          `Error deleting fault, please try again. Error: ${err.message}`,
        );
      });
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp(header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
