import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'planTypeToPeriod'
})
export class PlanTypeToPeriodPipe implements PipeTransform {

  transform(planType: string, numberOfPeriods?: number): string {
    let period: string;
    switch (planType) {
      case 'monthly':
        period = 'month';
        break;
      case 'quarterly':
        period = 'quarter';
        break;
      case 'annual':
        period = 'year';
        break;
      default:
        return '';
    }
    if (numberOfPeriods && (numberOfPeriods > 1)) {
      return `${period}s`;
    }
    return period;
  }

}
