import {NotificationService} from './../notifications/notification.service';
import {Router} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {BreakpointObserver} from '@angular/cdk/layout';
import {NotificationsSocketService} from '../sockets/notifications-socket.service';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {CRM_CLIENT_VERSION} from '../models/environment.model';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import {clearLocalStorage} from '../helpers/helperFunctions';
import {getBoardNames} from '../lookups/actions';
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private notificationSocket: NotificationsSocketService,
    private breakpointObserver: BreakpointObserver,
  ) {}
  boards: string[];
  crmName: string;
  navBarColour: string;
  crmClientVersion: string = CRM_CLIENT_VERSION;
  isCollapsed = true;
  isCreateNotificationDialogOpen = false;
  isDisplayNotificationDialogOpen = false;
  outstandingNotificationCount: number = 0;
  isSmallerThanLarge: boolean;
  announcementMessage: string;

  ngOnInit(): void {
    this.crmName = environment.webName;
    this.navBarColour = environment.navBarColour;
    this.announcementMessage = '';
    this.getOutstandingNotifications();
    this.notificationSocket.on('updateNotificationCount', (userIds: string[]) => {
      if (userIds.includes(this.userId)) {
        this.getOutstandingNotifications();
      }
    });

    this.notificationSocket.on('announcement', (newMessage: string) => {
      this.announcementMessage = newMessage;
    });

    this.notificationSocket.emit('getAnnouncement');

    this.isSmallerThanLarge = this.breakpointObserver.isMatched('(max-width: 991px)');
    this.breakpointObserver.observe([
      '(max-width: 991px)'
    ]).subscribe(result => {
      this.isSmallerThanLarge = result.matches;
    });
    this.boards = getBoardNames();
  }

  ngOnDestroy(): void {
    this.notificationSocket.removeAllListeners();
  }

  get token() {
    return localStorage.getItem('token');
  }

  get userName() {
    return localStorage.getItem('userName');
  }

  get userId() {
    return localStorage.getItem('userId')
  }

  get unreadNotificationCount(): number {
    const notificationCountStr: string = localStorage.getItem('outstandingNotificationCount');
    return notificationCountStr? parseInt(notificationCountStr, 10): 0;
  }

  collapse() {
    this.isCollapsed = true;
  }

  closeDropdown(dropdown: NgbDropdown): boolean {
    dropdown.close();
    return true;
  }

  openCreateNotificationDialog() {
    this.isCreateNotificationDialogOpen = true;
  }

  closeCreateNotificationDialog(){
    this.isCreateNotificationDialogOpen = false
  }

  openDisplayNotificationDialog() {
    this.isDisplayNotificationDialogOpen = true;
  }

  closeDisplayNotificationDialog() {
    this.isDisplayNotificationDialogOpen = false;
  }

  getOutstandingNotifications() {
    const userId = localStorage.getItem('userId');
    this.notificationService.getOutstandingNotificationsCount(userId).subscribe((response: SingleRecordResponse<number>) => {
      if (response.success && response.data) {
        this.outstandingNotificationCount = response.data;
      } else {
        this.outstandingNotificationCount = 0;
      }
    }, (err: Error) => {
      console.error('Error getting notifications', err);
    });
  }

  logout() {
    clearLocalStorage();
    this.router.navigate(['/login']);
  }

}
