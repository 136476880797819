import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shopifyId'
})
export class ShopifyIdPipe implements PipeTransform {

  transform(shopifyId: string): string {
    if (!shopifyId) {
      return '';
    }
    return shopifyId.replace(/^.*\/(\d+)$/, '$1');
  }

}
