<div class="alarm-activations">
  <p-table #activationTable [value]="alarmActivations" [columns]="cols" [paginator]="true" [rows]="20" 
      sortField="activated" [sortOrder]="-1" sortMode="single">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          <ng-container [ngSwitch]="col.field">
            <div *ngSwitchCase="'deviceIdent'" [pSortableColumn]="col.field">
              Device
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon><br/>
              Ident
            </div>
            <div *ngSwitchCase="'notifications'">
              {{col.header}}
            </div>
            <div *ngSwitchCase="'incident'">
              {{col.header}}
            </div>
            <div *ngSwitchDefault [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon><br/>
            </div>
          </ng-container>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
          <div *ngSwitchCase="'notifications'">
            <label>Customer:</label><br/>{{rowData.customerNotificationSent | date:'dd/MM/y HH:mm'}}<br/>
            <label>ARC:</label><br/>{{rowData.arcNotificationSent | date:'dd/MM/y HH:mm'}}
          </div>
          <div *ngSwitchCase="'incident'">
            <label>Started:</label> {{rowData.incidentStarted | date:'dd/MM/y HH:mm'}}<br/>
            <label>Ended:</label> {{rowData.incidentEnded | date:'dd/MM/y HH:mm'}}<br/>
            <label>End Reason:</label> {{rowData.endReason}}
          </div>
          <div *ngSwitchCase="'activated'">
            {{rowData.activated | date:'dd/MM/y HH:mm'}}
          </div>
          <div *ngSwitchDefault>
            {{rowData[col.field]}}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>