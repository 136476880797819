<div class="container" *ngIf="!loading">
  <div class="row">
    <div class="col-11">
      <h1 class='mt-3'>{{boardName}} Board</h1>
    </div>
    <div class="col-1">
      <button type="button" class="btn btn-primary mt-4" (click)="export()">Export</button>
    </div>
  </div>
  <div class="block-section1">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="container">
          <div class="row">
            <div class="col-sm-8 col-10 offset-sm-2">
              <h1 style="padding-bottom: 15px;">Today</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8 col-10 offset-sm-2">
              <div class="row">
                <div class="col-6">
                <h4>Total  </h4>
                </div>
                <div class="col-6">
                  <h4> {{todaysData.total}} </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                <h6>Morning </h6>
                </div>
                <div class="col-6">
                  <h6>{{todaysData.morning}}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h6>Afternoon </h6>
                </div>
                <div class="col-6">
                  <h6>{{todaysData.afternoon}}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h6>Evening </h6>
                </div>
                <div class="col-6">
                  <h6>{{todaysData.evening}}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h4>Overdue </h4>
                </div>
                <div class="col-6">
                  <h4> {{todaysData.overdue}} </h4>
                </div>
              </div>
          </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 ">
        <div class="container">
          <div class="row">
            <div class="col-sm-10 col-8 offset-sm-2">
              <h1 style="padding-bottom: 15px;">Yesterday Complete</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8 col-12 offset-sm-2">
              <div class="row">
                <div class="col-6">
                  <h4>Total </h4>
                </div>
                <div class="col-6">
                    <h4>{{
                    yesterdayData?.morningShiftCount +
                    yesterdayData?.afternoonShiftCount+
                    yesterdayData?.eveningShiftCount
                  }}</h4>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h6>Morning </h6>
                </div>
                <div class="col-6">
                    <h6>{{yesterdayData?.morningShiftCount || 0}}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h6>Afternoon </h6>
                </div>
                <div class="col-6">
                    <h6>{{yesterdayData?.afternoonShiftCount || 0}}</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h6>Evening </h6>
                </div>
                <div class="col-6">
                    <h6>{{yesterdayData?.eveningShiftCount || 0}}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container" *ngIf="!loading">
  <div class="row">
    <app-outstanding-action class="col-12 col-md-6 col-lg-4 mt-4" *ngFor="let outstandingAction of outstandingActions" [actionData]="outstandingAction">
    </app-outstanding-action>
  </div>
</div>
<div class="d-flex justify-content-center" *ngIf="loading">
  <p-progressSpinner></p-progressSpinner>
</div>
<p-toast></p-toast>