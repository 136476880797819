import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {PostSheet} from '../models/postSheet.model';
import {PostsheetReportData} from '../models/reporting/postsheetReportData.model';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
@Injectable({
  providedIn: 'root'
})
export class PostSheetService {
  
  constructor(
    private http: HttpClient,
  ) { }
  
  API_URL: string = environment.protocol + environment.IPAddress + '/api/post-sheets';

  addPostSheet(params:PostSheet): Observable<SingleRecordResponse<PostSheet>> {
    return this.http.post<SingleRecordResponse<PostSheet>>(this.API_URL, params);
  }

  getPostedSheets(startDate:string, endDate: string): Observable<MultiRecordResponse<PostSheet>> {
    return this.http.get<MultiRecordResponse<PostSheet>>(this.API_URL+`/posted/${startDate}/${endDate}`);
  }

  getUnpostedSheets(): Observable<MultiRecordResponse<PostSheet>> {
    return this.http.get<MultiRecordResponse<PostSheet>>(this.API_URL+`/unposted`);
  }

  getPostSheetById(postSheetId: string): Observable<SingleRecordResponse<PostSheet>> {
    return this.http.get<SingleRecordResponse<PostSheet>>(`${this.API_URL}/byId/${postSheetId}`);
  }

  updatePostSheet(postSheet: PostSheet): Observable<SingleRecordResponse<PostSheet>> {
    return this.http.patch<SingleRecordResponse<PostSheet>>(this.API_URL, postSheet);
  }

  postPostSheet(postSheet: PostSheet): Observable<SingleRecordResponse<PostSheet>> {
    return this.http.post<SingleRecordResponse<PostSheet>>(`${this.API_URL}/postPostSheet`, postSheet);
  }

  unpostPostSheet(postSheet: PostSheet): Observable<SingleRecordResponse<PostSheet>> {
    return this.http.post<SingleRecordResponse<PostSheet>>(`${this.API_URL}/unpostPostSheet`, postSheet);
  }


  deletePostSheet(params) {
    return this.http.delete<SingleRecordResponse<any>>(`${this.API_URL}/${params._id}/${params.postedOn}`, params);
  }

  findPostedToday(params: any): Observable<MultiRecordResponse<PostSheet>> {
    return this.http.post<MultiRecordResponse<PostSheet>>(this.API_URL+`/findPostedToday`, params);
  }

  findAccountingSheets(startDate:string, endDate: string): Observable<MultiRecordResponse<PostSheet>> {
    return this.http.get<MultiRecordResponse<PostSheet>>(this.API_URL+`/findAccountingArchived/${startDate}/${endDate}`);
  }

  createLeadsFromPost(params: any): Observable<MultiRecordResponse<PostSheet>> {
    return this.http.post<MultiRecordResponse<PostSheet>>(this.API_URL+`/createLeadsFromPost`, params);
  }

  getItemReportData(startDate:string, endDate: string): Observable<MultiRecordResponse<PostsheetReportData>> {
    return this.http.get<MultiRecordResponse<PostsheetReportData>>(this.API_URL+`/post-item-report/${startDate}/${endDate}`);
  }

  findItemReport(startDate:string, endDate: string): Observable<MultiRecordResponse<PostSheet>> {
    return this.http.get<MultiRecordResponse<PostSheet>>(this.API_URL+`/findPostItemReport/${startDate}/${endDate}`);
  }

  getInfoPackReportData(startDate:string, endDate: string): Observable<MultiRecordResponse<PostsheetReportData>> {
    return this.http.get<MultiRecordResponse<PostsheetReportData>>(this.API_URL+`/post-infopack-report/${startDate}/${endDate}`);
  }
}
