import {SelectItem} from 'primeng/api';

let freeMonthChangeReasons: SelectItem<string>[];

function getFreeMonthChangeReasons(): SelectItem<string>[] {
  if (!freeMonthChangeReasons) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('free months: reasons for changing')) {
      return [];
    }
    freeMonthChangeReasons = JSON.parse(localStorage.getItem('free months: reasons for changing')) || [];
  }
  return freeMonthChangeReasons;
}

let freeMonthHoldReasons: SelectItem<string>[];

function getFreeMonthHoldReasons(): SelectItem<string>[] {
  if (!freeMonthHoldReasons) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('free months: reasons for hold')) {
      return [];
    }
    freeMonthHoldReasons = JSON.parse(localStorage.getItem('free months: reasons for hold')) || [];
  }
  return freeMonthHoldReasons;
}

export {
  getFreeMonthChangeReasons,
  getFreeMonthHoldReasons,
}