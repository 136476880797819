import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DomHandler} from 'primeng/dom';
import {environment} from '../../../environments/environment';
import {CustomerFeedbackService} from '../../customer-feedback/customer-feedback.service';
import {CustomerFeedback} from '../../models/customerFeedback.model';
import {Order} from '../../models/order.model';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {CancellationCategorySelectItem, CancellationReasonSelectItem, getCancellationReasonCategories, getCancellationReasonsForCatgory} from '../../lookups/cancellationReasons';
import {RetentionDialogReturn} from '../../models/function-params/retentionDialogReturn.model';
import {getDaysBetween} from '../../helpers/helperFunctions';
import {DEFAULT_OVERLAY} from '../../lookups/primeng/overlayOptions';
import {OverlayOptions} from 'primeng/api';

@Component({
  selector: 'app-retention-dialog[order][closeModal][retentionActionDate]',
  templateUrl: './retention-dialog.component.html',
  styleUrls: ['./retention-dialog.component.scss']
})
export class RetentionDialogComponent implements OnInit {
  @Input() order: Order;
  @Output() closeModal: EventEmitter<RetentionDialogReturn> = new EventEmitter<RetentionDialogReturn>();
  @Input() retentionActionDate: string;
  saveDisabled: boolean;
  dialogVisible: boolean;
  resultMessage: string;
  resultSuccess: boolean;
  retentionForm: UntypedFormGroup;
  username: string;
  closing: boolean;
  cancellationCategoriesSelectItems: CancellationCategorySelectItem[] = [];
  cancellationReasonsSelectItems: CancellationReasonSelectItem[] = [];
  overlayOptions: OverlayOptions = DEFAULT_OVERLAY;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customerFeedbackService: CustomerFeedbackService,
  ) { }

  ngOnInit(): void {
    this.saveDisabled = true;
    this.closing = false;
    this.username = localStorage.getItem('userName');
    this.dialogVisible = true;
    this.retentionForm = this.formBuilder.group({
      'cancellationReason': ['', Validators.required],
      'detailedCancellationReason': ['', Validators.required],
      'otherReason': ['', Validators.required],
      'retentionComment': ['', Validators.required],
    });
    this.cancellationCategoriesSelectItems = getCancellationReasonCategories(true);
    this.changeCancellationCategory('');
    this.saveDisabled = false;
  }

  get cancellationReason(): string {
    return this.retentionForm.get('cancellationReason').value;
  }

  get detailedCancellationReason(): string {
    if (this.retentionForm.get('detailedCancellationReason').disabled) {
      return '';
    }
    return this.retentionForm.get('detailedCancellationReason').value;
  }

  get otherReason(): string {
    if (this.retentionForm.get('otherReason').disabled) {
      return '';
    }
    return this.retentionForm.get('otherReason').value;
  }

  changeCancellationCategory(newCategory: string): void {
    if (!newCategory) {
      this.cancellationReasonsSelectItems = [];
    } else {
      this.cancellationReasonsSelectItems = getCancellationReasonsForCatgory(newCategory);
    }
    this.retentionForm.get('detailedCancellationReason').setValue('');
    this.changeCancellationReason('');
  }

  changeCancellationReason(newReason: string): void {
    const categorySelect: CancellationCategorySelectItem = this.cancellationCategoriesSelectItems.find((catSelect: CancellationCategorySelectItem) => 
      catSelect.value == this.cancellationReason
    );
    const reasonSelect: CancellationReasonSelectItem = this.cancellationReasonsSelectItems.find((reasonSelect: CancellationReasonSelectItem) => 
      reasonSelect.value == newReason
    );
    this.retentionForm.get('otherReason').setValue('');
    if (this.cancellationReasonsSelectItems.length > 0) {
      this.retentionForm.get('detailedCancellationReason').enable();
    } else {
      this.retentionForm.get('detailedCancellationReason').disable();
    }
    if ((categorySelect && categorySelect.requiresFreeText) || (reasonSelect && reasonSelect.requiresFreeText)) {
      this.retentionForm.get('otherReason').enable();
    } else {
      this.retentionForm.get('otherReason').disable();
    }
  }

  closeDialog(): void {
    if (!this.closing) {
      this.closing = true;
      this.closeModal.emit();
    }
  }
  
  isOkToSave(): boolean {
    return this.retentionForm.valid && !this.saveDisabled;
  }

  saveRetentionRecord(): void {
    this.saveDisabled = true;
    this.resultMessage = '';
    const currentDate: string = (new Date()).toISOString();
    const newFeedback: CustomerFeedback = {
      'websiteId': this.order.website,
      'tdCode': this.order.alarmUserDetails.tdCode,
      'orderId': this.order._id,
      'loggedBy': this.username,
      'dateLogged': this.retentionActionDate? this.retentionActionDate: currentDate,
      'datePending': undefined,
      'closedBy': this.username,
      'dateClosed': currentDate,
      'feedbackType': 'Retention',
      'priority': 'Medium',
      'partnership': 'None',
      'status': 'Closed',
      'resolutionDays': this.retentionActionDate? getDaysBetween(this.retentionActionDate, currentDate): 0,
      'pendingDays': 0,
      'whoContactedUs': '',
      'relationshipToUser': '',
      'emailAddress': '',
      'phoneNumber': '',
      'contactMethod': '',
      'mainReason': this.cancellationReason,
      'specificReason': this.detailedCancellationReason,
      'feedbackDetails': this.otherReason,
      'resolvedAtFirstContact': false,
      'ccTo': '',
      'contactAttempts': [],
      'resolution': this.retentionForm.get('retentionComment').value,
      'respondByEmail': false,
      'freeServiceGiven': false,
      'datePaymentRestarts': undefined,
      'chaserEmailSent': false,
      'updatedAt': undefined
    };
    this.customerFeedbackService.saveNewCustomerFeedback({
      'feedback': newFeedback,
      'crmBaseUrl': `${environment.protocol}${environment.IPAddress}/`,
    }).subscribe((response: SimpleResponse) => {
      this.saveDisabled = false;
      if (response.success) {
        this.closing = true;
        let noteText: string = `Customer was retained by ${this.username}.\nCustomer was going to cancel for main reason: ${this.cancellationReason}`;
        if (this.detailedCancellationReason) {
          noteText = `${noteText}, specific reason: ${this.detailedCancellationReason}`;
        }
        if (this.otherReason) {
          noteText = `${noteText} and addditional information: ${this.otherReason}`;
        }
        noteText = `${noteText}\n${this.retentionForm.get('retentionComment').value}\n`;
        this.closeModal.emit({'reason': this.detailedCancellationReason, 'noteText': noteText});
      } else {
        this.resultMessage = `Error adding Customer Feedback. Error: ${response.message}`;
      }
    }, (err: Error) => {
      this.saveDisabled = false;
      this.resultMessage = `Error adding Customer Feedback. Error: ${err.message}`;
    });
  }

  removeScrollBlock() {
    DomHandler.removeClass(document.body, 'p-overflow-hidden');
  }
}
