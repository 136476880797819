import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Order} from '../../models/order.model';
import {OrderService} from '../order.service';
import {OrderThirdPartyPaymentRequest} from '../../models/requests/orderThirdPartyPaymentRequest.model';
import {OrderThirdPartyPaymentDataResponse} from '../../models/responses/orderThirdPartyPaymentDataResponse.model';
import {GoCardlessSubscription} from '../../models/thirdPartyPayment/goCardlessSubscription.model';
import {SmartDebitPayer} from '../../models/thirdPartyPayment/smartDebitPayer.model';
import {StripeSubscription} from '../../models/thirdPartyPayment/stripeSubscription.model';
import {ConfirmationService} from 'primeng/api';
import {Column} from '../../models/column.model';
import {Environment} from '../../models/environment.model';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-third-party-payment-dialog[order][closeModal]',
  templateUrl: './third-party-payment-dialog.component.html',
  styleUrls: ['../post-order-sections.scss', './third-party-payment-dialog.component.scss']
})
export class ThirdPartyPaymentDialogComponent implements OnInit {
  @Input() order: Order;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  dialogVisible: boolean;
  goCardlessSubs: GoCardlessSubscription[] = [];
  smartDebitPayers: SmartDebitPayer[] = [];
  stripeSubs: StripeSubscription[] = [];
  formattedRenewalPrice: string;
  environment: Environment = environment;
  goCardlessCols: Column[] = [
    { field: 'referenceNumber', header: 'GC Customer Id' },
    { field: 'subscriptionId', header: 'Subscription Id' },
    { field: 'customerId', header: 'Custom Ref (tdCode)' },
    { field: 'paymentDueDate', header: 'Payment Due Date' },
    { field: 'renewalPrice', header: 'Renewal Price' },
    { field: 'lastUpdated', header: 'Last Updated' },
  ];
  smartDebitCols: Column[] = [
    { field: 'accountId', header: 'SD Account' },
    { field: 'referenceNumber', header: 'BACS Reference' },
    { field: 'customerId', header: 'Customer Id (tdCode)' },
    { field: 'accountName', header: 'Payer Name' },
    { field: 'paymentDueDate', header: 'Payment Due Date' },
    { field: 'renewalPrice', header: 'Renewal Price' },
    { field: 'lastUpdated', header: 'Last Updated' },
  ];
  stripeCols: Column[] = [
    { field: 'brandId', header: 'Stripe Brand' },
    { field: 'customerId', header: 'Stripe Customer Id' },
    { field: 'subscriptionId', header: 'Subscription Id' },
    { field: 'paymentDueDate', header: 'Payment Due Date' },
    { field: 'renewalPrice', header: 'Renewal Price' },
    { field: 'lastUpdated', header: 'Last Updated' },
  ];

  constructor(
    private orderService: OrderService,
    public confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    if (!this.order) {
      return;
    }
    this.formattedRenewalPrice = '0.00';
    if (/^\d*\.?\d*$/.test(this.order.renewalInformation.renewalPrice)) {
      this.formattedRenewalPrice = Number(this.order.renewalInformation.renewalPrice).toFixed(2);
    }
    const ottpRequest: OrderThirdPartyPaymentRequest = {
      'tdCode': this.order.alarmUserDetails.tdCode,
      'goCardlessCustomerId': this.order.goCardlessId,
      'goCardlessSubscriptionId': this.order.goCardlessSubId,
      'smartDebitBacsRef': this.order.referencenumber,
    };
    if (this.order.accountDetails) {
      if (this.order.accountDetails.stripeCustomerId) {
        ottpRequest.stripeCustomerId = this.order.accountDetails.stripeCustomerId;
      }
      if (this.order.accountDetails.stripeSubscriptionId) {
        ottpRequest.stripeSubscriptionId = this.order.accountDetails.stripeSubscriptionId;
      }
    }
    this.dialogVisible = true;
    this.orderService.getOrderThirdPartyPaymentData(ottpRequest).subscribe((ottpResult: OrderThirdPartyPaymentDataResponse) => {
      if (ottpResult.success) {
        this.goCardlessSubs = ottpResult.goCardlesss;
        this.smartDebitPayers = ottpResult.smartDebit;
        this.stripeSubs = ottpResult.stripe;
      } else {
        console.error('Error getting Third Party Payment Data', ottpResult.message);
        this.showErrorPopUp('Error loading Third Party Payment Data',
          `There was an error loading the Third Party Payment Data please try again. Error: ${ottpResult.message}`);
      }
    });
  }

  closeDialog() {
    this.dialogVisible = false;
    this.closeModal.emit();
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('aboveBlockMessage', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  getStripeBrand(brandId: string): string {
    if (brandId.includes('OLD')) {
      return brandId.replace(/_OLD/,' (old)');
    }
    return `${brandId} (new)`;
  }
}
