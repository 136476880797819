import {SelectItem} from 'primeng/api';

let discountReasonSelectItems: SelectItem<string>[];
function getDiscountReasons(): SelectItem<string>[] {
  if (!discountReasonSelectItems) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('discounts: reasons')) {
      return [];
    }
    discountReasonSelectItems = JSON.parse(localStorage.getItem('discounts: reasons')) || [];
  }
  return discountReasonSelectItems;
}

export {
  getDiscountReasons,
}