interface UserPreference<T> {
  'preferenceLabel': string;
  'preferenceValue': T;
}

let userPreferences: UserPreference<any>[];

function getUserPreferences(): UserPreference<any>[] {
  if (!userPreferences) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('userPreferences')) {
      return [];
    }
    userPreferences = JSON.parse(localStorage.getItem('userPreferences'));
  }
  return userPreferences;
}

function getUserPreferenceValue<T>(preferenceName: string): T|undefined {
  getUserPreferences();
  const userPreference: UserPreference<any>|undefined = userPreferences.find((userPref: UserPreference<any>) =>
    userPref.preferenceLabel == preferenceName
  );

  if (userPreference) {
    return (userPreference.preferenceValue as T);
  }
  return undefined;
}

function updateUserPreference<T>(preferenceName: string, value: T): void {
  getUserPreferences();
  const userPreference: UserPreference<any>|undefined = userPreferences.find((userPref: UserPreference<any>) =>
    userPref.preferenceLabel == preferenceName
  );

  if (userPreference) {
    userPreference.preferenceValue = value;
  } else {
    userPreferences.push({
      'preferenceLabel': preferenceName,
      'preferenceValue': value,
    });
  }
  localStorage.setItem('userPreferences', JSON.stringify(userPreferences));
}

export {
  UserPreference,
  getUserPreferences,
  getUserPreferenceValue,
  updateUserPreference,
}
