import { Pipe, PipeTransform } from '@angular/core';
import {PaymentAccount} from '../../models/payments/paymentAccount.model';
import {STRIPE_ACCOUNTS} from '../../lookups/stripeAccounts';

@Pipe({
  name: 'stripeAccountName'
})
export class StripeAccountNamePipe implements PipeTransform {

  transform(legalCompany: string, brand: string): string {
    const account: PaymentAccount = STRIPE_ACCOUNTS[legalCompany][brand];
    return account? account.accountName: '';
  }

}
