import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

const MIN_VALID_ADDRESS_LINES: number = 3;
const MAX_ADDRESS_LINES: number = 5;

export function isValidAddressArray(addressLines: string[]): boolean {
  let validLineCount: number = 0;
  let nonEmptyLineCount: number = 0;
  addressLines.forEach((addressLine: string) => {
    // Need to check for at least two non-whitespace characters, but they might not be consecutive
    const addressLineWithoutSpaces: string = addressLine.replace(/\s/g, '');
    if (addressLineWithoutSpaces.length > 0) {
      nonEmptyLineCount++;
    }
    if (addressLineWithoutSpaces.length > 2) {
      validLineCount++;
    }
    nonEmptyLineCount
  });
  return ((validLineCount >= MIN_VALID_ADDRESS_LINES) && (nonEmptyLineCount <= MAX_ADDRESS_LINES));
}

export const unstructuredAddressValidator: ValidatorFn = (addressField: AbstractControl): ValidationErrors|null => {
  const address: string = (addressField.value as string);
  if (!address) {
    return {'addressValid': 'blank address'}; 
  }
  const addressLines: string[] = address.split('\n');
  if (!isValidAddressArray(addressLines)) {
    return {'addressValid': 'invalid number of lines'};
  }
  return null;
}
