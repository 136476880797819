<div class="formbar row no-gutters">
  <div class="col-6">
    Jontek Contacts
  </div>
  <div class="col-6">
    <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
      <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
      <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
    </button>
  </div>
</div>
<div class="formsect" [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
  <div class="formbox contactbox" *ngFor="let jontekContactRecord of jontekContactRecords">
    <div class="subsectionHeading">
      <span class="title-h">Contacts For Alarm Code {{jontekContactRecord.jontekCode}}</span>
      <span class="form-h">Updated: {{jontekContactRecord.contactsUpdated | date:'dd/MM/y'}}</span>
    </div>
    <ng-container *ngFor="let jontekContact of jontekContactRecord.jontekContacts; let jontekContactIndex=index">
      <div class="subsectionHeading">
        Contact No. {{jontekContactIndex+1}}
      </div>
      <div class="form-row">
        <div class="form-group col-8">
          <label>Contact Type &amp; Name</label>
          <input type="text" name="contactName" readonly value="{{jontekContact.contactName}}">
        </div>
        <div class="form-group col-4">
          <label>Relationship</label>
          <input type="text" name="contactRelationship" readonly value="{{jontekContact.contactRelationship}}">
        </div>
        <div class="form-group col-12">
          <label>Address</label>
          <input type="text" name="contactAddress" readonly value="{{jontekContact.contactAddress}}">
        </div>
        <!-- Alex says this is always No, so taking out for now -->
        <div *ngIf="false" class="form-group col-3">
          <label>Emergency Contact</label>
          <input type="text" name="emergencyContact" readonly value="{{jontekContact.emergencyContact}}">
        </div>
        <div class="form-group col-4">
          <label>Key Holder</label>
          <input type="text" name="keyHolder" readonly value="{{jontekContact.keyHolder}}">
        </div>
        <div class="form-group col-4">
          <label>Next of Kin</label>
          <input type="text" name="nextOfKin" readonly value="{{jontekContact.nextOfKin}}">
        </div>
        <div class="form-group col-4">
          <label>Contact Responder</label>
          <input type="text" name="contactResponder" readonly value="{{jontekContact.contactResponder}}">
        </div>
        <div class="form-group col-6" *ngFor="let jontekContactDetail of jontekContact.contactDetails">
          <label>{{jontekContactDetail.contactType}}</label>
          <input type="text" name="contactValue" readonly value="{{jontekContactDetail.contactValue}}">
        </div>
      </div>
    </ng-container>
  </div>
</div>