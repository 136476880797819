import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: AbstractControl[], searchText: string, fromDateStr?: string, toDateStr?: string, noteCategories?: string[], filterType?: string): AbstractControl[] {
    if (!items) {
      return [];
    }
    if (!searchText && !fromDateStr && !toDateStr && (!noteCategories || !noteCategories.length)) {
      return items;
    }
    let toDateMs: number;
    let fromDateMs: number;
    if (!!searchText) {
      searchText = searchText.toLowerCase();
    }
    if (!!toDateStr) {
      toDateMs = new Date(toDateStr).getTime() + (23*60*60*1000);
    }
    if (!!fromDateStr) {
      fromDateMs = new Date(fromDateStr).getTime();
    }
    items = items.filter((it: AbstractControl) => {
      if (noteCategories) {
        switch (filterType) {
          case 'all':
            for (let i = 0; i< noteCategories.length; i++) {
              if (!it.value.categories.includes(noteCategories[i])) {
                return false;
              }
            }
            break;
          case 'not':
            for (let i = 0; i< noteCategories.length; i++) {
              if (it.value.categories.includes(noteCategories[i])) {
                return false;
              }
            }
            break;
          case 'any':
            let hasCategory: boolean = false;
            for (let i = 0; i< noteCategories.length; i++) {
              if (it.value.categories.includes(noteCategories[i])) {
                hasCategory = true;
                break;
              }
            }
            if (!hasCategory) {
              return false;
            }
            break;
          default:
            break;
        }
        
      }
      // if ((noteCategory != undefined) && (noteCategory != null) && (noteCategory != '') &&
      //     !it.value.categories.includes(noteCategory)) {
      //   return false;
      // }
      let noteDateMs: number = new Date(it.value.date).getTime();
      if (!!toDateMs && (noteDateMs > toDateMs)) {
        return false;
      }
      if (!!fromDateMs && (noteDateMs < fromDateMs)) {
        return false;
      }
      if (!!searchText && !it.value.content.toLowerCase().includes(searchText)) {
        return false;
      }
      return true;
    });
    return items;
  }
}
