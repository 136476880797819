import {SelectItem} from 'primeng/api';

let smartDebitSelectItems: SelectItem<string>[];
function getSmartDebitAccounts(): SelectItem<string>[] {
  if (!smartDebitSelectItems) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('smartdebit: accounts')) {
      return [];
    }
    smartDebitSelectItems = JSON.parse(localStorage.getItem('smartdebit: accounts')) || [];
  }
  return smartDebitSelectItems;
}

export {
  getSmartDebitAccounts,
}