import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function reasonRequiredValidator(errorName: string, reasonListField: string, otherReasonField: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors|null => {
    const selectedReason: AbstractControl = formGroup.get(reasonListField)!;
    const otherReasonText: AbstractControl = formGroup.get(otherReasonField)!;
    if (!selectedReason.value) {
      const returnValue: ValidationErrors = {};
      returnValue[errorName] = 'A reason must be selected from the drop down list';
      return returnValue;
    }
    if ((selectedReason.value == 'Other') && (!otherReasonText.value || (otherReasonText.value.trim().length < 4))) {
      const returnValue: ValidationErrors = {};
      returnValue[errorName] = 'You must provide details of the "Other" reason';
      return returnValue;
    }
    return null;
  }
}
