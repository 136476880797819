import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ProposedMessageService} from '../../messages-list/proposed-message.service';
import {ConfirmationService} from 'primeng/api';
import {MessageLog} from '../../models/messageLog.model';
import {ProposedMessage} from '../../models/proposedMessage.model';
import {OrderMsgReportResponse} from '../../models/responses/orderMsgReportResponse.model';
import {Column} from '../../models/column.model';
import {getSubfieldData, isValidObjectId, subfieldSort} from '../../helpers/helperFunctions';

@Component({
  selector: 'app-message-report[orderId][tdCode][closeModal]',
  templateUrl: './message-report.component.html',
  styleUrls: ['../post-order-sections.scss', './message-report.component.scss']
})
export class MessageReportComponent implements OnInit {

  constructor(
    private proposedMsgService: ProposedMessageService,
    public confirmationService: ConfirmationService,
  ) { }
  @Input() orderId: string;
  @Input() tdCode: string;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  subfieldSort = subfieldSort;
  getSubfieldData = getSubfieldData;
  messageLogs: MessageLog[] = [];
  proposedMsgs: ProposedMessage[] = [];
  dialogVisible: boolean;
  proposedCols: Column[] = [
    { field: 'generated', header: 'Created' },
    { field: 'messageType', header: 'Message' },
    { field: 'methods', header: 'Method(s)' },
    { field: 'status', header: 'Status' },
    { field: 'contactDetails.firstName', header: 'First Name'},
    { field: 'contactDetails.lastName', header: 'Last Name'},
    { field: 'contactDetails.reFirstName', header: 'Re First Name'},
    { field: 'contactDetails.reLastName', header: 'Re Last Name'},
    { field: 'contactDetails.email', header: 'Email'},
    { field: 'contactDetails.mobile', header: 'Mobile'},
    { field: 'contactDetails.address', header: 'Address'},
  ];
  logsCols: Column[] = [
    { field: 'dateLogged', header: 'Logged' },
    { field: 'messageType', header: 'Message' },
    { field: 'messageSentSuccessfully', header: 'Sent OK?' },
    { field: 'communicationMethod', header: 'Method'},
    { field: 'contactDetailsUsed', header: 'Contact Details' },
    { field: 'notes', header: 'Notes' },
  ];

  ngOnInit(): void {
    this.dialogVisible = true;
    if (!isValidObjectId(this.orderId)) {
      return;
    }
    this.proposedMsgService.getOrderReport(this.orderId, this.tdCode).subscribe((response: OrderMsgReportResponse) => {
      if (response.success) {
        this.proposedMsgs = response.data;
        this.messageLogs = response.sent
      } else {
        console.error('Error getting Order Message Report', response.message);
        this.showErrorPopUp('Error loading message details',
          `There was an error loading the message details please try again. Error: ${response.message}`);
      }
    });
  }

  closeDialog() {
    this.closeModal.emit();
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('aboveBlockMessage', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  showConfirmationPopup(header: string, message: string, acceptCallback?: () => void, rejectCallback?: () => void,
      isRejectLabelVisisble: boolean = true) {
    let acceptClicked: boolean = false;
    this.confirmationService.confirm({
      'key': 'general',
      'header': header,
      'message': message,
      'rejectVisible': isRejectLabelVisisble,
      accept: () => {
        acceptClicked = true;
        if (acceptCallback) {
          acceptCallback();
        }
      },
      reject: () => {
        if (rejectCallback && !acceptClicked) {
          rejectCallback();
        }
      }
    });
  }
}
