<div class="mb-xl-2"></div>
<div class="container-fluid" id="importLogTable">
  <p-table #tt [value]="logs" [paginator]=true [rows]="20"
      [globalFilterFields]="['fileName','user','fields','nbRecorders']"
      [sortField]="'date'" [sortOrder]=-1 [sortMode]="'single'" 
  >
    <ng-template pTemplate="caption">
      <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
      <input type="search" pInputText size="50"
             placeholder="Global Filter" (input)="globalFilter($event, 'contains')"
             style="width:auto">
      <label class="load-logs" for="startDate">Logs Between:</label>
      <input id="startDate" type="date" autocomplete="off" #startDate min="{{minDateStr}}" max="{{maxDateStr}}" value="{{defaultStartDateStr}}"/>
      <label class="load-logs" for="endDate">and:</label>
      <input id="endDate" type="date" autocomplete="off" #endDate min="{{minDateStr}}" max="{{maxDateStr}}" value="{{maxDateStr}}"/>
      <span class="load-logs" *ngIf="!logsLoading else logsLoadingBlock">
        <button type="button" class="btn btn-primary" (click)="loadImportLogs(startDate.value, endDate.value)">Load Import Log</button>
      </span>
      <ng-template #logsLoadingBlock>
        <div class="load-logs spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </ng-template>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of cols">
          <th *ngIf="((col.field === 'job') || (col.field === 'recorderPerBrand')) else sortableBlock">
            {{col.header}}
          </th>
          <ng-template #sortableBlock>
            <th [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                ariaLabelDesc="Activate to sort in descending order" 
                ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon>
            </th>  
          </ng-template>
        </ng-container>
      </tr>
      <tr>
        <th *ngFor="let col of cols">
          <ng-container [ngSwitch]="col.field">
            <ng-container *ngSwitchCase="'source'">
              <select name="source" style="width: 100%;" (change)="applyFilter($event, col.field, 'startsWith')">
                <option value="">All</option>
                <option value="upload">Upload</option>
                <option value="Export">Export</option>
              </select>
            </ng-container>
            <ng-container *ngSwitchCase="'user'">
              <input pInputText type="search" (input)="applyFilter($event, col.field, 'startsWith')" style="width: 100%;" />
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
              <div>
                <p-calendar [appendTo]="'body'" [selectionMode]="'range'" [readonlyInput]=true [dateFormat]="'dd/mm/yy'"
                    [(ngModel)]="dateFilters" (onSelect)="tt.filter(dateFilters, col.field, 'date-range')"
                    (onClearClick)="tt.filter(dateFilters, col.field, 'date-range')" [yearRange]=filterYearRange
                    [monthNavigator]=true [yearNavigator]=true [showButtonBar]=true [keepInvalid]=false
                    [minDate]=filterMinDate [maxDate]=filterMaxDate [numberOfMonths]="1"
                    [showOtherMonths]=true [selectOtherMonths]=true [showIcon]=true [style]="{width: '100%'}" 
                    [inputStyle]="{width: '100%'}">
                  </p-calendar>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'fields'">
              <input pInputText type="search" (input)="applyFilter($event, col.field, 'contains')" style="width: 100%;" />
            </ng-container>
            <ng-container *ngSwitchCase="'recorderPerBrand'">
              <p-multiSelect *ngIf="col.header === 'Brands'" [options]="brands" [(ngModel)]="selectedBrands"
                  filterPlaceHolder="brand" [panelStyle]="{maxWidth:'135px'}" scrollHeight="600px" [maxSelectedLabels]=0
                  selectedItemsLabel="{0} selected" [style]="{'display':'flex'}" 
                  (onChange)="tt.filter($event.value, col.field, 'brand-count')" >
                <ng-template let-Pbrand let-i="index" pTemplate="item">
                  <div class="brandBar" [ngStyle]="{'background-color': brandConfigs[Pbrand.value].background, 'color':brandConfigs[Pbrand.value].color}">
                    &nbsp;{{Pbrand.value}}
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-container>
            <ng-container *ngSwitchDefault></ng-container>
          </ng-container>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-log let-rowIndex ="rowIndex">
      <tr>
        <td>{{logs.length - rowIndex}}</td>
        <td>{{log.source}}</td>
        <td>{{log.user}}</td>
        <td>{{log.date | date:'dd/MM/y HH:mm'}}</td>
        <td>{{log.fields}}</td>
        <td>{{log.nbRecorders}}</td>
        <td>
          <div *ngIf="log.recorderPerBrand">
            <div *ngFor="let key of objectKeys(log.recorderPerBrand) ; let i = index ">
              <p>{{key}}</p>
              <hr>
            </div>

          </div>
        </td>
        <td>
          <div *ngIf="log.recorderPerBrand">
            <div *ngFor="let key of objectKeys(log.recorderPerBrand) ; let i = index ">
              <p >{{log.recorderPerBrand[key]}}</p>
              <hr>
            </div>

          </div>
        </td>
        <td> 
          <a *ngIf="log.source === 'Export' || log.isFileCsv" href="{{API_URL}}/upload/{{log._id}}.csv"> {{log.fileName}}</a>
          <a *ngIf="log.source !== 'Export'" href="{{API_URL}}/upload/{{log._id}}.{{getFileExtension(log.fileName)}}"> {{log.fileName}}</a>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      There are {{logs.length}} import/export logs
    </ng-template>
  </p-table>
</div>
<p-toast></p-toast>