import {Injectable} from '@angular/core';
import {GenericSocketService} from './generic-socket.service';
import {environment} from '../../environments/environment';
import {OrderLockData, OrderLockFunction} from '../models/socket-io/orderLockData.model';
import {MessageLockData, MessageLockFunction} from '../models/socket-io/messageLockData.model';
import {LeadLockData, LeadLockFunction} from '../models/socket-io/leadLockData.model';
import {ConfirmationCallback} from '../models/socket-io/confirmationCallback.model';
import {CustFeedbackLockData, CustFeedbackLockFunction} from '../models/socket-io/custFeedbackLockData.model';

interface LockServToCliEvs {
  // Orders
  'lockList': (lockList: OrderLockData[]) => void;
  'locked': (lockedOrder: OrderLockData) => void;
  // Messages
  'messageLockList': (messageLockList: MessageLockData[]) => void;
  'lockedMessage': (lockedMessage: MessageLockData) => void;
  // Leads
  'leadLockList': (leadLockList: LeadLockData[]) => void;
  'lockedLead': (lockedLead: LeadLockData) => void;
  // Customer Feedback
  'custFeedbackLockList': (custFeedbackLockList: CustFeedbackLockData[]) => void;
  'lockedCustFeedback': (lockedCustFeedback: CustFeedbackLockData) => void;
}

interface LockCliToServEvs {
  // Orders
  'locking': (data: OrderLockData, fn: OrderLockFunction) => void;
  'getLocked': () => void;
  'unlocking': (data: OrderLockData, fn?: ConfirmationCallback) => void;
  'unlocking-F': (data: OrderLockData, fn: OrderLockFunction) => void;
  // Messages
  'lockingMessage': (data: MessageLockData, fn: MessageLockFunction) => void;
  'getLockedMessage': () => void;
  'unlockingMessage': (data: MessageLockData) => void;
  'unlockingMessage-F': (data: MessageLockData, fn: MessageLockFunction) => void;
  // Leads
  'lockingLead': (data: LeadLockData, fn: LeadLockFunction) => void;
  'getLockedLead': () => void;
  'unlockingLead': (data: LeadLockData) => void;
  'unlockingLead-F': (data: LeadLockData, fn: LeadLockFunction) => void;
  // Customer Feedback
  'lockingCustFeedback': (data: CustFeedbackLockData, fn: CustFeedbackLockFunction) => void;
  'getLockedCustFeedback': () => void;
  'unlockingCustFeedback': (data: CustFeedbackLockData) => void;
  'unlockingCustFeedback-F': (data: CustFeedbackLockData, fn: CustFeedbackLockFunction) => void;
}

@Injectable()
export class LocksSocketService extends GenericSocketService<LockServToCliEvs, LockCliToServEvs> {

  /*
  closeOnBeforeunload stops a disconnect happening in beforeunload, if this were true (the default)
  then if a user is prompted that they have unsaved changes when leaving the post-order page it 
  unlocks the order and if they choose not to leave 2 users can be in the order
  */
  constructor() {
    super({
      'url': environment.protocol + environment.ioAddress + '/locks', 
      'options': {
        'closeOnBeforeunload': false,
        'transports': ['websocket'],
        'path': '/socket.io/locks/',
      }
    });
  }
}
