import {SelectItem} from 'primeng/api';
import { sortByLabel } from '../helpers/helperFunctions';

interface NoteTemplate extends SelectItem<string> {
  'noteCategories': string[],
}

let noteTemplates: NoteTemplate[];

function getNoteTemplates(): NoteTemplate[] {
  if (!noteTemplates) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('noteTemplates')) {
      return [];
    }
    noteTemplates = JSON.parse(localStorage.getItem('noteTemplates'));
    sortByLabel(noteTemplates);
  }
  return noteTemplates;
}
  
export {
  NoteTemplate,
  getNoteTemplates
}
