import {MessageService} from 'primeng/api';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {HomeOutboundService} from './home-outbound.service';
import {OrderUpdatesSocketService} from '../sockets/order-updates-socket.service';
import {OrderUpdate} from '../models/socket-io/orderUpdate.model';
import {BoardSummary, BoardSummaryResponse} from '../models/responses/boardSummaryResponse.model';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {ActionCompetionSummary} from '../models/actionCompetionSummary.model';
import {Title} from '@angular/platform-browser';
import {Subject} from 'rxjs';
import {bufferTime} from 'rxjs/operators';
import {ActivatedRoute, Params} from '@angular/router';
import {ExcelExportService} from '../post-order/excel-export.service';

@Component({
  selector: 'app-home-outbound',
  templateUrl: './home-outbound.component.html',
  styleUrls: ['./home-outbound.component.scss'],
  providers: [MessageService]
})
export class HomeOutboundComponent implements OnInit, OnDestroy {

  constructor(
    private homeOutboundService: HomeOutboundService,
    private orderUpdatesSocket: OrderUpdatesSocketService,
    private messageService: MessageService,
    private title: Title,
    private route: ActivatedRoute,
    private excelService: ExcelExportService,
  ) { }
  boardName: string;
  outstandingActions: BoardSummary[] = [];
  todaysData: BoardSummary = {
    'total': 0,
    'morning': 0,
    'afternoon': 0,
    'evening': 0,
    'overdue': 0,
  };
  yesterdayData: ActionCompetionSummary = {
    morningShiftCount: 0,
    afternoonShiftCount: 0,
    eveningShiftCount:0,
  };
  loading: boolean = true;
  socketIoThrottle: Subject<string>;

  processUpdateFromSocketIo: (orderUpdateData: OrderUpdate) => void = (data: OrderUpdate) => {
    if (data.order) {
      this.socketIoThrottle.next(data.order._id);
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.boardName = params['boardName'];
      this.preparePageData();
      this.title.setTitle(`CRM ${this.boardName} Board`);
    });
    this.socketIoThrottle = new Subject<string>();
    // Throttle preparePageData calls to once every 5 seconds
    this.socketIoThrottle.pipe(
      bufferTime(5000)
    ).subscribe((orderIds: string[]) => {
      // Only call the method if the buffer had data
      if (orderIds.length > 0) {
        this.preparePageData();
      }
    });
    /* Refreshing on updating record (called on contact attempt changes too) */
    this.orderUpdatesSocket.on('updateOrders', this.processUpdateFromSocketIo);
  }

  ngOnDestroy() {
    this.orderUpdatesSocket.removeAllListeners();
    this.socketIoThrottle.complete();
  }

  preparePageData() {
    if (!this.boardName) {
      return;
    }
    this.homeOutboundService.getYesterdaysReport(this.boardName)
      .subscribe((report: SingleRecordResponse<ActionCompetionSummary>) => {
        if (report.success) {
          this.yesterdayData = report.data;
        } else {
          console.error('Error getting action summary. Error: ', report.message);
          this.somethingWentWrongTryAgain();
        }
      }, (err) => {
        console.error('Error getting action summary. Error: ', err.message);
        this.somethingWentWrongTryAgain();
      });
    this.homeOutboundService.getDataForActionPerBox(this.boardName)
      .subscribe((summaryData: BoardSummaryResponse) => {
        this.loading = false;
        if (summaryData.success) {
          this.todaysData = summaryData.todaysData;
          this.outstandingActions = summaryData.actionData;
        } else {
          console.error('Error getting action summary. Error: ', summaryData.message);
          this.somethingWentWrongTryAgain();
        }
      }, err => {
          this.loading = false;
          console.error("Error getting action summary. Error: ", err);
          this.somethingWentWrongTryAgain();
      });
  }

  somethingWentWrongTryAgain() {
    this.messageService.add({
      severity: 'warn',
      life: 300000,
      summary: "Something went wrong try again",
      detail: "Changes are not applied"
    });
  }

  export(): void {
    const formattedForExport: any[] = this.outstandingActions.map((boardSummary: BoardSummary) => {
      return {
        'action': boardSummary._id!,
        'today': boardSummary.today!,
        'morning': boardSummary.morning,
        'afternoon': boardSummary.afternoon,
        'evening': boardSummary.evening,
        'overdue': boardSummary.overdue,
        'total': boardSummary.total,
      }
    });
    formattedForExport.push({
      'action': 'Board Today',
      'today': this.todaysData.total,
      'morning': this.todaysData.morning,
      'afternoon': this.todaysData.afternoon,
      'evening': this.todaysData.evening,
      'overdue': this.todaysData.overdue,
      'total': '',
    });
    formattedForExport.push({
      'action': 'Completed Yesterday',
      'today': '',
      'morning': this.yesterdayData.morningShiftCount,
      'afternoon': this.yesterdayData.afternoonShiftCount,
      'evening': this.yesterdayData.eveningShiftCount,
      'overdue': '',
      'total': this.yesterdayData.morningShiftCount + this.yesterdayData.afternoonShiftCount + this.yesterdayData.eveningShiftCount,
    });
    this.excelService.exportAsExcelFile({'Board Data': formattedForExport}, `${this.boardName}_summary`);
  }
}
