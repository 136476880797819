
<ng-template #notificationMessageList>
  <ul class="list-group list-group-flush">
    <li class="list-group-item" *ngFor="let userNotification of notificationList" (click)="displayNotificationDetail(userNotification)">
      <span>{{userNotification.notificationId?.summary}}<br></span>
      <span>Message sent by: {{userNotification.notificationId?.createdBy === 'System'?'System Generated':userNotification.notificationId?.createdBy}}</span>
      <span>   {{userNotification.notificationId?.date}}</span>
      <!-- {{moment.tz(userNotification.notificationId?.createdAt,'Europe/London')}} -->
    </li>
  </ul>
</ng-template>
<ng-template #notificationDetailRef>
  <div class="disable-card-box-shadow">
    <!-- [header]="notificationDetail?.notificationId?.summary" -->
    <p-card [header]="notificationDetail?.notificationId?.summary">
      <div class="notificationDetail">
        <div style="white-space: pre-line;">
          <div>
            {{notificationDetail?.notificationId?.notificationText}}
          </div>
          <div *ngIf="notificationDetail?.notificationId?.createdBy !=='System'">
            <span>Message sent by: {{notificationDetail?.notificationId?.createdBy}}</span>
            <span> {{notificationDetail?.notificationId?.date}}</span>
          </div>
        </div>
      </div>
    </p-card>
  </div>
</ng-template>
<p-dialog class="display-notifications" header="Notifications" (onHide)="onHideDialog()" [(visible)]="displayAsSideBar"
    [modal]="true" [blockScroll]="true" [dismissableMask]="true" [minY]="70" [maximizable]="false"
    [baseZIndex]="10000">
  <ng-template pTemplate="content">
    <div *ngIf="loading" class="loading-indicator d-flex align-items-center justify-content-center" style="height: 200px;">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="!notificationDetail">
      <ng-container *ngTemplateOutlet="notificationMessageList">
      </ng-container>
    </div>
    <div *ngIf="notificationDetail">
      <ng-container *ngTemplateOutlet="notificationDetailRef">
      </ng-container>
    </div>
    <p-toast></p-toast>
  </ng-template>
  <ng-template pTemplate="footer">
    <ng-container *ngIf="!notificationDetail">
      <p-button icon="pi pi-check" (click)="markAllNotificationsAsRead()" label="Mark All As Read" styleClass="p-button-text"
        [disabled]="isMarkAsReadDisabled">
      </p-button>
    </ng-container>
    <ng-container *ngIf="notificationDetail">
      <button type="button" style="margin-top: 8px;" class="btn btn-warning ml-2" (click)="closeNotificationDetail()">
        Close
      </button>
      <button type="button" style="margin-top: 8px;" class="btn btn-primary" (click)="markNotificationAsRead()" [disabled]="isMarkAsReadDisabled">
        Mark As Read
      </button>
    </ng-container>
  </ng-template>
</p-dialog>

<p-dialog header="Notifications" class="notifications-as-popup" (onHide)="onHideAsPopupDialog()" [(visible)]="displayAsPopup" [modal]="true"
   [draggable]="true" [style]="{width: '55vw', minWidth: '200px'}" [minY]="70" [maximizable]="false"
  [baseZIndex]="10000" [blockScroll]="true">
  <div *ngIf="loading" class="loading-indicator d-flex align-items-center justify-content-center" style="height: 200px;">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div *ngIf="!notificationDetail">
    <ng-container *ngTemplateOutlet="notificationMessageList"></ng-container>
  </div>
  <div *ngIf="notificationDetail">
    <ng-container *ngTemplateOutlet="notificationDetailRef"></ng-container>
  </div>
  <p-toast></p-toast>
</p-dialog>