import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {JontekContactRecord} from '../../models/jontekContactRecord.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';

@Injectable({
  providedIn: 'root'
})
export class JontekService {

constructor(
  private http: HttpClient
) { }

  private API_URL: string = environment.protocol + environment.IPAddress + '/api';

  getJontekContactsForJontekCodes(jontekCodes: string[]): Observable<MultiRecordResponse<JontekContactRecord>> {
    return this.http.post<MultiRecordResponse<JontekContactRecord>>(
        `${this.API_URL}/jontek/contactsForJontekCodes`, {'jontekCodes': jontekCodes});
  }
}
