<div class="mb-xl-2"></div>
<div class="container-fluid" id="asAtOrderTable">
  <p-table #asAtOrderTable [value]="asAtOrderRecords" [paginator]="true" [rows]="30" dataKey="_id"
      [columns]="selectedOrderCols" [sortMode]="'single'" [sortField]="'tdCode'">
    <ng-template pTemplate="caption">
      <div>

      </div>
      <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
      <label class="load-orders" for="forDate">Orders as at:</label>
      <input id="forDate" type="date" autocomplete="off" #forDate min="{{minDateStr}}" max="{{maxDateStr}}" value="{{maxDateStr}}"/>
      <label class="load-orders" for="includeDeleted">Include Deleted?:</label>
      <select id="includeDeleted" name="includeDeleted" #includeDeleted>
        <option value="no" [defaultSelected]="true">No</option>
        <option value="yes">Yes</option>
      </select>
      <span class="load-orders" *ngIf="!recordsLoading else recordsLoadingBlock">
        <button type="button" class="btn btn-primary" (click)="loadOrderRecords(forDate.value, includeDeleted.value)">
          Load Orders
        </button>
      </span>
      <span>
        <label class="load-orders" for="orderColsSelect">Order Fields:</label>
        <p-multiSelect id='orderColsSelect' [options]="orderCols" [(ngModel)]="selectedOrderCols" optionLabel="header" [maxSelectedLabels]=2
              selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Filter Columns" scrollHeight="600px"
              (onChange)="columnsChanged($event)">
        </p-multiSelect>
      </span>
      <div ngbDropdown class="d-inline-flex">
        <button type="button" id="exportAsAtOrders" ngbDropdownToggle class="ml-2 btn btn-primary" aria-controls="exportAsAtOrdersControl"
            [disabled]="recordsLoading || (asAtOrderRecords.length === 0)">
          Export
        </button>
        <div ngbDropdownMenu aria-labelledby="exportAsAtOrders" id="exportAsAtOrdersOptions">
          <button ngbDropdownItem type="button" (click)="exportAsAtOrdersToExcel()">
           To Excel
          </button>
          <button ngbDropdownItem type="button" (click)="exportAsAtOrdersAsJson()">
            As JSON
          </button>
        </div>
      </div>
      <div class='left'>
        Notes: 
        <p>
          There is no data for actions "Due Payment" or "Overdue Payment" prior to 03/05/2024 as the automated process that adds
          and removes those actions did not log the fact it had done so prior to that date.
        </p>
        <p>
          You can only select to load data for dates back to 01/04/2023 as the old style spreadsheet import 
          that was in use before then did not create the log records this data was built using.
        </p>
      </div>
      <ng-template #recordsLoadingBlock>
        <div class="load-orders spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </ng-template>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let orderCol of columns">
          <th [pSortableColumn]="orderCol.field">
            {{orderCol.header}}
            <p-sortIcon [field]="orderCol.field" ariaLabel="Activate to sort"
              ariaLabelDesc="Activate to sort in descending order" 
              ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-asAtOrder let-columns='columns'>
      <tr>
        <ng-container *ngFor="let orderCol of columns">
          <ng-container [ngSwitch]="orderCol.field">
            <td *ngSwitchCase="'websiteId'">
              <div class="brandBar" [ngStyle]="{
                'background-color': brandConfigs[asAtOrder.brand].background, 'color':brandConfigs[asAtOrder.brand].color
              }">
                {{asAtOrder.brand}}
              </div>
            </td>
            <td *ngSwitchDefault>
              <ng-container *ngIf="orderCol.field.includes('Date'); else notDate">
                {{asAtOrder[orderCol.field] | date:'dd/MM/y'}}
              </ng-container>
              <ng-template #notDate>
                {{asAtOrder[orderCol.field]}}
              </ng-template>
            </td>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="summary">
      There are {{asAtOrderRecords?.length}} orders
    </ng-template>

  </p-table>
</div>
<p-confirmDialog key="general"></p-confirmDialog>
<p-confirmDialog key="error"></p-confirmDialog>