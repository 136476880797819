<section id="registrationPage">
  <div class="container p-5">
    <div class="row mt-5">
      <div class="col-lg-4 offset-lg-4">
        <app-message></app-message>
        <div class="text-center"><h1>Registration Form</h1></div>
        <div id="errrorpass" *ngIf="unsafePassword">
          <h3>Your Password is not safe!</h3>
          <ul>
            <li>[a-z]	The password must contain at least 1 lowercase alphabetical character</li>
            <li>[A-Z]	The password must contain at least 1 uppercase alphabetical character</li>
            <li>[0-9]	The password must contain at least 1 numeric character</li>
            <li>[&#64;!$%^&*] The password must contain at least one special character</li>
            <li>The password must be a minimum of 12 characters</li>
          </ul>
        </div>
        <div class="card bg-light">
          <div class="card-body">
            <h4 class="text-center">Please remember to use a safe password</h4>
            <hr>
            <form>
              <div class="form-group">
                <label class="form-control-label">Name</label>
                <input type="text" name="name" class="form-control" [(ngModel)]="name">
              </div>
              <div class="form-group">
                <label class="form-control-label">Email</label>
                <input type="email" name="email" class="form-control" [(ngModel)]="email">
              </div>
              <div class="form-group">
                <label class="form-control-label">Password</label>
                <input type="password" name="password" class="form-control" [(ngModel)]="password" >
              </div>
              <div class="form-group">
                <label class="form-control-label">Confirm Password</label>
                <input type="password" name="password1" class="form-control" [(ngModel)]="password1">
              </div>
              <hr>
              <button type="button" class="btn btn-primary btn-block" (click)="register()" [disabled]="btnDisabled">Register</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
