import {AfterViewChecked, HostListener} from '@angular/core';
import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appAutoResizeTextarea]'
})
export class AutoResizeTextareaDirective implements AfterViewChecked {
  initialSizeSet: boolean;

  constructor(private textArea: ElementRef) { 
    this.initialSizeSet = false;
  }

  ngAfterViewChecked(): void {
    if (!this.initialSizeSet && !!this.textArea.nativeElement.value) {
      this.resize();
      this.initialSizeSet = true;
    }
  }

  resize(): void {
    // console.log(this.textArea.nativeElement.value);
    this.textArea.nativeElement.style.height = '0px';
    this.textArea.nativeElement.style.height = (this.textArea.nativeElement.scrollHeight + 2) + 'px';
  }

  @HostListener(':input') 
  onInput() {
    this.resize();
  }
}
