import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {clearLocalStorage} from '../helpers/helperFunctions';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent {

  constructor(
    private router: Router,
  ) { }

  logout() {
    clearLocalStorage();
    this.router.navigate(['/login']);
  }

  gohome() {
     this.router.navigate(['/']);
  }
}
