import { Component, OnInit, ViewChild } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ConfirmationService, MessageService, SelectItem, SortEvent} from 'primeng/api';
import {FriendsAndFamilyContentService} from './friends-and-family-content.service';
import {SbrApplicationContent} from '../../models/responses/sbr/SbrApplicationContent.model';
import {Column} from '../../models/column.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {contentTypeToDisplayName, contentTypes} from '../../lookups/sbrTypes';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {SbrApplicationCategory} from '../../models/responses/sbr/SbrApplicationCategory.model';
import {sortByLabel} from '../../helpers/helperFunctions';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-friends-and-family-content',
  templateUrl: './friends-and-family-content.component.html',
  styleUrls: ['./friends-and-family-content.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class FriendsAndFamilyContentComponent implements OnInit {

  constructor(
    private contentService: FriendsAndFamilyContentService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private title: Title,
  ) { }

  @ViewChild('fAndFContent', {static: false})
  fAndFContent: Table;
  contentTypeToDisplayName: {[contentId: string]: string} = contentTypeToDisplayName;
  applicationContents: SbrApplicationContent[] = [];
  columns: Column[] = [
    { field: 'sbr_sortorder', header: 'Sort Order' },
    { field: '_sbr_applicationcategoryid_value', header: 'Category' },
    { field: 'sbr_name', header: 'Internal Name' },
    { field: 'sbr_title', header: 'Title' },
    { field: 'sbr_contenttype', header: 'Type' },
    { field: 'sbr_description', header: 'Description' },
    { field: 'sbr_targeturl', header: 'Link Url' },
    { field: 'sbr_imageurl', header: 'Image' },
    { field: 'modifiedon', header: 'Last Modified' },
    { field: 'actions', header: 'Actions' },
  ];
  contentToEdit: SbrApplicationContent;
  showMaintainContent: boolean = false;
  updateDisabled: boolean = false;
  showAddCategory: boolean = false;
  categoryName: string;
  categories: SelectItem<string>[];
  contentTypeSelects: SelectItem<number>[] = contentTypes;

  ngOnInit(): void {
    this.title.setTitle('CRM Friends and Family Content');
    this.contentService.getContent().subscribe((contentResponse: MultiRecordResponse<SbrApplicationContent>) => {
      if (contentResponse.success) {
        this.applicationContents = contentResponse.data;
      } else {
        this.showErrorPopUp('Error loading Content', contentResponse.message || 'Something went wrong try again.');
      }
    });
    this.contentService.getContentCategories().subscribe((categoryResponse: MultiRecordResponse<SbrApplicationCategory>) => {
      if (categoryResponse.success) {
        this.categories = categoryResponse.data.map((category: SbrApplicationCategory) => {
          return {
            'label': category.sbr_name,
            'value': category.sbr_applicationcategoryid,
          };
        });
        sortByLabel(this.categories);
      } else {
        this.showErrorPopUp('Error loading Categories', categoryResponse.message || 'Something went wrong try again.');
      }
    });
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success Update!',
      detail: 'Changes Successfully Applied',
    });
  }

  showInfoPopUp(header: string, message: string) {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string) {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string) {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  closeMaintainContent(updatedContent: SbrApplicationContent) {
    this.showMaintainContent = false;
    // If !updateContent then dialog was cancelled
    if (updatedContent) {
      if (this.contentToEdit) {
        // It was an update
        this.applicationContents = this.applicationContents.map((content: SbrApplicationContent) => {
          if (content.sbr_applicationcontentid == updatedContent.sbr_applicationcontentid) {
            return updatedContent;
          }
          return content;
        })
      } else {
        // It was an add
        this.applicationContents = this.applicationContents.concat(updatedContent);
      }
    }
  }

  getDisplayUrl(url: string): string {
    if (!url) {
      return url;
    }
    return url.replace(/^https:\/\/www\.careline\.co\.uk/, '');
  }

  editContent(content: SbrApplicationContent): void {
    this.contentToEdit = content;
    this.showMaintainContent = true;
  }

  deleteContent(contentId: string): void {
    this.updateDisabled = true;
    this.contentService.deleteContent(contentId).subscribe((contentResponse: SingleRecordResponse<SbrApplicationContent>) => {
      if (contentResponse.success) {
        this.applicationContents =
          this.applicationContents.filter((content: SbrApplicationContent) => content.sbr_applicationcontentid != contentId);
      } else {
        this.showErrorPopUp('Error deleting Content', contentResponse.message || 'Something went wrong try again.');
      }
      this.updateDisabled = false;
    });
  }

  closeCategoryDialog() {
    const existingCategory: SelectItem<string> = this.categories.find((categorySelect: SelectItem<string>) => 
      categorySelect.label.toLocaleLowerCase() == this.categoryName.toLocaleLowerCase()
    );
    if (existingCategory) {
      this.showErrorPopUp('Name must be unique', 'You must specify a name you have not already used');
    } else {
      this.contentService.addContentCategory({
        'sbr_name': this.categoryName,
      }).subscribe((categoryResponse: SingleRecordResponse<SbrApplicationCategory>) => {
        if (categoryResponse.success && categoryResponse.data) {
          this.categoryName = '';
          this.showAddCategory = false;
          this.categories.push({
            'label': categoryResponse.data.sbr_name,
            'value': categoryResponse.data.sbr_applicationcategoryid,
          });
          sortByLabel(this.categories);
        } else {
          this.showErrorPopUp('Error adding category', categoryResponse.message);
        }
      });
    }
  }

  customSort(event: SortEvent) {
    event.data.sort((row1: SbrApplicationContent, row2: SbrApplicationContent): number => {
      let result: number = 0;
      if (event.field === '_sbr_applicationcategoryid_value') {
        if (!row1._sbr_applicationcategoryid_value && row2._sbr_applicationcategoryid_value) {
          result = -1;
        } else if (row1._sbr_applicationcategoryid_value && !row2._sbr_applicationcategoryid_value) {
          result = 1;
        } else {
          const cat1Select: SelectItem<string> = this.categories.find((categorySelect: SelectItem<string>) => 
            categorySelect.value == row1._sbr_applicationcategoryid_value
          );
          const cat2Select: SelectItem<string> = this.categories.find((categorySelect: SelectItem<string>) => 
            categorySelect.value == row2._sbr_applicationcategoryid_value
          );
          if (!cat1Select && cat2Select) {
            result = -1;
          } else if (cat1Select && !cat2Select) {
            result = 1;
          } else {
            result = cat1Select.label.localeCompare(cat2Select.label);
          }
        }
      } else {
        let value1 = row1[event.field];
        let value2 = row2[event.field];
        if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
      }
      return result * event.order;
    });
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.fAndFContent.filter(($event.target as HTMLInputElement).value, field, filterType);
  }
}
