import {OrderService} from './../order.service';
import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {Order} from '../../models/order.model';
import {UpdateFreeMonthsRequest} from '../../models/requests/updateFreeMonthsRequest.model';
import {OrderUpdateResponse} from '../../models/responses/orderUpdateResponse.model';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DomHandler} from 'primeng/dom';
import {getFreeMonthChangeReasons, getFreeMonthHoldReasons} from '../../lookups/freeMonths';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-free-months-update[orderData][closeDialog][updateOrderOnFreeMonthsChange]',
  templateUrl: './free-months-update.component.html',
  styleUrls: ['./free-months-update.component.scss'],
  providers: [],
})
export class FreeMonthsUpdateComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private orderService: OrderService,
    private confirmationService: ConfirmationService,
  ) { }
  display: boolean = true;
  @Input() orderData: Order = undefined;
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateOrderOnFreeMonthsChange: EventEmitter<Order> = new EventEmitter<Order>();
  freeMonthsForm: UntypedFormGroup;
  changeReasons: SelectItem<string>[];
  addFreeMonthsOptions: SelectItem<number>[] = [
    {'label': '1 Months', 'value': 1},
    {'label': '2 Months', 'value': 2},
    {'label': '3 Months', 'value': 3},
    {'label': '4 Months', 'value': 4},
    {'label': '5 Months', 'value': 5},
    {'label': '6 Months', 'value': 6},
    {'label': '7 Months', 'value': 7},
    {'label': '8 Months', 'value': 8},
    {'label': '9 Months', 'value': 9},
    {'label': '10 Months', 'value': 10},
    {'label': '11 Months', 'value': 11},
    {'label': '12 Months', 'value': 12},
  ];
  removeFreeMonthOptions: SelectItem<number>[] = [];
  accountOnHoldReasons: SelectItem<string>[]; 
  isFormSubmitted: boolean = false;
  isSubmitButtonDisabled: boolean = false;

  ngOnInit() {
    this.changeReasons = getFreeMonthChangeReasons();
    this.accountOnHoldReasons = getFreeMonthHoldReasons();
    this.freeMonthsForm = this.formBuilder.group({
      'selectedChangeReason': [null, Validators.required],
      'freeMonths': [null, Validators.required],
      'selectedReasonForAccountOnHold': [null],
      'reasonText': [''],
      'additionalNote': [''],
    });
    const currentFreeMonths: number = parseInt(this.orderData.renewalInformation.freemonths, 10);
    if (Number.isNaN(currentFreeMonths)) {
      // Remove the free month removal option
      this.changeReasons = this.changeReasons.filter((selectItem: SelectItem<string>) =>
        selectItem.value != 'Remove Free Months'
      );
    } else {
      for (let i: number = 1; i <= currentFreeMonths; i++) {
        this.removeFreeMonthOptions.push({
          'label': `${i} Months`,
          'value': i,
        });
      }
    }
  }

  get selectedChangeReason(): AbstractControl {
    return this.freeMonthsForm.get('selectedChangeReason');
  }

  get freeMonths(): AbstractControl {
    return this.freeMonthsForm.get('freeMonths');
  }

  get selectedReasonForAccountOnHold(): AbstractControl {
    return this.freeMonthsForm.get('selectedReasonForAccountOnHold');
  }

  get reasonText(): AbstractControl {
    return this.freeMonthsForm.get('reasonText');
  }

  get additionalNote(): AbstractControl {
    return this.freeMonthsForm.get('additionalNote');
  }

  freeMonthReasonChange() {
    if (this.selectedChangeReason.value) {
      if (this.selectedChangeReason.value === 'Account on Hold') {
        this.selectedReasonForAccountOnHold.setValidators(Validators.required);
        if (this.selectedReasonForAccountOnHold.value === 'Other') {
          this.reasonText.setValidators(Validators.required);
        } else {
          this.reasonText.clearValidators();
        }
      } else if (this.selectedChangeReason.value === 'Remove Free Months') {
        this.selectedReasonForAccountOnHold.clearValidators();
        this.reasonText.setValidators(Validators.required);
      } else {
        this.selectedReasonForAccountOnHold.clearValidators();
        this.reasonText.clearValidators();
      }
      // Have to do this to re-evalutate valid status
      this.selectedReasonForAccountOnHold.updateValueAndValidity();
      this.reasonText.updateValueAndValidity();
    }
  }

  holdReasonChange() {
    if (this.selectedReasonForAccountOnHold.value) {
      if (this.selectedReasonForAccountOnHold.value === 'Other') {
        this.reasonText.setValidators(Validators.required);
      } else {
        this.reasonText.clearValidators();
      }
      // Have to do this to re-evalutate valid status
      this.reasonText.updateValueAndValidity();
    }    
  }

  submitData() {
    this.isSubmitButtonDisabled = true;
    const params: UpdateFreeMonthsRequest = {
      'orderId': this.orderData._id,
      'userName': localStorage.getItem('userName'),
      'selectedChangeReason': this.selectedChangeReason.value,
      'freeMonths': this.freeMonths.value,
      'selectedReasonForAccountOnHold': this.selectedReasonForAccountOnHold.value,
      'reasonText': this.reasonText.value,
      'additionalNote': this.additionalNote.value,
      'orderLink': `${environment.protocol}${environment.IPAddress}/order/${this.orderData._id}`,
    };
    this.orderService.updateFreeMonths(params).subscribe((response: OrderUpdateResponse) => {
        if (response.success) {
          this.updateOrderOnFreeMonthsChange.emit(response.order);
        } else {
          this.showErrorPopUp('Error Updating Free Months', `There was an error updating free months. ${response.message}.`);
        }
        this.isSubmitButtonDisabled = false;
      }, (err: Error) => {
        this.showErrorPopUp('Error Updating Free Months',
          `There was an error updating free months. Error: ${err? err.message: 'uknown error'}`
        );
        this.isSubmitButtonDisabled = false;
      });
  }

  onHideDialog() {
    this.closeDialog.emit();
  }

  removeScrollBlock() {
    DomHandler.removeClass(document.body, 'p-overflow-hidden');

  }
  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('aboveBlockMessage', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
