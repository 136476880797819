import {ActionService} from './action.service';
import {MessageService} from 'primeng/api';

import {Component, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {ActionConfig} from '../../lookups/actions';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  providers:[MessageService]
})
export class ActionsComponent implements OnInit {

  constructor(
    private messageService: MessageService,
    private actionService: ActionService,
    private title:Title
  ) { }
  @ViewChild('actionsTable', {static: false})
  actionTable: Table;

  actionDetail: Partial<ActionConfig> = null;
  actionOpenMode: string = 'add';
  actions: ActionConfig[] = [];
  columns = [
    { field: 'actionName', header: 'Action Name' },
    { field: 'background', header: 'Background' },
    { field: 'color', header: 'Text Colour' },
    { field: 'usersCanAdd', header: 'User Can Add' },
    { field: 'board', header: 'Board' },
    { field: 'boardSortOrder', header: 'Board Sort Order' },
    { field: 'defaultDueInDays', header: 'Default Due In Days' },
    { field: 'actions', header: 'Actions' },
  ]

  ngOnInit(): void {
    this.getActions();
    this.title.setTitle('CRM Actions');
  }

  getActions() {
    this.actionService.getActions().subscribe((response: MultiRecordResponse<ActionConfig>) => {
      // console.log("Response on get all actions", response);
      this.actions = response.data;
    }, (err: Error) => {
      console.log("Error on getActions :: ", err);
    });
  }

  closeAddNewAction(event) {
    if (event.name == 'close') {
      this.actionDetail = null;
    } else if (event.name == 'success') {
      this.getActions();
      this.showSuccess();
    } else if (event.name == 'error') {
      this.showError();
    }
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Changes successfully applied',
      life: 1000
    });
  }

  showError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Something went wrong, try again',
      life: 300000
    });
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.actionTable.filter(($event.target as HTMLInputElement).value, field, filterType);
  }
}
