<div class="renewal-links">
  <div class="container">
    <div class="d-flex justify-content-center m-1">
      <p-dropdown [options]="brands" appendTo="body" [(ngModel)]="selectedBrand" placeholder="Brands" scrollHeight="600px">
        <ng-template let-Pbrand let-i="index" pTemplate="item">
            <span class="brandDropDown"
                [ngStyle]="{'background-color': brandConfigs[Pbrand.value].background, 'color': brandConfigs[Pbrand.value].color}">
                &nbsp;{{Pbrand.value}}
            </span>
        </ng-template>
      </p-dropdown>
      <div *ngIf="!loading else loadingBlock">
          <button type="button" class="btn btn-primary ml-2" (click)="loadRenewalLinks()" pButton
              label="Load Links" [disabled]="!selectedBrand"></button>
      </div>
      <ng-template #loadingBlock>
          <div class="ml-2 spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
          </div>
      </ng-template>
      <form [formGroup]="addRenewalLinkForm" class="ml-3">
          <input type="text" pInputText (keypress)="numberOnly($event, true)" class="ml-3" formControlName="renewalPrice" placeholder="Enter renewal price" required>
          <p-checkbox class="ml-3 mb-2" formControlName="priceIncVat" [binary]="true" inputId="binary"></p-checkbox>
          <label for="binary">PriceIncVat</label>
          <input type="text" pInputText class="ml-3" formControlName="link" placeholder="Enter link" required>
          <button type="button" (click)="addRenewalLink()" class="btn btn-primary ml-2" 
              [disabled]="!canAddRenewalLink || addRenewalLinkForm.invalid" required>
            Add Link
          </button>
      </form>
    </div>
  </div>
  <p-table #dt [columns]="cols" [value]="renewalLinks" [paginator]="true" [rows]="15">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <div *ngSwitchCase="'renewalPrice'" [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order">
                    </p-sortIcon>
                </div>
                <div *ngSwitchCase="'link'" [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order">
                    </p-sortIcon>
                </div>
                <div *ngSwitchDefault>
                    {{col.header}}
                </div>
            </th>
        </tr>
        <tr>
            <th *ngFor="let col of columns" [ngSwitch]="col.field">
                <div *ngSwitchCase="'renewalPrice'">
                    <p-columnFilter type="text" [matchMode]="'startsWith'" field="{{col.field}}" class="p-ml-auto">
                    </p-columnFilter>
                </div>
                <div *ngSwitchCase="'link'">
                    <p-columnFilter type="text" [matchMode]="'startsWith'" field="{{col.field}}" class="p-ml-auto">
                    </p-columnFilter>
                </div>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData" class="renewal-link-row">
            <td *ngFor="let col of columns" [ngSwitch]="col.field">
                <div *ngSwitchCase="'brand'">
                  <span class="brand"
                      [ngStyle]="{'background-color': brandConfigs[rowData[col.field]].background, 'color': brandConfigs[rowData[col.field]].color}">
                      &nbsp;{{rowData[col.field]}}
                  </span>
                </div>
                <div *ngSwitchCase="'priceIncVat'">
                    <p-checkbox class="ml-2" [(ngModel)]="rowData['priceIncVat']" [binary]="true" inputId="binary"></p-checkbox>
                </div>
                <div *ngSwitchCase="'renewalPrice'">
                    <input type="text" class="form-control" pInpuText (keypress)="numberOnly($event, true)" [(ngModel)]="rowData[col.field]">
                </div>
                <div *ngSwitchCase="'link'">
                    <input type="text" pInputText [(ngModel)]="rowData[col.field]" class="form-control">
                </div>
                <div *ngSwitchCase="'actions'">
                    <button type="button" class="btn btn-primary" (click)="updateRenewalLink(rowData)" [disabled]="!canRenewalLinkUpdate">Update</button>
                    <button type="button" class="btn btn-danger ml-2" (click)="deleteRenewalLink(rowData['_id'])"
                        [disabled]="!canDeleteRenewalLink">Delete</button>
                </div>
                <div *ngSwitchDefault>
                    {{rowData[col.field]}}
                </div>
            </td>
        </tr>
    </ng-template>
  </p-table>
</div>
<p-toast></p-toast>