import {SelectItem} from 'primeng/api';
import { selectItemSort } from '../helpers/helperFunctions';

// This is for primeng dropdowns/multiselects
const equipmentCategories: SelectItem<string>[] = [
  { 'label': 'Alarm', 'value': 'Alarm' },
  { 'label': 'Pendant/Accessories', 'value': 'Pendant/Accessories' },
  { 'label': 'Cable', 'value': 'Cable' },
];

let alarmsBrands: SelectItem<string>[];

function getAlarmsBrands(): SelectItem<string>[] {
  if (!alarmsBrands) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('faulty equipment: alarm brands')) {
      return [];
    }
    alarmsBrands = JSON.parse(localStorage.getItem('faulty equipment: alarm brands')) || [];
    selectItemSort(alarmsBrands);
  }
  
  return alarmsBrands;
}

let accessoryBrands: SelectItem<string>[];

function getAccessoryBrands(): SelectItem<string>[] {
  if (!accessoryBrands) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('faulty equipment: accessory brands')) {
      return [];
    }
    accessoryBrands = JSON.parse(localStorage.getItem('faulty equipment: accessory brands')) || [];
    selectItemSort(accessoryBrands);
  }
  
  return accessoryBrands;
}

let cableBrands: SelectItem<string>[];

function getCableBrands(): SelectItem<string>[] {
  if (!cableBrands) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('faulty equipment: cable brands')) {
      return [];
    }
    cableBrands = JSON.parse(localStorage.getItem('faulty equipment: cable brands')) || [];
    selectItemSort(cableBrands);
  }
  return cableBrands;
}

interface FaultType extends SelectItem<string> {
  'equipCategories': string[],
}

let faultTypes: FaultType[];

function getAllFaultTypes(): FaultType[] {
  if (!faultTypes) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('faulty equipment: fault types')) {
      return [];
    }
    faultTypes = JSON.parse(localStorage.getItem('faulty equipment: fault types')) || [];
    selectItemSort(faultTypes);
  }
  return faultTypes;
}

let alarmFaults: FaultType[];

function getAlarmFaults(): FaultType[] {
  if (!alarmFaults) {
    alarmFaults = getAllFaultTypes().filter((fault: FaultType) => 
      fault.equipCategories.includes('Alarm')
    );
    selectItemSort(alarmFaults);
  }
  return alarmFaults;
}

let accessoryFaults: FaultType[];

function getAccessoryFaults(): FaultType[] {
  if (!accessoryFaults) {
    accessoryFaults = getAllFaultTypes().filter((fault: FaultType) => 
      fault.equipCategories.includes('Pendant/Accessories')
    );
    selectItemSort(accessoryFaults);
  }
  return accessoryFaults;
}

let cableFaults: FaultType[];

function getCableFaults(): FaultType[] {
  if (!cableFaults) {
    cableFaults = getAllFaultTypes().filter((fault: FaultType) => 
      fault.equipCategories.includes('Cable')
    );
    selectItemSort(cableFaults);
  }
  return cableFaults;
}

let alarmAndAccessoryFaultyProducts: SelectItem<string>[];

function getAlarmAndAccessoryFaultyProducts(): SelectItem<string>[] {
  if (!alarmAndAccessoryFaultyProducts) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('faulty equipment: alarm and accessory faulty product')) {
      return [];
    }
    alarmAndAccessoryFaultyProducts = JSON.parse(localStorage.getItem('faulty equipment: alarm and accessory faulty product')) || [];
    selectItemSort(alarmAndAccessoryFaultyProducts);
  }
  return alarmAndAccessoryFaultyProducts;
}


let cableFaultyProducts: SelectItem<string>[];

function getCableFaultyProducts(): SelectItem<string>[] {
  if (!cableFaultyProducts) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('faulty equipment: cable faulty product')) {
      return [];
    }
    cableFaultyProducts = JSON.parse(localStorage.getItem('faulty equipment: cable faulty product')) || [];
    selectItemSort(cableFaultyProducts);
  }
  return cableFaultyProducts;
}

const shelfLocations: SelectItem<number>[] = [
  { 'label': '1', 'value': 1 },
  { 'label': '2', 'value': 2 },
  { 'label': '3', 'value': 3 },
];

let notFixedReasons: SelectItem<string>[];

function getNotFixedReasons(): SelectItem<string>[] {
  if (!notFixedReasons) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('faulty equipment: not fixed reason')) {
      return [];
    }
    notFixedReasons = JSON.parse(localStorage.getItem('faulty equipment: not fixed reason')) || [];
    selectItemSort(notFixedReasons);
  }
  return notFixedReasons;
}

let cseOrderPageFaults: SelectItem<string>[];

function getCseOrderPageFaults(): SelectItem<string>[] {
  if (!cseOrderPageFaults) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('CSE Order: faults')) {
      return [];
    }
    cseOrderPageFaults = JSON.parse(localStorage.getItem('CSE Order: faults')) || [];
  }
  return cseOrderPageFaults;
}

export {
  equipmentCategories,
  getAlarmsBrands,
  getAccessoryBrands,
  getCableBrands,
  getAllFaultTypes,
  getAlarmFaults,
  getAccessoryFaults,
  getCableFaults,
  getAlarmAndAccessoryFaultyProducts,
  getCableFaultyProducts,
  shelfLocations,
  getNotFixedReasons,
  getCseOrderPageFaults,
}