import {SelectItem} from 'primeng/api';

const contentTypes: SelectItem<number>[] = [
  {'label': 'Advert', 'value': 858810000},
  {'label': 'Article', 'value': 858810001},
];

const contentTypeToDisplayName: {[contentTypeId: string]: string} = {};

contentTypes.forEach((contentType: SelectItem<number>) => {
  contentTypeToDisplayName[contentType.value] = contentType.label;
});

export {
  contentTypes,
  contentTypeToDisplayName,
};
