import {Component, EventEmitter, Output} from '@angular/core';
import {RoleService} from '../../maintain-roles/role.service';
import {permissions} from '../../../lookups/rolesAndPermissions';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {UsersService} from '../../users/users.service';
import {MultiSelectChangeEvent} from '../../../models/primeng/multiSelectChangeEvent.model';
import {SingleRecordResponse} from '../../../models/responses/singleRecordResponse.model';
import {Role} from '../../../models/role.model';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss'],
  providers: [ConfirmationService]
})
export class AddRoleComponent {

  constructor(
    private roleService: RoleService,
    private usersService: UsersService,
    private confirmationService: ConfirmationService,
  ) {
  }
  @Output() closeAddRoleModal = new EventEmitter<{eventName:string}>();
  displayModal: boolean = true;
  roleName: string = '';
  rolePermissions: SelectItem<string>[] = permissions;
  selectedPermissions: string[];
  isSubmitButtonDisabled: boolean = false;

  addRole() {
    this.isSubmitButtonDisabled = true;
    this.roleService.addRole({
      roleName: this.roleName.trim(),
      permissions: this.selectedPermissions
    }).subscribe((response: SingleRecordResponse<Role>) => {
      this.isSubmitButtonDisabled = false;
      if (response.success)
        this.closeAddRoleModal.emit({ eventName: 'success' });
      else
        this.closeAddRoleModal.emit({ eventName: 'error' });
    }, (err: Error) => {
      this.isSubmitButtonDisabled = false;
      console.log('Error on adding new role :: ', err);
      this.closeAddRoleModal.emit({ eventName: 'error' });
    })
  }

  closeModal() {
    this.closeAddRoleModal.emit({ eventName: 'close' });
  }

  // MultSelectChangeEvent incorrectly has value as array of strings and itemValue as SelectItem<string> both should be the same type
  confirmPermissionAddition(event: MultiSelectChangeEvent<any>) {
    if (event.value.includes(event.itemValue.value)) {
      // The user has tried to add a permission
      if (!this.usersService.userHasPermission(event.itemValue.value)) {
        // Undo the change
        this.selectedPermissions = this.selectedPermissions.filter((permission: string) => permission != event.itemValue.value);
        this.confirmationService.confirm({
          message: 'You are not allowed to add a permission that you do not have yourself.',
          header: 'Permission Denied',
          icon: 'pi pi-info-circle',
          acceptVisible: true,
          acceptLabel: 'OK',
          rejectVisible: false,
          accept: () => {
          },
        });
        return;
      }
    }
  }
}
