<div class="container">
  <br>
  <div>
    <div class="alert alert-primary" role="alert" [ngStyle]="{'border':selectedWebsite? '3px solid ' + selectedWebsite.titleBarColor  :''}">
      Step 1 - Brand
    </div>
    <div class="row">
      <ng-container *ngFor="let website of websites">
        <div class="col-2" *ngIf="['WooCommerce', 'Shopify'].includes(website.usingWebBackend)">
          <img (click)="selectBrand(website);" src="assets/img/{{website.title}}.png" class="img-thumbnail"
            [ngStyle]="{'border': (selectedWebsite && (website._id === selectedWebsite._id))? '6px solid ' + website.titleBarColor  :''}">
          {{website.title}}
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="selectedWebsite">
    <div class="alert alert-primary" role="alert" [ngStyle]="{'border':selectedWebsite? '3px solid ' + selectedWebsite.titleBarColor  :''}">
      Step 2 - Order Type
    </div>
    <div class="row">
      <div class="col-3">
        <label>Order Type</label>
      </div>
      <div class="col-9">
        <p-dropdown [options]="orderTypes" placeholder="Select an Order Type" [(ngModel)]="baseOrderInformation.orderType"
          [style]="{'width':'100%'}" [readonly]="!showPlaceOrder" (onChange)="changeOrderType()">
        </p-dropdown>
      </div>
      <div class="col-12">
        <label>Note</label><br/>If you change the order type the basket will be cleared as item prices are different for different types of order.
      </div>
    </div>
    <ng-container *ngIf="isExistingCustomer()">
      <div class="row">
        <div class="col-3">
          <label>Add to existing CRM Account?</label>
        </div>
        <div class="col-3">
          <input [ngClass]="{'error': !baseOrderInformation.allowReplacementWithoutTd && !tdCodeFound}" type="text" placeholder="Search CRM - TD Code"
            [(ngModel)]="searchTDCode" (change)="search()" [readonly]="!showPlaceOrder">
          <p *ngIf="tdCodeSearched && !tdCodeFound" class="alert alert-danger">TD Code Not Found</p>
        </div>
        <ng-container *ngIf="isReplacementWithoutTdAllowed()">
          <div class="col-4">
            <label>No existing CRM Account</label><br/>
            e.g. replacement on keysafe only order
          </div>
          <div class="col-2">
            <input type="checkbox" [(ngModel)]="baseOrderInformation.allowReplacementWithoutTd" style="width: auto;"
            (change)="changeReplacementWithoutTd()">
          </div>
        </ng-container>
      </div>
      <div class="row">
        <ng-container *ngIf="tdCodeFound">
          <div class="col-6">
            <label>Customer Name</label><br/>
            {{order.alarmUserDetails.firstName}} {{order.alarmUserDetails.lastName}}
          </div>
          <div class="col-2">
            <label>Order Date</label><br/>
            {{order.created | date:'dd/MM/y'}}
          </div>
          <div class="col-2">
            <label>Free Months</label><br/>
            {{order.renewalInformation.freemonths}}
          </div>
          <div class="col-2">
            <label>Plan Type</label><br/>
            {{planType}}
          </div>
          <div class="col-3">
            <label>Current Renewal Price</label><br/>
            {{order.renewalInformation.renewalPrice}}
          </div>
          <div class="col-3">
            <label>CRM Renewal Date</label><br/>
            {{crmRenewalDate}}
          </div>
          <div class="col-3">
            <label>Next Renewal Date</label><br/>
            {{baseOrderInformation.nextRenewalDate}}
          </div>
          <div class="col-3">
            <label>Pro-rata Charge to Date</label><br/>
            {{baseOrderInformation.cseOrder.proRataToDate}}
          </div>
          <div class="col-12">
            <label>Notes</label><br/>
            Pro-rata charges are only relevant to Additional Equipment and Upgrade orders. Overdue past renewals will not be included.<br/>
            If the next renewal date is too close and the payment date is passed or too close to change the payment
            additional renewal periods will be included in the pro-rata cost calculation.<br/>
          </div>
        </ng-container>
        <ng-container *ngIf="isReplacementDueToFault()">
          <div class="col-3">
            <label>What is the fault?</label>
          </div>
          <div class="col-3">
            <p-dropdown [ngClass]="{'error': !baseOrderInformation.cseOrder.fault}" [options]="faultOptions" placeholder="Select a fault"
                [(ngModel)]="baseOrderInformation.cseOrder.fault" [style]="{'width':'100%'}" [readonly]="!showPlaceOrder">
            </p-dropdown>
          </div>
          <div *ngIf="baseOrderInformation.cseOrder.fault === 'Other'" class="col-6">
            <input [ngClass]="{'error': !baseOrderInformation.cseOrder.otherFault}" type="text" placeholder="Fault details"
              [(ngModel)]="baseOrderInformation.cseOrder.otherFault" [readonly]="!showPlaceOrder">
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="baseOrderInformation.orderType && (baseOrderInformation.orderType.title === 'Phone Order')">
      <div class="row">
        <div class="col-3">
          <label>Number customer called from</label> 
        </div>
        <div class="col-4">
          <input [ngClass]="{'error' : !baseOrderInformation.cseOrder.calledFrom && !baseOrderInformation.withheldNumber}" (keypress)="phoneNumber($event)"
            [(ngModel)]="calledFrom" type="text" [readonly]="!showPlaceOrder || baseOrderInformation.withheldNumber" autocomplete="off"
            >
        </div>
        <div class="col-3" style="padding-left: 60px">
          <label>Customer withheld number</label>
        </div>
        <div class="col-1">
          <input type="checkbox" [(ngModel)]="baseOrderInformation.withheldNumber" style="width: auto;" [disabled]="!showPlaceOrder">
        </div>
      </div>
    </ng-container>
    <ng-template #vimMessages>
      <div *ngFor="let vim of vims; let vimIndex=index">
        <span class="vimmessage"> {{vim.content}}</span>
        <span class="vimby"> By {{vim.userName}}&#64; {{vim.date | date:'dd/MM/y HH:mm'}}</span>
      </div>
    </ng-template>
    <p-dialog id="vimPopup" [(visible)]="vimPopupOpen" header="" [modal]="true"  [style]="{width: '80%'}" (onHide)="closeVimPopup()"
      [transitionOptions]="'0ms'">
      <p-header>
        <div class="d-flex justify-content-between">
          <span>Very Important Message</span>
        </div>
      </p-header>
      <ng-container *ngTemplateOutlet="vimMessages"></ng-container>
      <p-footer>
        <button type="button" class="btn btn-primary" (click)="closeVimPopup()">
          <span aria-hidden="true" class="pi pi-check"></span>OK
        </button>
      </p-footer>
    </p-dialog>
    <ng-container *ngIf="showOrderForm">
      <app-shopify-order *ngIf='selectedWebsite.usingWebBackend === "Shopify"'
        [baseOrderInformation]='baseOrderInformation' [partnerships]='partnerships' [partnershipByVoucherCode]='partnershipByVoucherCode'
        (updateShowPlaceOrder)='updateShowPlaceOrder($event)'></app-shopify-order>
      <app-woocommerce-order *ngIf='selectedWebsite.usingWebBackend === "WooCommerce"'
        [baseOrderInformation]='baseOrderInformation' [partnerships]='partnerships' [partnershipByVoucherCode]='partnershipByVoucherCode'
        (updateShowPlaceOrder)='updateShowPlaceOrder($event)'>
      </app-woocommerce-order>
    </ng-container>
  </ng-container>
</div>
<p-confirmDialog key="general"></p-confirmDialog>
<p-confirmDialog key="error"></p-confirmDialog>