import {Component, OnDestroy, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Params} from '@angular/router';
import {ConfirmationService, FilterService, OverlayOptions, SelectItem} from 'primeng/api';
import {Table} from 'primeng/table';
import {crmDelay, getWorkingDaysBetween, isValidObjectId} from '../helpers/helperFunctions';
import {getBrandBackgroundColour, getBrandColour, getBrandSelectItems} from '../lookups/brands';
import {getCustomerFeedbackContactMethods, getCustomerFeedbackMainReasons, 
  getCustomerFeedbackPartnerships, getCustomerFeedbackSpecificReasonsForMainReason, getCustomerFeedbackStatuses,
  getCustomerFeedbackTypes} from '../lookups/customerFeedback';
import {Column} from '../models/column.model';
import {CustFeedbackForListPage, CustomerFeedback, FeebackContactAttempt} from '../models/customerFeedback.model';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {SelectItemWithEmail} from '../models/selectItemWithEmail.model';
import {CustFeedbackLockData} from '../models/socket-io/custFeedbackLockData.model';
import {OrderLockData} from '../models/socket-io/orderLockData.model';
import {CustomerFeedbackSocketService} from '../sockets/customer-feedback-socket.service';
import {LocksSocketService} from '../sockets/locks-socket.service';
import {CustomerFeedbackService} from './customer-feedback.service';
import moment from 'moment-timezone';
import {ExcelExportService} from '../post-order/excel-export.service';
import {AddNoteParams} from '../models/addNoteParams.model';
import {UsersService} from '../setup/users/users.service';
import {dateRangeFilters} from '../helpers/tableDateRangeFilters';
import {PageCountResponse} from '../models/responses/pageCountResponse.model';
import {OrderIdPlanOnly} from '../models/mongoose-populated-ids/orderIdPlanOnly.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {DEFAULT_OVERLAY} from '../lookups/primeng/overlayOptions';

const MAX_PARALLEL: number = 5;
const PAGE_RETRIES: number = 3;

enum ExportOptions {
  ALL = 'All',
  FILTERED = 'Filtered',
  THIS_MONTH = 'This_Months',
  LAST_MONTH = 'Last_Months',
  THIS_MONTH_AND_LAST_MONTH = 'This_and_Last_Months',
}

@Component({
  selector: 'app-customer-feedback',
  templateUrl: './customer-feedback.component.html',
  styleUrls: ['./customer-feedback.component.scss'],
  providers: [ConfirmationService]
})
export class CustomerFeedbackComponent implements OnInit, OnDestroy, AfterViewInit {
  ExportOptions = ExportOptions;
  showAddFeedbackDialog: boolean;
  feedbackRecords: CustFeedbackForListPage[] = [];
  cols: Column[] = [
    { field: 'websiteId', header: 'Brand' },
    { field: 'feedbackType', header: 'Type' },
    { field: 'priority', header: 'Priority' },
    { field: 'partnership', header: 'Partner' },
    { field: 'tdCode', header: 'TD Code' },
    { field: 'dateLogged', header: 'Date Logged' },
    { field: 'loggedBy', header: 'Logged By' },
    { field: 'dateClosed', header: 'Date Closed' },
    { field: 'closedBy', header: 'Closed By' },
    { field: 'daysActive', header: 'Days Active' },
    { field: 'daysPending', header: 'Pending Days' },
    { field: 'status', header: 'Status' },
    { field: 'mainReason', header: 'Main Reason' },
    { field: 'specificReason', header: 'Specific Reason' },
    { field: 'contactMethod', header: 'Contact Method' },
    { field: 'respondByEmail', header: 'Respond By Email'},
    { field: 'actions', header: 'Actions' },
  ];
  orderLockList: OrderLockData[] = [];
  custFeedbackLockList: CustFeedbackLockData[] = [];
  feedbackIdToEdit: string;
  updateReceived: boolean;
  first: number;
  @ViewChild('custFeedback', {static: false})
  custFeedbackTable: Table;
  currentDate: string;
  overlayOptions: OverlayOptions = DEFAULT_OVERLAY;
  getWorkingDaysBetween = getWorkingDaysBetween;
  getBrandBackgroundColour = getBrandBackgroundColour;
  getBrandColour = getBrandColour;
  brands: SelectItem<string>[];
  feedbackTypes: SelectItem<string>[];
  feedbackPartnerships: SelectItemWithEmail<string>[];
  feedbackMainReasons: SelectItemWithEmail<string>[];
  feedbackSpecificReasons: SelectItem<string>[];
  feedbackContactMethods: SelectItem<string>[];
  feedbackStatuses: SelectItem<string>[];
  selectedStatuses: string[];
  filterYearRange: string;
  dateLoggedFilters: Date[];
  dateClosedFilters: Date[];
  feedbackPriorities: SelectItem<string>[] = [{
    'label': 'Low',
    'value': 'Low'
  }, {
    'label': 'Medium',
    'value': 'Medium'
  }, {
    'label': 'High',
    'value': 'High'
  }];
  respondByEmailFilter: SelectItem<string|boolean>[] = [{
    'label': 'All',
    'value': ''
  }, {
    'label': 'No',
    'value': false
  }, {
    'label': 'Yes',
    'value': true
  }];
  customerFeedbackLoading: boolean = false;
  customerFeedbackPagesToLoad: number = 0;
  pagesProcessing: number;
  userCanExportFeedback: boolean;
  userCanDeleteFeedback: boolean;
  
  constructor(
    private customerFeedbackService: CustomerFeedbackService,
    private customerFeedbackSocket: CustomerFeedbackSocketService,
    private locksSocket: LocksSocketService,
    private confirmationService: ConfirmationService,
    private title: Title,
    private route: ActivatedRoute,
    private excelService: ExcelExportService,
    private userService: UsersService,
    private filterService: FilterService,
  ) { }

  ngOnInit(): void {
    this.userCanExportFeedback = this.userService.userHasPermission('Export Customer Feedback');
    this.userCanDeleteFeedback = this.userService.userHasPermission('Delete Customer Feedback');
    this.showAddFeedbackDialog = false;
    this.first = 0;
    this.updateReceived = false;
    this.title.setTitle('CRM Customer Feedback');
    this.filterYearRange = `2014:${moment.tz('Europe/London').add(1, 'year').get('year')}`;
    let self = this;
    this.filterService.register('dateLogged', (value: any, filter: any): boolean => {
      return dateRangeFilters(value, filter, self.dateLoggedFilters);
    });
    this.filterService.register('dateClosed', (value: any, filter: any): boolean => {
      return dateRangeFilters(value, filter, self.dateClosedFilters);
    });
    this.currentDate = moment.tz('Europe/London').toISOString();
    this.brands = getBrandSelectItems();
    this.feedbackTypes = getCustomerFeedbackTypes();
    this.feedbackPartnerships = getCustomerFeedbackPartnerships();
    this.feedbackMainReasons = getCustomerFeedbackMainReasons();
    this.feedbackSpecificReasons = [];
    this.feedbackMainReasons.forEach((mainReason: SelectItemWithEmail<string>) => {
      const specificReasons: SelectItem<string>[] = getCustomerFeedbackSpecificReasonsForMainReason(mainReason.label);
      if (specificReasons.length > 0) {
        this.feedbackSpecificReasons.push(...specificReasons);
      }
    });
    this.feedbackContactMethods = getCustomerFeedbackContactMethods();
    this.feedbackStatuses = getCustomerFeedbackStatuses();
    // Set the default filter to display open feedback only
    this.selectedStatuses = ['Open'];
    this.customerFeedbackSocket.on('updateCustFeedback', this.processUpdateFromSocketIo);
    this.customerFeedbackSocket.on('deletedCustFeedback', this.processFeedbackDeletion);
    this.locksSocket.on('lockList', (orderLocks: OrderLockData[]) => {
      this.orderLockList = orderLocks;
    });
    this.locksSocket.emit('getLocked');
    this.locksSocket.on('custFeedbackLockList', (custFeedbackLocks: CustFeedbackLockData[]) => {
      this.custFeedbackLockList = custFeedbackLocks;
    });
    this.locksSocket.emit('getLockedCustFeedback');

    this.feedbackIdToEdit = '';
    this.loadCustomerFeedback();
  }

  loadCustomerFeedback(): void {
    this.customerFeedbackLoading = true;
    this.customerFeedbackPagesToLoad = 0;
    this.customerFeedbackService.getCustomerFeedbackPageCount()
      .subscribe(async (response: PageCountResponse) => {
        if (!response.success) {
          this.customerFeedbackLoading = false;
          this.showErrorPopUp('Error', `Error getting page count for customer feedback. ${response.message}`);
        } else {
          this.pagesProcessing = 0;
          this.customerFeedbackPagesToLoad = response.pageCount!;
          if (this.customerFeedbackPagesToLoad === 0) {
            this.customerFeedbackLoading = false;
          } else {
            for (let page: number = 1; page <= response.pageCount!; page++) {
              // Limit the max number of pages being updated in parallel which allows other work to complete
              // whilst pages are still loading and allows abort if page navigated away from
              while (this.pagesProcessing >= MAX_PARALLEL) {
                await crmDelay(250);
              }
              this.loadCustomerFeedbackPage(page, PAGE_RETRIES);
            }
          }
        }
      });
  }

  loadCustomerFeedbackPage(page: number, retryCount: number) {
    this.pagesProcessing++;
    this.customerFeedbackService
      .getCustomerFeedbackPage(page)
      .subscribe((response: MultiRecordResponse<CustomerFeedback>) => {
        if (!response.success || !response.data) {
          console.log(`Error loading page ${page}. Error: ${response.message}`);
          if (retryCount > 0) {
            this.loadCustomerFeedbackPage(page, retryCount - 1);
            return;
          }
          // Only decrement count if we are not retrying page
          this.pagesProcessing--;
          this.showErrorPopUp('Error loading feedback records', `Something went wrong try again. Error: ${response.message}`);
          return;
        }
        this.pagesProcessing--;
        const feedbackToAdd: CustFeedbackForListPage[] = [];
        response.data.forEach((newFeedback: CustomerFeedback) => {
          if (!this.feedbackRecords.some((existingFeedback: CustomerFeedback) => {
            newFeedback._id == existingFeedback._id
          })) {
            feedbackToAdd.push(this.getCustFeedbackForListPage(newFeedback));
          }
        });
        if (feedbackToAdd.length > 0) {
          this.feedbackRecords = this.feedbackRecords.concat(feedbackToAdd);
        }
        this.customerFeedbackPagesToLoad--;
        if (this.customerFeedbackPagesToLoad == 0) {
          this.customerFeedbackLoading = false;
          this.route.params.subscribe((params: Params) => {
            if (params.id && isValidObjectId(params.id)) {
              this.feedbackIdToEdit = params.id;
            }
          });
        }
      }, (err: Error) => {
        console.log(`Error loading page ${page}. Error ${err.message}`);
        if (retryCount > 0) {
          this.loadCustomerFeedbackPage(page, retryCount - 1);
          return;
        }
        // Only decrement count if we are not retrying page
        this.pagesProcessing--;
        this.showErrorPopUp('Error loading feedback records', `Something went wrong try again. Error: ${err.message}`);
      });
  }

  ngAfterViewInit() {
    this.custFeedbackTable.filter(this.selectedStatuses, 'status', 'in');
  }

  getCustFeedbackForListPage(custFeedback: CustomerFeedback): CustFeedbackForListPage {
    const custFeedbackForListPage: CustFeedbackForListPage = (custFeedback as CustFeedbackForListPage);
    if (['Open'].includes(custFeedbackForListPage.status)) {
      custFeedbackForListPage.daysActive = getWorkingDaysBetween(custFeedbackForListPage.dateLogged, this.currentDate);
    } else {
      custFeedbackForListPage.daysActive = custFeedbackForListPage.resolutionDays;
    }
    if (['Pending'].includes(custFeedbackForListPage.status)) {
      custFeedbackForListPage.daysPending = getWorkingDaysBetween(custFeedbackForListPage.datePending, this.currentDate);
    } else {
      custFeedbackForListPage.daysPending = custFeedbackForListPage.pendingDays;
    }
    if (['Complaint'].includes(custFeedbackForListPage.feedbackType) && (
        (['Open'].includes(custFeedbackForListPage.status) && (custFeedbackForListPage.daysActive >= 3)) ||
        (['Pending'].includes(custFeedbackForListPage.status) && (custFeedbackForListPage.daysPending >= 3))
    )) {
      custFeedbackForListPage.rowClass = 'overdue';
    } else {
      custFeedbackForListPage.rowClass = '';
    }
    return custFeedbackForListPage;
  }

  processUpdateFromSocketIo: (updatedCustFeedback: CustomerFeedback) => void = (updatedCustFeedback: CustomerFeedback) => {
    this.updateReceived = true;
    this.first = this.custFeedbackTable.first;
    this.feedbackRecords = [...[this.getCustFeedbackForListPage(updatedCustFeedback)], ...this.feedbackRecords.filter((feedback: CustFeedbackForListPage) => 
      feedback._id !== updatedCustFeedback._id
    )];
  }

  processFeedbackDeletion: (orderId: string, feedbackId: string) => void = (_orderId: string, feedbackId: string) => {
    this.updateReceived = true;
    this.first = this.custFeedbackTable.first;
    this.feedbackRecords = this.feedbackRecords.filter((feedback: CustFeedbackForListPage) => 
      feedback._id !== feedbackId
    );
  }

  ngOnDestroy(): void {
    this.customerFeedbackSocket.removeAllListeners();
    this.locksSocket.removeAllListeners();
  }

  isOrderLocked(_id: string|OrderIdPlanOnly): boolean {
    const orderId: string = (typeof _id == 'string')? _id: _id._id;
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == orderId).length > 0;
  }

  orderLockedBy(_id: string|OrderIdPlanOnly): string {
    const orderId: string = (typeof _id == 'string')? _id: _id._id;
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == orderId)[0].user;
  }

  isCustFeedbackLocked(custFeedbackId: string) {
    return this.custFeedbackLockList.filter((lockedCustFeedback: CustFeedbackLockData) => lockedCustFeedback.id == custFeedbackId).length > 0;
  }

  custFeedbackLockedBy(custFeedbackId: string) {
    return this.custFeedbackLockList.filter((lockedCustFeedback: CustFeedbackLockData) => lockedCustFeedback.id == custFeedbackId)[0].user;
  }

  showInfoPopUp(header: string, message: string): void {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  addFeedback(): void {
    this.showAddFeedbackDialog = true;
  }

  closeAddFeedback(_addNoteParams: AddNoteParams): void {
    // Adds from here don't relate to an order, so we don't need to do anything with the note
    this.showAddFeedbackDialog = false;
  }

  editFeedback(feedbackId: string): void {
    this.feedbackIdToEdit = feedbackId;
  }

  closeEditFeedback(_complaintClosed: boolean): void {
    // Feedback related to orders cannot be updated, so nothing to do here
    this.feedbackIdToEdit = '';
  }

  deleteFeedback(feedbackId: string): void {
    this.customerFeedbackService
      .deleteCustomerFeedback(feedbackId)
      .subscribe((response: SimpleResponse) => {
        if (!response.success) {
          this.showErrorPopUp('Error Deleting', 
            `There was an error deleting the feedback. Error: ${response.message}.`
          );
        }
      });
  }

  exportCustomerFeedback(exportOption: ExportOptions): void {
    let recordsToExport: CustFeedbackForListPage[];
    const startOfMonth: moment.Moment = moment.tz('Europe/London').startOf('month');
    const startOfPriorMonth: moment.Moment = moment.tz('Europe/London').startOf('month').subtract(1, 'month');
    if (ExportOptions.ALL == exportOption) {
      recordsToExport = this.feedbackRecords;
    } else if (ExportOptions.FILTERED == exportOption) {
      recordsToExport = this.custFeedbackTable.filteredValue? this.custFeedbackTable.filteredValue: this.feedbackRecords;
    } else {
      recordsToExport = this.feedbackRecords.filter((feedback: CustFeedbackForListPage) => {
        switch (exportOption) {
          case ExportOptions.LAST_MONTH:
            return startOfMonth.isAfter(feedback.dateLogged) && startOfPriorMonth.isSameOrBefore(feedback.dateLogged);
          case ExportOptions.THIS_MONTH:
            return startOfMonth.isSameOrBefore(feedback.dateLogged);
          case ExportOptions.THIS_MONTH_AND_LAST_MONTH:
            return startOfPriorMonth.isSameOrBefore(feedback.dateLogged);
          default:
            return false;
        }
      });
    }
    const formattedForExport: any[] = recordsToExport.map((custFeedback: CustFeedbackForListPage) => {
      return {
        'Brand': custFeedback.websiteId.title,
        'Td Code': custFeedback.tdCode,
        'Partnership': custFeedback.partnership,
        'Feedback Type': custFeedback.feedbackType,
        'Priority': custFeedback.priority,
        'Status': custFeedback.status,
        'Main Reason': custFeedback.mainReason,
        'Specifically': custFeedback.specificReason,
        'Details': custFeedback.feedbackDetails,
        'CC\'ed To': custFeedback.ccTo,
        'Resolution': custFeedback.resolution,
        'Respond To Customer By Email': custFeedback.respondByEmail? 'Yes': 'No',
        'Free Service Given': custFeedback.freeServiceGiven? 'Yes': 'No',
        'Date Payment Restarts': custFeedback.datePaymentRestarts,
        'Logged By': custFeedback.loggedBy,
        'Logged At': custFeedback.dateLogged? new Date(custFeedback.dateLogged): '',
        'Set to Pending At': custFeedback.datePending? new Date(custFeedback.datePending): '',
        'Closed By': custFeedback.closedBy,
        'Closed At': custFeedback.dateClosed? new Date(custFeedback.dateClosed): '',
        'Days Open': custFeedback.daysActive,
        'Days Pending': custFeedback.daysPending,
        'Contact Attempts': !custFeedback.contactAttempts? '': custFeedback.contactAttempts.map((contactAttempt: FeebackContactAttempt) => 
          `Contacted By ${contactAttempt.byUsername} on ${contactAttempt.dateOfAttempt}`
        ).join('\n'),
        'Who Contacted Us': custFeedback.whoContactedUs,
        'Relationship': custFeedback.relationshipToUser,
        'email': custFeedback.emailAddress,
        'Phone No.': custFeedback.phoneNumber,
        'Contact Method': custFeedback.contactMethod,
        'Plan': custFeedback.orderId? (custFeedback.orderId as OrderIdPlanOnly).accountDetails.plan: '',
      }
    });
    this.excelService.exportAsExcelFile({'Customer Feedback': formattedForExport}, `${exportOption}_Customer_feedback`);
  }

  onFilter($event: any) {
    // If the re-run of the filter is triggered by an update want to go back to the page we were on
    if (this.updateReceived) {
      this.custFeedbackTable.first = Math.min(this.first, $event.filteredValue.length);
      // reset the flag
      this.updateReceived = false;
    }
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.custFeedbackTable.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  clearDateLoggedFilters() {
    delete this.custFeedbackTable.filters['dateLogged'];
    let filters: any = { ...this.custFeedbackTable.filters };
    this.custFeedbackTable.reset();
    for (let key in filters) {
      this.custFeedbackTable.filter(filters[key].value,key,filters[key].matchMode)
    }
  }

  clearDateClosedFilters() {
    delete this.custFeedbackTable.filters['dateClosed'];
    let filters: any = { ...this.custFeedbackTable.filters };
    this.custFeedbackTable.reset();
    for (let key in filters) {
      this.custFeedbackTable.filter(filters[key].value,key,filters[key].matchMode)
    }
  }

}
