import {CancellationLink} from './../../models/cancellation-links.model';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SelectItem, MessageService} from 'primeng/api';
import {Component, OnInit} from '@angular/core';
import {getBrandSelectItems, BrandCfg, getBrandConfigs} from '../../lookups/brands';
import {CancellationLinkService} from './cancellation-link.service';
import {Title} from '@angular/platform-browser';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
@Component({
  selector: 'app-cancellation-link',
  templateUrl: './cancellation-link.component.html',
  styleUrls: ['./cancellation-link.component.scss'],
  providers: [MessageService],
})
export class CancellationLinkComponent implements OnInit {

  constructor(
    private cancellationLinkService: CancellationLinkService,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    private title: Title,
  ) { }
  
  cols: { field: string, header: string }[] = [];
  cancellationLinks = [];
  brands: SelectItem<string>[];
  selectedBrand;
  brandConfigs: BrandCfg;
  numberOnly = numberOnly;
  loading: boolean = false;
  canAddCancellationLink: boolean = false;
  canUpdateCancellationLink: boolean = true;
  canDeleteCancellationLink: boolean = true;
  addCancellationLinkForm: UntypedFormGroup = this.fb.group({
    derivedPlan: [''],
    link: [''],
    unitCost: [''],
    vatCost: [''],
    equipCost: [''],
    equipName: ['']
  });

  ngOnInit(): void {
    this.initializeVariables();
    this.title.setTitle('CRM Cancellation Links');
  }

  loadCancellationLinks() {
    this.canAddCancellationLink = false;
    this.loading = true;
    this.cancellationLinkService.getCancellationLinks(this.selectedBrand).subscribe(response => {
      this.loading = false;
      if (response.success) {
        this.cancellationLinks = response.data;
        this.canAddCancellationLink = true;
      } else {
        this.showError(
          'Something went wrong, try again'
        );
      }
    }, (err: Error) => {
      this.loading = false
      this.showError(
        err.message
      );
    })
  }

  addCancellationLink(): void {
    this.canAddCancellationLink = false;
    if (this.selectedBrand && !this.brandConfigs[this.selectedBrand]) {
      return;
    }
    const params: CancellationLink = {
      ...this.addCancellationLinkForm.value,
      brand:this.selectedBrand
    };
    this.cancellationLinkService.addCancellationLink(params).subscribe(response => {
      this.canAddCancellationLink = true;
      if (response.success) {
        this.addCancellationLinkForm.reset();
        this.loadCancellationLinks();
        this.showSuccess(
          'Record has added successfully'
        );
      } else {
        this.showError('Something went wrong, Try again');
      }
    }, (err: Error) => {
      console.log("Error on adding cancellation link :: ", err);
      this.showError(err.message);
    })
  }

  updateCancellationLink(data: CancellationLink) {
    this.canUpdateCancellationLink = false;
    console.log("Row data :: ", data);
    const params: any = {
      ...data
    };
    delete params['__v'];
    this.cancellationLinkService.updateCancellationLink(params).subscribe((response: SimpleResponse) => {
      this.canUpdateCancellationLink = true;
      if (response.success) {
        this.loadCancellationLinks();
        this.showSuccess('Record successfuly updated');
      } else
        this.showError('Something went wrong, Try again');
    }, (err: Error) => {
      this.canUpdateCancellationLink = true;
      this.showError(err.message);
    })
  }

  deleteCancellationLink(linkId: string) {
    this.canDeleteCancellationLink = false;
    this.cancellationLinkService.deleteCancellationLink(linkId).subscribe(response => {
      this.canDeleteCancellationLink = true;
      if (response.success) {
        this.loadCancellationLinks();
        this.showSuccess('Record successfully deleted');
      } else {
        this.showError('Something went wrong, Try again');
      }
    }, (err: Error) => {
      this.canDeleteCancellationLink = true;
      this.showError(err.message);
    })
  }

  initializeVariables() {
    this.cols = [
      { field: 'brand', header: 'Brand' },
      { field: 'derivedPlan', header: 'Derived Plan' },
      { field: 'link', header: 'Link' },
      { field: 'unitCost', header: 'Unit Cost' },
      { field: 'vatCost', header: 'Vat Cost' },
      { field: 'equipCost', header: 'Equip Cost' },
      { field: 'equipName', header: 'Equip Name' },
      { field: 'actions', header: 'Actions' },
    ];
    this.brands = getBrandSelectItems();
    this.brandConfigs = getBrandConfigs();
  }

  showSuccess(message:string) {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success',
      detail: message
    })
  }

  showError(error: string) {
    this.messageService.add({
      severity: 'error',
      life: 300000,
      summary: 'Error',
      detail: error
    });
  }
}
