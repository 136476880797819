<p-blockUI *ngIf="documentBlocked" [blocked]="documentBlocked">
  <div
    style="width: 100%; height: 200px;background-color: #ff3f4e; position: absolute;top:0; bottom: 0; left: 0; right: 0; margin: auto;">
    <div class="row">
      <div class="col text-center">
        <h1 style="color: #fff; padding: 20px;">
          This lead is currently being reviewed by {{leadEditingBy.user}}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <i class="fa fa-lock fa-3x" (click)="unlock()">
          Unlock
        </i>
        &nbsp;
        <i class="fa fa-ban fa-3x" (click)="closeDialog()">
          Cancel
        </i>
      </div>
    </div>
  </div>
</p-blockUI>
<div *ngIf="!documentBlocked" class="update-lead">
  <p-dialog header="Lead Detail" (onHide)="closeDialog()" [(visible)]="displayModal" [modal]="true"
      [style]="{width: '90vw'}" [draggable]="false" [resizable]="false" [maximizable]="true">
    <div *ngIf="loading" class="loading-indicator d-flex align-items-center justify-content-center" style="height: 200px;">
      <p-progressSpinner *ngIf="loading"></p-progressSpinner>
    </div>
    <div *ngIf="!loading">
      <form [formGroup]="leadForm" #form="ngForm">
        <div class="row">
          <div class="col-12 col-lg-12">
            <div class="formsect pt-1 mt-0">
              <div>
                <div class="form-row">
                  <div class="form-group col-4 col-lg-4">
                    <label>Lead Type</label>
                    <p-dropdown [options]="leadTypes" name="leadType" formControlName="leadType"
                      [showClear]="true" placeholder="Select a Lead Type" [required]="false"
                      (onChange)="changeLeadType()">
                      <ng-template pTemplate="selectedItem">
                        <div *ngIf="selectedLeadType.value">
                          <div>{{selectedLeadType.value}}</div>
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <div class="col-2 col-lg-2 form-group">
                    <label>Who Referred?</label>
                    <p-dropdown [options]="whoReferredOptions" placeholder="Who referred?" [showClear]="true" [filter]="true"
                      [panelStyle]="{minWidth:'12em'}" scrollHeigh="600px" selectedItemsLabel="{0} selected"
                      [style]="{'display':'flex'}" formControlName="whoReferred" [required]="true">
                    </p-dropdown>
                  </div>
                  <div class="form-group col-2 col-lg-2">
                    <label>Brand</label>
                    <p-dropdown [options]="brands" name="brand" formControlName="brand"
                      [filter]="true" filterBy="label" [showClear]="true"
                      placeholder="Select a Brand">
                      <ng-template pTemplate="selectedItem">
                        <div *ngIf="selectedBrand.value">
                          <div>{{selectedBrand.value}}</div>
                        </div>
                      </ng-template>
                      <ng-template let-brand pTemplate="item">
                        <span class="brandBar"
                          [ngStyle]="{'background-color': brandConfigs[brand.value].background, 'color':brandConfigs[brand.value].color}">
                          &nbsp;{{brand.value}}
                        </span>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <div class="form-group col-4 col-lg-4">
                    <label>Referral Date</label>
                    <input type="date" name="referralDate" class="form-control"formControlName="referralDate">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="formsect">
              <div class="formbar row no-gutters">
                <div class="col-6">
                  User Details
                </div>
              </div>
              <div formGroupName="alarmUserDetails">
                <div class="form-row">
                  <div class="col-6 col-lg-6 form-group">
                    <label>First Name</label>
                    <input type="text" name="firstName" class="form-control" formControlName="firstName" placeholder="Enter First Name">
                  </div>
                  <div class="form-group col-6 col-lg-6">
                    <label>Last Name</label>
                    <input type="text" name="lastName" class="form-control" formControlName="lastName" placeholder="Enter Last Name">
                  </div>
                  <div class="form-group col-6 col-lg-6">
                    <label>Preferred Name</label>
                    <input type="text" name="preferredName" class="form-control" formControlName="preferredName" placeholder="Enter Preferred Name">
                  </div>
                  <div class="form-group col-6 col-lg-6">
                    <label>Email</label>
                    <input type="email" name="email" class="form-control" formControlName="email" placeholder="Enter Email">
                  </div>
                  <div class="form-group col-6 col-lg-6">
                    <label>Email Marketing</label>
                    <p-dropdown [options]="marketingOptInSelects" formControlName="emailMarketing" class="noAutoSave">
                    </p-dropdown>
                  </div>
                </div>
                <div class="form-row form-group">
                  <div class="form-group col-6 col-lg-6">
                    <label>Telephone</label>
                    <input type="text" name="telephone" class="form-control"
                      formControlName="telephone" placeholder="Enter Telephone"
                      (keypress)="numberOnly($event, false)">
                  </div>
                  <div class="form-group col-6 col-lg-6">
                    <label>Mobile</label>
                    <input type="text" name="mobile" class="form-control" formControlName="mobile"
                      placeholder="Enter Mobile" (keypress)="numberOnly($event, false)">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="formsect">
              <div class="formbar row no-gutters">
                <div class="col-6">
                  Referrer Details
                </div>
              </div>
              <div formGroupName="referrerDetails">
                <div class="form-row">
                  <div class="form-group col-6 col-lg-6">
                    <label>First Name</label>
                    <input type="text" name="firstName" class="form-control" formControlName="firstName" placeholder="Enter First Name">
                  </div>
                  <div class="form-group col-6 col-lg-6">
                    <label>Last Name</label>
                    <input type="text" name="lastName" class="form-control" formControlName="lastName" placeholder="Enter Last Name">
                  </div>
                  <div class="form-group col-6 col-lg-6">
                    <label>Email</label>
                    <input type="text" name="email" class="form-control" formControlName="email" placeholder="Enter Email">
                  </div>
                  <div class="form-group col-6 col-lg-6" >
                    <label>Email Marketing</label>
                    <p-dropdown [options]="marketingOptInSelects" formControlName="emailMarketing" class="noAutoSave">
                    </p-dropdown>
                  </div>
                  <div class="form-group col-6 col-lg-6">
                    <label>Relationship</label>
                    <input type="text" name="relationship" class="form-control" formControlName="relationship" placeholder="Enter Relationship">
                  </div>
                </div>
                <div class="form-row form-group">
                  <div class="form-group col-6 col-lg-6">
                    <label>Telephone</label>
                    <input type="text" name="telephone" class="form-control"
                      formControlName="telephone" placeholder="Enter Telephone"
                      (keypress)="numberOnly($event, false)">
                  </div>
                  <div class="form-group col-6 col-lg-6">
                    <label>Mobile</label>
                    <input type="text" name="mobile" class="form-control" formControlName="mobile"
                      placeholder="Enter Mobile" (keypress)="numberOnly($event, false)">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12 col-lg-12">
                    <label>Referred By</label>
                    <input type="text" name="referredBy" class="form-control" formControlName="referredBy" placeholder="Enter reference">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4" *ngIf="isProfessionalReferral">
            <div class="formsect">
              <div class="formbar row no-gutters">
                <div class="col-6">
                  Sales Contacts Details
                </div>
              </div>
              <div formGroupName="salesContactDetails">
                <div class="form-row">
                  <div class="form-group col-6 col-lg-6">
                    <label>First Name</label>
                    <input type="text" name="firstName" class="form-control" formControlName="firstName" placeholder="Enter First Name">
                  </div>
                  <div class="form-group col-6 col-lg-6">
                    <label>Last Name</label>
                    <input type="text" name="lastName" class="form-control" formControlName="lastName" placeholder="Enter Last Name">
                  </div>
                  <div class="form-group col-6 col-lg-6">
                    <label>Email</label>
                    <input type="text" name="email" class="form-control" formControlName="email" placeholder="Enter Email">
                  </div>
                  <div class="form-group col-6 col-lg-6">
                    <label>Relationship</label>
                    <input type="text" name="relationship" class="form-control" formControlName="relationship" placeholder="Enter Relationship">
                  </div>
                </div>
                <div class="form-row form-group">
                  <div class="form-group col-6 col-lg-6">
                    <label>Telephone</label>
                    <input type="text" name="telephone" class="form-control"
                      formControlName="telephone" placeholder="Enter Telephone"
                      (keypress)="numberOnly($event, false)">
                  </div>
                  <div class="div form-group col-6 col-lg-6">
                    <label>Mobile</label>
                    <input type="text" name="mobile" class="form-control" formControlName="mobile"
                      placeholder="Enter Mobile" (keypress)="numberOnly($event, false)">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="formsect">
              <div class="formbar row no-gutters">
                <div class="col-6">
                  Additional Details
                </div>
              </div>
              <div>
                <div class="form-row">
                  <div class="col-12 form-group">
                    <legend class="col-form-label pt-0">Customer Asked For Info Pack</legend>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="askedForInfoPack"
                        id="askedInfoPackYes" value="Yes" formControlName="askedForInfoPack">
                      <label class="form-check-label" for="askedInfoPackYes">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="askedForInfoPack"
                        id="askedInfoPackNo" value="No" formControlName="askedForInfoPack">
                      <label class="form-check-label" for="askedInfoPackNo">
                        No
                      </label>
                    </div>
                    <div *ngIf="false" class="form-check form-check-inline">
                      <button type="button" title="Create post sheet" class="btn btn-primary" (click)="showAddPost()">
                        Create post sheet
                      </button>
                    </div>
                  </div>
                  <div class="col-12 form-group">
                    <label for="status">Status</label>
                    <p-dropdown [options]="statusSelects" formControlName="status" (onChange)="statusChange()" [required]="true">
                    </p-dropdown>
                  </div>
                  <div class="col-8 form-group">
                    <ng-container *ngIf="['Pending Payment'].includes(status.value) || leadForm.value.paymentRef">
                      <label for='paymentRef'>Payment Ref</label>
                      <input formControlName='paymentRef' class="form-control" readonly />
                    </ng-container>
                  </div>
                  <div class="col-4 form-group">
                    <ng-container *ngIf="['Pending Payment'].includes(status.value) && !leadForm.value.paymentRef">
                      <label for='paymentRefBtn'></label>
                      <button type="button" title="Get Payment Ref" id='paymentRefBtn' class="btn btn-primary" (click)="getPaymentRef()">
                        Get Ref
                      </button>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="['Active', 'Pending Payment', 'Considering', 'Unable to Contact'].includes(status.value)">
                    <div class="col-6 col-lg-6 form-group">
                      <label>Call Shift</label>
                      <p-dropdown [options]="callDateShifts" name="callDateShift"
                        formControlName="callDateShift" placeholder="Select Call Date Shift"
                        [showClear]="true" (onChange)="shiftChange($event)" appendTo="body">
                      </p-dropdown>
                    </div>
                    <div class="col-6 col-lg-6 form-group ">
                      <label>Call Date</label>
                      <input [type]="callDateType" name="callDate" class="form-control"
                        formControlName="callDate" onkeydown="return false"
                        min="{{todayDate | date:callDateType === 'datetime-local'?'yyyy-MM-ddTHH:mm':'yyyy-MM-dd'}}">
                    </div>
                  </ng-container>
                  <ng-container *ngIf="['Declined'].includes(status.value)">
                    <div class="col-6 col-lg-6 form-group">
                      <label>Why do they not want a call back?</label>
                      <p-dropdown [options]="leadDeclinedOptions" name="leadDeclinedReason"
                        formControlName="leadDeclinedReason" placeholder="Select a reason"
                        [showClear]="true" [required]="false" appendTo="body">
                      </p-dropdown>
                    </div>
                    <div *ngIf="leadDeclinedReason.value === 'Other'" class="col-6 col-lg-6 form-group">
                      <label>Other Reason</label>
                      <input type="text" name="leadDeclinedReason2" class="form-control"
                        formControlName="leadDeclinedReason2" placeholder="Enter why lead is not proceeding">
                    </div>
                  </ng-container>
                </div>
              </div>
              <br>
              <legend class="col-form-label pt-0">Additional Notes</legend>
              <div class="formbox" formArrayName="comments">
                <div class="form-row note-list" id="noteList">
                  <li class="note-l" *ngFor="let note of commentForms.controls; let i=index" [formGroupName]="i">
                    <div class="note-m">
                      Added By {{note.value.createdBy}}
                    </div>
                    <div class="note-m">
                      {{note.value.createdAt | date:'EEEE, dd/MM/y - HH:mm'}}
                    </div>
                    <label>Additional Notes</label>
                    <textarea class="noteContent" [rows]="4" [cols]="30" pInputTextareaname="comments" formControlName="comments" readonly>
                  </textarea>
                  </li>
                </div>
                <div class="form-row mt-2" [formGroup]="addCommentForm">
                  <div class="form-group col-12 col-lg-12">
                    <textarea class="form-control" formControlName="comment" name="comment" [rows]="4" [cols]="15" pInputTextarea>
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <div class="formsect">
              <div class="formbar row no-gutters">
                <div class="col-6 col-lg-6">
                  Call Log
                </div>
              </div>
              <div class="formbox" formArrayName="callNotes">
                <div class="form-row note-list" id="callLogList">
                  <li class="note-l" *ngFor="let note of callNotesForms.controls;let i=index" [formGroupName]="i">
                    <div class="note-m">
                      Added By {{note.value.createdBy}}
                    </div>
                    <div class="note-m">
                      {{note.value.createdAt | date:'EEEE, dd/MM/y - HH:mm'}}
                    </div>
                    <textarea class="noteContent" [rows]="4" [cols]="30"
                      pInputTextareaname="content" formControlName="content" readonly>
                    </textarea>
                  </li>
                </div>
                <div class="form-row mt-2" [formGroup]="addCallNoteForm">
                  <div class="form-group col-12 col-lg-12">
                    <label>Call log</label>
                    <textarea class="form-control" formControlName="content" name="content" [rows]="4" [cols]="30" pInputTextarea>
                    </textarea>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-primary mr-2" (click)="showContactAttempt()" class="btn">
                Contact Attempt
              </button>
              <p-dialog (onHide)="displayContactAttempt = false" header="Contact Attempt"
                [(visible)]="displayContactAttempt" [modal]="true"
                [style]="{width: '75vw',height:'auto'}" [maximizable]="true" [baseZIndex]="3100">
                <div id="contactDialogBody">
                  <div *ngIf="!isDateChange">
                    <form [formGroup]="contactAttemptForm" (ngSubmit)="submitData()">
                      <div class="row">
                        <div class="col-md-6 form-group">
                          <p-dropdown (onChange)="contactOptionChange()" [options]="contactOptions"
                            formControlName="selectedContactOption" placeholder="Choose Contact Option" [showClear]="true"
                            name="selectedContactOption" [required]=true>
                          </p-dropdown>
                          <div *ngIf="selectedContactOption.touched && selectedContactOption.invalid" class="alert alert-danger">
                            The contact attempt outcome is required
                          </div>
                          <div *ngIf="(selectedContactOption.value === 'Successful Contact - Order placed')">
                          </div>
                        </div>
                        <div class="col-md-6 form-group" *ngIf="selectedContactOption.value">
                          <p-dropdown [options]="phoneNumbersForSelect"
                            formControlName="selectedNumber"
                            [placeholder]="placeholderForContactAttemptOption"
                            [showClear]="true" name="selectedNumber" [required]=true
                            [autoDisplayFirst]=false (onChange)="selectedNumberChange()">
                          </p-dropdown>
                          <div *ngIf="selectedNumber.touched && selectedNumber.invalid" class="alert alert-danger">
                            The phone number used must be selected
                          </div>
                        </div>
                        <div class="col-md-2 form-group"
                          *ngIf="contactAttemptForm!.value.selectedContactOption && contactAttemptForm.value.selectedContactOption !== 'Unsuccessful Contact Attempt'">
                        <label>&nbsp;</label>
                          <div>
                            <p-checkbox name="isManualDateSelected" [binary]="true"
                              label="Manually change callback date?" formControlName="isManualDateSelected">
                            </p-checkbox>
                          </div>
                        </div>
                        <div *ngIf="isManualDateSelected.value" class="col-md-6 form-group">
                          <div class="col-6 col-lg-6 form-group">
                             <label>Call Date</label>
                            <input type="datetime-local" name="manualContactDate" class="form-control"
                              formControlName="manualContactDate" onkeydown="return false"
                              min="{{todayDate | date:'yyyy-MM-ddTHH:mm'}}">
                          </div>
                        </div>
                        <div *ngIf="contactAttemptForm.value.selectedContactOption === 'Declined - No Callback Required'" class="col-md-4 form-group">
                          <label>&nbsp;</label>
                          <p-dropdown [options]="leadDeclinedOptions" name="leadDeclinedReason"
                            formControlName="leadDeclinedReason" placeholder="Select a reason"
                            [showClear]="true" [required]="false" appendTo="body">
                          </p-dropdown>
                        </div>
                        <div *ngIf="contactAttemptForm.value.leadDeclinedReason === 'Other'" class="col-6 col-lg-6 form-group">
                        <label>Other Reason</label>
                        <input type="text" name="leadDeclinedReason2" class="form-control"
                          formControlName="leadDeclinedReason2" placeholder="Enter why lead is not proceeding">
                      </div>
                      </div>
                      <div class="row" *ngIf="selectedNumber.value">
                        <div class="col-md-12">
                          <h6>Additional Note</h6>
                          <textarea [rows]="3" [cols]="30" id="float-textarea"
                            class="form-control" name="additionaNote" pInputTextarea formControlName="additionalNote">
                          </textarea>
                        </div>
                        <div class="col-12">
                          <label>Note Template</label>
                          <p-dropdown [options]="noteTemplates" name="noteTemplates"
                            [showClear]="true" placeholder="Select a template"
                            id="noteTemplates" (onChange)="changeNoteTemplate($event)"
                            [filter]=true [resetFilterOnHide]="true"
                            [optionLabel]="'label'" [overlayOptions]="overlayOptions">
                          </p-dropdown>
                        </div>
                      </div>
                      <div class="row" *ngIf="selectedNumber.value && selectedContactOption.value === 'Unsuccessful Contact Attempt'">
                        <div class="col-md-6">
                          <p-checkbox name="group1" [binary]="true" label="Message left for customer?" formControlName="isMessageLeftOnUnsuccessfulAttempt">
                          </p-checkbox>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 form-group">
                          <button type="submit" class="btn btn-primary" [disabled]="!contactAttemptForm.valid || isSubmitButtonDisabled">
                            Submit
                          </button>
                          <button type="button" class="btn btn-warning ml-2" (click)="displayContactAttempt = false">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </p-dialog>
            </div>
          </div>
        </div>
      </form>
    </div>
    <ng-template pTemplate="footer">
      <button type="button" class="btn btn-warning" (click)="closeDialog()">Cancel</button>
      <button *ngIf="!loading" type="button" class="btn btn-primary mr-2" (click)="submit()" [disabled]="isFormInvalid">
        Submit
      </button>
      <button *ngIf="!loading && !['Already Ordered', 'Ordered Over Phone'].includes(leadDetail.status)"
          type="button" class="btn btn-primary mr-2" (click)="createOrder()" [disabled]="isFormInvalid">
        Create Order
      </button>
    </ng-template>
  </p-dialog>
</div>
<app-add-post-sheet *ngIf="showPostSheetAdd" openMode="addFromLead" [lead]="leadDetail" (closeAddPostSheet)="closeAddPostSheet($event)">
</app-add-post-sheet>
<p-confirmDialog [baseZIndex]="2000"></p-confirmDialog>
<router-outlet (window:unload)="close()"></router-outlet>
