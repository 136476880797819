<p-dialog (onHide)="closeDialog()" header="Third Party Data" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '95vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100">
  <div class="paymentDetails container-fluid">
    <div class='row'>
      <div class='col-12'>
        <h4>Order Details</h4>
        <div>
          If the order has renewal type Direct Debit, Stripe or Go Cardless the relevant section will have a green background.
          Any references, or payment amounts that match the order will be highlighted green within the tables.
          As the CRM does not hold payment due date when the renewal type is one of these payment methods the payment due date
          cannot be compared.
        </div>
      </div>
      <div class='col-3'>
        <label>Renewal Price</label>
        {{formattedRenewalPrice}}
      </div>
      <div class='col-3'>
        <label>Renewal Type</label>
        {{order.renewalInformation.renewalType}}
      </div>
      <div class='col-2'>
        <label>Payment Due Date</label>
        {{order.renewalInformation.paymentDueDate | date: 'dd/MM/yyyy'}}
      </div>
      <div class='col-2'>
        <label>Order Date</label>
        {{order.created | date: 'dd/MM/yyyy'}}
      </div>
      <div class='col-2'>
        <label>Free Months</label>
        {{order.renewalInformation.freemonths}}
      </div>
    </div>
    <div class='row py-2' [ngClass]="(order.renewalInformation.renewalType === 'directDebit')? 'matched': ''">
      <div class='col-12'>
        <h4>Smart Debit</h4>
        <p-table #smartDebit [value]="smartDebitPayers" [columns]="smartDebitCols" styleClass='smartDebit'>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                <div>
                  {{col.header}}
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns" [ngSwitch]="col.field">
                <div *ngSwitchCase="'paymentDueDate'">
                  {{rowData.paymentDueDate | date:'dd/MM/yyyy'}}
                </div>
                <div *ngSwitchCase="'lastUpdated'">
                  {{rowData.lastUpdated | date:'dd/MM/yyyy HH:mm'}}
                </div>
                <div *ngSwitchCase="'referenceNumber'" class="d-flex justify-content-between" [ngClass]="(rowData.referenceNumber === order.referencenumber)? 'matched': ''">
                  {{rowData.referenceNumber}}
                  <a href="https://{{environment.production? 'online': 'sandbox'}}.smartdebit.co.uk/Payers/Details?Pslid={{rowData.accountId}}&ReferenceNumber={{rowData.referenceNumber}}"
                      target="_blank" title="Open Payer on SmartDebit">
                    <i class="pi pi-external-link"></i>
                  </a>
                </div>
                <div *ngSwitchCase="'customerId'" [ngClass]="(rowData.customerId === order.alarmUserDetails.tdCode)? 'matched': ''">
                  {{rowData.customerId}}
                </div>
                <div *ngSwitchCase="'renewalPrice'" [ngClass]="(rowData.renewalPrice === formattedRenewalPrice)? 'matched': ''">
                  {{rowData.renewalPrice}}
                </div>
                <div *ngSwitchDefault>
                  {{rowData[col.field]}}
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div class='row py-2' [ngClass]="(order.renewalInformation.renewalType === 'recurringBilling')? 'matched': ''">
      <div class='col-12'>
        <h4>Stripe</h4>
        <p-table #stripe [value]="stripeSubs" [columns]="stripeCols" styleClass='stripe'>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                <div>
                  {{col.header}}
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns" [ngSwitch]="col.field">
                <div *ngSwitchCase="'paymentDueDate'">
                  {{rowData.paymentDueDate | date:'dd/MM/yyyy'}}
                </div>
                <div *ngSwitchCase="'lastUpdated'">
                  {{rowData.lastUpdated | date:'dd/MM/yyyy HH:mm'}}
                </div>
                <div *ngSwitchCase="'subscriptionId'" class="d-flex justify-content-between" [ngClass]="(rowData.subscriptionId === order.accountDetails.stripeSubscriptionId)? 'matched': ''">
                  {{rowData.subscriptionId}}
                  <a href="https://dashboard.stripe.com{{environment.production? '': '/test'}}/subscriptions/{{rowData.subscriptionId}}"
                      target="_blank"  title="Open Subscription on Stripe">
                    <i class="pi pi-external-link"></i>
                  </a>
                </div>
                <div *ngSwitchCase="'customerId'" class="d-flex justify-content-between" [ngClass]="(rowData.customerId === order.accountDetails.stripeCustomerId)? 'matched': ''">
                  {{rowData.customerId}}
                  <a href="https://dashboard.stripe.com{{environment.production? '': '/test'}}/customers/{{rowData.customerId}}"
                      target="_blank" title="Open Customer on Stripe">
                    <i class="pi pi-external-link"></i>
                  </a>
                </div>
                <div *ngSwitchCase="'renewalPrice'" [ngClass]="(rowData.renewalPrice === formattedRenewalPrice)? 'matched': ''">
                  {{rowData.renewalPrice}}
                </div>
                <div *ngSwitchCase="'brandId'">
                  {{getStripeBrand(rowData.brandId)}}
                </div>
                <div *ngSwitchDefault>
                  {{rowData[col.field]}}
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div class='row py-2' [ngClass]="(order.renewalInformation.renewalType === 'goCardless')? 'matched': ''">
      <div class='col-12'>
        <h4>Go Cardless</h4>
        <p-table #goCardless [value]="goCardlessSubs" [columns]="goCardlessCols" styleClass='goCardless'>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                <div>
                  {{col.header}}
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns" [ngSwitch]="col.field">
                <div *ngSwitchCase="'paymentDueDate'">
                  {{rowData.paymentDueDate | date:'dd/MM/yyyy'}}
                </div>
                <div *ngSwitchCase="'lastUpdated'">
                  {{rowData.lastUpdated | date:'dd/MM/yyyy HH:mm'}}
                </div>
                <div *ngSwitchCase="'referenceNumber'" class="d-flex justify-content-between" [ngClass]="(rowData.referenceNumber === order.goCardlessId)? 'matched': ''">
                  {{rowData.referenceNumber}}
                  <a href="https://{{environment.production? 'manage': 'manage-sandbox'}}.gocardless.com/customers/{{rowData.referenceNumber}}"
                      target="_blank" title="Open Customer on GoCardless">
                    <i class="pi pi-external-link"></i>
                  </a>
                </div>
                <div *ngSwitchCase="'subscriptionId'" class="d-flex justify-content-between" [ngClass]="(rowData.subscriptionId === order.goCardlessSubId)? 'matched': ''">
                  {{rowData.subscriptionId}}
                  <a href="https://{{environment.production? 'manage': 'manage-sandbox'}}.gocardless.com/subscriptions/{{rowData.subscriptionId}}"
                      target="_blank" title="Open Subscription on GoCardless">
                    <i class="pi pi-external-link"></i>
                  </a>
                </div>
                <div *ngSwitchCase="'customerId'" [ngClass]="(rowData.customerId === order.alarmUserDetails.tdCode)? 'matched': ''">
                  {{rowData.customerId}}
                </div>
                <div *ngSwitchCase="'renewalPrice'" [ngClass]="(rowData.renewalPrice === formattedRenewalPrice)? 'matched': ''">
                  {{rowData.renewalPrice}}
                </div>
                <div *ngSwitchDefault>
                  {{rowData[col.field]}}
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</p-dialog>