import {Socket, SocketIoConfig} from 'ngx-socket-io';

interface EventsMap {
  [event: string]: any;
}
type EventNames<Map extends EventsMap> = keyof Map & string;
type EventCallback<Map extends EventsMap,  Ev extends EventNames<Map>> = Map[Ev];
type EventParams<Map extends EventsMap, Ev extends EventNames<Map>> = Parameters<Map[Ev]>;

export class GenericSocketService<OnEvents extends EventsMap, EmitEvents extends EventsMap> extends Socket {
  constructor(config: SocketIoConfig) {
    super(config);
  }
  
  on<Ev extends EventNames<OnEvents>>(eventName: Ev, callback: EventCallback<OnEvents, Ev>): void {
    super.on(eventName, callback);
  }

  emit<Ev extends EventNames<EmitEvents>>(eventName: Ev, ...args: EventParams<EmitEvents, Ev>): boolean {
    return super.emit(eventName, ...args);
  }
}
