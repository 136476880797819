import moment from 'moment-timezone';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {ProductsService} from '../../setup/products/products.service';
import {CouponsService} from '../../setup/coupons/coupons.service';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {RawProduct} from '../../models/product.model';
import {Coupon} from '../../models/coupon.model';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {OrderVatStatus} from '../../lookups/cseOrder/orderVatStatus';
import {BasketItem} from '../../models/cseOrder/wooCommerce/basketItem.model';
import {VariationWithTitle} from '../../models/cseOrder/wooCommerce/variationWithTitle.model';
import {FullContactDetails} from '../../models/cseOrder/fullContactDetails.model';
import {loadStripe, PaymentMethodResult, Stripe, StripeCardElement, StripeCardElementChangeEvent, StripeCardElementOptions, StripeElements, StripeError} from '@stripe/stripe-js';
import ServerSideStripe from 'stripe';
import {GravityformsService} from '../gravityforms.service';
import {OrderService} from '../../post-order/order.service';
import {vatStatuses} from '../../lookups/vatStatuses';
import {getWooProcessingSteps} from '../../lookups/cseOrder/wooCommerce/processingSteps';
import {ProcessingSteps} from '../../models/cseOrder/processingSteps.model';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {BusinessGrowthCustomer} from '../../models/businessGrowthCustomer.model';
import {getHowHeardOptions} from '../../lookups/howHeard';
import {Address} from '../../models/address.model';
import getAddressClient, {FindFailed, FindSuccess, Result} from 'getaddress-api';
import {DropDownChangeEvent} from '../../models/primeng/dropdownChangeEvent.model';
import {environment} from '../../../environments/environment';
import {BaseOrderInformation} from '../../models/cseOrder/baseOrderInformation.model';
import {Hardware} from '../../models/hardware.model';
import {HardwareSet} from '../../models/hardwareSet.model';
import {HardwareService} from '../../setup/hardware.service';
import {getLookupFromGetAddressResult, validateAddress} from '../../helpers/getAddressHelper';
import {RawVariation, VariationAttribute} from '../../models/variation.model';
import {marketingOptInSelects} from '../../lookups/marketingOptIn';
import {Order} from '../../models/order.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {StringIndexedObject} from '../../models/utility/stringIndexedObject.model';
import {BANK_ACCOUNT_REGEX, EX_TO_INC_VAT_MULTIPLIER, floorToTwoDecimalPlaces, roundToTwoDecimalPlaces, SORT_REGEX, VAT_MULTIPLIER} from '../../helpers/helperFunctions';
import {ItemForCouponCalulations} from '../../models/cseOrder/wooCommerce/itemForCouponCalulations.model';
import {NotificationService} from '../../notifications/notification.service';
import {LeadsService} from '../../leads/leads.service';
import {getPriceForOrderDate} from '../../helpers/getPlanCodeAndPrice';
import {CalculationMethod, OrderType} from '../../lookups/cseOrder/cseOrderTypes';
import {SetupFeeRequired} from '../../lookups/cseOrder/setupFeeRequired';
import {SetupFeeConfig} from '../../models/cseOrder/setupFeeConfig.model';
import {WOO_SETUP_FEE_CONFIGS} from '../../lookups/cseOrder/wooCommerce/setupFeeConfigs';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {Router} from '@angular/router';
import {OrderResponse} from '../../models/responses/orderResponse.model';
import {isValidAnyCountryMobile, isValidAnyCountryPhoneNumber} from '../../validators/phone-number.validator';
import {getFormattedAddress} from '../../helpers/getUserAddress';
import {WooItem} from '../../models/cseOrder/wooCommerce/wooItem.model';
import {isValidEmailAddress} from '../../validators/email.validator';
import {CseOrder} from '../../models/reporting/cseOrder.model';
import {HardwareId} from '../../models/mongoose-populated-ids/hardwareId.model';
import {GravityFormResponse} from '../../models/responses/gravityFormResponse.model';
import {FindOrderResponse} from '../../models/responses/findOrderResponse.model';
import {WOO_PRODUCT_CATEGORY_OPTS} from '../../lookups/productCategories';
import {ProcessingError} from '../../lookups/cseOrder/processingError';
import {OrderDetails} from '../../models/cseOrder/wooCommerce/orderDetails.model';
import {Website} from '../../models/website.model';

@Component({
  selector: 'app-woocommerce-order[baseOrderInformation][partnerships][partnershipByVoucherCode][updateShowPlaceOrder]',
  standalone: false,
  templateUrl: './woocommerce-order.component.html',
  styleUrls: ['../cse-order.component.scss', './woocommerce-order.component.scss']
})
export class WoocommerceOrderComponent implements OnInit, AfterViewInit {
  @ViewChild('cardInfo') cardInfo: ElementRef;
  @Input() baseOrderInformation: BaseOrderInformation;
  @Input() partnerships: SelectItem<BusinessGrowthCustomer>[];
  @Input() partnershipByVoucherCode: {[code: string]: BusinessGrowthCustomer};
  @Output() updateShowPlaceOrder: EventEmitter<boolean> = new EventEmitter<boolean>();
  Object = Object;
  numberOnly = numberOnly;
  processingSteps: ProcessingSteps;
  readonly ORDER_CREATION_STEP: string = 'Creating Order on Alarm Website';
  readonly ORDER_STATUS_UPDATE_STEP: string = 'Update Status on Alarm Website';
  vatOptions: SelectItem<string>[];
  vatSelected: string;
  orderVatStatus: OrderVatStatus;
  OrderVatStatusEnum = OrderVatStatus;
  orderRenewalAfter: number;
  howHeardOptions: SelectItem<string>[];
  products: RawProduct[];
  productsFiltered: RawProduct[];
  variations: VariationWithTitle[];
  selectedProduct: RawProduct;
  selectedVariation: VariationWithTitle;
  hardware: Hardware[];
  hardwareSets: HardwareSet[];
  coupons: Coupon[];
  coupon: Coupon|undefined;
  selectedPartnership: BusinessGrowthCustomer;
  couponCode: string;
  couponError: string;
  calculationError: string;
  items: BasketItem[];
  woodata: any;
  vat: number;
  TTorder: number;
  hasRentalItems: boolean;
  totalBeforeDiscountAndOverride: number;
  couponDiscount: number;
  infoBilling: FullContactDetails;
  infoDeliver: FullContactDetails;
  orderDetails: OrderDetails;
  directDebit: any;
  differentDeliver: boolean;
  currencySymbol: string;
  currencyCode: string;
  totalOverridden: boolean;
  stripeCardError: string;
  processingError: ProcessingError;
  category: string;
  categories: SelectItem<string>[];
  notExemptCategories: SelectItem<string>[];
  exemptCategories: SelectItem<string>[];
  orderBeingCreated: boolean;
  showProgressBar: boolean;
  orderCreationSuccess: boolean;
  displayErrorDetails: boolean;
  validationErrors: string[];
  orderLink: string;
  crmOrderLink: string;
  websiteOrderId: number;
  paymentIntentRef: string;
  userName: string;
  currentStep: string;
  alarmUserAddressOption: string;
  alarmUserNameOption: string;
  alarmUserPhoneOption: string;
  alarmUserAddress: Address;
  getAddrClient: getAddressClient;
  billingSearchPostCode: string;
  deliverySearchPostCode: string;
  alarmUserSearchPostCode: string;
  billingAddressResults: SelectItem<Address>[];
  deliveryAddressResults: SelectItem<Address>[];
  alarmUserAddressResults: SelectItem<Address>[]; 
  allowBillingAddressManualEntry: boolean;
  allowDeliveryAddressManualEntry: boolean;
  allowAlarmUserAddressManualEntry: boolean;
  billingSearchError: string;
  deliverySearchError: string;
  alarmUserSearchError: string;
  url: string;
  emailSuffix: string;
  stripeBrand: string;
  stripe: Stripe;
  cardElement: StripeCardElement;
  cardHandler = this.onChange.bind(this);
  nameOnCard: string;
  emailMarketing: string;
  phoneMarketing: string;
  marketingOptInSelects: SelectItem<string>[];
  orderDateYMD: string;
  recentOrder: boolean;
  lifetimeLineNeedsOverride: boolean;
  referralTdFound: boolean;
  referralTDCode: string;
  referralTdSearched: boolean;

  constructor(
    private productsService: ProductsService,
    private couponsService: CouponsService,
    private hardwareService: HardwareService,
    private changeDetector: ChangeDetectorRef,
    private gravityformsService: GravityformsService,
    private orderService: OrderService,
    private confirmationService: ConfirmationService,
    private notificationService: NotificationService,
    private leadService: LeadsService,
    private router: Router,
  ) {

  }
  
  ngOnInit(): void {
    this.orderCreationSuccess = false;
    this.vatOptions = vatStatuses;
    this.marketingOptInSelects = marketingOptInSelects;
    this.orderVatStatus = OrderVatStatus.NOT_SET;
    this.orderRenewalAfter = 0;
    this.displayErrorDetails = false;
    this.validationErrors = [];
    this.orderLink = '';
    this.crmOrderLink = '';
    this.websiteOrderId = undefined;
    this.paymentIntentRef = '';
    this.userName = localStorage.getItem('userName');
    this.howHeardOptions = getHowHeardOptions();
    this.vatSelected = null;
    this.category = null;
    this.categories = [];
    this.selectedProduct = null;
    this.productsFiltered = [];
    this.selectedVariation = null;
    this.variations = [];

    this.items = [];
    this.TTorder = 0;
    this.totalBeforeDiscountAndOverride = 0;
    this.vat = 0;
    this.couponCode = '';
    this.couponError = '';
    this.couponDiscount = 0;
    this.calculationError = '';
    this.totalOverridden = false;
    this.selectedPartnership = undefined;
    this.emailMarketing = '';
    this.phoneMarketing = '';
    this.processingSteps = getWooProcessingSteps();
    this.notExemptCategories = WOO_PRODUCT_CATEGORY_OPTS;
    this.exemptCategories = this.notExemptCategories.filter((selectItem: SelectItem<string>) => 
      (selectItem.value != 'keySafe')
    );
    this.billingAddressResults = [];
    this.deliveryAddressResults = [];
    this.alarmUserAddressResults = [];
    this.allowBillingAddressManualEntry = false;
    this.allowDeliveryAddressManualEntry = false;
    this.allowAlarmUserAddressManualEntry = false;
    this.getAddrClient = new getAddressClient(environment.getAddressDomainToken);
    this.hardware = [];
    this.hardwareSets = [];
    this.referralTdFound = false;
    this.referralTDCode = '';
    this.referralTdSearched = false;
    this.productsService.getProductsForCsesPage(this.selectedWebsite._id).subscribe({
      next: (response: MultiRecordResponse<RawProduct>) => {
        // Filter out the replacement product as it should not be done this way anymore
        this.products = response.data.filter((product: RawProduct) => 
          !(product.crmTitle.toLowerCase().includes('replacement') && (!product.regularPrice || (product.regularPrice == 0)))
        );
      },
      error: (err: any) => {
        console.error('Error getting product data', err);
      }
    });
    this.couponsService.getCouponsByWebsite(this.selectedWebsite._id).subscribe({
      next: (couponResponse: MultiRecordResponse<Coupon>) => {
        this.coupons = couponResponse.data;
      },
      error: (err: any) => {
        console.error('Error getting coupon data', err);
      }
    });
    this.hardwareService.getHardwareForSite(this.selectedWebsite._id).subscribe({
      next: (response: MultiRecordResponse<Hardware>) => {
        if (response.success) {
          this.hardware = response.data;
        }
      },
      error: (err: any) => {
        console.error('Error getting hardware data', err);
      }
    });
    this.hardwareService.getHardwareSetsForSite(this.selectedWebsite._id).subscribe({
      next: (response: MultiRecordResponse<HardwareSet>) => {
        if (response.success) {
          this.hardwareSets = response.data;
        }
      },
      error: (err: any) => {
        console.error('Error getting hardware set data', err);
      }
    });
    this.differentDeliver = false;
    this.directDebit = {};
    this.coupon = undefined;
    this.woodata = {
      generatedEmail: false,
      status: "pending",
      paid: true,
      payment_method: "bacs",
      note: '',
      payment_method_title: "Direct Bank Transfer",
      set_paid: true,
      billing_address: {
        first_name: '',
        last_name: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        postcode: '',
        country:'GB',
        email: '',
        phone: ''
      },
      shipping_address: {
        first_name: '',
        last_name: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        postcode: '',
        country:'GB',
      },
      fee_lines: [
      ],
      line_items: [
      ],
      coupon_lines: [
      ],
      shipping_lines: [{
        "method_id": "free_shipping:8",
        "method_title": "Next Day Delivery (FREE)",
        "total": "0"
      }]
    };
    this.infoBilling = {
      'email': '',
      'firstName': '',
      'lastName': '',
      'mobile': '',
      'userAddress': {
        'addressOne': '',
        'addressTwo': '',
        'city': '',
        'county': '',
        'postcode': '',
        'validated': false,
      }
    };
    this.infoDeliver ={
      'email': '',
      'firstName': '',
      'lastName': '',
      'mobile': '',
      'userAddress': {
        'addressOne': '',
        'addressTwo': '',
        'city': '',
        'county': '',
        'postcode': '',
        'validated': false,
      }
    };
    this.alarmUserAddress = {
      'addressOne': '',
      'addressTwo': '',
      'city': '',
      'county': '',
      'postcode': '',
      'validated': false,
    };
    this.orderDetails = {
      'alarmUserFirstName': '',
      'alarmUserLastName': '',
      'alarmUserMobile': '',
      'alarmUserPhone': '',
      'notes': '',
      'renewalMethod': '',
      'paymentMethod': '',
      'NCFRequired': 'Yes',
    };
    this.products = [];
    if (this.selectedWebsite.currencyCode) {
      this.currencyCode = this.selectedWebsite.currencyCode;
    } else {
      this.currencyCode = 'GBP';
    }
    if (this.currencyCode == 'GBP') {
      this.currencySymbol = '\u00A3';
    } else if (this.currencyCode == 'EUR') {
      this.currencySymbol = '\u20AC';
    }
    this.stripeBrand = this.selectedWebsite.title;
    if (this.order && (this.order.legalCompany == 'Lifeline24 Ltd')) {
      this.stripeBrand = `${this.selectedWebsite.title}_OLD`
    }
    this.url = this.selectedWebsite.api.url;
    this.emailSuffix = this.url.replace('www.','').replace('https://','');
    this.initialiseFromNavigationData();
    this.initialiseFromExistingOrder();
    this.showPlaceOrder = true;
  }

  get selectedWebsite(): Website {
    return (this.baseOrderInformation.cseOrder.websiteId as Website);
  }

  set showPlaceOrder(newValue: boolean) {
    this.orderBeingCreated = !newValue;
    this.updateShowPlaceOrder.emit(newValue);
  }

  get showPlaceOrder(): boolean {
    return !this.orderBeingCreated;
  }

  get order(): Order {
    return this.baseOrderInformation.existingOrder;
  }

  set order(newValue: Order) {
    this.baseOrderInformation.existingOrder = newValue;
  }

  get planType(): string {
    return (this.order && this.order.accountDetails)? this.order.accountDetails.planType: '';
  }

  get selectedOrderType(): OrderType {
    return this.baseOrderInformation.orderType;
  }

  isExistingCustomer(): boolean {
    return !this.selectedOrderType.isNewOrder;
  }

  isAdditional(): boolean {
    return this.selectedOrderType && (this.selectedOrderType.calculationMethod == CalculationMethod.ADDITIONAL);
  }

  isReplacementDueToFault(): boolean {
    return this.selectedOrderType && (this.selectedOrderType.title == 'Faulty');
  }

  isAllowedDiscontinued(): boolean {
    return this.selectedOrderType &&
      ([CalculationMethod.FREE, CalculationMethod.LOST].includes(this.selectedOrderType.calculationMethod));
  }

  initialiseFromNavigationData() {
    if (!this.baseOrderInformation.cseOrderNavigationData) {
      return;
    }
    this.alarmUserNameOption = 'other';
    this.alarmUserPhoneOption = 'other';
    this.alarmUserAddressOption = 'other';
    this.orderDetails.alarmUserFirstName = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.firstName;
    this.orderDetails.alarmUserLastName = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.lastName;
    this.orderDetails.alarmUserPhone = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.telephone;
    this.orderDetails.alarmUserMobile = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.mobile;
    this.alarmUserAddress = {
      'addressOne': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.addressOne,
      'addressTwo': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.addressTwo,
      'city': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.city,
      'county': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.county,
      'postcode': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.postcode,
      'validated': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.validated,
    };
    this.infoBilling.firstName = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.firstName;
    this.infoBilling.lastName = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.lastName;
    this.infoBilling.email = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.email;
    if (this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.mobile) {
      this.infoBilling.mobile = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.mobile;
    } else if (!this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.mobile && this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.telephone) {
      this.infoBilling.mobile = this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.telephone;
    }
    this.infoBilling.userAddress = {
      'addressOne': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.addressOne,
      'addressTwo': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.addressTwo,
      'city': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.city,
      'county': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.county,
      'postcode': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.postcode,
      'validated': this.baseOrderInformation.cseOrderNavigationData.alarmUserDetails.userAddress.validated,
    };
    this.validateBillingAddress(true);
  }

  initialiseFromExistingOrder() {
    if (!this.order) {
      return;
    }
    this.baseOrderInformation.cseOrder.orderId = this.order._id;
    this.baseOrderInformation.cseOrder.tdCode = this.order.alarmUserDetails.tdCode;
    this.baseOrderInformation.cseOrder.currentPlan = this.order.accountDetails.plan;
    this.orderDetails.NCFRequired = 'No';
    this.crmOrderLink = '/order/' + this.order['_id'];
    this.infoBilling = {
      'email': this.order.alarmUserDetails.email,
      'mobile': this.order.alarmUserDetails.mobile? this.order.alarmUserDetails.mobile: this.order.alarmUserDetails.telephone,
      'firstName': this.order.alarmUserDetails.firstName,
      'lastName': this.order.alarmUserDetails.lastName,
      'userAddress': this.order.alarmUserDetails.userAddress,
    };
    const createdMoment: moment.Moment = moment.tz(this.order.created, 'Europe/London');
    this.orderDateYMD = createdMoment.format('YYYY-MM-DD');
    this.recentOrder = (this.baseOrderInformation.currentMoment.diff(createdMoment, 'months') <= 11);
    switch (this.planType) {
      case 'annual':
        this.orderRenewalAfter = 12;
        break;
      case 'quarterly':
      case 'monthly':
        this.orderRenewalAfter = 3;
        break;
      case 'lifetime':
        this.orderRenewalAfter = -1;
        break;
    }
    if (this.order.accountDetails && this.order.accountDetails.plan) {
      if (this.order.accountDetails.plan.includes('V')) {
        this.vatSelected = 'not exempt';
        this.orderVatStatus = OrderVatStatus.VATABLE;
      } else {
        this.vatSelected = 'exempt';
        this.orderVatStatus = OrderVatStatus.VAT_EXEMPT;
      }
      this.setVat();
    }
    this.validateBillingAddress(false);
  }

  onChange(event: StripeCardElementChangeEvent) {
    if (event.error) {
      this.stripeCardError = event.error.message;
    } else {
      this.stripeCardError = null;
    }
    this.changeDetector.detectChanges();
  }

  async ngAfterViewInit() {
    const stripeCardElementOptions: StripeCardElementOptions = {
      'style': {
        'base': {
          'fontFamily': 'monospace',
          'fontSmoothing': 'antialiased',
          'fontSize': '19px',
          '::placeholder': {
            'color': 'purple'
          }
        }
      }
    };
    this.stripe = await loadStripe(environment.stripeKeys[this.stripeBrand]);
    const stripeElements: StripeElements = this.stripe.elements();
    this.cardElement = stripeElements.create('card', stripeCardElementOptions);
    this.cardElement.mount(this.cardInfo.nativeElement);
    this.cardElement.on('change', this.cardHandler);
  }
  
  billingAddressSearch() {
    if (!this.billingSearchPostCode) {
      return;
    }
    this.billingAddressResults = [];
    this.allowBillingAddressManualEntry = false;
    this.billingSearchError = '';
    this.getAddrClient.find(this.billingSearchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.billingAddressResults = getLookupFromGetAddressResult(this.billingSearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.billingSearchError = addressResult.toFailed().message;
        console.error('Billing Address search failed. Error:', this.billingSearchError);
      } else if (this.billingAddressResults.length <= 2) {
        this.billingSearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Billing Address search failed. Error:', error);
    })
  }

  setBillingAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowBillingAddressManualEntry = true;
      if (!this.infoBilling.userAddress.addressOne && !this.infoBilling.userAddress.postcode) {
        this.infoBilling.userAddress.postcode = this.billingSearchPostCode;
      }
      this.infoBilling.userAddress.validated = false;
      return;
    }
    this.allowBillingAddressManualEntry = false;
    // Don't want to copy Role
    this.infoBilling.userAddress = {
      'addressOne': selectedAddress.addressOne,
      'addressTwo': selectedAddress.addressTwo,
      'city': selectedAddress.city,
      'county': selectedAddress.county,
      'postcode': selectedAddress.postcode,
      'validated': selectedAddress.validated,
    };
  }

  deliveryAddressSearch() {
    if (!this.deliverySearchPostCode) {
      return;
    }
    this.deliveryAddressResults = [];
    this.allowDeliveryAddressManualEntry = false;
    this.deliverySearchError = '';
    this.getAddrClient.find(this.deliverySearchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.deliveryAddressResults = getLookupFromGetAddressResult(this.deliverySearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.deliverySearchError = addressResult.toFailed().message;
        console.error('Delivery Address search failed. Error:', this.deliverySearchError);
      } else if (this.deliveryAddressResults.length <= 2) {
        this.deliverySearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Delivery Address search failed. Error:', error);
    })
  }

  setDeliveryAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowDeliveryAddressManualEntry = true;
      if (!this.infoDeliver.userAddress.addressOne && !this.infoDeliver.userAddress.postcode) {
        this.infoDeliver.userAddress.postcode = this.deliverySearchPostCode;
      }
      this.infoDeliver.userAddress.validated = false;
      return;
    }
    this.allowDeliveryAddressManualEntry = false;
    // Don't want to copy Role
    this.infoDeliver.userAddress = {
      'addressOne': selectedAddress.addressOne,
      'addressTwo': selectedAddress.addressTwo,
      'city': selectedAddress.city,
      'county': selectedAddress.county,
      'postcode': selectedAddress.postcode,
      'validated': selectedAddress.validated,
    };
  }

  alarmUserAddressSearch() {
    if (!this.alarmUserSearchPostCode) {
      return;
    }
    this.alarmUserAddressResults = [];
    this.allowAlarmUserAddressManualEntry = false;
    this.alarmUserSearchError = '';
    this.getAddrClient.find(this.alarmUserSearchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.alarmUserAddressResults = getLookupFromGetAddressResult(this.alarmUserSearchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.alarmUserSearchError = addressResult.toFailed().message;
        console.error('Alarm User Address search failed. Error:', this.alarmUserSearchError);
      } else if (this.alarmUserAddressResults.length <= 2) {
        this.alarmUserSearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Alarm User Address search failed. Error:', error);
    })
  }

  setAlarmUserAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowAlarmUserAddressManualEntry = true;
      if (!this.alarmUserAddress.addressOne && !this.alarmUserAddress.postcode) {
        this.alarmUserAddress.postcode = this.alarmUserSearchPostCode;
      }
      this.alarmUserAddress.validated = false;
      return;
    }
    this.allowAlarmUserAddressManualEntry = false;
    // Don't want to copy Role
    this.alarmUserAddress = {
      'addressOne': selectedAddress.addressOne,
      'addressTwo': selectedAddress.addressTwo,
      'city': selectedAddress.city,
      'county': selectedAddress.county,
      'postcode': selectedAddress.postcode,
      'validated': selectedAddress.validated,
    };
  }

  validateBillingAddress(fromPassedData: boolean) {
    // If fromPassedData then alarm user address set from the same data, so unlock it too if there's a problem
    this.allowBillingAddressManualEntry = false;
    if (fromPassedData) {
      this.allowAlarmUserAddressManualEntry = false;
    }
    if (this.infoBilling.userAddress.validated) {
      return;
    }
    validateAddress(this.getAddrClient, this.infoBilling.userAddress).then(
      (addressValResponse: MultiRecordResponse<SelectItem<Address>>) => {
        if (addressValResponse.success) {
          this.infoBilling.userAddress.validated = true;
          if (fromPassedData) {
            this.alarmUserAddress.validated = true;
          }
          return;
        }
        this.billingSearchError = addressValResponse.message;
        this.billingSearchPostCode = this.infoBilling.userAddress? this.infoBilling.userAddress.postcode: '';
        this.infoBilling.userAddress.validated = false;
        if (fromPassedData) {
          this.alarmUserSearchError = addressValResponse.message;
          this.alarmUserSearchPostCode = this.infoBilling.userAddress? this.infoBilling.userAddress.postcode: '';
          this.alarmUserAddress.validated = false;
        }
        if (!addressValResponse.data) {
          this.showErrorPopUp('Error Validating Address',
            `Error validating the existing address. Please check it to make sure it is correct. Reason: ${addressValResponse.message}`
          );
        } else {
          this.billingAddressResults = addressValResponse.data;
          if (fromPassedData) {
            this.alarmUserAddressResults = addressValResponse.data;
          }
          this.showInfoPopUp('Possible Invalid Address',
            `The existing address could not be validated please check it is correct. Reason: ${addressValResponse.message}`
          );
        }
      }
    );
  }

  emailBlur(): void {
    if (this.infoBilling.email) {
      this.orderDetails.NCFRequired = 'No';
    }
  }
    
  refresh(): void {
    window.location.reload();
  }

  delete(i: number) {
    this.items.splice(i, 1);
    this.priceupdate();
    this.updateFilters();
  }

  updateFilters() {
    // Update the category list for changes to the VAT status and frequency of products in the basket
    if (this.category) {
      // This calls setVat, so no need to call that if category is set
      this.setCategory();
    } else if (this.vatSelected) {
      this.setVat();
    }
  }

  setCategory() {
    this.setVat();
    this.productsFiltered = this.productsFiltered.filter((product: RawProduct) => {
      if (product.category != this.category) {
        return false;
      }
      if (this.orderRenewalAfter) {
        if (product.renewalAfterDate) {
          return product.renewalAfterDate == this.orderRenewalAfter;
        }
        if (product.variations && product.variations.length) {
          return product.variations.some((variation: RawVariation) =>
            // Find a variation that does not have a renew after set, or matches
            !variation.renewalAfterDate || (variation.renewalAfterDate == this.orderRenewalAfter)
          );
        }
      }
      return true;
    });
    this.selectedProduct = null;
    this.variations = [];
  }

  setVat() {
    this.productsFiltered = this.products.filter((product: RawProduct) => 
      (product.vat == null) || (product.vat == '') || (product.vat == this.vatSelected)
    );
    if (this.vatSelected == 'exempt') {
      if (this.orderVatStatus == OrderVatStatus.VATABLE) {
        // They can't choose anything from this category with VATable items in their basket
        this.categories = [];
      } else {
        this.categories = this.exemptCategories; // Ex keysafe
      }
    } else {
      if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
        // They have VAT exempt items in the order, so can only add keysafes from the VATable category
        this.categories = [{
          'label': 'Key Safe',
          'value': 'keySafe'
        }];
      } else {
        this.categories = this.notExemptCategories; // Inc keysafe
      }
    }
    this.categories = this.categories.filter((categorySelect: SelectItem<string>) => {
      switch (categorySelect.value) {
        case 'quarterlyPlan':
          return !this.orderRenewalAfter || (this.orderRenewalAfter == 3);
        case 'annualPlan':
          return !this.orderRenewalAfter || (this.orderRenewalAfter == 12);
        case 'lifetimePlan':
          return !this.orderRenewalAfter || (this.orderRenewalAfter == -1);
        case 'discontinued':
          return (this.isAllowedDiscontinued());
        case 'cables':
          return (this.isAllowedDiscontinued());
        default:
          return true;
      }
    });
  }

  searchCoupon() {
    if (!this.couponCode) {
      this.couponError = '';
      this.coupon = undefined;
    }
    // Coupon codes on WooCommerce are all lowercase
    this.couponCode = this.couponCode.toLocaleLowerCase();
    let res: Coupon|undefined = undefined;
    for (let coupon of this.coupons) {
      if (coupon.code == this.couponCode) {
        res = coupon;
        if (this.partnershipByVoucherCode[this.couponCode]) {
          this.baseOrderInformation.cseOrder.howHeard = 'Partnership';
          this.selectedPartnership = this.partnershipByVoucherCode[this.couponCode];
        }
      }
    }
    this.coupon = res;
    this.priceupdate();
  }

  renewalMethodChange() {
    if ('directDebit' == this.orderDetails.renewalMethod) {
      this.directDebit.addressLineOne=this.infoBilling.userAddress.addressOne;
      this.directDebit.addressLineTwo=this.infoBilling.userAddress.addressTwo;
      this.directDebit.city=this.infoBilling.userAddress.city;
      this.directDebit.postcode = this.infoBilling.userAddress.postcode;
      this.showInfoPopUp('Reminder', 'Remember to tell the customer about direct debit guarantee.');
    } else {
      this.directDebit.addressLineOne='';
      this.directDebit.addressLineTwo='';
      this.directDebit.city='';
      this.directDebit.postcode='';
    }
  }

  addToInvoice() {
    const selectedProd: BasketItem = Object.assign({'priceCalculated': false}, this.selectedProduct);
    selectedProd.quantity = 1;
    if (!!this.selectedVariation) {
      const variationPrice: number = parseFloat(this.selectedVariation.regular_price);
      selectedProd.selectedVariation = {
        '_id': this.selectedVariation._id,
        'title': this.selectedVariation.title,
        'sku': this.selectedVariation.sku,
        'symbol': this.selectedVariation.symbol,
        'regular_price': variationPrice,
        'original_price': variationPrice,
        'vat': this.selectedVariation.vat,
        'renewalAfterDate': this.selectedVariation.renewalAfterDate,
        'hardware': this.selectedVariation.hardware,
        'hardwareSets': this.selectedVariation.hardwareSets,
      };
    } else {
      selectedProd.selectedVariation = undefined;
    }
    selectedProd.original_price = selectedProd.regularPrice;
    this.variations = [];

    const pos: number = this.items.findIndex((item: BasketItem) => selectedProd._id == item._id);
    if (pos == -1) {
      this.items.push(selectedProd);
    } else {
      this.items[pos].quantity+=1;
    }

    this.selectedVariation = null;
    this.selectedProduct = null;

    this.priceupdate();
    this.updateFilters();
  }

  updateFeeIfRequired(setupFeeConfig: SetupFeeConfig, feeRequired: SetupFeeRequired): boolean {
    this.setVat();
    let requiredFeeSku: string;
    let alreadyHasRequiredFee: boolean = false;
    let feesChanged: boolean = false;
    this.selectedProduct = null;
    if (feeRequired != SetupFeeRequired.NO_FEE) {
      // orderVatStatus 0 is NOT_SET, so subtract one to get sku index
      requiredFeeSku = (feeRequired == SetupFeeRequired.NORMAL_FEE)?
        setupFeeConfig.normalFeeProducts[this.orderVatStatus - 1]:
        setupFeeConfig.reducedFeeProducts[this.orderVatStatus - 1];
      if (!requiredFeeSku) {
        console.error('Setup Fee not found');
        // Call this to reapply the category filter else the product list contains items from other categories
        if (!!this.category) {
          this.setCategory();
        }
        this.calculationError = 'Setup Fee required, but setup fee SKU not found';
        return true;
      }
    }
    const feeSkusToRemove: string[] = setupFeeConfig.normalFeeProducts
      .concat(setupFeeConfig.reducedFeeProducts).filter((feeSku: string) => 
        requiredFeeSku != feeSku
      );
    // Remove incorrect fees first
    for (let i: number = this.items.length - 1; i > -1; i--) {
      const currentItemSku: string = this.items[i].sku;
      if (feeSkusToRemove.includes(currentItemSku)) {
        feesChanged = true;
        this.items.splice(i, 1);
      } else if (requiredFeeSku && (requiredFeeSku == currentItemSku)) {
        alreadyHasRequiredFee = true;
      }
    }
    if ((feeRequired != SetupFeeRequired.NO_FEE) && !alreadyHasRequiredFee) {
      feesChanged = true;
      this.selectedProduct = this.productsFiltered.find((product: RawProduct) => product.sku == requiredFeeSku);
      if (!this.selectedProduct) {
        // Call this to reapply the category filter else the product list contains items from other categories
        if (!!this.category) {
          this.setCategory();
        }
        console.error('Setup Fee not found');
        this.calculationError = 'Setup Fee required, but setup fee product not found';
        return true;
      }
    }
    if (alreadyHasRequiredFee) {
      feesChanged = false;
    }
    if (feesChanged) {
      if (this.selectedProduct) {
        this.addToInvoice();
      } else {
        // Fees were removed, so need to recalculate price
        this.priceupdate();
        this.updateFilters();
      }
    }
    // Call this to reapply the category filter else the product list contains items from other categories
    if (!!this.category) {
      this.setCategory();
    }
    return feesChanged;
  }

  get overrideTotal(): boolean {
    return this.totalOverridden;
  }

  set overrideTotal(overridden: boolean) {
    // Total was overridden, but turned off, so reset prices
    if (!overridden && this.totalOverridden) {
      for (let item of this.items) {
        item.regularPrice = item.original_price;
        if (!!item.selectedVariation) {
          item.selectedVariation.regular_price = item.selectedVariation.original_price;
        }
      }
    }
    this.totalOverridden = overridden;
    this.priceupdate();
  }

  hasCouponExpired(): boolean {
    // Handle WooCommerce legacy or new format coupons
    if (this.coupon && this.coupon.expiry_date) {
      if (moment(this.coupon.expiry_date).isBefore(moment.utc())) {
        this.couponError = 'Coupon has expired';
        return true;
      }
    } 
    return false;
  }

  isOrderValueValidForCoupon(): boolean {
    if (!this.coupon) {
      return true;
    }
    const minAllowed: number = Number(this.coupon.minimum_amount);
    const maxAllowed: number = Number(this.coupon.maximum_amount);
    if (!!minAllowed && !isNaN(minAllowed) && (minAllowed > 0) && (minAllowed > this.TTorder)) {
      this.couponError = 'Order does not meet minimum value for coupon';
      return false;
    }
    if (!!maxAllowed && !isNaN(maxAllowed) && (maxAllowed > 0) && (maxAllowed < this.TTorder)) {
      this.couponError = 'Order exceeds maximum value for coupon';
      return false;
    }
    return true;
  }

  isCartCoupon(): boolean {
    if (!this.coupon) {
      return false;
    }
    if (this.coupon.type == 'fixed_cart') {
      return true;
    }

    return false;
  }

  areProductsValidForCoupon(): boolean {
    if (this.items.length == 0) {
      return false;
    }
    const setupFeeConfig: SetupFeeConfig = WOO_SETUP_FEE_CONFIGS[this.selectedWebsite._id];
    if ((this.coupon.product_ids.length == 0) && (this.coupon.exclude_product_ids.length == 0)
        && (!setupFeeConfig || (setupFeeConfig.productsRequiringReducedFee.length == 0))) {
      // No product restrictions
      return true;
    }
    let containsAllowedItems: boolean = false;
    let containsExcludedItems: boolean = false;
    let containsNoCouponItems: boolean = false;
    for (let item of this.items) {
      // Explicitly allowed item
      if (this.coupon.product_ids.includes(item.productWebID) ||
          (!!item.selectedVariation && this.coupon.product_ids.includes(item.selectedVariation._id))) {
        containsAllowedItems = true;
      }
      if (this.coupon.exclude_product_ids.includes(item.productWebID) || 
          (!!item.selectedVariation && this.coupon.exclude_product_ids.includes(item.selectedVariation._id))) {
        containsExcludedItems = true;
      } else if (this.coupon.product_ids.length == 0) {
        // Implicitly allowed item (not in exclusion list and does not have to be on allowed list)
        containsAllowedItems = true;
      }
      if (setupFeeConfig && (setupFeeConfig.productsRequiringReducedFee.includes(item.sku))) {
        containsNoCouponItems = true;
      }
    }
    if (containsNoCouponItems) {
      this.couponError = 'No coupons can be used on orders wtih reduced setup fee items';
      return false;
    }
    // For cart level coupons cannot have excluded products
    if (this.isCartCoupon() && containsExcludedItems) {
      this.couponError = 'Order contains items not allowed by the coupon';
      return false;
    }
    // Regardless of whether it is a cart or product coupon it needs at least one allowed item
    if (!containsAllowedItems) {
      this.couponError = 'Order does not contain required items for coupon';
      return false;
    }
    return true;
  }

  isCouponValid(): boolean {
    this.couponError = '';
    if (!this.couponCode) {
      return true;
    }
    if (!!this.couponCode && (!this.coupon || !this.coupon.code)) {
      this.couponError = 'Coupon code not found';
    }
    /* Currently not applying WooCommerce checks for:
      usage limit (As coupons on the CRM are a copy of the WooCommerce record the count to apply the limit will be out of date)
      user usage limit
      product categories (WooCommerce category ids are not CRM product records)
      excluded categories
      sale price check (CRM products don't have sale prices on)
    */
    if (!this.coupon || !this.coupon.code) {
      return false;
    }
    if (!['percent', 'percent_product', 'fixed_cart', 'fixed_product' , 'free_gift'].includes(this.coupon.type)) {
      this.couponError = 'Invalid coupon type';
      return false;
    }
    if (this.hasCouponExpired()) {
      return false;
    }
    if (!this.isOrderValueValidForCoupon()) {
      return false;
    }
    if (!this.areProductsValidForCoupon()) {
      return false;
    }
    return true;
  }

  priceupdate() {
    this.vat = 0;
    this.TTorder = 0;
    this.totalBeforeDiscountAndOverride = 0;
    this.calculationError = '';
    this.lifetimeLineNeedsOverride = false;
    this.hasRentalItems = false;
    if (this.baseOrderInformation.hasBulkTag) {
      this.freeOrderPriceUpdate();
    } else {
      switch (this.selectedOrderType.calculationMethod) {
        case CalculationMethod.FULL:
          this.newOrderPriceUpdate();
          break;
        case CalculationMethod.ADDITIONAL:
          this.proRataPriceUpdate(false);
          break;
        case CalculationMethod.DIFFERENCE:
          this.proRataPriceUpdate(true);
          break;
        case CalculationMethod.FREE:
          this.freeOrderPriceUpdate();
          break;
        case CalculationMethod.LOST:
          this.lostItemPriceUpdate();
          break;
      default:
          break;
      }
    }
    if (this.TTorder == 0) {
      this.orderDetails.paymentMethod = 'No Payment Required';
    } else if ((this.TTorder > 0) && (this.orderDetails.paymentMethod == 'No Payment Required')) {
      this.orderDetails.paymentMethod = '';
    }
  }

  newOrderPriceUpdate() {
    let feeRequired: SetupFeeRequired = SetupFeeRequired.NO_FEE;
    this.orderVatStatus = OrderVatStatus.NOT_SET;
    this.orderRenewalAfter = 0;
    const calculationItems: ItemForCouponCalulations[] = [];
    const setupFeeConfig: SetupFeeConfig = WOO_SETUP_FEE_CONFIGS[this.selectedWebsite._id];

    // Calculate undiscounted value for use in Coupon checks and check if fee needs to be applied
    for (let currentItem of this.items) {
      // Reduced fee is the priority one to use, so don't change it if already set
      if ((setupFeeConfig) && (feeRequired != SetupFeeRequired.REDUCED_FEE)) {
        if (currentItem.category.includes('Plan') && !currentItem.crmTitle.toLocaleLowerCase().includes('outlet')) {
          if (setupFeeConfig.productsRequiringReducedFee.includes(currentItem.sku)) {
            feeRequired = SetupFeeRequired.REDUCED_FEE;
          } else {
            feeRequired = SetupFeeRequired.NORMAL_FEE;
          }
        }
      }
      let unitCost: number = 0;
      let originalUnitCost: number = 0;
      let unitVat: number = 0;
      let notExempt: boolean;
      let rental: boolean = false;
      if (currentItem.selectedVariation) {
        unitCost = currentItem.selectedVariation.regular_price;
        originalUnitCost = currentItem.selectedVariation.original_price;
        if (!currentItem.selectedVariation.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.selectedVariation.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.selectedVariation.vat = 'exempt';
          }
        }
        notExempt = (currentItem.selectedVariation.vat == 'not exempt');
        rental = (currentItem.selectedVariation.hardwareSets.length > 0) || (currentItem.selectedVariation.hardware.length > 0);
        if (currentItem.selectedVariation.renewalAfterDate) {
          this.orderRenewalAfter = currentItem.selectedVariation.renewalAfterDate;
        }
      } else {
        unitCost = currentItem.regularPrice;
        originalUnitCost = currentItem.original_price;
        if (!currentItem.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.vat = 'exempt';
          }
        }
        notExempt = (currentItem.vat == 'not exempt');
        rental = (currentItem.hardwareSets.length > 0) || (currentItem.hardware.length > 0);
        if (currentItem.renewalAfterDate) {
          this.orderRenewalAfter = currentItem.renewalAfterDate;
        }
      }
      if (currentItem.category == 'lifetimePlan') {
        // Lifetime plans don't have a renewal after, but we need to stop them being mixed
        // With non-lifetime plans and accessories
        this.orderRenewalAfter = -1;
      }

      this.TTorder += unitCost * currentItem.quantity;
      this.totalBeforeDiscountAndOverride += originalUnitCost * currentItem.quantity;
      if (notExempt) {
        unitVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * unitCost / EX_TO_INC_VAT_MULTIPLIER);
        // Set unitCost to VAT exempt cost
        unitCost = roundToTwoDecimalPlaces(unitCost - unitVat);
        this.vat += roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
        if (currentItem.category != 'keySafe') {
          // Key safes are never exempt, so don't define the order status
          this.orderVatStatus = OrderVatStatus.VATABLE;
        }
      } else {
        // If they have at least 1 exempt item treat them as exempt (key safes are never exempt)
        this.orderVatStatus = OrderVatStatus.VAT_EXEMPT;
      }
      const itemForCouponCalulations: ItemForCouponCalulations = {
        'product_id': currentItem.productWebID,
        'variation_id': !!currentItem.selectedVariation? currentItem.selectedVariation._id: undefined,
        'subtotalExVat': unitCost,
        'subtotalVat': unitVat,
        'totalExVat': unitCost,
        'totalVat': unitVat,
        'rentalItem': rental,
      };
      if (rental) {
        this.hasRentalItems = true;
      }
      for (let i = 0; i < currentItem.quantity; i++) {
        calculationItems.push(itemForCouponCalulations);
      }
    }
    if (setupFeeConfig && this.updateFeeIfRequired(setupFeeConfig, feeRequired)) {
      // Exit this call of priceupdate as fee was udpated and will call this method again it once the the fee is updated
      return;
    }

    console.log('Price before coupon', this.TTorder);
    const totalBeforeDiscount: number = this.TTorder;
    if (!this.couponCode || !this.isCouponValid()) {
      this.convertCalculationItemsToWooItems(calculationItems);
    } else {
      switch (this.coupon.type) {
        /*
          Percent amount off whole cart
        */
        case 'percent':
        /*
          Percent amount off specific product(s)
        */
        case 'percent_product':
          this.applyPercentDiscount(calculationItems);
          break;
        /*
          Currency amount off whole cart - divided across products in ratio of VAT inc value of each line
        */
        case 'fixed_cart':
          this.applyWholeCartFixedDiscount(calculationItems);
          break;
        /*
          Currency amount off specific product(s)
        */
        case 'fixed_product':
          this.applyProductFixedDiscount(calculationItems);
          break;
        default:
          // Straight convert, no discounts need applying
          this.convertCalculationItemsToWooItems(calculationItems);
          break;
      }
    }
    console.log('WooItems', this.woodata.line_items);
    // Coupon discount is what the value was before, minus the value now
    this.couponDiscount = totalBeforeDiscount - this.TTorder;
  }

  getProRataPriceForHardware(hardwareSetIds: string[], hardwareIds: string[], notExempt: boolean): number {
    let tempPrice: number = 0;
    hardwareSetIds.forEach((setId: string) => {
      const hardwareSet: HardwareSet = this.hardwareSets.find((hwSet: HardwareSet) =>
        hwSet._id == setId
      );
      if (hardwareSet) {
        const setPrice: number|undefined = getPriceForOrderDate(this.recentOrder, this.planType, this.orderDateYMD,
            hardwareSet.overridePricesExVat, hardwareSet.overrideRecentQuarterlyPrice, hardwareSet.overrideRecentAnnualPrice);
        if (!setPrice) {
          this.calculationError = `${this.calculationError}\nNo price for order date for ${hardwareSet.title}`;
        } else {
          tempPrice += setPrice;
        }
      }
    });
    hardwareIds.forEach((hwId: string) => {
      const hardware: Hardware = this.hardware.find((hw: Hardware) =>
        hw._id == hwId
      );
      if (hardware) {
        const hardwarePrice: number|undefined = getPriceForOrderDate(this.recentOrder, this.planType, this.orderDateYMD,
            hardware.pricesExVat, hardware.recentQuarterlyPrice, hardware.recentAnnualPrice);
        if (!hardwarePrice) {
          this.calculationError = `${this.calculationError}\nNo price for order date for ${hardware.title}`;
        } else {
          tempPrice += hardwarePrice;
        }
      }
    });
    if (this.planType == 'monthly') {
      tempPrice = tempPrice / 3.00;
    }
    if (notExempt) {
      tempPrice = tempPrice * EX_TO_INC_VAT_MULTIPLIER;
    }
    return roundToTwoDecimalPlaces(tempPrice * this.baseOrderInformation.proRataMultiplier);
  }

  proRataPriceUpdate(removeCurrentPrice: boolean) {
    const calculationItems: ItemForCouponCalulations[] = [];

    // Calculate the un-prorated value and check if fee needs to be removed
    for (let currentItem of this.items) {
      let unitCost: number = 0;
      let originalUnitCost: number = 0;
      let unitVat: number = 0;
      let notExempt: boolean;
      let rental: boolean = false;
      if (currentItem.selectedVariation) {
        if (!currentItem.selectedVariation.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.selectedVariation.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.selectedVariation.vat = 'exempt';
          }
        }
        notExempt = (currentItem.selectedVariation.vat == 'not exempt');
        rental = (currentItem.selectedVariation.hardwareSets.length > 0) || (currentItem.selectedVariation.hardware.length > 0);
        if (rental) {
          if ((this.planType == 'lifetime') && (currentItem.selectedVariation.regular_price == currentItem.selectedVariation.original_price)) {
            // We can't calculate for lifetime, but flag price hasn't been overridden
            this.lifetimeLineNeedsOverride = true;
          } else if (!currentItem.priceCalculated) {
            // Don't need to calculate price if already done. Overridden prices will have to have been calculated first, so this won't reset them
            const tempPrice: number =
              this.getProRataPriceForHardware(currentItem.selectedVariation.hardwareSets, currentItem.selectedVariation.hardware, notExempt);
            // Set the original price too, so if override is turned off can set back without recalculating
            currentItem.selectedVariation.regular_price = tempPrice;
            currentItem.selectedVariation.original_price = tempPrice;
            currentItem.priceCalculated = true;
          }
        }
        unitCost = currentItem.selectedVariation.regular_price;
        originalUnitCost = currentItem.selectedVariation.original_price;
      } else {
        if (!currentItem.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.vat = 'exempt';
          }
        }
        notExempt = (currentItem.vat == 'not exempt');
        rental = (currentItem.hardwareSets.length > 0) || (currentItem.hardware.length > 0);
        if (rental) {
          if ((this.planType == 'lifetime') && (currentItem.regularPrice == currentItem.original_price)) {
            // We can't calculate for lifetime, but flag price hasn't been overridden
            this.lifetimeLineNeedsOverride = true;
          } else if (!currentItem.priceCalculated) {
            const tempPrice: number =
              this.getProRataPriceForHardware(currentItem.hardwareSets, currentItem.hardware, notExempt);
            // Set the original price too, so if override is turned off can set back without recalculating
            currentItem.regularPrice = tempPrice;
            currentItem.original_price = tempPrice;
            currentItem.priceCalculated = true;
          }
        }
        unitCost = currentItem.regularPrice;
        originalUnitCost = currentItem.original_price;
      }
      this.TTorder += unitCost * currentItem.quantity;
      this.totalBeforeDiscountAndOverride += originalUnitCost * currentItem.quantity;
      if (notExempt) {
        unitVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * unitCost / EX_TO_INC_VAT_MULTIPLIER);
        // Set unitCost to VAT exempt cost
        unitCost = roundToTwoDecimalPlaces(unitCost - unitVat);
        this.vat += roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
      }
      const itemForCouponCalulations: ItemForCouponCalulations = {
        'product_id': currentItem.productWebID,
        'variation_id': !!currentItem.selectedVariation? currentItem.selectedVariation._id: undefined,
        'subtotalExVat': unitCost,
        'subtotalVat': unitVat,
        'totalExVat': unitCost,
        'totalVat': unitVat,
        'rentalItem': rental,
      };
      if (rental) {
        this.hasRentalItems = true;
      }
      for (let i = 0; i < currentItem.quantity; i++) {
        calculationItems.push(itemForCouponCalulations);
      }
    }

    console.log('Price before existing renewal adjustment', this.TTorder);
    if (!removeCurrentPrice || (this.TTorder <= 0) || (this.baseOrderInformation.cseOrder.proRataCurrentPrice <= 0)) {
      this.convertCalculationItemsToWooItems(calculationItems);
    } else {
      this.removeCurrentPlanPriceFromLines(calculationItems);
      this.totalBeforeDiscountAndOverride =
        roundToTwoDecimalPlaces(this.totalBeforeDiscountAndOverride - this.baseOrderInformation.cseOrder.proRataCurrentPrice);
      if (this.totalBeforeDiscountAndOverride < 0) {
        this.totalBeforeDiscountAndOverride = 0;
      }
    }
    console.log('WooItems', this.woodata.line_items);
  }

  removeCurrentPlanPriceFromLines(calculationItems: ItemForCouponCalulations[]) {
    // Reset the order total as need to calculate with discount applied
    this.TTorder = 0;
    const line_items: WooItem[] = [];
    const itemsToDiscount: ItemForCouponCalulations[] = [];
    // Need to split the discount in the ratio of the item's value to the value of the items that can be discounted
    let discountableItemTotal: number = 0;
    let discountApplied: number = 0;

    for (let calculationItem of calculationItems) {
      if (calculationItem.rentalItem && (calculationItem.subtotalExVat > 0)) {
        itemsToDiscount.push(calculationItem);
        // Need to include the VAT when determining the ratio
        discountableItemTotal += roundToTwoDecimalPlaces(calculationItem.subtotalExVat + calculationItem.subtotalVat);
      } else {
        // Item not discounted, so straight add
        this.TTorder += roundToTwoDecimalPlaces(calculationItem.totalExVat + calculationItem.totalVat);
        line_items.push({
          'product_id': !!calculationItem.variation_id? calculationItem.variation_id: calculationItem.product_id,
          'quantity': 1,
          'subtotal': roundToTwoDecimalPlaces(calculationItem.subtotalExVat),
          'total': roundToTwoDecimalPlaces(calculationItem.totalExVat),
        });
      }
    }
    // Don't allow discounting more than the new equipment pro rata'ed cost (i.e. downgrades don't give a refund)
    const amountToDiscount: number = (discountableItemTotal < this.baseOrderInformation.cseOrder.proRataCurrentPrice)?
      discountableItemTotal: this.baseOrderInformation.cseOrder.proRataCurrentPrice;
    if (amountToDiscount > 0) {
      for (let itemToDiscount of itemsToDiscount) {
        // Floor the discount here, if necessary the logic in the next step will add pennies back in until the correct discount is given
        // This stops us possibly giving a pennies too much
        const discountIncVat: number = 
          floorToTwoDecimalPlaces(amountToDiscount * (itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) / discountableItemTotal);
        const discountExVat: number = (itemToDiscount.subtotalVat == 0)? discountIncVat: roundToTwoDecimalPlaces(discountIncVat / EX_TO_INC_VAT_MULTIPLIER);
        itemToDiscount.totalExVat = roundToTwoDecimalPlaces(Math.max(0, itemToDiscount.totalExVat - discountExVat));
        // If it is VATable recalculate the VAT
        if (itemToDiscount.subtotalVat > 0) {
          itemToDiscount.totalVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * itemToDiscount.totalExVat);
        }
        // Add up the including VAT discount as the voucher amount includes VAT
        discountApplied += 
          roundToTwoDecimalPlaces((itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) - (itemToDiscount.totalExVat + itemToDiscount.totalVat));
      }
      // If we have a remainder due to capping the discount at the line value take pennies/cents of eligible 
      // lines until we get to the appropriate applied discount.
      while (discountApplied < amountToDiscount) {
        let eligibleLineFound: boolean = false;
        for (let itemToDiscount of itemsToDiscount) {
          // Cannot substract any value from this line
          if (itemToDiscount.totalExVat < 0.01) {
            continue;
          }
          eligibleLineFound = true;
          const discountBefore: number = 
            roundToTwoDecimalPlaces((itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) - (itemToDiscount.totalExVat + itemToDiscount.totalVat));
          itemToDiscount.totalExVat -= 0.01;
          // If it is VATable recalculate the VAT
          if (itemToDiscount.subtotalVat > 0) {
            itemToDiscount.totalVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * itemToDiscount.totalExVat);
          }
          const discountAfter: number = 
            roundToTwoDecimalPlaces((itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) - (itemToDiscount.totalExVat + itemToDiscount.totalVat));
          discountApplied += (discountAfter - discountBefore);
          if (discountApplied >= amountToDiscount) {
            break;
          }
        }
        // Avoid getting stuck in loop if there are no more lines we can discount
        if (!eligibleLineFound) {
          break;
        }
      }
    }
    // Add the discounted lines into the total ane line_items
    for (let itemToDiscount of itemsToDiscount) {
      this.TTorder += roundToTwoDecimalPlaces(itemToDiscount.totalExVat + itemToDiscount.totalVat);
      line_items.push({
        'product_id': !!itemToDiscount.variation_id? itemToDiscount.variation_id: itemToDiscount.product_id,
        'quantity': 1,
        'subtotal': roundToTwoDecimalPlaces(itemToDiscount.subtotalExVat),
        'total': roundToTwoDecimalPlaces(itemToDiscount.totalExVat),
      });
    }

    this.woodata.line_items = line_items;
  }

  freeOrderPriceUpdate() {
    const calculationItems: ItemForCouponCalulations[] = [];

    // Update appropriate prices to zero and check if fee needs to be removed
    // Calculations still happen in case price on any items has been overridden to be non-zero
    for (let currentItem of this.items) {
      let unitCost: number = 0;
      let unitVat: number = 0;
      let notExempt: boolean;
      let rental: boolean = false;
      if (currentItem.selectedVariation) {
        if (!currentItem.selectedVariation.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.selectedVariation.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.selectedVariation.vat = 'exempt';
          }
        }
        notExempt = (currentItem.selectedVariation.vat == 'not exempt');
        rental = (currentItem.selectedVariation.hardwareSets.length > 0) || (currentItem.selectedVariation.hardware.length > 0);
        if (!currentItem.priceCalculated) {
          currentItem.selectedVariation.regular_price = 0.00;
          currentItem.selectedVariation.original_price = 0.00;
          currentItem.priceCalculated = true;
        }
        unitCost = currentItem.selectedVariation.regular_price;
      } else {
        if (!currentItem.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.vat = 'exempt';
          }
        }
        notExempt = (currentItem.vat == 'not exempt');
        rental = (currentItem.hardwareSets.length > 0) || (currentItem.hardware.length > 0);
        if (!currentItem.priceCalculated) {
          currentItem.regularPrice = 0.00;
          currentItem.original_price = 0.00;
          currentItem.priceCalculated = true;
        }
        unitCost = currentItem.regularPrice;
      }
      this.TTorder += unitCost * currentItem.quantity;
      if (notExempt) {
        unitVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * unitCost / EX_TO_INC_VAT_MULTIPLIER);
        // Set unitCost to VAT exempt cost
        unitCost = roundToTwoDecimalPlaces(unitCost - unitVat);
        this.vat += roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
      }
      const itemForCouponCalulations: ItemForCouponCalulations = {
        'product_id': currentItem.productWebID,
        'variation_id': !!currentItem.selectedVariation? currentItem.selectedVariation._id: undefined,
        'subtotalExVat': unitCost,
        'subtotalVat': unitVat,
        'totalExVat': unitCost,
        'totalVat': unitVat,
        'rentalItem': rental,
      };
      if (rental) {
        this.hasRentalItems = true;
      }
      for (let i = 0; i < currentItem.quantity; i++) {
        calculationItems.push(itemForCouponCalulations);
      }
    }
    this.convertCalculationItemsToWooItems(calculationItems);
    console.log('WooItems', this.woodata.line_items);
  }

  /**
   * This gets the lost item price, including VAT if necessary
   * @param hardwareSetIds The hardware set ids to get the lost price for
   * @param hardwareIds The hardware ids to get the lost price for
   * @param notExempt whether VAT is due
   * @returns the lost item price
   */
  getLostItemPriceForHardware(hardwareSetIds: string[], hardwareIds: string[], notExempt: boolean): number {
    let tempPrice: number = 0;
    hardwareSetIds.forEach((setId: string) => {
      const hardwareSet: HardwareSet = this.hardwareSets.find((hwSet: HardwareSet) =>
        hwSet._id == setId
      );
      if (hardwareSet) {
        tempPrice += hardwareSet.overrideReplacementPrice;
      }
    });
    hardwareIds.forEach((hwId: string) => {
      const hardware: Hardware = this.hardware.find((hw: Hardware) =>
        hw._id == hwId
      );
      if (hardware) {
        tempPrice += hardware.replacementPrice;
      }
    });
    if (notExempt) {
      tempPrice = tempPrice * EX_TO_INC_VAT_MULTIPLIER;
    }
    return tempPrice;
  }

  lostItemPriceUpdate() {
    const calculationItems: ItemForCouponCalulations[] = [];

    // Update appropriate prices to zero and check if fee needs to be removed
    for (let currentItem of this.items) {
      let unitCost: number = 0;
      let originalUnitCost: number = 0;
      let unitVat: number = 0;
      let notExempt: boolean;
      let rental: boolean = false;
      if (currentItem.selectedVariation) {
        if (!currentItem.selectedVariation.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.selectedVariation.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.selectedVariation.vat = 'exempt';
          }
        }
        notExempt = (currentItem.selectedVariation.vat == 'not exempt');
        rental = (currentItem.selectedVariation.hardwareSets.length > 0) || (currentItem.selectedVariation.hardware.length > 0);
        if (rental && !currentItem.priceCalculated) {
          const tempPrice: number =
            this.getLostItemPriceForHardware(currentItem.selectedVariation.hardwareSets, currentItem.selectedVariation.hardware, notExempt);
          currentItem.selectedVariation.regular_price = tempPrice;
          currentItem.selectedVariation.original_price = tempPrice;
          currentItem.priceCalculated = true;
        }
        unitCost = currentItem.selectedVariation.regular_price;
        originalUnitCost = currentItem.selectedVariation.original_price;
      } else {
        if (!currentItem.vat) {
          // If VAT status not set on the item, use the order VAT status
          if (this.orderVatStatus == OrderVatStatus.VATABLE) {
            currentItem.vat = 'not exempt';
          } else if (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT) {
            currentItem.vat = 'exempt';
          }
        }
        notExempt = (currentItem.vat == 'not exempt');
        rental = (currentItem.hardwareSets.length > 0) || (currentItem.hardware.length > 0);
        if (rental && !currentItem.priceCalculated) {
          const tempPrice: number =
            this.getLostItemPriceForHardware(currentItem.hardwareSets, currentItem.hardware, notExempt);
          currentItem.regularPrice = tempPrice;
          currentItem.original_price = tempPrice;
          currentItem.priceCalculated = true;
        }
        unitCost = currentItem.regularPrice;
        originalUnitCost = currentItem.original_price;
      }
      this.TTorder += unitCost * currentItem.quantity;
      this.totalBeforeDiscountAndOverride += originalUnitCost * currentItem.quantity;
      if (notExempt) {
        unitVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * unitCost / EX_TO_INC_VAT_MULTIPLIER);
        // Set unitCost to VAT exempt cost
        unitCost = roundToTwoDecimalPlaces(unitCost - unitVat);
        this.vat += roundToTwoDecimalPlaces(unitVat * currentItem.quantity);
      }
      const itemForCouponCalulations: ItemForCouponCalulations = {
        'product_id': currentItem.productWebID,
        'variation_id': !!currentItem.selectedVariation? currentItem.selectedVariation._id: undefined,
        'subtotalExVat': unitCost,
        'subtotalVat': unitVat,
        'totalExVat': unitCost,
        'totalVat': unitVat,
        'rentalItem': rental,
      };
      if (rental) {
        this.hasRentalItems = true;
      }
      for (let i = 0; i < currentItem.quantity; i++) {
        calculationItems.push(itemForCouponCalulations);
      }
    }
    this.convertCalculationItemsToWooItems(calculationItems);
    console.log('WooItems', this.woodata.line_items);
  }

  convertCalculationItemsToWooItems(calculationItems: ItemForCouponCalulations[]): void {
    // Just convert the items for coupon calculations into WooItems
    this.woodata.line_items = [];
    for (let calculationItem of calculationItems) {
      this.woodata.line_items.push({
        'product_id': !!calculationItem.variation_id? calculationItem.variation_id: calculationItem.product_id,
        // Quantity set to 1 here as 1 copy of this line is added per quantity i.e. the line is multiplied up the required number of times
        'quantity': 1,
        'subtotal': roundToTwoDecimalPlaces(calculationItem.subtotalExVat),
        'total': roundToTwoDecimalPlaces(calculationItem.totalExVat),
      });
    }
  }

  couponValidForProduct(item: ItemForCouponCalulations): boolean {
    if (item.totalExVat <= 0) {
      return false;
    }
    // Cart coupons once valid to apply apply to all non-zero priced items
    if (this.isCartCoupon()) {
      return true;
    }
    if ((this.coupon.product_ids.length == 0) && (this.coupon.exclude_product_ids.length == 0)) {
      return true;
    }
    if (this.coupon.exclude_product_ids.includes(item.product_id) ||
        (!!item.variation_id && this.coupon.exclude_product_ids.includes(item.variation_id))) {
      return false;
    }
    // Not excluded and does not require opt in
    if (this.coupon.product_ids.length == 0) {
      return true;
    }
    return (this.coupon.product_ids.includes(item.product_id) ||
        (!!item.variation_id && this.coupon.product_ids.includes(item.variation_id)));
  }

  /* 
    The only difference between cart and product % discount apply is that cart
    level vouchers will have checked there are no excluded items in the cart.
    No harm in checking again here - which allows both types to share one method.
    Both types can be limited to apply to a particular list of products and/or 
    limit the number of items they apply to.
  */
  applyPercentDiscount(calculationItems: ItemForCouponCalulations[]) {
    // Reset the order total as need to calculate with discount applied
    this.TTorder = 0;
    let appliedCount: number = 0;
    const line_items: WooItem[] = [];
    const discountMultiplier: number = 1 - parseFloat(this.coupon.amount)/100;
    for (let calculationItem of calculationItems) {
      let applyDiscount: boolean = false;
      if (!!this.coupon.limit_usage_to_x_items && (this.coupon.limit_usage_to_x_items <= appliedCount)) {
        applyDiscount = false;
      } else {
        applyDiscount = this.couponValidForProduct(calculationItem); 
      }
      if (applyDiscount) {
        calculationItem.totalExVat = roundToTwoDecimalPlaces(calculationItem.totalExVat * discountMultiplier);
        // If it is VATable recalculate the VAT
        if (calculationItem.subtotalVat > 0) {
          calculationItem.totalVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * calculationItem.totalExVat);
        }
      }
      this.TTorder += roundToTwoDecimalPlaces(calculationItem.totalExVat + calculationItem.totalVat);
      line_items.push({
        'product_id': !!calculationItem.variation_id? calculationItem.variation_id: calculationItem.product_id,
        'quantity': 1,
        'subtotal': roundToTwoDecimalPlaces(calculationItem.subtotalExVat),
        'total': roundToTwoDecimalPlaces(calculationItem.totalExVat),
      });
    }
    this.woodata.line_items = line_items;
  }

  /*
    TODO Newer WooCommerce divides the discount evenly across the quantity of eligible items, 
    rather than in ratio of value
  */
  applyWholeCartFixedDiscount(calculationItems: ItemForCouponCalulations[]) {
    // Reset the order total as need to calculate with discount applied
    this.TTorder = 0;
    const line_items: WooItem[] = [];
    const cartDiscount: number = parseFloat(this.coupon.amount);
    const itemsToDiscount: ItemForCouponCalulations[] = [];
    // Need to split the discount in the ratio of the item's value to the value of the items that can be discounted
    let discountableItemTotal: number = 0;
    let discountApplied: number = 0;

    for (let calculationItem of calculationItems) {
      if (this.couponValidForProduct(calculationItem)) {
        itemsToDiscount.push(calculationItem);
        // Need to include the VAT when determining the ratio
        discountableItemTotal += roundToTwoDecimalPlaces(calculationItem.subtotalExVat + calculationItem.subtotalVat);
      } else {
        // Item not discounted, so straight add
        this.TTorder += roundToTwoDecimalPlaces(calculationItem.totalExVat + calculationItem.totalVat);
        line_items.push({
          'product_id': !!calculationItem.variation_id? calculationItem.variation_id: calculationItem.product_id,
          'quantity': 1,
          'subtotal': roundToTwoDecimalPlaces(calculationItem.subtotalExVat),
          'total': roundToTwoDecimalPlaces(calculationItem.totalExVat),
        });
      }
    }
    for (let itemToDiscount of itemsToDiscount) {
      // Floor the discount here, if necessary the logic in the next step will add pennies back in until the correct discount is given
      // This stops us possibly giving a pennies too much
      const discountIncVat: number = 
        floorToTwoDecimalPlaces(cartDiscount * (itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) / discountableItemTotal);
      const discountExVat: number = (itemToDiscount.subtotalVat == 0)? discountIncVat: roundToTwoDecimalPlaces(discountIncVat / EX_TO_INC_VAT_MULTIPLIER);
      itemToDiscount.totalExVat = roundToTwoDecimalPlaces(Math.max(0, itemToDiscount.totalExVat - discountExVat));
      // If it is VATable recalculate the VAT
      if (itemToDiscount.subtotalVat > 0) {
        itemToDiscount.totalVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * itemToDiscount.totalExVat);
      }
      // Add up the including VAT discount as the voucher amount includes VAT
      discountApplied += 
        roundToTwoDecimalPlaces((itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) - (itemToDiscount.totalExVat + itemToDiscount.totalVat));
    }
    // If we have a remainder due to capping the discount at the line value take pennies/cents of eligible 
    // lines until we get to the appropriate applied discount.
    while (discountApplied < cartDiscount) {
      let eligibleLineFound: boolean = false;
      for (let itemToDiscount of itemsToDiscount) {
        // Cannot substract any value from this line
        if (itemToDiscount.totalExVat < 0.01) {
          continue;
        }
        eligibleLineFound = true;
        const discountBefore: number = 
          roundToTwoDecimalPlaces((itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) - (itemToDiscount.totalExVat + itemToDiscount.totalVat));
        itemToDiscount.totalExVat -= 0.01;
        // If it is VATable recalculate the VAT
        if (itemToDiscount.subtotalVat > 0) {
          itemToDiscount.totalVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * itemToDiscount.totalExVat);
        }
        const discountAfter: number = 
          roundToTwoDecimalPlaces((itemToDiscount.subtotalExVat + itemToDiscount.subtotalVat) - (itemToDiscount.totalExVat + itemToDiscount.totalVat));
        discountApplied += (discountAfter - discountBefore);
        if (discountApplied >= cartDiscount) {
          break;
        }
      }
      // Avoid getting stuck in loop if there are no more lines we can discount
      if (!eligibleLineFound) {
        break;
      }
    }
    // Add the discounted lines into the total ane line_items
    for (let itemToDiscount of itemsToDiscount) {
      this.TTorder += roundToTwoDecimalPlaces(itemToDiscount.totalExVat + itemToDiscount.totalVat);
      line_items.push({
        'product_id': !!itemToDiscount.variation_id? itemToDiscount.variation_id: itemToDiscount.product_id,
        'quantity': 1,
        'subtotal': roundToTwoDecimalPlaces(itemToDiscount.subtotalExVat),
        'total': roundToTwoDecimalPlaces(itemToDiscount.totalExVat),
      });
    }

    this.woodata.line_items = line_items;
  }

  applyProductFixedDiscount(calculationItems: ItemForCouponCalulations[]) {
    // Reset the order total as need to calculate with discount applied
    this.TTorder = 0;
    let appliedCount: number = 0;
    const line_items: WooItem[] = [];
    const itemDiscount: number = parseFloat(this.coupon.amount);
    for (let calculationItem of calculationItems) {
      let applyDiscount: boolean = false;
      if (!!this.coupon.limit_usage_to_x_items && (this.coupon.limit_usage_to_x_items <= appliedCount)) {
        applyDiscount = false;
      } else {
        applyDiscount = this.couponValidForProduct(calculationItem); 
      }
      if (applyDiscount) {
        // Apply discount, but do not let line value go negative
        calculationItem.totalExVat = roundToTwoDecimalPlaces(Math.max(0, calculationItem.totalExVat - itemDiscount));
        // If it is VATable recalculate the VAT
        if (calculationItem.subtotalVat > 0) {
          calculationItem.totalVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * calculationItem.totalExVat);
        }
      }
      this.TTorder += roundToTwoDecimalPlaces(calculationItem.totalExVat + calculationItem.totalVat);
      line_items.push({
        'product_id': !!calculationItem.variation_id? calculationItem.variation_id: calculationItem.product_id,
        'quantity': 1,
        'subtotal': roundToTwoDecimalPlaces(calculationItem.subtotalExVat),
        'total': roundToTwoDecimalPlaces(calculationItem.totalExVat),
      });
    }
    this.woodata.line_items = line_items;
  }

  onOptionsSelected() {
    console.log(this.selectedProduct);
    if (!this.selectedProduct) {
      this.selectedVariation = null;
      this.variations = [];
      return;
    }
    this.variations = this.selectedProduct.variations
      .filter((variation: RawVariation) => {
        if (variation.vat != this.vatSelected) {
          return false;
        }
        if (this.orderRenewalAfter && variation.renewalAfterDate) {
          return variation.renewalAfterDate == this.orderRenewalAfter;
        }
        return true;
      })
      .map((variation: RawVariation) => {
        const variationWithTitle: VariationWithTitle = Object.assign({}, variation);
        let tmpAttributes: VariationAttribute[] = variationWithTitle.attributes.filter((attribute: VariationAttribute) => 
          !/initial term/i.test(attribute.name)
        );
        if (tmpAttributes.length > 1) {
          tmpAttributes = tmpAttributes.filter((attribute: VariationAttribute) => 
            !/\bVAT\b/i.test(attribute.name)
          );
        }
        variationWithTitle.title = tmpAttributes.map((attribute: VariationAttribute) => 
          `${attribute.name}: ${attribute.option}`
        ).join(' / ');
        return variationWithTitle;
      });
    if (this.variations.length == 1) {
      this.selectedVariation = this.variations[0];
    } else {
      this.selectedVariation = null;
    }
  }

  needsWearingOption(item: BasketItem) {
    // If the product or variation's plan code includes F it is a fall detector
    // However, if they also includes 1T or 1B it is a GPS unit, so we don't need the question
    if (this.itemPlanContainsCode(item, 'F') && !this.itemPlanContainsCode(item, '1B') &&
        !this.itemPlanContainsCode(item, '1T')) {
      return true;
    }
    return false;
  }

  itemPlanContainsCode(item: BasketItem, code: string): boolean {
    return (item.planOrEquipment && item.planOrEquipment.includes(code))
      || (item.selectedVariation && item.selectedVariation.symbol && item.selectedVariation.symbol.includes(code));
  }

  manuallyCompleteStep(event: Event) {
    this.processingSteps[this.currentStep].completed = (event.target as HTMLInputElement).checked;
  }

  get isPlaceOrderDisabled(): boolean {
    const tmpItems: BasketItem[] = this.items.filter((item: BasketItem) => 
      item.quantity && (item.quantity > 0)
    );
    if (tmpItems.length == 0) {
      return true;
    }
    if (!this.orderDetails.paymentMethod || (this.orderDetails.paymentMethod.trim() == '')) {
      return true;
    }
    if ((this.orderDetails.paymentMethod == 'Secured Debit/Credit Card') &&
        (!this.nameOnCard || (this.nameOnCard.trim() == ''))) {
      return true;
    }
    if (isNaN(this.TTorder) || (this.TTorder < 0)) {
      return true;
    }
    if (this.overrideTotal) {
      if (!this.baseOrderInformation.cseOrder.overrideReason ||
          ((this.baseOrderInformation.cseOrder.overrideReason == 'Other') && !this.baseOrderInformation.cseOrder.overrideReasonOther)) {
        return true;
      }
    }
    return false;
  }

  changeHowHeard(): void {
    if (this.baseOrderInformation.cseOrder.howHeard != 'Partnership') {
      this.selectedPartnership = undefined;
    }
  }

  changePartnership(): void {
    if (this.couponCode || !this.selectedPartnership) {
      return;
    }
    const partnerCouponCode: string = this.selectedPartnership.bgcCouponCode;
    if (partnerCouponCode) {
      this.confirmationService.confirm({
        'key': 'general',
        'header': 'Partnership Coupon',
        'message': 
          `${this.selectedPartnership.bgcName} has a coupon code ${partnerCouponCode} configured. Would you like to apply it now?`,
        'rejectVisible': true,
        'acceptLabel': 'Yes',
        'rejectLabel': 'No',
        'icon': 'pi pi-question-circle',
        'accept': () => {
          this.couponCode = partnerCouponCode;
          this.searchCoupon();
        },
        'reject': () => {}
      });
    }
  }

  /**
   * Apply the rules the CRM uses to decide whether it will import the order
   * to determine if those steps are required
   */
  updateImportIntoCrmProcessingSteps(): void {
    // rule that only applies up until "One Off Purchase" status is added
    const hasPullinOrderItems: boolean = this.items.some((item: BasketItem) => 
      (item.pullinOrders == 'yes')
    );
    // isExistingCustomer is the rule that is staying even after "One Off Purchase" is added
    if (!this.isExistingCustomer() && hasPullinOrderItems) {
      this.processingSteps['Importing into CRM'].required = true;
      this.processingSteps['Updating CRM order ref, processor and alarm user'].required = true;
      this.processingSteps[this.ORDER_STATUS_UPDATE_STEP].allowToBeSkipped = false;
    } else {
      this.processingSteps['Importing into CRM'].required = false;
      this.processingSteps['Updating CRM order ref, processor and alarm user'].required = false;
      this.processingSteps[this.ORDER_STATUS_UPDATE_STEP].allowToBeSkipped = true;
    }
  }

  createOrder() {
    this.showPlaceOrder = false;
    // Make sure payment steps are set to not required
    this.processingSteps['Creating Payment Method on Stripe'].required = false;
    this.processingSteps['Creating Payment Intent on Stripe'].required = false;
    // Set Whether the Direct Debit Form is required
    this.processingSteps['Creating Direct Debit Form'].required = (this.orderDetails.renewalMethod == 'directDebit');
    // Set whether steps for importing into the CRM will be required
    this.updateImportIntoCrmProcessingSteps();

    const stepName: string = 'Validating Order';
    if (!this.processingSteps[stepName].completed) {
      this.currentStep = stepName;
      if (this.validationErrorsExist()) {
        this.currentStep = '';
        this.showPlaceOrder = true;
        return;
      }
      this.processingSteps[stepName].completed = true;
    }
    this.captureOrderDetailsForReporting();
  }

  createCardPaidOrder() {
    this.showPlaceOrder = false;
    // Make sure payment steps are set to required
    this.processingSteps['Creating Payment Method on Stripe'].required = true;
    this.processingSteps['Creating Payment Intent on Stripe'].required = true;
    // Set Whether the Direct Debit Form is required
    this.processingSteps['Creating Direct Debit Form'].required = (this.orderDetails.renewalMethod == 'directDebit');
    // Set whether steps for importing into the CRM will be required
    this.updateImportIntoCrmProcessingSteps();

    const stepName: string = 'Validating Order';
    if (!this.processingSteps[stepName].completed) {
      this.currentStep = stepName;
      if (this.validationErrorsExist()) {
        this.currentStep = '';
        this.showPlaceOrder = true;
        return;
      }
      this.processingSteps[this.currentStep].completed = true;
    }
    this.createPaymentMethod();
  }

  prepareWooData() {
    if (this.alarmUserNameOption == 'billing') {
      this.orderDetails.alarmUserFirstName = this.infoBilling.firstName;
      this.orderDetails.alarmUserLastName = this.infoBilling.lastName;
    } else if (this.alarmUserNameOption == 'delivery') {
      this.orderDetails.alarmUserFirstName = this.infoDeliver.firstName;
      this.orderDetails.alarmUserLastName = this.infoDeliver.lastName;
    }
    if (this.alarmUserPhoneOption == 'billing') {
      this.orderDetails.alarmUserPhone = this.infoBilling.mobile;
    }

    this.woodata.billing_address.first_name = this.infoBilling.firstName;
    this.woodata.billing_address.last_name = this.infoBilling.lastName;
    this.woodata.billing_address.address_1 = this.infoBilling.userAddress.addressOne;
    this.woodata.billing_address.address_2 = this.infoBilling.userAddress.addressTwo;
    this.woodata.billing_address.city = this.infoBilling.userAddress.city;
    this.woodata.billing_address.state = this.infoBilling.userAddress.county;
    this.woodata.billing_address.postcode = this.infoBilling.userAddress.postcode;
    this.woodata.billing_address.email = this.infoBilling.email;
    this.woodata.billing_address.phone = this.infoBilling.mobile;
    if (!this.differentDeliver) {
      this.infoDeliver = this.infoBilling;
    }

    this.woodata.shipping_address.first_name = this.infoDeliver.firstName;
    this.woodata.shipping_address.last_name = this.infoDeliver.lastName;
    this.woodata.shipping_address.address_1 = this.infoDeliver.userAddress.addressOne;
    this.woodata.shipping_address.address_2 = this.infoDeliver.userAddress.addressTwo;
    this.woodata.shipping_address.city = this.infoDeliver.userAddress.city;
    this.woodata.shipping_address.state = this.infoDeliver.userAddress.county;
    this.woodata.shipping_address.postcode = this.infoDeliver.userAddress.postcode;
    this.woodata.shipping_address.email = this.infoDeliver.email;
    this.woodata.shipping_address.phone = this.infoDeliver.mobile;
    this.woodata.note = '';
    if (this.orderDetails.notes) {
      this.woodata.note = `${this.orderDetails.notes}\n`;
    }
    if (this.selectedOrderType.title == 'Phone Order') {
      if (this.baseOrderInformation.withheldNumber) {
        this.baseOrderInformation.cseOrder.calledFrom = 'Withheld Number';
      } 
    } else {
      this.baseOrderInformation.cseOrder.calledFrom = '';
    }

    let pendantCount: number = 0;
    for (const item of this.items) {
      if (this.needsWearingOption(item)) {
        pendantCount++;
        if (this.needsWearingOption(item) && item.wearingOption) {
          this.woodata.note += `Pendant ${pendantCount} Wearing Option: ${item.wearingOption}\n`;
        }
      }
    }
    if (this.overrideTotal) {
      if (this.baseOrderInformation.cseOrder.overrideReason == 'Other') {
        this.woodata.note +=
          `Price was overridden reason: ${this.baseOrderInformation.cseOrder.overrideReason}\n ${this.baseOrderInformation.cseOrder.overrideReasonOther}\n`;
      } else {
        this.woodata.note += `Price was overridden reason: ${this.baseOrderInformation.cseOrder.overrideReason}\n`;
      }
    }
   
    if (this.order) {
      this.woodata.note += `Existing Order TD Code: ${this.order.alarmUserDetails.tdCode}\nCompany: ${this.order.legalCompany}`;
    }
    if (this.isExistingCustomer()) {
      if (this.isAdditional()) {
        this.woodata.note += 'Additional Equipment, not replacement\n';
      } else {
        this.woodata.note += `Reason for Replacement: ${this.selectedOrderType.title}\n`;
      }
    }
    if (this.isReplacementDueToFault()) {
      this.woodata.note += `Fault: ${this.baseOrderInformation.cseOrder.fault} ${this.baseOrderInformation.cseOrder.otherFault}\n`;
    }

    this.woodata.note += `Payment Method: ${this.orderDetails.paymentMethod}`;
    if (this.coupon && this.coupon.code) {
      this.woodata.coupon_lines = [{
        'id': this.coupon.id,
        'code': this.coupon.code,
        'amount': this.couponDiscount,
      }];
    }

    //LL custom fields
    if (this.selectedWebsite.title == 'LL') {
      // This ends up in product_meta, trying to put it in product_meta it does not get saved
      this.woodata.order_meta = {
        'ncf_required': this.orderDetails.NCFRequired,
        'renewal_method': this.orderDetails.renewalMethod,
        'renewal_order': 'no',
        'calender_ideal_delivery_date': '',
        'is_vat_exempt': (this.orderVatStatus == OrderVatStatus.VAT_EXEMPT)? 'yes': 'no',
      };

      if ('directDebit' == this.orderDetails.renewalMethod) {
        this.woodata.order_meta.account_holder_name = this.directDebit.accountHolderName;
        this.woodata.order_meta.dd_address_line_one = this.directDebit.addressLineOne;
        this.woodata.order_meta.dd_address_line_two = this.directDebit.addressLineTwo
        this.woodata.order_meta.dd_city = this.directDebit.city;
        this.woodata.order_meta.dd_postcode = this.directDebit.postcode;
        this.woodata.order_meta.sort_code = this.directDebit.sortCode;
        this.woodata.order_meta.account_number = this.directDebit.accountNumber;
      }
    }

    //CL custom fields
    if (this.selectedWebsite.title == 'CL') {
      // This ends up in product_meta, trying to put it in product_meta it does not get saved
      this.woodata.order_meta = {
        'additional_field_116': this.orderDetails.NCFRequired,
        'additional_field_197': this.orderDetails.renewalMethod,
        'additional_field_83': this.selectedOrderType.title,
      };
      if ('directDebit' == this.orderDetails.renewalMethod) {
        this.woodata.order_meta.additional_field_812 = this.directDebit.accountHolderName;
        this.woodata.order_meta.additional_field_330 = this.directDebit.addressLineOne;
        this.woodata.order_meta.additional_field_893 = this.directDebit.sortCode;
        this.woodata.order_meta.additional_field_911 = this.directDebit.accountNumber;
      }
    }

    //TC custom fields
    if (this.selectedWebsite.title == 'TC') {
      // This ends up in product_meta, trying to put it in product_meta it does not get saved
      this.woodata.order_meta = {
        'additional_field_373': `${this.orderDetails.alarmUserFirstName} ${this.orderDetails.alarmUserLastName}`,
        'additional_field_307': this.selectedOrderType.title,
        'additional_field_231': this.orderDetails.renewalMethod,
        'additional_field_72': this.orderDetails.NCFRequired,
        'additional_field_309': '',
      };
      if ('directDebit' == this.orderDetails.renewalMethod) {
        this.woodata.order_meta.additional_field_480 = this.directDebit.sortCode;
        this.woodata.order_meta.additional_field_545 = this.directDebit.accountNumber;
        this.woodata.order_meta.additional_field_244 = this.directDebit.accountHolderName;
        this.woodata.order_meta.additional_field_674 = this.directDebit.addressLineOne;
        this.woodata.order_meta.additional_field_151 = this.directDebit.addressLineTwo;
        this.woodata.order_meta.additional_field_78 = this.directDebit.city;
        this.woodata.order_meta.additional_field_696 = this.directDebit.postcode;
      }
    }

    //CK custom fields
    if (this.selectedWebsite.title == 'CK') {
      // This ends up in product_meta, trying to put it in product_meta it does not get saved
      this.woodata.order_meta = {
        'additional_field_234': `${this.orderDetails.alarmUserFirstName} ${this.orderDetails.alarmUserLastName}`,
        'additional_field_89': this.selectedOrderType.title,
        'additional_field_68': this.orderDetails.renewalMethod,
        'additional_field_10': this.orderDetails.NCFRequired,
        'additional_field_967': '',
      };
      if ('directDebit' == this.orderDetails.renewalMethod) {
        this.woodata.order_meta.additional_field_352 = this.directDebit.sortCode;
        this.woodata.order_meta.additional_field_679 = this.directDebit.accountNumber;
        this.woodata.order_meta.additional_field_513 = this.directDebit.accountHolderName;
        this.woodata.order_meta.additional_field_957 = this.directDebit.addressLineOne;
        this.woodata.order_meta.additional_field_901 = this.directDebit.addressLineTwo;
        this.woodata.order_meta.additional_field_546 = this.directDebit.city;
        this.woodata.order_meta.additional_field_768 = this.directDebit.postcode;
      }
    }

    //LLIE custom fields
    if (this.selectedWebsite.title == 'LLIE') {
      // This ends up in product_meta, trying to put it in product_meta it does not get saved
      this.woodata.order_meta = {
        'additional_field_3': `${this.orderDetails.alarmUserFirstName} ${this.orderDetails.alarmUserLastName}`,
        'additional_field_374': this.selectedOrderType.title,
        'additional_field_591': this.orderDetails.renewalMethod,
        // 'additional_field_10': this.orderDetails.NCFRequired,
        'additional_field_481': '',
      };
    }

    //Common fields 
    this.woodata.order_meta.store_clerk = this.userName;
    this.woodata.order_meta.how_heard = this.baseOrderInformation.cseOrder.howHeard;
    this.woodata.order_meta.how_heard_partnership = this.selectedPartnership? this.selectedPartnership.bgcName: '';
    this.woodata.order_meta.alarm_users_name = `${this.orderDetails.alarmUserFirstName} ${this.orderDetails.alarmUserLastName}`;
    this.woodata.order_meta.alarm_users_phone_number = this.orderDetails.alarmUserPhone;
    this.woodata.order_meta.alarm_users_mobile_number = this.orderDetails.alarmUserMobile;
    this.woodata.order_meta.customer_called_from = this.baseOrderInformation.cseOrder.calledFrom;
    this.woodata.order_meta.order_type = this.selectedOrderType.title;
    if (this.order) {
      this.woodata.order_meta.td_code = this.order.alarmUserDetails.tdCode;
    }
    if (this.baseOrderInformation.cseOrderNavigationData) {
      this.woodata.order_meta.leadId = this.baseOrderInformation.cseOrderNavigationData.leadId;
    }
    this.woodata.order_meta.email_marketing = this.emailMarketing
    this.woodata.order_meta.phone_marketing = this.phoneMarketing;
    if (!this.baseOrderInformation.hasBulkTag) {
      if (this.baseOrderInformation.proRataMultiplier && (this.baseOrderInformation.proRataMultiplier > 0)) {
        if (this.baseOrderInformation.cseOrder.proRataCurrentPrice && (this.baseOrderInformation.cseOrder.proRataCurrentPrice > 0) &&
            (this.selectedOrderType.calculationMethod == CalculationMethod.DIFFERENCE)) {
          this.woodata.order_meta.pro_rata_current_price = this.baseOrderInformation.cseOrder.proRataCurrentPrice.toFixed(2);
        }
        this.woodata.order_meta.pro_rata_multiplier = this.baseOrderInformation.proRataMultiplier.toFixed(6);
        this.woodata.order_meta.pro_rata_to_date = this.baseOrderInformation.cseOrder.proRataToDate;
      }
    }
    if (this.orderDetails.paymentMethod == 'Secured Debit/Credit Card') {
      this.woodata.order_meta.name_on_card = this.nameOnCard;
    }
    this.woodata.order_meta.total_before_changes = this.totalBeforeDiscountAndOverride;
    if (this.overrideTotal) {
      if (this.baseOrderInformation.cseOrder.overrideReason == 'Other') {
        this.woodata.order_meta.override_reason =
          `${this.baseOrderInformation.cseOrder.overrideReason} ${this.baseOrderInformation.cseOrder.overrideReasonOther}`;
      } else {
        this.woodata.order_meta.override_reason = this.baseOrderInformation.cseOrder.overrideReason;
      }
    }
    console.log('WooData to be sent to website', this.woodata);
  }

  validationErrorsExist(): boolean {
    this.validationErrors = [];
    this.changeDetector.detectChanges();
    if (this.items.length === 0) {
      this.validationErrors.push('No Product was added to the basket');
    } else {
      const hasZeroQtyItems: boolean = this.items.some((item: BasketItem) => 
        !item.quantity || (item.quantity <= 0)
      );
      if (hasZeroQtyItems) {
        this.validationErrors.push('Products must have a quantity of 1 or more. Please remove any items not required.');
      }
    }
    if (this.infoBilling.firstName.trim() === '' || this.infoBilling.lastName.trim() === '' || this.infoBilling.mobile.trim() === '' ||
        this.infoBilling.userAddress.addressOne.trim() === '' || this.infoBilling.userAddress.city.trim() === '' ||
        this.infoBilling.userAddress.postcode.trim() === '') {
      this.validationErrors.push('Missing required Billing fields');
    }
    if ((this.infoBilling.email == null) || (this.infoBilling.email == undefined) || (this.infoBilling.email.trim() == '')) {
      this.woodata.generatedEmail = true;
      let emailStart: string = this.infoBilling.firstName+'.'+this.infoBilling.lastName+'.'+ Math.floor(Math.random() * 10000);
      emailStart = emailStart.replace(/[^A-Za-z0-9\.]/g, '_').replace(/\.\.+/g, '.');
      this.infoBilling.email = `${emailStart}@${this.emailSuffix}`;
    }
    this.infoBilling.email = this.infoBilling.email.trim();
    if ((!!this.infoBilling.email) && !isValidEmailAddress(this.infoBilling.email)) {
      this.validationErrors.push('Billing Email is invalid');
    }
    this.validateDifferentDelivery();
    if (!this.selectedOrderType) {
      this.validationErrors.push('Missing required Order Type');
    }
    if ((this.selectedOrderType.title == 'Phone Order') && !this.baseOrderInformation.withheldNumber &&
        ((this.baseOrderInformation.cseOrder.calledFrom == '') ||
          !isValidAnyCountryPhoneNumber(this.baseOrderInformation.cseOrder.calledFrom))) {
      this.validationErrors.push('Missing or Invalid required Customer Called From'); 
    }
    if (!this.orderDetails.paymentMethod || (this.orderDetails.paymentMethod.trim() === '')) {
      this.validationErrors.push('Missing required Payment Method');
    }
    if (this.isExistingCustomer()) {
      if (!this.order && !this.baseOrderInformation.allowReplacementWithoutTd) {
        this.validationErrors.push(`You must enter an existing order's TD code or tick "No existing CRM Account" for ${this.baseOrderInformation.orderType.title} orders`);
      }
      if (this.lifetimeLineNeedsOverride) {
        this.validationErrors.push('You must override the price for all rental items on Lifetime orders as the CRM cannot calculate the values');
      }
      if (this.hasRentalItems && this.baseOrderInformation.allowReplacementWithoutTd) {
        this.validationErrors.push(`You cannot add rental items unless you have provided a TD Code for ${this.baseOrderInformation.orderType.title} orders`);
      }
      if (this.isReplacementDueToFault() && (!this.baseOrderInformation.cseOrder.fault ||
          ((this.baseOrderInformation.cseOrder.fault == 'Other') && !this.baseOrderInformation.cseOrder.otherFault))) {
        this.validationErrors.push('You must enter details of the fault.');
      }
    } else {
      if (!this.isCouponValid()) {
        this.validationErrors.push('Coupon is invalid - please remove it');
      }
      this.validateAlarmUserDetails();
      if (!this.orderDetails.renewalMethod || (this.orderDetails.renewalMethod.trim() === '')) {
        this.validationErrors.push('Missing required Renewal Method field');
      }
      this.validateDirectDebitForm();
      if (!this.orderDetails.NCFRequired || (this.orderDetails.NCFRequired.trim() === '')) {
        this.validationErrors.push('Missing required Paper NCF field');
      }
    }
    if (this.calculationError) {
      this.validationErrors.push(`Error calculating basket value: ${this.calculationError}`);
    }
    if (this.validationErrors.length > 0) {
      console.error('Validation errors found', this.validationErrors);
      return true;
    }
    this.prepareWooData();
    return false;
  }

  validateDifferentDelivery() {
    if (!this.differentDeliver) {
      return;
    }
    if (this.infoDeliver.firstName.trim() === '' || this.infoDeliver.lastName.trim() === '' ||
        this.infoDeliver.userAddress.addressOne.trim() === '' || this.infoDeliver.userAddress.city.trim() === '' ||
        this.infoDeliver.userAddress.postcode.trim() === '') {
      this.validationErrors.push('Missing required Delivery fields');
    }
  }

  validateAlarmUserDetails() {
    if (!this.alarmUserNameOption) {
      this.validationErrors.push("You must select an option for the Alarm User's name");
    } else if ((this.alarmUserNameOption == 'other') &&
        (!this.orderDetails.alarmUserLastName || (this.orderDetails.alarmUserLastName.trim() === '') ||
        !this.orderDetails.alarmUserFirstName || (this.orderDetails.alarmUserFirstName.trim() === ''))) {
      this.validationErrors.push("Missing required Alarm User's Name fields");
    }
    if (!this.alarmUserPhoneOption) {
      this.validationErrors.push("You must select an option for the Alarm User's phone number");
    } else if ((this.alarmUserPhoneOption == 'other') &&
        (!this.orderDetails.alarmUserPhone || (this.orderDetails.alarmUserPhone.trim() === '')) && 
        (!this.orderDetails.alarmUserMobile || (this.orderDetails.alarmUserMobile.trim() === ''))) {
      this.validationErrors.push("Missing required Alarm User's Phone number or Mobile");
    }
    if (!this.alarmUserAddressOption) {
      this.validationErrors.push("You must select an option for the Alarm User's address");
    } else if ((this.alarmUserAddressOption == 'other') &&
        ((!this.alarmUserAddress.addressOne || this.alarmUserAddress.addressOne.trim() === '') || (!this.alarmUserAddress.city || this.alarmUserAddress.city.trim() === '') ||
        (!this.alarmUserAddress.postcode || this.alarmUserAddress.postcode.trim() === ''))) {
      this.validationErrors.push("Missing required Alarm User's Address fields");
    }
  }

  validateDirectDebitForm() {
    if (this.orderDetails.renewalMethod != 'directDebit') {
      return;
    }
    if (!this.directDebit.sortCode || !this.directDebit.sortCode.trim()) {
      this.validationErrors.push('Missing Sort Code for Direct Debit');
    } else if (!SORT_REGEX.test(this.directDebit.sortCode)) {
      this.validationErrors.push('Invalid Sort Code for Direct Debit. It must be 6 digits exactly.');
    }
    if (!this.directDebit.accountNumber || !this.directDebit.accountNumber.trim()) {
      this.validationErrors.push('Missing Account Number for Direct Debit');
    } else if (!BANK_ACCOUNT_REGEX.test(this.directDebit.accountNumber)) {
      this.validationErrors.push('Invalid Account Number for Direct Debit. It must be 8 digits exactly.');
    }
    if (!this.directDebit.accountHolderName || !this.directDebit.accountHolderName.trim()) {
      this.validationErrors.push('Missing Account Holder Name for Direct Debit');
    }
    if (!this.directDebit.addressLineOne || !this.directDebit.addressLineOne.trim()) {
      this.validationErrors.push('Missing Address Line One for Direct Debit');
    }
    if (!this.directDebit.city || !this.directDebit.city.trim()) {
      this.validationErrors.push('Missing City for Direct Debit');
    }
    if (!this.directDebit.postcode || !this.directDebit.postcode.trim()) {
      this.validationErrors.push('Missing Post Code for Direct Debit');
    }
  }

  get validationErrorExists(): boolean {
    return this.validationErrors.length > 0;
  }

  async createPaymentMethod() {
    this.showPlaceOrder = false;
    this.showProgressBar = true;
    this.processingError = null;
    /*
      Even if 'Creating Payment Method on Stripe' completed we need to redo it to get the paymentMethod id for 
      'Creating Payment Intent on Stripe' if that step has not been completed
    */
    if (this.processingSteps['Creating Payment Intent on Stripe'].completed) {
      this.captureOrderDetailsForReporting();
    } else {
      this.currentStep = 'Creating Payment Method on Stripe';
      try {
        const result: PaymentMethodResult = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.cardElement,
        });
        if (!!result.error) {
          this.processingError = result.error;
          this.showProgressBar = false;
        } else {
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = result.paymentMethod.id;
          this.createPaymentIntent();
        }
      } catch (error) {
        this.processingError = {'message': error.message};
        console.error('Payment processing error', this.processingError);
        this.showProgressBar = false;
      }
    }
  }

  createPaymentIntent() {
    const custName: string = `${this.infoBilling.firstName} ${this.infoBilling.lastName}`;
    let paymentDescription: string = '';
    let customerDescription: string = custName;
    const metadata: StringIndexedObject<string> = {};
    if (this.order) {
      paymentDescription = `${this.order.alarmUserDetails.tdCode} existing customer order`;
      customerDescription = `${this.order.alarmUserDetails.tdCode} ${custName}`;
      metadata.tdCode = this.order.alarmUserDetails.tdCode;
    } else {
      paymentDescription = `${custName} - CSE Order`;
    }
    this.currentStep = 'Creating Payment Intent on Stripe';
    this.orderService.createOrderPaymentIntent({
      stripeBrand: this.stripeBrand,
      amount: this.TTorder.toFixed(2),
      currency: this.currencyCode.toLocaleLowerCase(),
      description: paymentDescription,
      customer_name: this.nameOnCard,
      billing_details: {
        address: {
          line1: this.infoBilling.userAddress.addressOne,
          line2: this.infoBilling.userAddress.addressTwo,
          city: this.infoBilling.userAddress.city,
          postal_code: this.infoBilling.userAddress.postcode,
        },
        email: this.infoBilling.email,
        name: custName,
        phone: this.infoBilling.mobile,
        description: customerDescription,
        metadata: metadata,
      },
      paymentMethod: this.processingSteps['Creating Payment Method on Stripe'].resultData
    }).subscribe({
      next: (rsp: SingleRecordResponse<ServerSideStripe.PaymentIntent>) => {
        console.log(rsp);
        if (!rsp.success) {
          const error: StripeError & {raw: any} = rsp.error;
          this.processingError = {'message': rsp.message, 'code': error.code};
          // error.type is StripeCardError typing says it should be card_error, which is what the type on the raw has
          this.processingSteps[this.currentStep].allowToBeSkipped = (error.raw && (error.raw.type != 'card_error'));
          this.showProgressBar = false;
          return;
        } else if (rsp.data.status != 'succeeded') {
          this.processingError = {'message': 'Payment Intent created, but not at "succeeded" status'};
          this.processingSteps[this.currentStep].allowToBeSkipped = false;
          this.showProgressBar = false;
        } else {
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = rsp.data.id;
          this.captureOrderDetailsForReporting();
        }
      },
      error: (err: any) => {
        console.error('Error creating payment intent on stripe', err);
        this.processingError = {'message': err.message};
        console.error('Something is wrong: V2', this.processingError);
        this.showProgressBar = false;
      }
    });
  }

  captureOrderDetailsForReporting() {
    this.showProgressBar = true;
    this.showPlaceOrder = false;
    this.processingError = null;
    const stepName: string = 'Capturing Details for Reporting';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      this.captureDdInformation();
    } else {
      this.currentStep = stepName;
      this.baseOrderInformation.cseOrder.orderType = this.selectedOrderType.title;
      this.baseOrderInformation.cseOrder.originalOrderTotal = this.totalBeforeDiscountAndOverride;
      this.baseOrderInformation.cseOrder.finalOrderTotal = this.TTorder;
      this.baseOrderInformation.cseOrder.finalOrderVat = this.vat;
      this.baseOrderInformation.cseOrder.couponCode = (this.coupon)? this.coupon.code: '';
      this.baseOrderInformation.cseOrder.couponAmount = this.couponDiscount;
      this.baseOrderInformation.cseOrder.partnership = this.selectedPartnership? this.selectedPartnership.bgcName: '',
      this.baseOrderInformation.cseOrder.billingPostcode = this.infoBilling.userAddress.postcode;
      this.baseOrderInformation.cseOrder.leadId =
        this.baseOrderInformation.cseOrderNavigationData? this.baseOrderInformation.cseOrderNavigationData.leadId: null;
      this.baseOrderInformation.cseOrder.paymentMethod = this.orderDetails.paymentMethod;
      if (!this.baseOrderInformation.proRataMultiplier || (this.baseOrderInformation.proRataMultiplier <= 0)) {
        if (!this.baseOrderInformation.cseOrder.proRataCurrentPrice || (this.baseOrderInformation.cseOrder.proRataCurrentPrice <= 0) ||
            (this.selectedOrderType.calculationMethod != CalculationMethod.DIFFERENCE)) {
              this.baseOrderInformation.cseOrder.proRataCurrentPrice = 0;
        }
        this.baseOrderInformation.cseOrder.proRataMultiplier = '';
        this.baseOrderInformation.cseOrder.proRataToDate = '';
      } else {
        this.baseOrderInformation.cseOrder.proRataMultiplier = this.baseOrderInformation.proRataMultiplier.toFixed(6);
      }
      if (this.alarmUserAddressOption == 'other') {
        this.baseOrderInformation.cseOrder.alarmUserPostcode = this.alarmUserAddress.postcode;
      } else if (this.alarmUserAddressOption == 'delivery') {
        this.baseOrderInformation.cseOrder.alarmUserPostcode = this.infoDeliver.userAddress.postcode;
      } else if (this.alarmUserAddressOption == 'billing') {
        this.baseOrderInformation.cseOrder.alarmUserPostcode = this.infoBilling.userAddress.postcode;
      }
      this.items.forEach((item: BasketItem, itemIdx: number) => {
        let originalPrice: number = item.selectedVariation? item.selectedVariation.original_price: item.original_price;
        let finalPrice: number = item.selectedVariation? item.selectedVariation.regular_price: item.regularPrice;
        const notExempt: boolean = item.selectedVariation? (item.selectedVariation.vat == 'not exempt'): (item.vat == 'not exempt');
        originalPrice = originalPrice * item.quantity;
        finalPrice = finalPrice * item.quantity;
        let finalVat: number = 0;
        if (notExempt) {
          finalVat = roundToTwoDecimalPlaces(VAT_MULTIPLIER * finalPrice / EX_TO_INC_VAT_MULTIPLIER);
        }
        if ((item.hardware.length == 0) && (item.hardwareSets.length == 0)) {
          this.baseOrderInformation.cseOrder.cseOrderItems.push({
            'cseOrderLine': itemIdx + 1,
            'cseOrderLinePart': 1,
            'cseOrderTitle': item.crmTitle,
            'originalPrice': originalPrice,
            'finalPrice': finalPrice,
            'finalVat': finalVat,
            'quantity': item.quantity,
            'productId': item._id,
            'variationId': item.selectedVariation? item.selectedVariation._id: 0,
            'hardwareId': null,
          });
        } else {
          let priceAssigned: boolean = false;
          let partId: number = 1;
          item.hardware.forEach((hardwareId: string) => {
            const hardware: Hardware = this.hardware.find((hw: Hardware) =>
              hw._id == hardwareId
            );
            if (hardware) {
              this.baseOrderInformation.cseOrder.cseOrderItems.push({
                'cseOrderLine': itemIdx + 1,
                'cseOrderLinePart': partId,
                'cseOrderTitle': hardware.title,
                'originalPrice': !priceAssigned? originalPrice: 0,
                'finalPrice': !priceAssigned? finalPrice: 0,
                'finalVat': !priceAssigned? finalVat: 0,
                'quantity': item.quantity,
                'productId': item._id,
                'variationId': item.selectedVariation? item.selectedVariation._id: 0,
                'hardwareId': hardwareId,
              });
              priceAssigned = true;
              partId++;
            }
          });
          item.hardwareSets.forEach((hardwareSetId: string) => {
            const hardwareSet: HardwareSet = this.hardwareSets.find((hwSet: HardwareSet) =>
              hwSet._id == hardwareSetId
            );
            if (hardwareSet) {
              hardwareSet.containedHardware.forEach((hardwareId: HardwareId) => {
                this.baseOrderInformation.cseOrder.cseOrderItems.push({
                  'cseOrderLine': itemIdx + 1,
                  'cseOrderLinePart': partId,
                  'cseOrderTitle': hardwareId.title,
                  'originalPrice': !priceAssigned? originalPrice: 0,
                  'finalPrice': !priceAssigned? finalPrice: 0,
                  'finalVat': !priceAssigned? finalVat: 0,
                  'quantity': item.quantity,
                  'productId': item._id,
                  'variationId': item.selectedVariation? item.selectedVariation._id: 0,
                  'hardwareId': hardwareId._id,
                });
                priceAssigned = true;
                partId++;
              });
            }
          });
        }
  
      });
      this.orderService.createRecordOfCseOrder({cseOrder: this.baseOrderInformation.cseOrder}).subscribe({
        next: (cseOrderResponse: SingleRecordResponse<CseOrder>) => {
          if (!cseOrderResponse.success) {
            console.error('Error recording CSE order. Error:', cseOrderResponse.message);
            this.processingError = !!cseOrderResponse.error? cseOrderResponse.error: {'message': cseOrderResponse.message};
            this.showProgressBar = false;
          } else {
            this.processingSteps[stepName].resultData = cseOrderResponse.data._id;
            this.woodata.order_meta.cse_order_id = cseOrderResponse.data._id;
            this.processingSteps[stepName].completed = true;
            this.captureDdInformation();
          }
        },
        error: (err: any) => {
          console.error('Error recording CSE order. Error:', err);
          this.processingError = err;
          this.showProgressBar = false;
        }
      });
    }
  }

  captureDdInformation() {
    const stepName: string = 'Creating Direct Debit Form';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      this.createOrderOnWooCommerce();
    } else {
      this.currentStep = stepName;
      const form: {[field: string]: string} = {
        'status': 'active',
        '1': this.directDebit.accountHolderName,
        '2': this.directDebit.sortCode,
        '3': this.directDebit.accountNumber,
        '6.3': this.orderDetails.alarmUserFirstName,
        '6.6': this.orderDetails.alarmUserLastName,
        '13': this.infoBilling.email,
      };
      if (['CL', 'TC', 'CK'].includes(this.selectedWebsite.title)) {
        form['12.1'] = this.directDebit.addressLineOne;
        form['12.2'] = this.directDebit.addressLineTwo;
        form['12.3'] = this.directDebit.city;
        form['12.5'] = this.directDebit.postcode;
        form['12.6'] = 'United Kingdom';
      }

      if (this.selectedWebsite.title == 'LL') {
        form['form_id'] = '13';
        form['19'] = this.directDebit.addressLineOne;
        form['20'] = this.directDebit.addressLineTwo;
        form['21'] = this.directDebit.city;
        form['22'] = 'United Kingdom';
        form['24'] = this.directDebit.postcode;
      } else if (this.selectedWebsite.title == 'CL') {
        form['form_id'] = '17';
      } else if (this.selectedWebsite.title == 'TC') {
        form['form_id'] = '15';
      } else if (this.selectedWebsite.title == 'CK') {
        form['form_id'] = '5';
      }

      this.gravityformsService.createForm(this.selectedWebsite._id, form).subscribe({
        next: (res: SingleRecordResponse<GravityFormResponse>) => {
          let gravityResponse: GravityFormResponse;
          if (!!res.data && (typeof res.data == 'string')) {
            if (!(res.data as string).startsWith('<!DOCTYPE')) {
              gravityResponse = JSON.parse(res.data);
            }
          } else {
            gravityResponse = res.data;
          }
          if (res.success && !!gravityResponse) {
            // Is coming back from Lifeline site as string was object from Careline
            if (!!gravityResponse.response && (gravityResponse.status == 201) && (gravityResponse.response.length > 0)) {
              this.processingSteps[this.currentStep].completed = true;
              this.processingSteps[this.currentStep].resultData = `${gravityResponse.response[0]}`;
              console.log('Gravity form response', res);
              this.createOrderOnWooCommerce();
              return;
            }
          }
          console.error('Gravity form request failed', res);
          this.processingError = {'message': 'Direct Debit (Gravity) form request failed', 'code': `${gravityResponse?.status}`};
          this.showProgressBar = false;
        }, 
        error: (err: any) => {
          console.error('Direct Debit (Gravity) form error', err);
          this.processingError = err;
          this.showProgressBar = false;
        }
      });
    }
  }

  createOrderOnWooCommerce() {
    if (this.processingSteps[this.ORDER_CREATION_STEP].completed) {
      this.callCrmWebhook();
    } else {
      this.currentStep = this.ORDER_CREATION_STEP;
      // TODO correct return type
      this.orderService.createOrderWebsites(this.selectedWebsite._id, this.woodata).subscribe({
        next: (rsp: SingleRecordResponse<any>) => {
          if (!rsp.success) {
            console.error('Success false creating order on Alarm website', rsp.message);
            this.processingError = !!rsp.error? rsp.error: {'message': rsp.message};
            this.showProgressBar = false;
            return;
          }
          this.processingSteps[this.currentStep].completed = true;
          this.processingSteps[this.currentStep].resultData = rsp.data.order.id;
          this.orderLink = this.url+'/wp-admin/post.php?post='+rsp.data.order.id+'&action=edit';
          // We know the order id, so we do not need the staff member to enter it manually
          this.websiteOrderId = rsp.data.order.id;

          this.currentStep = this.ORDER_STATUS_UPDATE_STEP;
          // If success, but an error object is returned then the order was created, but status was not updated
          if (!!rsp.error) {
            console.error('Order created on Alarm website, but status not updated to processing.', rsp.message);
            this.processingError = rsp.error;
            this.showProgressBar = false;
            return;
          }
          this.processingSteps[this.currentStep].completed = true;
          console.log('WooCommerce order creation response', rsp);
          this.callCrmWebhook();
        },
        error: (err: any) => {
          console.error('Error creating order on alarm website', err);
          this.processingError = err;
          this.showProgressBar = false;
        }
      });
    }
  }
  
  retryOrder() {
    // clear the previous error
    this.processingError = null;
    this.showProgressBar = true;
    this.displayErrorDetails = false;
    if ([this.ORDER_CREATION_STEP, 'Checking User Supplied OrderId'].includes(this.currentStep)) {
      if (!!this.websiteOrderId) {
        this.checkOrderId();
        return;
      }
    } else if (this.currentStep == this.ORDER_STATUS_UPDATE_STEP) {
      // This step the code can't redo and the user should have done this before retrying, so mark it done
      this.processingSteps[this.ORDER_STATUS_UPDATE_STEP].completed = true;
    }
    if (this.processingSteps['Creating Payment Intent on Stripe'].required
        && !this.processingSteps['Creating Payment Intent on Stripe'].completed) {
      if (!!this.paymentIntentRef) {
        this.checkPaymentReference();
      } else {
        // Card order, with payment not taken so go down that route
        this.createPaymentMethod();
      }
    } else {
      this.captureOrderDetailsForReporting();
    }
  }

  checkOrderId() {
    this.currentStep = 'Checking User Supplied OrderId';
    this.orderService.checkWooOrderId(this.selectedWebsite._id, this.websiteOrderId).subscribe({
      next: (rsp: SingleRecordResponse<string>) => {
        if (rsp.success) {
          console.log('Woo Order Id check - Order Status:', rsp.data);
          this.processingSteps[this.ORDER_CREATION_STEP].completed = true;
          this.processingSteps[this.ORDER_CREATION_STEP].resultData = `${this.websiteOrderId}`;
          this.processingSteps[this.ORDER_STATUS_UPDATE_STEP].completed = true;
          this.orderLink = this.url+'/wp-admin/post.php?post='+this.websiteOrderId+'&action=edit';
          this.captureOrderDetailsForReporting();
        } else {
          console.error(`Error checking WooCommerce order id ${this.websiteOrderId}`, !!rsp.error? rsp.error: rsp.message);
          if (!!rsp.error) {
            this.processingError = rsp.error;
          } else {
            this.processingError = {'message': rsp.message};
          }
          this.showProgressBar = false;
          this.displayErrorDetails = true;
        }
      },
      error: (err: any) => {
        console.error(`Thrown Error checking WooCommerce order id ${this.websiteOrderId}`, err);
        this.processingError = err;
        this.showProgressBar = false;
        this.displayErrorDetails = true;
      }});    
  }

  checkPaymentReference() {
    this.currentStep = 'Checking User Supplied Payment Reference';
    this.paymentIntentRef = this.paymentIntentRef.trim();
    if (!this.paymentIntentRef.startsWith('pi_')) {
      this.processingError = {
        'message': 'Invalid payment reference - it must start with pi_',
      };
      this.showProgressBar = false;
      this.displayErrorDetails = false;
      return;
    }
    this.orderService.getPaymentIntent(this.stripeBrand, this.paymentIntentRef).subscribe({
      next: (response: SingleRecordResponse<ServerSideStripe.PaymentIntent>) => {
        if (response.success) {
          if (response.data.status != 'succeeded') {
            this.processingError = {
              'message': `Payment Intent ${this.paymentIntentRef} is not at "succeeded" status. Payment must be successful to proceed`
            };
            this.displayErrorDetails = true;
            this.showProgressBar = false;
          } else {
            this.processingSteps['Creating Payment Intent on Stripe'].completed = true;
            this.captureOrderDetailsForReporting();
          }
        } else {
          console.error(`Error getting payment intent for pi_ reference ${this.paymentIntentRef}`, response.error);
          const error: StripeError = response.error;
          this.processingError = {'message': response.message, 'code': error.code};
          this.displayErrorDetails = true;
          this.showProgressBar = false;
        }
      },
      error: (err: any) => {
        console.error(`Thrown Error getting payment intent for pi_ reference ${this.paymentIntentRef}`, err);
        this.processingError = err;
        this.displayErrorDetails = true;
        this.showProgressBar = false;
      }
    });
  }

  callCrmWebhook() {
    const stepName: string = 'Importing into CRM';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      this.updateCrmOrder();
    } else {
      this.currentStep = stepName;
      const orderId: string = this.processingSteps[this.ORDER_CREATION_STEP].resultData;
      console.log('calling webhook');
      this.orderService.addOrderCRM(this.selectedWebsite._id, orderId).subscribe({
        next: (_res: SimpleResponse) => {
          console.log('webhook called');
          // Do not mark as completed here as webhook returns before it's actually finished it's work
          setTimeout(() => this.updateCrmOrder(), 4000);
        }, 
        error: (err: any) => {
          console.error('Error calling CRM webhook to import order', err);
          this.processingError = err;
          this.showProgressBar = false;
        }
      });
    }
  }

  updateCrmOrder() {
    const stepName: string = 'Updating CRM order ref, processor and alarm user';
    const orderId: string = this.processingSteps[this.ORDER_CREATION_STEP].resultData;
    if (this.processingSteps[stepName].completed) {
      this.updateCseOrderRecord();
    } else if (!this.processingSteps[stepName].required) {
      if (this.isExistingCustomer() && this.order) {
        const tdCode: string = this.order.alarmUserDetails.tdCode;
        let equipmentSent: string[] = this.items.map((basketItem: BasketItem) => 
            `${basketItem.crmTitle} x ${basketItem.quantity}`
        );
        let overrideMsg: string = '';
        if (this.overrideTotal) {
          overrideMsg = `Note: the order total was overridden for reason: ${this.baseOrderInformation.cseOrder.overrideReason}` +
            this.baseOrderInformation.cseOrder.overrideReasonOther;
        }
        /*
        TODO remove until notes not on order itself
        let noteText: string = `${this.userName} placed a ${this.orderTypeTitle} order WooCommerce Order: ${this.orderLink}\n` +
          `The items on the order are: ${equipmentSent.join('\n')}`;
        if (this.hasRentalItems &&
            [CalculationMethod.ADDITIONAL, CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
          noteText = `${noteText}\nTheir next renewal is ${this.baseOrderInformation.nextRenewalDate} and payment of ${this.TTorder.toFixed(2)} ` +
            `has been taken to cover the increase up to ${this.proRataToDate}.\n${overrideMsg}`;
        }

        const params: AddNoteViaQueueRequest = {
          'mongoOrderId': this.order._id,
          'brand': this.websitetitle,
          'tdCode': tdCode,
          'note': noteText,
          'username': this.userName,
          'categories': ['General']
        };
        this.proposedMessageService.addEntryToNoteQueue(params).subscribe((response: any) => {
          if (!response.success) {
            this.showErrorPopUp('Error adding note to note queue', `Error queuing request to add a note about this order. Error: ${response.message}`);
          }
        }, (err: Error) => {
          this.showErrorPopUp('Error adding note to note queue', `Error queuing request to add a note about this order. Error: ${err.message}`);
        });
        */
        if (this.hasRentalItems &&
            [CalculationMethod.ADDITIONAL, CalculationMethod.DIFFERENCE].includes(this.selectedOrderType.calculationMethod)) {
          const crmOrderLink: string = `${environment.protocol}${environment.IPAddress}/order/${this.order._id}`;
          const plainTextMsg: string = 
            `a ${this.baseOrderInformation.orderType.title} order has been placed for the below order, which will require a change to their renewal.\n` +
            `Customer Order (TD): ${tdCode} ${crmOrderLink}\nWooCommerce Order: ${this.orderLink}\n` +
            `The items on the order are: ${equipmentSent.join('\n')}` +
            `Their next renewal is ${this.baseOrderInformation.nextRenewalDate} and payment of ${this.TTorder.toFixed(2)} ` +
            `has been taken to cover the increase up to ${this.baseOrderInformation.cseOrder.proRataToDate}.\n${overrideMsg}`;
          const recipients: string[] = JSON.parse(localStorage.getItem('email: Admin'));
          this.notificationService.sendEmail({
            'recipients': recipients,
            'subject': `Renewal Payment Change due to ${this.baseOrderInformation.orderType.title} order`,
            'plainTextMsg': `Hi Team,\n${plainTextMsg}\nThanks,\n${this.userName}`,
            'htmlMsg': 
                `<p>Hi Team,</p>` +
                `<p>a ${this.baseOrderInformation.orderType.title} order has been placed for the below order, which will require a change to their renewal.</p>` + 
                `<p>Customer Order (TD): <a href='${crmOrderLink}' target='_blank'>${tdCode}</a><br/>` +
                `WooCommerce Order: <a href='${this.orderLink}' target='_blank'>${this.processingSteps[this.ORDER_CREATION_STEP].resultData}</a></p>` +
                `<p>The items on the order are: ${equipmentSent.join('<br/>')}</p>` +
                `<p>Their next renewal is ${this.baseOrderInformation.nextRenewalDate} and payment of ${this.TTorder.toFixed(2)} ` + 
                `has been taken to cover the increase up to ${this.baseOrderInformation.cseOrder.proRataToDate}.</p><p>${overrideMsg}</p>` +
                `<p>Thanks,<br/>${this.userName}</p>`,
          }).subscribe((emailResponse: MultiRecordResponse<string>) => {
            if (emailResponse.success) {
              if (emailResponse.data.length > 0) {
                this.processingError = {
                  'message': `There was an error emailing ${emailResponse.data.join(';')} to notify them. Please email them these details manually:\n${plainTextMsg}`
                };
              } else {
                this.processingSteps[stepName].completed = true;
                this.updateCseOrderRecord();
                return;
              }
            } else {
              this.processingError = {
                'message': `There was an error emailing ${recipients.join(';')} to notify them. Please email them these details manually:\n${plainTextMsg}`
              };
            }
            this.showProgressBar = false;
            // Skip sending the email as we've asked staff to do it manually
            this.processingSteps[stepName].completed = true;
          });
        } else {
          this.processingSteps[stepName].completed = true;
          this.updateCseOrderRecord();
        }
      } else {
        this.processingSteps[stepName].completed = true;
        this.updateCseOrderRecord();
      }
      return;
    }
    // Have to run find to be able to do the follow on steps
    this.orderService.findOrder({
      'orderId': orderId,
      'initialOrderDetails.orderSource': 'WooCommerce',
      'website': this.selectedWebsite._id,
      'deleted': false
    }).subscribe({
      next: (findResponse: FindOrderResponse) => {
        console.log('Find CRM Order response', findResponse);
        if (!findResponse.success || (findResponse.orders.length == 0)) {
          this.processingError = {'message': 'Order not found in CRM'};
          this.showProgressBar = false;
        } else {
          // Now we can mark webhook as complete as it must have been called to find the order
          this.processingSteps['Importing into CRM'].completed = true;
          this.order = findResponse.orders[0];
          // This is done as normal webhook sets the alarm user's name to the biller's name
          if (this.orderDetails.alarmUserFirstName) {
            this.order.alarmUserDetails.firstName = this.orderDetails.alarmUserFirstName;
            this.order.alarmUserDetails.lastName = this.orderDetails.alarmUserLastName;
            this.order.alarmUserDetails.userAddress.validated = this.infoBilling.userAddress.validated;
          }
          this.order.initialOrderDetails.processor = this.userName;
          this.order.initialOrderDetails.payment = this.orderDetails.paymentMethod;
          if (this.orderDetails.paymentMethod == 'Secured Debit/Credit Card') {
            this.order.initialOrderDetails.transactionId = this.processingSteps['Creating Payment Intent on Stripe'].resultData;
          }
          this.order.alarmUserDetails.tdCode = this.order.alarmUserDetails.tdCode.replace('WEB','CSE');
          if (this.baseOrderInformation.cseOrderNavigationData) {
            this.leadService.updateLead({
              _id: this.baseOrderInformation.cseOrderNavigationData.leadId,
              orderId: this.order._id,
              tdCode: this.order.alarmUserDetails.tdCode,
              status: 'Ordered Over Phone',
            }).subscribe({
              next: (response: SimpleResponse) => {
                console.log('Response :: ', response);
              },
              error: (err: Error) => {
                console.log('ERROR :: ', err);
              }
            });
          }
          if (this.alarmUserAddressOption == 'other') {
            this.order.alarmUserDetails.userAddress.addressOne = this.alarmUserAddress.addressOne;
            this.order.alarmUserDetails.userAddress.addressTwo = this.alarmUserAddress.addressTwo;
            this.order.alarmUserDetails.userAddress.city = this.alarmUserAddress.city;
            this.order.alarmUserDetails.userAddress.county = this.alarmUserAddress.county;
            this.order.alarmUserDetails.userAddress.postcode = this.alarmUserAddress.postcode;
            this.order.alarmUserDetails.userAddress.validated = this.alarmUserAddress.validated;
            this.order.initialOrderDetails.installationAddress = getFormattedAddress(this.alarmUserAddress);
          } else if (this.alarmUserAddressOption == 'unknown') {
            this.order.alarmUserDetails.userAddress.unknown = true;
            this.order.initialOrderDetails.installationAddress = '"Unknown" alarm user address selected when CSE entered this.order'
          } else if (this.alarmUserAddressOption == 'delivery') {
            this.order.alarmUserDetails.userAddress.addressOne = this.infoDeliver.userAddress.addressOne;
            this.order.alarmUserDetails.userAddress.addressTwo = this.infoDeliver.userAddress.addressTwo;
            this.order.alarmUserDetails.userAddress.city = this.infoDeliver.userAddress.city;
            this.order.alarmUserDetails.userAddress.county = this.infoDeliver.userAddress.county;
            this.order.alarmUserDetails.userAddress.postcode = this.infoDeliver.userAddress.postcode;
            this.order.alarmUserDetails.userAddress.validated = this.alarmUserAddress.validated;
            this.order.initialOrderDetails.installationAddress = getFormattedAddress(this.infoDeliver.userAddress);
          } else if (this.alarmUserAddressOption == 'billing') {
            // The alarm user will already be set to this, so just need to populate installation address
            this.order.initialOrderDetails.installationAddress = getFormattedAddress(this.infoBilling.userAddress);
          }

          this.order.accountContacts[0].emailMarketing = this.emailMarketing;
          this.order.accountContacts[0].emailMarketingUpdated = new Date();
          if (isValidAnyCountryMobile(this.infoBilling.mobile)) {
            this.order.accountContacts[0].mobileMarketing = this.phoneMarketing;
            this.order.accountContacts[0].mobileMarketingUpdated = new Date();
          }

          // If the alarm user is the billing user then the alarm user is opted in
          if (this.alarmUserNameOption == 'billing') {
            this.order.alarmUserDetails.emailMarketing = this.emailMarketing;
            this.order.alarmUserDetails.emailMarketingUpdated = new Date();
            if (((this.alarmUserPhoneOption == 'billing') || (this.infoBilling.mobile == this.orderDetails.alarmUserMobile)) &&
                isValidAnyCountryMobile(this.infoBilling.mobile)) {
              this.order.alarmUserDetails.mobileMarketing = this.phoneMarketing;
              this.order.alarmUserDetails.mobileMarketingUpdated = new Date();
            }
          }
          
          this.orderService.updateOrder(this.order._id, {'order': this.order}).subscribe((res: OrderResponse) => {
            if (res.success) {
              this.processingSteps[this.currentStep].completed = true;
              this.updateCseOrderRecord();
            } else {
              this.processingError = {
                'message': `There was an error updating the CRM order details. ${res.message}`
              };
              this.showProgressBar = false;
            }
          }, err => {
            console.error('Error updating CRM order ref, processor and alarm user', err);
            this.processingError = err;
            this.showProgressBar = false;
          });
        }
      },
      error: (err: any) => {
        console.error('Error finding order on CRM after webhook called', err);
        this.processingError = err;
        this.showProgressBar = false;
      }
    });
  }

  updateCseOrderRecord() {
    const stepName: string = 'Updating Reporting Record';
    if (!this.processingSteps[stepName].required || this.processingSteps[stepName].completed) {
      if (this.order) {
        this.crmOrderLink = '/order/' + this.order['_id'];
      }
      this.showProgressBar = false;
      this.orderCreationSuccess = true;
      this.currentStep = '';
    } else {
      this.currentStep = stepName;
      const orderId: string = this.processingSteps[this.ORDER_CREATION_STEP].resultData;
      this.orderService.updateCseOrderRecord({
        cseOrderUpdate: {
          '_id': this.processingSteps['Capturing Details for Reporting'].resultData,
          'websiteOrderId': orderId,
          'orderId': this.order? this.order._id: null,
          'tdCode': this.order? this.order.alarmUserDetails.tdCode: '',
          'currentPlan': this.order? this.order.accountDetails.plan: '',
        }
      }).subscribe({
        next: (cseOrderResponse: SimpleResponse) => {
          if (!cseOrderResponse.success) {
            console.error('Error updating CSE order. Error:', cseOrderResponse.message);
            this.processingError = !!cseOrderResponse.error? cseOrderResponse.error: {'message': cseOrderResponse.message};
            this.showProgressBar = false;
          } else {
            this.showProgressBar = false;
            this.orderCreationSuccess = true;
            this.currentStep = '';
            if (this.order) {
              this.crmOrderLink = '/order/' + this.order['_id'];
              if (!this.isExistingCustomer()) {
                this.router.navigate(['/order/' + this.order['_id']]);
              }
            }
          }
        },
        error: (err: any) => {
          console.error('Error updating CSE order. Error:', err);
          this.processingError = err;
          this.showProgressBar = false;
        }
      });
    }
  }

  searchReferral() {
    this.referralTdFound = false;
    this.referralTdSearched = false;
    this.referralTDCode = this.referralTDCode.trim().toLocaleUpperCase();

    if (!this.referralTDCode) {
      return;
    }
    this.orderService.getOrderTdCode(this.referralTDCode, this.selectedWebsite._id).subscribe({
      'next': (response: OrderResponse) => {
        this.referralTdSearched = true;
        if (response.success && !!response.order) {
          this.referralTdFound = true;
          this.baseOrderInformation.cseOrder.referringAccount = this.referralTDCode;
        } else {
          console.error('Order not found');
        }
      },
      'error': (err: any) => {
        this.referralTdSearched = true;
        console.error('Error finding existing order from TD Code', err);
      }
    });
  }
  
  showInfoPopUp(header: string, message: string): void {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
