import {SelectItem} from 'primeng/api';

enum CustomerMarketingOptInLevel {
  SINGLE_OPT_IN = 'SINGLE_OPT_IN',
  CONFIRMED_OPT_IN = 'CONFIRMED_OPT_IN',
  UNKNOWN = 'UNKNOWN',
}

enum CustomerEmailMarketingState {
  NOT_SUBSCRIBED = 'NOT_SUBSCRIBED',
  PENDING = 'PENDING',
  SUBSCRIBED = 'SUBSCRIBED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  REDACTED = 'REDACTED',
  INVALID = 'INVALID',
}

enum CustomerSmsMarketingState {
  NOT_SUBSCRIBED = 'NOT_SUBSCRIBED',
  PENDING = 'PENDING',
  SUBSCRIBED = 'SUBSCRIBED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  REDACTED = 'REDACTED',
}

const shopifyEmailOptInSelects: SelectItem<CustomerEmailMarketingState>[] = [
  { label: '-- Select --', value: null, disabled: true },
  { label: 'Opted In', value: CustomerEmailMarketingState.SUBSCRIBED },
  { label: 'Opted Out', value: CustomerEmailMarketingState.NOT_SUBSCRIBED },
];

const shopifySmsOptInSelects: SelectItem<CustomerSmsMarketingState>[] = [
  { label: '-- Select --', value: null, disabled: true },
  { label: 'Opted In', value: CustomerSmsMarketingState.SUBSCRIBED },
  { label: 'Opted Out', value: CustomerSmsMarketingState.NOT_SUBSCRIBED },
];

export {
  CustomerMarketingOptInLevel,
  CustomerEmailMarketingState,
  CustomerSmsMarketingState,
  shopifyEmailOptInSelects,
  shopifySmsOptInSelects,
};
