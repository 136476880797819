import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {SimpleResponse} from './../../models/responses/simpleResponse.model';
import {ContactDetails} from './../../models/contactDetails.model';
import {UpdateProposedMessageRequest} from './../../models/requests/updateProposedMessageRequest.model';
import {unstructuredAddressValidator} from './../../validators/unstructured-address.validator';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {ProposedMessageService} from './../proposed-message.service';
import {Component, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {MessageService} from 'primeng/api';
import {getBrandBackgroundColour, getBrandColour} from '../../lookups/brands';
import {ProposedMessageResponse} from '../../models/responses/proposedMessageResponse.model';
import {ProposedMessage} from '../../models/proposedMessage.model';
import {Order} from '../../models/order.model';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ukMobileNumberValidator} from '../../validators/phone-number.validator';
import {multiExternalEmailValidator} from '../../validators/email.validator';
import moment from 'moment-timezone';
import {Subscription} from 'rxjs';
import {LocksSocketService} from '../../sockets/locks-socket.service';
import {OrderUpdatesSocketService} from '../../sockets/order-updates-socket.service';
import {OrderUpdate} from '../../models/socket-io/orderUpdate.model';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {getMessageRejectionReasons} from '../../lookups/messageRejectionReasons';
import {MessageLockData} from '../../models/socket-io/messageLockData.model';
import {OrderLockData} from '../../models/socket-io/orderLockData.model';
import {AddNoteViaQueueRequest} from '../../models/requests/addNoteViaQueueRequest.model';

@Component({
  selector: 'app-message-detail[messageId][closeMessageDetailView][messageTypes]',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.scss'],
  providers: [ConfirmationService]
})
export class MessageDetailComponent implements OnInit, OnDestroy {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private proposedMessageService: ProposedMessageService,
    private locksSocket: LocksSocketService,
    private orderUpdatesSocket: OrderUpdatesSocketService,
    private confirmationService: ConfirmationService,
    private breakpointObserver: BreakpointObserver,
  ) { }
  messageForm: UntypedFormGroup;
  user: string;
  documentBlocked: boolean = false;
  messageEditingBy: MessageLockData;
  orderLockList: OrderLockData[] = [];
  loading: boolean = true;
  socketId: string;
  getBrandColour = getBrandColour;
  getBrandBackgroundColour = getBrandBackgroundColour;
  numberOnly = numberOnly;
  isSmallerThanLarge: boolean;
  messageDetail: ProposedMessage;
  order: Order;
  proposalInvalid: boolean = false;
  reasonsProposalInvalid: string[];
  validMethods: string[];
  isSubmitButtonDisabled: boolean = false;
  display: boolean = true;
  closing: boolean = false;
  @Input() messageId: string;
  @Input() messageTypes: SelectItem<string>[] = [];
  @Output() closeMessageDetailView = new EventEmitter<SimpleResponse>();
  widthChangeSubscription: Subscription;
  doNotSendDialog: boolean = false;
  rejectionReasons: SelectItem<string>[];
  selectedRejectionReason: string = '';
  otherRejectionReason: string = '';
  disableDoNotSendButton: boolean = false;

  ngOnInit() {
    this.user = localStorage.getItem('userName');
    this.closing = false;
    this.messageForm = this.formBuilder.group({
      'firstName': [null, Validators.required],
      'lastName': [null],
      'email': [null, [Validators.required, multiExternalEmailValidator]],
      'address': [null, [Validators.required, unstructuredAddressValidator]],
      // We don't just want to check it's a valid number, it needs to be a UK Mobile too
      'mobile': [null, [Validators.required, ukMobileNumberValidator]],
    });

    this.rejectionReasons = getMessageRejectionReasons();
    this.locksSocket.emit('lockingMessage', {
      messageId: this.messageId,
      user: this.user
    }, (addedMessageToLockList: MessageLockData) => {
        if (addedMessageToLockList.added) {
          this.documentBlocked = false;
          this.socketId = addedMessageToLockList.socketId;
          this.messageEditingBy = addedMessageToLockList;
        } else {
          this.messageEditingBy = addedMessageToLockList;
          this.documentBlocked = true;
          this.socketId = '';
        }
        this.proposedMessageService.getMessageById(this.messageId)
          .subscribe((response: ProposedMessageResponse) => {
            this.updatePageWithResponse(response);
            this.loading = false;
          }, (err: Error) => {
            this.showErrorPopUp('Something went wrong', `Error retrieving message details. Error: ${err.message}`);
          });
    });

    this.locksSocket.on('lockedMessage', this.messageLockUpdate);

    this.locksSocket.on('messageLockList', this.messageLockListUpdate);
    this.locksSocket.on('lockList', this.orderLockListUpdate);
    this.locksSocket.emit('getLocked');
    
    this.orderUpdatesSocket.on('updateOrders', this.processUpdateFromSocketIo);

    this.isSmallerThanLarge = this.breakpointObserver.isMatched('(max-width: 991px)');
    this.widthChangeSubscription = this.breakpointObserver.observe([
      '(max-width: 991px)'
    ]).subscribe((result: BreakpointState) => {
      this.isSmallerThanLarge = result.matches;
    });
  }

  get firstName(): AbstractControl {
    return this.messageForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.messageForm.get('lastName');
  }

  get email(): AbstractControl {
    return this.messageForm.get('email');
  }

  get address(): AbstractControl {
    return this.messageForm.get('address');
  }

  get mobile(): AbstractControl {
    return this.messageForm.get('mobile');
  }

  get reFirstName(): AbstractControl {
    return this.messageForm.get('reFirstName');
  }

  get reLastName(): AbstractControl {
    return this.messageForm.get('reLastName');
  }

  get showEmailInput(): boolean {
    return this.messageDetail.methods.includes('email');
  }

  get showMobileInput(): boolean {
    return this.messageDetail.methods.includes('text');
  }

  get showRegardingInputs(): boolean {
    return this.messageDetail.messageType.startsWith('High User');
  }

  /*
    Whilst letter might not have been the first choice (so in methods already)
    If it is the list of valid methods and the email address is invalid 
    we might choose to override to send by letter
  */
  get showAddressInput(): boolean {
    return (this.messageDetail.methods.includes('letter')
      || (this.validMethods.includes('letter') && !this.email.valid));
  }

  get hasPlanOrPriceErrors(): boolean {
    // TODO remove for hardware version
    return false;
    // TODO add for hardware version
    // return (this.order.planCodeAndPrice.errors.length > 0);
  }
  
  get isSendLetterAllowed() {
    if (this.isSubmitButtonDisabled) {
      return false;
    }
    return this.areFormsValid();
  }

  get isSendAllowed(): boolean {
    if (this.isSubmitButtonDisabled) {
      return false;
    }
    if (!this.areFormsValid()) {
      return false;
    }
    return this.hasValidContactForOneOrMoreMethods;
  }

  get showSendButton(): boolean {
    return ['Awaiting Review', 'Rejected, Do not Send'].includes(this.messageDetail.status);
  }

  orderLockListUpdate: (lockList: OrderLockData[]) => void = (orderLocks: OrderLockData[]) => {
    this.orderLockList = orderLocks;
  }

  isOrderLocked(_id: string): boolean {
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == _id).length > 0;
  }

  orderLockedBy(_id: string): string {
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == _id)[0].user;
  }

  areFormsValid(): boolean {
    if (this.hasPlanOrPriceErrors) {
      return false;
    }
    if (!this.firstName.valid) {
      return false;
    }
    
    if (this.messageDetail.messageType.startsWith('Renewal')) {
      if (this.reasonsProposalInvalid.includes('There is no renewal price, or an invalid renewal price on the order.')) {
        return false;
      }
      if (!(this.messageForm.controls['renewal'] as UntypedFormGroup).controls['renewalLink'].valid) {
        return false;
      }
    }

    if (['Cancellation Phase 1', 'Cancellation Phase 3'].includes(this.messageDetail.messageType)) {
      if (!this.messageForm.controls['link'].valid) {
        return false;
      }
    }
    if ('Cancellation Phase 4' == this.messageDetail.messageType) {
      if (!this.messageForm.controls['invoice'].valid) {
        return false;
      }
    }

    if ('High User' == this.messageDetail.messageType) {
      if (!this.messageForm.controls['messageToSend'].valid) {
        return false;
      }
    }

    if (this.messageDetail.messageType.startsWith('High User')) {
      if (!this.reFirstName.valid) {
        return false;
      }
    }
    return true;
  }

  /* 
    Show send a letter if the message has a letter option configured and the email isn't valid, 
    but not if it's already set to go by letter (as then send can just be used)
  */
  get showSendLetterButton(): boolean {
    return this.validMethods.includes('letter') &&
      !(this.email.valid || this.messageDetail.methods.includes('letter'));
  }

  get showResendButton(): boolean {
    return this.messageDetail.status == 'Sent';
  }

  get showDoNotSendButton(): boolean {
    return ['Awaiting Review', 'Ready to Send'].includes(this.messageDetail.status);
  }

  hasValidDetailsForMethod(method: string): boolean {
    switch (method) {
      case 'email':
        if (this.email.valid) {
          return true;
        }
        break;
      case 'text':
        if (this.mobile.valid) {
          return true;
        }
        break;
      case 'letter':
        if (this.address.valid) {
          return true;
        }
        break;
      default:
        break;
    }
    return false;
  }

  get hasValidContactForOneOrMoreMethods(): boolean {
    for (let index: number = 0; index < this.messageDetail.methods.length; index++) {
      const method: string = this.messageDetail.methods[index];
      if (this.hasValidDetailsForMethod(method)) {
        return true;
      }
    }
    return false;
  }

  updatePageWithResponse(response: ProposedMessageResponse) {
    if (response.success) {
      this.messageDetail = response.data.proposedMessage;
      this.order = response.data.order;
      const contactDetails: ContactDetails = this.messageDetail.contactDetails;
      this.messageForm.patchValue({
        'firstName': contactDetails.firstName,
        'lastName': contactDetails.lastName,
        'email': contactDetails.email,
        'address': contactDetails.address.join('\n'),
        'mobile': contactDetails.mobile,
      });
      if (this.messageDetail.messageType.startsWith('High User')) {
        this.messageForm.addControl('reFirstName', new UntypedFormControl(contactDetails.reFirstName, [Validators.required]));
        this.messageForm.addControl('reLastName', new UntypedFormControl(contactDetails.reLastName));
      }
      this.proposalInvalid = (response.data.reasonsProposalInvalid.length > 0);
      this.reasonsProposalInvalid = response.data.reasonsProposalInvalid;
      this.validMethods = response.data.validMethods;
      if (this.messageDetail.status == 'Archived') {
        //setTimeout(() => {
          this.showInfoPopUp('Message Archived', 
            'This message has been archived as it is no longer valid. See the reasons at the bottom of the page.');
        //},1);
      }
    } else {
      this.showErrorPopUp('Something went wrong', `Error retrieving message details. Error: ${response.message}`);
    }
  }

  getUpdate() {
    this.proposedMessageService.getMessageById(this.messageId)
      .subscribe((response: ProposedMessageResponse) => {
        this.updatePageWithResponse(response);
      })
  }

  sendLetter() {
    // Swap out email for letter
    this.messageDetail.methods = this.messageDetail.methods.filter((method: string) => method !== 'email');
    this.messageDetail.methods.push('letter');
    this.updateMessage('Ready to Send');
  }

  updateMessage(messageStatus: string): void {
    this.isSubmitButtonDisabled = true;
    let methodsToUse: string[];
    let renewalDate: string | undefined = this.messageDetail.renewalDate;

    /*
      Filter out methods which do not have valid comms details, unless we are rejecting the message
      in which case we can wait as details may be made valid before status is updated to send.
    */
    if (messageStatus != 'Rejected, Do not Send') {
      /**
       * If message-type is Auto-enrolment then renewalType should be reccuringBilling on the order
       * to proceed, If condition is not matching return from the method and do not update status 
       */
      if (this.messageDetail.messageType === 'Auto-enrolment' &&
          this.order.renewalInformation.renewalType !== 'recurringBilling') {
        this.showInfoPopUp('Warning', 'Renewal type should be recurring billing before the message can be sent');
        return;
      }
      /**
       * If the messageType starts with Renewal and renewalType on order is not blank or standing then
       * updates should not be allowed
      */
      if (this.messageDetail.messageType.startsWith('Renewal') &&
          this.order.renewalInformation?.renewalType != '' &&
          this.order.renewalInformation?.renewalType != 'standing order') {
        this.showInfoPopUp('Warning', 'Renewal type should be blank/standing order before message can be sent');
        return;
      }

      methodsToUse = this.messageDetail.methods.filter((method: string) => 
        this.hasValidDetailsForMethod(method)
      );
      // If the order still has a renewal date refresh it
      if (this.order.renewalInformation.renewalDate) {
        renewalDate = this.order.renewalInformation.renewalDate;
      }
    } else {
      methodsToUse = this.messageDetail.methods;
    }

    let source: string = this.messageDetail.contactDetails.source;
    if (this.messageForm.dirty && !source.includes('overrides')) {
      source = `${source}, with overrides`;
    }
    const params: UpdateProposedMessageRequest = {
      'messageStatus': messageStatus,
      'methods': methodsToUse,
      'contactDetails': {
        'source': source,
        'firstName': this.firstName.value,
        'lastName': this.lastName.value,
        'email': this.email.value,
        'mobile': this.mobile.value,
        'address': this.address.value.split('\n')
            .filter((addressLine: string) =>
              addressLine.trim().length > 0,
            ),
      },
    };
    if (this.messageDetail.messageType.startsWith('High User')) {
      params.contactDetails.reFirstName = this.reFirstName.value;
      params.contactDetails.reLastName = this.reLastName.value;
    }
    if (renewalDate) {
      params.renewalDate = renewalDate;
    }
    if (this.messageDetail.messageType.startsWith('Renewal')) {
      params.renewalData = {
        'renewalLink': this.messageForm.controls['renewal'].value.renewalLink,
        'showDdOffer': this.messageForm.controls['renewal'].value.showDdOffer,
      };
    }
    if (this.messageDetail.messageType.startsWith('Cancellation')) {
      params.cancellationInfo = {
        'derivedPlan': this.messageDetail.cancellationInfo.derivedPlan,
      };
      if (this.messageDetail.messageType === 'Cancellation Phase 4') {
        params.cancellationInfo.unitCost = 
          this.messageForm.controls['invoice'].value.unitCost;
        params.cancellationInfo.vatCost = 
          this.messageForm.controls['invoice'].value.vatCost;
        params.cancellationInfo.equipCost = 
          this.messageForm.controls['invoice'].value.equipCost;
        params.cancellationInfo.equipName = 
          this.messageForm.controls['invoice'].value.equipName;
      } else {
        params.cancellationInfo.link = 
          this.messageForm.controls['link'].value;
      }
    }

    if (this.messageDetail.messageType == 'High User') {
      params.messageToSend = this.messageForm.controls['messageToSend'].value;
    }

    if (messageStatus == 'Rejected, Do not Send') {
      const reason: string = (this.selectedRejectionReason == 'Other')? this.otherRejectionReason.trim(): this.selectedRejectionReason;
      params.rejectionReason = reason;
    }

    this.proposedMessageService.updateMessage(this.messageDetail._id, params)
      .subscribe((response: SimpleResponse) => {
        this.isSubmitButtonDisabled = false;
        this.closeMessageDetailView.emit(response);
      }, (err: Error) => {
          this.isSubmitButtonDisabled = false;
          this.showErrorPopUp('Something went wrong',`Update failed. Error: ${err.message}`);
      });
  }

  addMessageDeclinedReason() {
    this.disableDoNotSendButton = true;
    const reason: string = (this.selectedRejectionReason == 'Other')? this.otherRejectionReason.trim(): this.selectedRejectionReason;
    const params: AddNoteViaQueueRequest = {
      'mongoOrderId': this.messageDetail.orderId,
      'brand': this.messageDetail.brand,
      'tdCode': this.messageDetail.tdCode,
      'note': `${this.user} rejected '${this.messageDetail.messageType}' message with reason: ${reason}`,
      'username': this.user,
      'categories': ['CRM Automation']
    };
    this.proposedMessageService.addEntryToNoteQueue(params).subscribe((response: any) => {
      if (response.success) {
        this.onHideDoNotSendDialog();
        this.updateMessage('Rejected, Do not Send');
      } else {
        this.showErrorPopUp('Something went wrong, try again', response.message);
      }
      this.disableDoNotSendButton = false;
    }, (err: Error) => {
      this.disableDoNotSendButton = false;
      this.showErrorPopUp('Something went wrong, try again', err.message);
      console.log('Error on adding entry to note queue ::', err.message);
    });
  }

  convertMessage(newType: string) {
    this.proposedMessageService.convertMessage(this.messageDetail._id, newType)
      .subscribe((response: SimpleResponse) => {
        this.isSubmitButtonDisabled = false;
        this.closeMessageDetailView.emit(response);
      }, (err: Error) => {
          this.isSubmitButtonDisabled = false;
          this.showErrorPopUp('Something went wrong',`Conversion failed. Error: ${err.message}`);
      });
  }

  unlock() {
    this.confirmationService.confirm({
      message: 
        `${this.messageEditingBy.user} is curently editing this message, do you want to have full access permission?` +
        `Warning: any changes made by ${this.messageEditingBy.user} will be lost`,
      header: 'Warning',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.locksSocket.emit('unlockingMessage-F', { messageId: this.messageId, user: this.user }, (data: MessageLockData) => {
          if (data.added) {
            this.socketId = data.socketId;
            this.getUpdate();
            this.documentBlocked = false;
          }
        })
      },
      reject: () => {
        
      }
    })
  }

  onHideDialog() {
    this.closeMessageDetailView.emit({'success': true, 'message': 'close'});
    this.closing = true;
    if (!this.documentBlocked) {
      this.documentBlocked = true;
      this.locksSocket.emit('unlockingMessage', { 'messageId': this.messageId, 'user': this.user });
    }
    this.display = false;
  }

  onHideDoNotSendDialog() {
    this.doNotSendDialog = false;
  }

  ngOnDestroy() {
    // Don't want to remove all listeners here as the parent component is listening on this socket too
    this.orderUpdatesSocket.removeListener('updateOrders', this.processUpdateFromSocketIo);
    this.locksSocket.removeListener('lockedMessage', this.messageLockUpdate);
    this.locksSocket.removeListener('messageLockList', this.messageLockListUpdate);
    this.locksSocket.removeListener('lockList', this.orderLockListUpdate);
    this.closing = true;
    if (!this.documentBlocked) {
      this.documentBlocked = true;
      this.locksSocket.emit('unlockingMessage', { 'messageId': this.messageId, 'user': this.user });
    }
    this.widthChangeSubscription.unsubscribe;
  }

  processUpdateFromSocketIo: (orderUpdateData: OrderUpdate) => void = (newData: OrderUpdate) => {
    // It's not this order, so ignore the update
    if (!newData.order || !this.order || (newData.order._id !== this.order._id)) {
      return;
    }
    if (!!this.order.renewalInformation.renewalDate && !newData.order.renewalInformation.renewalDate) {
      this.reasonsProposalInvalid.push('Order updated and no longer has an expiry date.');
    } else if (!!this.messageDetail.renewalDate && !!newData.order.renewalInformation.renewalDate) {
      const newExpiry: moment.Moment = moment(newData.order.renewalInformation.renewalDate);
      const oldExpiry: moment.Moment = moment(this.messageDetail.renewalDate);
      if (!newExpiry.isSame(oldExpiry)) {
        this.reasonsProposalInvalid.push('Order updated and the expiry date has changed since the request was created.');
      }
    }
    this.order = newData.order;
  }

  messageLockListUpdate: (data: MessageLockData[]) => void = (data: MessageLockData[]) => {
    // Stop it trying to relock the message if the page is closing
    if (this.closing) {
      return;
    }
    if (this.documentBlocked && data.filter((e: MessageLockData) => e.messageId == this.messageId).length == 0) {
      this.locksSocket.emit('lockingMessage', { messageId: this.messageId, user: this.user }, (currentMessage: MessageLockData) => {
        if (currentMessage.added) {
          this.socketId = currentMessage.socketId;
          this.getUpdate();
          this.documentBlocked = false;
        }
      });
    }
  }

  messageLockUpdate: (data: MessageLockData) => void = (data: MessageLockData) => {
    if (data.socketId === this.socketId) {
      this.messageEditingBy = data;
      this.documentBlocked = true;
    }
  }

  close() {
    this.closing = true;
    if (!this.documentBlocked) {
      this.documentBlocked = true;
      this.locksSocket.emit('unlockingMessage', {'messageId': this.messageId, 'user': this.user});
    }
  }

  getSendMethods(): string {
    return this.messageDetail.methods.filter((method: string) => 
      this.hasValidDetailsForMethod(method)
    ).join(', ');
  }

  getLetterSendMethods(): string {
    const baseMethods: string = this.getSendMethods();
    return baseMethods? baseMethods + ', letter': 'letter';
  }

  get reasonSupplied(): boolean {
    if (!this.selectedRejectionReason) {
      return false;
    }
    if (this.selectedRejectionReason == 'Other') {
      return (this.otherRejectionReason.trim().length > 0);
    }
    return true;
  }

  showInfoPopUp(header: string, message: string) {
    this.showPopUp(header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string) {
    this.showPopUp(header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(header: string, message: string, icon: string) {
    this.confirmationService.confirm({
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
