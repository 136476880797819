<div class="cancellation-links">
  <div class="justify-content-center m-1">
    <div class="row">
      <div class="col-2">
        <p-dropdown [options]="brands" appendTo="body" [(ngModel)]="selectedBrand" placeholder="Brands"
            scrollHeight="600px" class="ml-2">
          <ng-template let-Pbrand let-i="index" pTemplate="item">
            <span class="brandDropDown"
                [ngStyle]="{'background-color': brandConfigs[Pbrand.value].background, 'color': brandConfigs[Pbrand.value].color}">
              &nbsp;{{Pbrand.value}}
            </span>
          </ng-template>
        </p-dropdown>
        <div *ngIf="!loading else loadingBlock" class="mt-2 ml-2 d-inline-block">
          <button type="button" class="btn btn-primary" (click)="loadCancellationLinks()" pButton
              label="Load Links" [disabled]="!selectedBrand"></button>
        </div>
        <ng-template #loadingBlock>
          <div class="ml-2 spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </ng-template>
      </div>
      <div class="col-10">
        <form [formGroup]="addCancellationLinkForm" class="ml-2 d-inline-block align-middle">
          <input type="text" pInputText class="ml-2 mt-2" formControlName="derivedPlan"
              placeholder="Enter derived plan" required>
          <input type="text" pInputText class="ml-2 mt-2" formControlName="link" placeholder="Enter link" required>
          <input type="text" pInputText class="ml-2 mt-2" formControlName="unitCost"
              (keypress)="numberOnly($event, true)" placeholder="Enter unit cost" required>
          <input type="text" pInputText class="ml-2 mt-2" formControlName="vatCost" (keypress)="numberOnly($event, true)"
              placeholder="Enter vat cost" required>
          <input type="text" pInputText class="ml-2 mt-2" formControlName="equipCost"
              (keypress)="numberOnly($event, true)" placeholder="Enter equip cost" required>
          <input type="text" pInputText class="ml-2 mt-2" formControlName="equipName"
              placeholder="Enter equip name" required>
        </form>
        <button type="button" (click)="addCancellationLink()" class="btn btn-primary ml-3 mt-2"
            [disabled]="!canAddCancellationLink || addCancellationLinkForm.invalid" required>
          Add Link
        </button>
      </div>
    </div>
  </div>
  <p-table #dt [columns]="cols" [value]="cancellationLinks" [paginator]="true" [rows]="10">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [ngSwitch]="col.field">
          <div *ngSwitchCase="'brand'">{{col.header}}</div>
          <div *ngSwitchCase="'actions'">{{col.header}}</div>
          <div *ngSwitchDefault [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </div>
        </th>
      </tr>
      <tr>
        <th *ngFor="let col of columns" [ngSwitch]="col.field">
          <div *ngSwitchCase="'brand'"></div>
          <div *ngSwitchCase="'actions'"></div>
          <div *ngSwitchDefault>
            <p-columnFilter type="text" [matchMode]="'startsWith'" [field]="col.field" class="p-ml-auto">
            </p-columnFilter>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData" class="renewal-link-row">
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
          <div *ngSwitchCase="'brand'">
            <span class="brand"
                [ngStyle]="{'background-color': brandConfigs[rowData[col.field]].background, 'color': brandConfigs[rowData[col.field]].color}">
                &nbsp;{{rowData[col.field]}}
            </span>
          </div>
          <div *ngSwitchCase="'derivedPlan'">
            <input type="text" name="derivedPlan" class="form-control" [(ngModel)]="rowData[col.field]">
          </div>
          <div *ngSwitchCase="'equipName'">
            <input type="text" name="equipName" [(ngModel)]="rowData[col.field]" class="form-control">
          </div>
          <div *ngSwitchCase="'link'">
            <input type="text" name="link" [(ngModel)]="rowData[col.field]" class="form-control">
          </div>
          <div *ngSwitchCase="'actions'">
            <button type="button" class="btn btn-primary" [disabled]="!canUpdateCancellationLink"
                (click)="updateCancellationLink(rowData)">Update</button>
            <button type="button" class="btn btn-danger ml-2" [disabled]="!canDeleteCancellationLink" (click)="deleteCancellationLink(rowData['_id'])">Delete</button>
          </div>
          <div *ngSwitchDefault>
            <input type="text" name="{{col.field}}" (keypress)="numberOnly($event, true)"
                [(ngModel)]="rowData[col.field]" class="form-control">
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-toast></p-toast>