import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Params} from '@angular/router';
import {MessageService, OverlayOptions, SelectItem} from 'primeng/api';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {WebsiteResponse} from '../../models/responses/websiteResponse.model';
import {WebsitesResponse} from '../../models/responses/websitesResponse.model';
import {Website} from '../../models/website.model';
import {WebsiteService} from './website.service';
import {isValidObjectId} from '../../helpers/helperFunctions';
import {Observable} from 'rxjs';
import {DEFAULT_OVERLAY} from '../../lookups/primeng/overlayOptions';

@Component({
  selector: 'app-websites',
  templateUrl: './websites.component.html',
  styleUrls: ['./websites.component.scss'],
  providers: [MessageService]
})

export class WebsitesComponent implements OnInit {
  websiteForm: UntypedFormGroup;
  processing: boolean = false;
  website = new Website();
  websites: Website[];
  btnDisabled = false;
  formHeading: string;
  action: string;
  skus: string;
  id: string;
  overlayOptions: OverlayOptions = DEFAULT_OVERLAY;
  websiteBackends: SelectItem<string>[] = [{
    'label': 'None',
    'value': 'None',
  }, {
    'label': 'Shopify',
    'value': 'Shopify',
  }, {
    'label': 'WooCommerce',
    'value': 'WooCommerce',
  }];

  constructor(
    private fb: UntypedFormBuilder,
    private websiteService:WebsiteService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private title: Title,
  ) {
  }
  
  importNewProducts()   {
    if (!isValidObjectId(this.id)) {
      return;
    }
    this.websiteService
      .importNewProductsForWebsite(this.id)
      .subscribe({
        next: (response: WebsiteResponse) => {
          if (!response.success) {
            this.showError(`Error importing products ${response.message}`);
          } else {
            this.showSuccess('SKUs import started');
          }
        }, 
        error: (err: any) => {
          this.showError(`Error importing products ${err.message}`);
        }
      });
  };

  importSpecificProductsForWebsite()   {
    if (!isValidObjectId(this.id)) {
      return;
    }
    if (!this.skus && !this.skus.trim()) {
      this.showError('You must specify the SKUs to import');
      return;
    }
    this.websiteService
      .importSpecificProductsForWebsite(this.id, this.skus.split('\n'))
      .subscribe({
        next: (response: WebsiteResponse) => {
          if (!response.success) {
            this.showError(`Error importing products ${response.message}`);
          } else {
            this.showSuccess('SKUs import started');
            this.skus = '';
          }
        },
        error: (err: any) => {
          this.showError(`Error importing products ${err.message}`);
        }
      });
  };

  get isOkToSubmit(): boolean {
    return this.websiteForm.valid;
  }

  showError(message: string) {
    this.messageService.add({
      severity: 'error',
      life: 300000,
      summary: 'Something went wrong!',
      detail: message
    });
  }

  showSuccess(message: string) {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success',
      detail: message,
    });
  }

  private initForm() {
    this.websiteForm = this.fb.group({
      domain: [''],
      title: ['', Validators.required],
      background: ['', Validators.required],
      color: ['', Validators.required],
      titleBarColor: ['',Validators.required],
      order: [0],
      accountingCode: [''],
      api: this.fb.group({
        url: [''],
        secret: [''],
        key: [''],
        apiKeyGF: [''],
        privateKeyGF: [''],
      }),
      shopify: this.fb.group({
        storeDomain: [''],
        accessToken: [''],
        apiVersion: ['', Validators.pattern(/^\d{4}-\d{2}$/)],
        apiSecret: [''],
      }),
      usingWebBackend: [''],
      currencyCode: [''],
      businessCentralLLBrandCode: [''],
      businessCentralCLBrandCode: [''],
    });
  }

  ngOnInit() {
    this.initForm();
    this.skus = '';

    this.route.params.subscribe((params: Params) => {
      this.id = params.id;
      this.websiteService
        .getWebsites()
        .subscribe({
          next: (websites: WebsitesResponse) => {
            this.websites = websites.websites;
          }, 
          error: (err: any) => {
            this.showError(`Error getting website details ${err.message}`);
          }
        });
      if (!!this.id && isValidObjectId(this.id)) {
        this.websiteService
          .getWebsite(this.id)
          .subscribe({
            next: (website: WebsiteResponse) => {
              this.website = website['website'];
              this.websiteForm.patchValue({
                domain: this.website.domain,
                title: this.website.title,
                api: this.website.api,
                background: this.website.background,
                color: this.website.color,
                titleBarColor:this.website.titleBarColor,
                order: this.website.order,
                accountingCode: this.website.accountingCode,
                usingWebBackend: this.website.usingWebBackend,
                currencyCode: this.website.currencyCode,
                businessCentralLLBrandCode: this.website.businessCentralLLBrandCode,
                businessCentralCLBrandCode: this.website.businessCentralCLBrandCode,
              });
              if (this.website.shopify) {
                this.websiteForm.patchValue({
                  shopify: this.website.shopify,
                });
              } else {
                this.websiteForm.controls['shopify'].reset();
              }
            },
            error: (err: any) => {
              this.showError(`Error getting website details ${err.message}`);
            }
          });
      }
    });
    this.formHeading = this.id ? `Edit website!` : 'Create New website!';
    this.action = this.id ? 'Update Website' : 'Create Website';
    this.title.setTitle('CRM Websites');
  }


  submitForm() {
    let result: Observable<SimpleResponse>;
    let websiteParams = {
      website: this.websiteForm.value
    };
    this.processing = true;
    if (this.website._id) {
      result = this.websiteService.updateWebsite(this.website._id, websiteParams);
    } else {
      result = this.websiteService.createWebsite(websiteParams);
    }
    result.subscribe({
      next: (rsp: SimpleResponse) => {
        if (!rsp.success) {
          this.showError(`Error updating website ${rsp.message}`);
        } else {
          this.showSuccess('Website updated successfully');
        }
        this.processing = false;
        this.ngOnInit();
      }, 
      error: (err: any) => {
        this.processing = false;
        this.showError(`Error updating website details ${err.message}`);
        return;
      }
    })
  }

  get allowBrandCodeChange(): boolean {
    return !this.id || !this.website.title;
  }
}
