import {SelectItem} from 'primeng/api';

const rentalPeriodOptions: SelectItem<string>[] = [{
  'label': 'None',
  'value': 'none',
}, {
  'label': 'Monthly',
  'value': '1M',
}, {
  'label': 'Quarterly',
  'value': '1Q',
}, {
  'label': 'Annual',
  'value': '1Y',
}, {
  'label': 'Lifetime',
  'value': 'LIFE',
}];

export {
  rentalPeriodOptions,
}