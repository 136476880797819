import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import moment from 'moment-timezone';
import {ConfirmationService} from 'primeng/api';
import {Column} from '../../models/column.model';
import {PostsheetReportData} from '../../models/reporting/postsheetReportData.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {ExcelExportService} from '../../post-order/excel-export.service';
import {PostSheetService} from '../post-sheet.service';

@Component({
  selector: 'app-post-sheet-item-report',
  templateUrl: './post-sheet-item-report.component.html',
  styleUrls: ['./post-sheet-item-report.component.scss'],
  providers: [ConfirmationService],
})

export class PostSheetItemReportComponent implements OnInit {

  constructor(
    private title: Title,
    private postSheetService: PostSheetService,
    private confirmationService: ConfirmationService,
    private excelService: ExcelExportService,
  ) { }

  cols: Column[] = []; 
  reportData: PostsheetReportData[] = [];
  maxDateStr: string;
  loading: boolean;
  startDate: string;
  endDate: string;
  showChart: boolean;
  chartButtonEnabled: boolean;
  exportButtonEnabled: boolean;
  displayDateEntryModal: boolean = false;

  ngOnInit() {
    this.chartButtonEnabled = false;
    this.exportButtonEnabled = false;
    this.title.setTitle('CRM Post - Item Report');
    this.maxDateStr = moment.tz('Europe/London').format('YYYY-MM-DD');
    this.loading = false;
    this.startDate = moment.tz('Europe/London').subtract(1, 'month').format('YYYY-MM-DD');
    this.endDate = this.maxDateStr;
    this.showChart = false;
  }

  loadReport(): void {
    this.loading = true;
    this.postSheetService.getItemReportData(this.startDate, this.endDate)
      .subscribe((reportResponse: MultiRecordResponse<PostsheetReportData>) => {
        this.loading = false;
        if (!reportResponse.success) {
          this.showErrorPopUp('Failed to load report', `Failed to load data for report. Error: ${reportResponse.message}`);
          return;
        }
        this.reportData = reportResponse.data;
        if (this.reportData.length > 0) {
          this.chartButtonEnabled = true;
          this.exportButtonEnabled = true;
          this.cols = [
            {'field': 'whatToSend', 'header': 'What to Send'},
            {'field': 'total', 'header': 'Total'}
          ];
          Object.keys(this.reportData[0].breakdownByReason).forEach((key: string) => {
            this.cols.push({
              'field': `breakdownByReason.${key}`,
              'header': key,
            });
          });
          this.cols.push({
            'field': 'spacer',
            'header': '',
          });
          Object.keys(this.reportData[0].breakdownByBrand).forEach((key: string) => {
            this.cols.push({
              'field': `breakdownByBrand.${key}`,
              'header': key,
            });
          });
        }
      });
  }

  getRowData(data: PostsheetReportData, fieldName: string) {
    if (!fieldName.includes('.')) {
      return data[fieldName];
    }
    const fieldNames: string[] = fieldName.split('.');
    return data[fieldNames[0]][fieldNames.slice(1).join('.')];
  }

  showChartDialog(): void {
    this.showChart = true;
  }

  closeChart(): void {
    this.showChart = false;
  }

  showInfoPopUp(header: string, message: string) {
    this.showPopUp(header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string) {
    this.showPopUp(header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(header: string, message: string, icon: string) {
    this.confirmationService.confirm({
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }

  exportPostItemReport() {
    this.displayDateEntryModal = false;
    const dataToExport: any[] = this.reportData.map((reportDataRow: PostsheetReportData) => {
      const exportRow = {};
      this.cols.forEach((col: Column) => {
        exportRow[col.header] = this.getRowData(reportDataRow, col.field);
      });
      return exportRow;
    });
    this.excelService.exportAsExcelFile({ 'PostsheetReportData': dataToExport }, 'PostsheetReportData');
  }
}
