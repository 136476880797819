import { Pipe, PipeTransform } from '@angular/core';
import {SelectItem} from 'primeng/api';
import {getMessageConfig} from '../lookups/manualSendMessageTypes';

@Pipe({
  name: 'messageVariations'
})
export class MessageVariationsPipe implements PipeTransform {

  transform(messageType: string): SelectItem<string>[] {
    const variations: string[] = getMessageConfig(messageType)?.variations;
    if (!variations) {
      return [];
    }
    return variations.map((variation: string) => {
      return {
        'label': variation,
        'value': variation
      }
    });
  }

}
