<p-dialog (onHide)="closeDialog()" header="Send Letter" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '90vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100">
  <div id="letterDialogBody">
    <form [formGroup]="contactForm">
      <div class="row">
        <div *ngIf="resultMessage" class="col-12 form-group">
          <div class="alert" [ngClass]="resultSuccess? 'alert-success' : 'alert-danger'">
            {{resultMessage}}
          </div>
        </div>
        <div *ngIf="hasBlockAlarmComms" class="col-12 form-group">
          <div class="alert alert-warning">
            This order has a "Block Alarm Correspondence" tag, which might indicate the customer is currently away.
          </div>
        </div>
        <div *ngIf="isRenewalWithNoPrice" class="col-12 form-group">
          <div class="alert alert-danger">
            This order has no renewal price, you must set a renewal price before you can send a renewal message.
          </div>
        </div>
        <div class="col-12 form-group">
          This form is for sending letters using one of the predefined templates, it cannot be used for general letter communication.
        </div>
        <div class="col-6 form-group">
          <ng-container *ngIf="letterTypes.length === 1">
            <label for="letterType">Letter Type:</label>
            <input type="text" class="form-control" name="letterType" id="letterType" formControlName="letterTypeToSend" readonly/>
          </ng-container>
          <ng-container *ngIf="letterTypes.length > 1">
            <label for="letterType">Letter Type:</label>
            <p-dropdown [options]="letterTypes" name="letterType" id="letterType" formControlName="letterTypeToSend" 
              placeholder="Select a letter type to send" [required]="true" (onChange)="selectedTypeChange()"
              [overlayOptions]="overlayOptions" [filter]="true">
            </p-dropdown>
          </ng-container>
        </div>
        <div class="col-6 form-group">
          <label for="contactDetails">Select contact to send letter to, or enter details manually below</label>
          <p-dropdown [options]="contactDetailsToPickFrom" name="contactDetails" id="contactDetails" 
              [(ngModel)]="selectedContactDetails" [showClear]="true" [overlayOptions]="overlayOptions"
              (onChange)="selectedContactChange()" [ngModelOptions]="{standalone: true}" [filter]="true"
              placeholder="Optionally select an existing contact">
          </p-dropdown>
        </div>
        <div class="col-12 form-group">
          If you have selected an existing contact you can override some of their details below -
          this will not update the order and is just the details to use for this letter.
        </div>
        <div class="col-6 form-group">
          <label for="firstName">First Name</label>
          <input type="text" class="form-control" name="firstName" id="firstName" formControlName="firstName" required/>
        </div>
        <div class="col-6 form-group">
          <label for="lastName">Last Name</label>
          <input type="text" class="form-control" name="lastName" id="lastName" formControlName="lastName" required/>
        </div>
        <ng-container *ngIf="areRegardingFieldsAllowed">
          <div class="col-6 form-group">
            <label for="reFirstName">Regarding First Name</label>
            <input type="text" class="form-control" name="reFirstName" id="reFirstName" formControlName="reFirstName"
              [required]="areRegardingFieldsRequired || hasRegardingLastName" />
          </div>
          <div class="col-6 form-group">
            <label for="reLastName">Regarding Last Name</label>
            <input type="text" class="form-control" name="reLastName" id="reLastName" formControlName="reLastName"
              [required]="areRegardingFieldsRequired || hasRegardingFirstName" />
          </div>
        </ng-container>
        <div class="col-12 form-group">
          <label for="address">Address</label>
          <textarea type="address" class="form-control" rows="5" name="address" id="address" formControlName="address" required
            [readOnly]="!allowAddressManualEntry">
          </textarea>
        </div>
        <div class="col-3 form-group">
          <input type="text" id="getaddress_input" autocomplete="off" [(ngModel)]="searchPostCode"
            [ngModelOptions]="{standalone:true}" placeholder="Find Postcode">
          <button type="button" id="getaddress_button" (click)="addressSearch()" [disabled]="!searchPostCode">
            Find Postcode
          </button>
        </div>
        <div *ngIf="addressSearchError" class="form-group col-9">
          <p class="alert alert-danger">{{addressSearchError}}</p>
        </div>
        <div *ngIf="addressResults && (addressResults.length > 0)" class="form-group col-9">
          <p-dropdown [options]="addressResults" [style]="{'width':'100%'}" (onChange)="setAddress($event)"
            [overlayOptions]="overlayOptions" placeholder="--Please Select an Address--">
          </p-dropdown>
        </div>
        <ng-container *ngIf="needsPaymentDetails">
          <div class="col-3 form-group">
            <label for="equipName">Equipment Name</label>
            <input type="equipName" class="form-control" name="equipName" id="equipName" formControlName="equipName" required/>
          </div>
          <div class="col-3 form-group">
            <label for="unitCost">Unit Cost</label>
            <input type="unitCost" class="form-control" name="unitCost" id="unitCost" formControlName="unitCost" required/>
          </div>
          <div class="col-3 form-group">
            <label for="vatCost">VAT Cost</label>
            <input type="vatCost" class="form-control" name="vatCost" id="vatCost" formControlName="vatCost" required/>
          </div>
          <div class="col-3 form-group">
            <label for="equipCost">Equipment Cost</label>
            <input type="equipCost" class="form-control" name="equipCost" id="equipCost" formControlName="equipCost" required/>
          </div>
        </ng-container>
        <div *ngIf="hasVariations" class="col-12 form-group">
          <label for="letterVariation">Select which type of letter to send</label>
          <p-dropdown [options]="contactForm.controls['letterTypeToSend'].value | messageVariations"
              name="letterVariation" id="letterVariation" formControlName="letterVariation" placeholder="Select email type"
              [required]="true" [overlayOptions]="overlayOptions" [filter]="true">
          </p-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-group text-right">
          <button type="button" class="btn btn-primary" (click)="sendLetter()" [disabled]="!isOkToSend()">Send</button>
          <button type="button" class="btn btn-warning ml-2" (click)="closeDialog()">Close</button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>