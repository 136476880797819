import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function contactDetailsRequiredValidator(groupDisplayName: string, contactFieldOne: string, contactFieldTwo: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors|null => {
    const contactDetailsOne: AbstractControl = formGroup.get(contactFieldOne)!;
    const contactDetailsTwo: AbstractControl = formGroup.get(contactFieldTwo)!;
    const contactDetailsProvided: boolean = 
        (((contactDetailsOne.value !== null) && ((contactDetailsOne.value as string).trim().length > 0) && contactDetailsOne.valid) ||
        ((contactDetailsTwo.value !== null) && ((contactDetailsTwo.value as string).trim().length > 0) && contactDetailsTwo.valid));
    if (contactDetailsProvided) {
      return null;
    }
    return {'contactDetailsRequired': groupDisplayName};
  }
}
