import { HttpClient, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {CancellationInfo} from '../models/cancellationInfo.model';
import {ProposedMessage} from '../models/proposedMessage.model';
import {AddNoteViaQueueRequest} from '../models/requests/addNoteViaQueueRequest.model';
import {UpdateProposedMessageRequest} from '../models/requests/updateProposedMessageRequest.model';
import {MessageLogReportResponse} from '../models/responses/messageLogReportResponse.model';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {ProposedMessageResponse} from '../models/responses/proposedMessageResponse.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {OrderMsgReportResponse} from '../models/responses/orderMsgReportResponse.model';
@Injectable({
  providedIn: 'root'
})
export class ProposedMessageService {

  constructor(
    private http: HttpClient,
  ) { }
  private API_URL: string = environment.protocol + environment.IPAddress + '/api';

  getCrmMessageTypes(): Observable<MultiRecordResponse<SelectItem<string>>> {
    return this.http.get<MultiRecordResponse<SelectItem<string>>>(this.API_URL + '/proposals/crmMessageTypes');
  }

  getAllMessages(): Observable<MultiRecordResponse<ProposedMessage>> {
    return this.http.get<MultiRecordResponse<ProposedMessage>>(this.API_URL + '/proposals/all');
  }

  getSelectedMessages(params: HttpParams): Observable<MultiRecordResponse<ProposedMessage>> {
    return this.http.get<MultiRecordResponse<ProposedMessage>>(this.API_URL + '/proposals/selected', {params: params});
  }

  getMessageById(msgId: string): Observable<ProposedMessageResponse> {
    return this.http.get<ProposedMessageResponse>(this.API_URL + '/proposals/' + msgId);
  }

  updateMessage(msgId: string, params: UpdateProposedMessageRequest): Observable<SimpleResponse> {
    return this.http.put<SimpleResponse>(this.API_URL + '/proposals/' + msgId, params);
  }

  convertMessage(msgId: string, newMessageType: string): Observable<SimpleResponse> {
    return this.http.put<SimpleResponse>(this.API_URL + '/proposals/convert/' + msgId, {'newMessageType': newMessageType});
  }

  addEntryToNoteQueue(params: AddNoteViaQueueRequest): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/proposals/note-queue/entry`, params);
  }

  addMessage(params: ProposedMessage): Observable<SingleRecordResponse<ProposedMessage>> {
    return this.http.post<SingleRecordResponse<ProposedMessage>>(`${this.API_URL}/proposals`, params);
  }

  getPendantTestCount(): Observable<MultiRecordResponse<number>> {
    return this.http.get<MultiRecordResponse<number>>(`${this.API_URL}/proposals/pendant-test/count`);
  }

  releasePendantTestEmails(numberToRelease: number): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/proposals/pendant-test/release`, {
      numberToRelease: numberToRelease,
    });
  }

  getMessageLogReport(): Observable<MessageLogReportResponse> {
    return this.http.get<MessageLogReportResponse>(`${this.API_URL}/proposals/message-log/report`);
  }

  getCancellationLink(orderId: string): Observable<SingleRecordResponse<string>> {
    return this.http.get<SingleRecordResponse<string>>(`${this.API_URL}/proposals/cancellationLink/${orderId}`);
  }

  getCancellationData(orderId: string): Observable<SingleRecordResponse<CancellationInfo>> {
    return this.http.get<SingleRecordResponse<CancellationInfo>>(`${this.API_URL}/proposals/cancellationData/${orderId}`);
  }

  getOrderReport(orderId: string, tdCode: string): Observable<OrderMsgReportResponse> {
    return this.http.get<OrderMsgReportResponse>(`${this.API_URL}/proposals/order-report/${orderId}/${tdCode}`);
  }
}
