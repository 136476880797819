import { Component, OnInit, ViewChild } from '@angular/core';
import {AccountService} from '../../models/accountService.model';
import {HardwareService} from '../hardware.service';
import {ConfirmationService, FilterMetadata, MessageService, SelectItem} from 'primeng/api';
import {Title} from '@angular/platform-browser';
import {Table} from 'primeng/table';
import {numberOnly} from '../../helpers/keyboardHelpers';
import {BrandCfg, getBrandConfigs, getBrandSelectItems} from '../../lookups/brands';
import {Column} from '../../models/column.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {datedPriceSort, doPricesOverlap} from '../../helpers/hardwareHelpers';
import {DatedPrice} from '../../models/datedPrice.model';
import {Observable} from 'rxjs';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';

interface AccountServiceRow extends AccountService {
  'newFromDate'?: string;
  'newToDate'?: string;
  'newQuarterly'?: number;
  'newAnnual'?: number;
}

@Component({
  selector: 'app-account-services',
  templateUrl: './account-services.component.html',
  styleUrls: ['./account-services.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class AccountServicesComponent implements OnInit {

  constructor(
    private hardwareService: HardwareService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private title: Title,
  ) { }

  @ViewChild('dt', {static: true})
  private table: Table;
  numberOnly = numberOnly;
  brandConfigs: BrandCfg;
  brands: SelectItem<string>[];
  selectedBrand: string;
  serviceRows: AccountServiceRow[] = [];
  cols: Column[] = [
    { field: 'title', header: 'Title' },
    { field: 'planSymbol', header: 'Plan Symbol' },
    { field: 'servicePricesExVat', header: 'Prices Ex VAT' },
    { field: 'websiteId', header: 'Brand' },
    { field: 'updated', header: 'Updated' },
    { field: 'actions', header: 'Actions' },
  ];
  canAddService: boolean = false;

  ngOnInit(): void {
    this.title.setTitle('CRM Services');
    this.brandConfigs = getBrandConfigs();
    this.brands = getBrandSelectItems();
  }

  loadServices(): void {
    if (!this.selectedBrand || !this.brandConfigs[this.selectedBrand]) {
      return;
    }
    const websiteId: string = this.brandConfigs[this.selectedBrand]._id;
    if (!websiteId) {
      return;
    }
    this.canAddService = false;
    this.hardwareService.getServicesForSite(websiteId)
      .subscribe((response: MultiRecordResponse<AccountService>) => {
        if (response.success) {
          this.serviceRows = response.data.map((accountService: AccountService) => {
            accountService.servicePricesExVat.sort(datedPriceSort);
            return accountService;
          });
          this.canAddService = true;
        } else {
          this.showErrorPopUp('Error', response.message || 'Something went wrong try again.');
        }
      }, (err: Error) => {
        console.log('ERROR while getting services: ', err);
        this.showErrorPopUp('Error', 'Something went wrong try again.');
      });
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.table.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  getFilterValue(field: string): string {
    if (!this.table.filters[field]) {
      return '';
    }
    return (this.table.filters[field] as FilterMetadata).value;
  }

  deletePrice(rowData: AccountServiceRow, index: number): void {
    rowData.servicePricesExVat.splice(index, 1);
  }

  addPrice(rowData: AccountServiceRow): boolean {
    if (!rowData.newFromDate && !rowData.newToDate) {
      this.showInfoPopUp('Missing Dates', 'You must enter at least one date for the price.');
      return false;
    }
    if (!!rowData.newFromDate && !!rowData.newToDate && (rowData.newFromDate >= rowData.newToDate)) {
      this.showInfoPopUp('Invalid Date Range', 'The from date must be before the to date');
      return false;
    }
    let overlappingRange: boolean = false;
    rowData.servicePricesExVat.forEach((datedPrice: DatedPrice) => {
      if (!rowData.newFromDate) {
        if (!datedPrice.fromDate) {
          // New price and an existing price are open on the from date, so must overlap
          overlappingRange = true;
        } else if (datedPrice.fromDate <= rowData.newToDate) {
          overlappingRange = true;
        }
      } else if (!rowData.newToDate) {
        if (!datedPrice.toDate) {
          // New price and an existing price are open on the to date, so must overlap
          overlappingRange = true;
        } else if (datedPrice.toDate >= rowData.newFromDate) {
          overlappingRange = true;
        }
      } else {
        // Both start and end date specified on the new price
        if ((!!datedPrice.fromDate) && (datedPrice.fromDate >= rowData.newFromDate) && (datedPrice.fromDate <= rowData.newToDate) ||
            (!!datedPrice.toDate) && (datedPrice.toDate >= rowData.newFromDate) && (datedPrice.toDate <= rowData.newToDate)) {
          // And one side or other of the existing price's date range is within the new range
          overlappingRange = true;
        } else if ((!!datedPrice.fromDate && (datedPrice.fromDate <= rowData.newFromDate) && (!datedPrice.toDate || (datedPrice.toDate >= rowData.newToDate))) ||
            (!!datedPrice.toDate && (datedPrice.toDate >= rowData.newToDate) && !datedPrice.fromDate)) {
          // The new range fits entirely within an existing range
          overlappingRange = true;
        }
      }
    });
    if (overlappingRange) {
      this.showInfoPopUp('Invalid Date Range', 'The date range you have entered overlaps with an existing range.');
      return false;
    }
    // Must have both prices
    if (!rowData.newQuarterly || !rowData.newAnnual || (rowData.newQuarterly <= 0) || (rowData.newAnnual <= 0)) {
      this.showInfoPopUp('Missing Price', 'You must enter a quarterly and an annual price.');
      return false;
    }

    rowData.servicePricesExVat.push({
      'fromDate': rowData.newFromDate,
      'toDate': rowData.newToDate,
      'quarterly': rowData.newQuarterly,
      'annual': rowData.newAnnual,
    });
    rowData.servicePricesExVat.sort(datedPriceSort);
    delete rowData.newFromDate;
    delete rowData.newToDate;
    delete rowData.newQuarterly;
    delete rowData.newAnnual;
    return true;
  }

  validToSave(rowData: AccountServiceRow) {
    const errors: string[] = [];
    if (!rowData.title) {
      errors.push('a title');
    }
    if (Object.keys(rowData.servicePricesExVat).length == 0) {
      errors.push('at least one date range with a price');
    } else {
      let pricesValid: boolean = true;
      let datesValid: boolean = true;
      Object.keys(rowData.servicePricesExVat).forEach((index: string) => {
        if ((rowData.servicePricesExVat[index].quarterly <= 0) || (rowData.servicePricesExVat[index].annual <= 0)) {
          pricesValid = false;
        }
        if (!rowData.servicePricesExVat[index].fromDate && !rowData.servicePricesExVat[index].toDate) {
          datesValid = false;
        }
      });
      if (!pricesValid) {
        errors.push('a quarterly and annual price for each date range');
      }
      if (!datesValid) {
        errors.push('a start and/or end date for each date range');
      }
      if (doPricesOverlap(rowData.servicePricesExVat)) {
        errors.push('date ranges that do not overlap');
      }
    }
    if (errors.length > 0) {
      this.showInfoPopUp('Invalid Information', `You must enter ${errors.join(', ')} before saving.`);
      return false;
    } 
    return true;
  }

  saveService(rowData: AccountServiceRow): void {
    if (!rowData.newFromDate && !rowData.newToDate) {
      this.updateService(rowData);
    } else {
      this.confirmationService.confirm({
        key: 'save',
        message: 'You have entered a price you have not yet added, do you want it to be added before saving the update?',
        header: 'Price Addition',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        rejectVisible: true,
        accept: () => {
          if (this.addPrice(rowData)) {
            this.updateService(rowData);  
          }
        },
        reject: () => {
          this.updateService(rowData);
        }
      });
    }
  }

  updateService(rowData: AccountServiceRow): void {
    if (!this.validToSave(rowData)) {
      return;
    }
    // Clear fields used for adding prices
    delete rowData.newFromDate;
    delete rowData.newToDate;
    delete rowData.newQuarterly;
    delete rowData.newAnnual;
    rowData.updatedBy = localStorage.getItem('userName');
    let response: Observable<SingleRecordResponse<AccountService>>;
    if (!!rowData._id) {
      response = this.hardwareService.updateAccountService(rowData._id, {'accountService': rowData});
    } else {
      response = this.hardwareService.saveNewAccountService({'accountService': rowData});
      this.canAddService = true;
    }
    
    response.subscribe((rsp: SingleRecordResponse<AccountService>) => {
      if (!rsp.success || !rsp.data) {
        this.showErrorPopUp('Error Updating Service', rsp.message || 'Something went wrong when trying to update the service. Please try again.');
        this.handleError(rsp.error);
      } else {
        const tempFirst: number = this.table.first;
        this.showSuccess();
        this.serviceRows = [...[rsp.data], ...this.serviceRows.filter((service: AccountService) => 
          !!service._id && (service._id !== rsp.data._id)
        )];
        this.table.first = tempFirst;
      }
    }, (err: Error) => {
      this.handleError(err);
      this.showErrorPopUp('Error Updating Service',
          `Something went wrong when trying to update the service. Please try again. Error: ${err.message}`);
    });
  }

  addService(): void {
    this.canAddService = false;
    const newService: AccountService = {
      'title': '',
      'planSymbol': '',
      'servicePricesExVat': [],
      'websiteId': {
        '_id': this.brandConfigs[this.selectedBrand]._id,
        'title': this.selectedBrand,
        'background': this.brandConfigs[this.selectedBrand].background,
        'color': this.brandConfigs[this.selectedBrand].color,
      },
      'updatedBy': localStorage.getItem('userName'),
      'createdAt': '',
      'updatedAt': '',
    };
    this.serviceRows = [newService, ...this.serviceRows];
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      life: 1000,
      summary: 'Success Update!',
      detail: 'Changes Successfully Applied',
    });
  }

  private handleError(err: Error) {
    console.error(err);
  }

  showInfoPopUp(header: string, message: string) {
    this.showPopUp('general', header, message, 'pi pi-info-circle');
  }

  showErrorPopUp(header: string, message: string) {
    this.showPopUp('error', header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(key: string, header: string, message: string, icon: string) {
    this.confirmationService.confirm({
      key: key,
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
