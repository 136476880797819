<p-dialog (onHide)="closeDialog()" header="Messages" [modal]="true" [(visible)]="dialogVisible"
    [style]="{width: '95vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100">
  <div class="messageRpt">
    <h4>Messages Being Processed</h4>
    <p-table #proposed [value]="proposedMsgs" [columns]="proposedCols" [paginator]="true" [rows]="20" styleClass='proposed'
        sortField="generated" [sortOrder]="1" sortMode="single" (sortFunction)="subfieldSort($event)" [customSort]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            <div [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon><br/>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns" [ngSwitch]="col.field">
            <div *ngSwitchCase="'generated'">
              {{rowData.generated | date:'dd/MM/y HH:mm'}}
            </div>
            <div *ngSwitchCase="'status'">
              {{(rowData.status === 'Sent')? 'Processing or recently sent': rowData.status}}
            </div>
            <div *ngSwitchCase="'methods'">
              {{rowData.methods.join(', ')}}
           </div>
            <div *ngSwitchCase="'contactDetails.address'">
               {{rowData.contactDetails.address.join(', ')}}
            </div>
            <div *ngSwitchDefault>
              {{getSubfieldData(rowData, col.field)}}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <h4>Messages Processed</h4>
    <p-table #logs [value]="messageLogs" [columns]="logsCols" [paginator]="true" [rows]="20" 
        sortField="dateLogged" [sortOrder]="1" sortMode="single" styleClass='logs'>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            <div [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon><br/>
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns" [ngSwitch]="col.field">
            <div *ngSwitchCase="'dateLogged'">
              {{rowData.dateLogged | date:'dd/MM/y HH:mm'}}
            </div>
            <div *ngSwitchCase="'messageSentSuccessfully'">
               {{rowData.messageSentSuccessfully? 'Yes': 'No'}}
            </div>
            <div *ngSwitchDefault>
              {{rowData[col.field]}}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>