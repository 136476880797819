import {Component, OnInit} from '@angular/core';
import {PostSheet} from '../../models/postSheet.model';
import {PrintMailingLabelsService} from '../print-mailing-labels.service';

@Component({
  selector: 'app-mailing-labels',
  templateUrl: './mailing-labels.component.html',
  styleUrls: ['./mailing-labels.component.scss']
})
export class MailingLabelsComponent implements OnInit {
  oddLabelNumbers: number[];

  constructor(
    private printMailingLabelsService: PrintMailingLabelsService,
  ) { }

  ngOnInit() {
    this.oddLabelNumbers = [];
    this.postToPrintLabelsFor.forEach((_postItem: PostSheet, index: number) => {
      if (index % 2 == 0) {
        this.oddLabelNumbers.push(index);
      }
    })
    this.printMailingLabelsService.onDataReady();
  }

  get postToPrintLabelsFor(): PostSheet[] {
    return this.printMailingLabelsService.postToPrintLabelsFor;
  }

}
