import { Component, OnInit } from '@angular/core';
import {GenesysService} from './genesys.service';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Params} from '@angular/router';
import {GenesysBGResponse, GenesysDataResponse, GenesysLeadResponse, GenesysOrderResponse} from '../models/responses/genesysDataResponse.model';
import {Column} from '../models/column.model';

@Component({
  selector: 'app-genesys',
  templateUrl: './genesys.component.html',
  styleUrls: ['./genesys.component.scss']
})
export class GenesysComponent implements OnInit {

  constructor(
    private title: Title,
    private genesysService: GenesysService,
    private route: ActivatedRoute,
  ) { }
  errorMsg: string;
  genesysOrders: GenesysOrderResponse[] = [];
  genesysLeads: GenesysLeadResponse[] = [];
  genesysBg: GenesysBGResponse[] = [];
  hasOrders: boolean;
  hasLeads: boolean;
  hasBgInfo: boolean;
  orderCols: Column[] = [
    { field: 'brandTitle', header: 'Brand' },
    { field: 'thisBrand', header: 'Brand being called' },
    { field: 'Alarm User Name', header: 'Alarm User Name' },
    { field: 'Address Line 1', header: 'Address Line 1' },
    { field: 'Postcode', header: 'Postcode' },
    { field: 'Order Status', header: 'Order Status' },
    { field: 'Order Link', header: 'Order Link' },
  ];
  leadCols: Column[] = [
    { field: 'brandTitle', header: 'Brand' },
    { field: 'thisBrand', header: 'Brand being called' },
    { field: 'Lead Name', header: 'Lead Name' },
    { field: 'Address Line 1', header: 'Address Line 1' },
    { field: 'Postcode', header: 'Postcode' },
    { field: 'Lead Status', header: 'Lead Status' },
    { field: 'Lead Link', header: 'Lead Link' },
  ];
  bgCols: Column[] = [
    { field: 'name', header: 'Name' },
  ];

  ngOnInit(): void {
    this.title.setTitle('CRM Genesys Links');
    this.errorMsg = '';
    this.hasOrders = false;
    this.hasLeads = false;
    this.hasBgInfo = false;
    this.route.params.subscribe((params: Params) => {
      if (params.brand && params.phoneNo) {
        this.genesysService.getGenesysData(params.brand, params.phoneNo).subscribe((response: GenesysDataResponse) => {
          if (!response.success) {
            this.errorMsg = `Error getting data. Error: ${response.message}`;
          } else {
            this.genesysOrders = response.orders;
            this.hasOrders = response.found.orders;
            this.genesysLeads = response.leads;
            this.hasLeads = response.found.leads;
            this.genesysBg = response.bgInfo;
            this.hasBgInfo = response.found.bg;
          }
        });
      }
    });
    
  }

}
