import {Component, OnInit} from '@angular/core';
import {DataService} from '../data.service';
import {UsersService} from '../setup/users/users.service';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {UserProfileChangeRequest} from '../models/requests/userProfileChangeRequest.model';
import {isStrongPassword} from '../helpers/passwordHelper';
import {Router} from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  btnDisabled: boolean = false;
  currentSettings: UserProfileChangeRequest;
  unsafePassword: boolean = false;

  constructor(
    private data: DataService,
    private usersService: UsersService,
    private router: Router,
  ) { }

  async ngOnInit() {
    try {
      this.currentSettings = {
        'email': localStorage.getItem('email'),
        'name': localStorage.getItem('userName'),
        'password': '',
        'confirmPassword': '',
      };
    } catch (error) {
      this.data.error(error);
    }
  }

  validate(): boolean {
    this.unsafePassword = false;
    if (!this.currentSettings.name) {
      this.data.error('Please enter your name.');
      return false;
    }
    if (!this.currentSettings.email) {
      this.data.error('Please enter your email.');
      return false;
    }
    
    /*
    If we decide to allow changing of username or email, add the below back in to 
    allow password and confirm password to be blank - indicating not changed
    if (!!this.currentSettings.password || !!this.currentSettings.confirmPassword) {
    */
    if (!this.currentSettings.password) {
      this.data.error('Please enter new password.');
      return false;
    }
    if (!this.currentSettings.confirmPassword) {
      this.data.error('Please enter password confirmation.');
      return false;
    }
    if (this.currentSettings.password != this.currentSettings.confirmPassword) {
      this.data.error('Passwords do not match.');
      return false;
    }
    if (!isStrongPassword(this.currentSettings.password)) {
      this.unsafePassword = true;
      return false;
    }
    return true;
  }

  update() {
    this.btnDisabled = true;
    if (!this.validate()) {
      this.btnDisabled = false;
      return;
    }
    this.usersService.updateOwnUserProfile(this.currentSettings)
      .subscribe((response: SimpleResponse) => {
        if (response.success) {
          // Put in if we allow these to be changed (currently read only).
          // Need to decide what checks to avoid duplication we have/need before we allow
          localStorage.setItem('email', this.currentSettings.email),
          localStorage.setItem('userName', this.currentSettings.name),
          this.data.success(response.message);
        } else {
          this.data.error(response.message);
        }
        this.btnDisabled = false;
      }, (error: any) => {
        this.data.error(error.message);
        this.btnDisabled = false;
      });
  }

  cancel() {
    this.router.navigate(["/profile"]);
  }
}
