import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {Coupon} from '../../models/coupon.model';
import {ShopifyDiscount} from '../../models/shopifyDiscount.model';
import {RefreshStatus} from '../../models/responses/refreshStatus.model';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {
  private API_URL: string = environment.protocol+environment.IPAddress+'/api';

  constructor(private http: HttpClient) {
  }

  getCouponsByWebsite(websiteId: string): Observable<MultiRecordResponse<Coupon>> {
    return this.http.get<MultiRecordResponse<Coupon>>(`${this.API_URL}/coupons/wooCommerce/${websiteId}`);
  }

  refreshShopifyDiscounts(websiteId: string, discountCodes: string[]): Observable<MultiRecordResponse<RefreshStatus>> {
    return this.http.post<MultiRecordResponse<RefreshStatus>>(`${this.API_URL}/coupons/shopify/refreshDiscounts/${websiteId}`, {
      'codes': discountCodes
    });
  }

  getShopifyDiscounts(websiteId: string): Observable<MultiRecordResponse<ShopifyDiscount>> {
    return this.http.get<MultiRecordResponse<ShopifyDiscount>>(`${this.API_URL}/coupons/shopify/getDiscounts/${websiteId}`);
  }
}
