import { Pipe, PipeTransform } from '@angular/core';
import {hardwareStatusConfig, keySafeStatusConfig} from '../lookups/statuses';

@Pipe({
  name: 'statusDisplaySection'
})
export class StatusDisplaySectionPipe implements PipeTransform {

  transform(status: string, statusType: string): string {
    if (!statusType || !['keysafe', 'hardware'].includes(statusType)) {
      return '';
    }
    if (statusType == 'keysafe') {
      if (keySafeStatusConfig[status]) {
        return keySafeStatusConfig[status].displaySection? keySafeStatusConfig[status].displaySection: '';
      } else {
        return '';
      }
    } else {
      // Default unrecognised statuses to the active section
      if (hardwareStatusConfig[status]) {
        return hardwareStatusConfig[status].displaySection? hardwareStatusConfig[status].displaySection: 'active';
      } else {
        return 'active';
      }
    }
  }

}
