import moment from 'moment-timezone';
import {SelectItem} from 'primeng/api';
import { selectItemSort } from '../helpers/helperFunctions';

interface LeadType extends SelectItem<string> {
  whoReferredRequired: boolean;
  callLogRequired: boolean;
  emailRequired: boolean;
}

const CALL_DATE_SHIFTS: SelectItem<string>[] = [
  { label: 'Morning', value: 'Morning' },
  { label: 'Afternoon', value: 'Afternoon' },
  { label: 'Evening', value: 'Evening' },
  { label: 'Specific Time', value: 'Specific Time' }
];

const LEAD_STATUSES: SelectItem<string>[] = [
  { label: 'Active', value: 'Active' },
  { label: 'Already Ordered', value: 'Already Ordered' },
  { label: 'Considering', value: 'Considering' },
  { label: 'Ordered Over Phone', value: 'Ordered Over Phone' },
  { label: 'Pending Payment', value: 'Pending Payment' },
  { label: 'Unable to Contact', value: 'Unable to Contact' },
  { label: 'Declined', value: 'Declined' },
];

function getDateAndType(callDateShift: string, callDate: string) {
  let callDateType = callDateShift ? callDateShift : 'datetime-local';
  if (callDateType == 'Specific Time') {
    callDateType = 'datetime-local';
    callDate = callDate? moment.tz(callDate, 'Europe/London').format('YYYY-MM-DDTHH:mm'): null;
  } else {
    callDateType = 'date';
    callDate = callDate? moment.tz(callDate, 'Europe/London').format('YYYY-MM-DD'): null;
  }
  return {
    callDateType,
    callDate
  }
}

let leadTypes: LeadType[];

function getLeadTypes(): LeadType[] {
  if (!leadTypes) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('leads: lead types')) {
      return [];
    }
    leadTypes = JSON.parse(localStorage.getItem('leads: lead types')) || [];
    selectItemSort(leadTypes);
  }
  return leadTypes;
}

let leadDeclinedOptions: SelectItem<string>[];

function getLeadNoCallbackOptions(): SelectItem<string>[] {
  if (!leadDeclinedOptions) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('leads: why callback is not required')) {
      return [];
    }
    leadDeclinedOptions = JSON.parse(localStorage.getItem('leads: why callback is not required')) || [];
  }
  return leadDeclinedOptions;
}

export {
    getDateAndType,
    getLeadNoCallbackOptions,
    leadDeclinedOptions,
    CALL_DATE_SHIFTS,
    getLeadTypes,
    LEAD_STATUSES,
    LeadType,
}