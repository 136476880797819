import moment from 'moment-timezone';
import {AdditionalOrReplacementEquipment, KeySafe, Order, OrderNote, Plan, Service} from '../models/order.model';
import {OutstandingAction} from '../models/outstandingAction.model';
import {RenewalDiscount} from '../models/renewalDiscount.model';
import {HomepageFormatOrder} from '../models/homepageFormatOrder.model';

function convertOrderToData(order: Order): HomepageFormatOrder {
  // TODO remove for hardware version
  const serials: string[] = [];
  const newOrder: HomepageFormatOrder = {
    '_id': order['_id'],
    'title': order['website']['title'],
    'tdCode': order['alarmUserDetails']['tdCode'],
    'customerName': order['alarmUserDetails']['firstName'] + ' ' + order['alarmUserDetails']['lastName'],
    'otherName': order['alarmUserDetails']['otherName'],
    'preferredName': order['alarmUserDetails']['preferredName'],
    'email': order['alarmUserDetails']['email'],
    'emailMarketing': order.alarmUserDetails.emailMarketing,
    'emailMarketingUpdated': order.alarmUserDetails.emailMarketingUpdated?
      moment(order.alarmUserDetails.emailMarketingUpdated).tz('Europe/London').format('DD/MM/YYYY HH:mm'): '',
    'emailTwo': order.alarmUserDetails.emailTwo,
    'emailTwoMarketing': order.alarmUserDetails.emailTwoMarketing,
    'emailTwoMarketingUpdated': order.alarmUserDetails.emailTwoMarketingUpdated?
      moment(order.alarmUserDetails.emailTwoMarketingUpdated).tz('Europe/London').format('DD/MM/YYYY HH:mm'): '',
    'telephone': order['alarmUserDetails']['telephone'],
    'mobile': order['alarmUserDetails']['mobile'],
    'mobileMarketing': order.alarmUserDetails.mobileMarketing,
    'mobileMarketingUpdated': order.alarmUserDetails.mobileMarketingUpdated?
      moment(order.alarmUserDetails.mobileMarketingUpdated).tz('Europe/London').format('DD/MM/YYYY HH:mm'): '',
    'addressOne': order['alarmUserDetails']['userAddress']['addressOne'],
    'addressTwo': order['alarmUserDetails']['userAddress']['addressTwo'],
    'city': order['alarmUserDetails']['userAddress']['city'],
    'county': order['alarmUserDetails']['userAddress']['county'],
    'postcode': order['alarmUserDetails']['userAddress']['postcode'],
    'jontekCodes': order.jontekCodes ? order.jontekCodes.join('|') : '',
    'oldAlarmCodes': order.oldAlarmCodes ? order.oldAlarmCodes.join('|') : '',
    'orderId': order['orderId'],
    'accName': order['accountContacts'].map(contact => {
      return (contact['accFirstName'] + ' ' + contact['accLastName']).trim();
    }).join('|'),
    'accFirstName': order['accountContacts'].map(contact => {
      return contact['accFirstName'];
    }).join('|'),
    'accLastName': order['accountContacts'].map(contact => {
      return contact['accLastName'];
    }).join('|'),
    'accOtherName': order['accountContacts'].map(contact => {
      return contact['accOtherName'];
    }).join('|'),
    'accEmail': order['accountContacts'].map(contact => {
      return contact['accEmail'];
    }).join('|'),
    'accEmailMarketing': order['accountContacts'].map(contact => {
      return contact['emailMarketing'];
    }).join('|'),
    'accEmailMarketingUpdated': order['accountContacts'].map(contact => {
      return contact.emailMarketingUpdated?
        moment(contact.emailMarketingUpdated).tz('Europe/London').format('DD/MM/YYYY HH:mm'): '';
    }).join('|'),
    'accTelephone': order['accountContacts'].map(contact => {
      return contact['accTelephone'];
    }).join('|'),
    'accMobile': order['accountContacts'].map(contact => {
      return contact['accMobile'];
    }).join('|'),
    'accMobileMarketing': order['accountContacts'].map(contact => {
      return contact['mobileMarketing'];
    }).join('|'),
    'accMobileMarketingUpdated': order['accountContacts'].map(contact => {
      return contact.mobileMarketingUpdated?
        moment(contact.mobileMarketingUpdated).tz('Europe/London').format('DD/MM/YYYY HH:mm'): '';
    }).join('|'),
    'accRelationship': order['accountContacts'].map(contact => {
      return contact['accRelationship'];
    }).join('|'),

    // TODO remove for hardware version
    'plan': order['accountDetails']['plan'],
    // TODO add for hardware version
    // 'plan': order['planCodeAndPrice']['planCode'],
    'vat': order['accountDetails']['vat'],
    'monitoring': order['accountDetails']['monitoring'],
    'planType': order['accountDetails']['planType'],
    // TODO remove for hardware version
    'renewalPrice': order['renewalInformation']['renewalPrice'],
    // TODO add for hardware version
    /*
    'baseRenewalPrice': order['planCodeAndPrice']['baseRenewalPrice'],
    'frozenPrice': order['renewalInformation']['frozenPrice'],
    'renewalPrice': order['planCodeAndPrice']['renewalPrice'],
    'renewalErrors': order['planCodeAndPrice']['errors'],
    */
    'renewalType': order['renewalInformation']['renewalType'],
    'iDetailsprocessor': order['initialOrderDetails']['processor'],
    'icouponCode': order['initialOrderDetails']['couponCode'],
    'icouponAmount': order['initialOrderDetails']['couponAmount'],
    'iDetailspayment': order['initialOrderDetails']['payment'],
    'iDetailsorderDate': order['initialOrderDetails']['orderDate'],
    'iDetailsorderType': order['initialOrderDetails']['orderType'],
    'iDetailsrenewal': order['initialOrderDetails']['renewal'],
    'iDetailsfirstName': order['initialOrderDetails']['firstName'],
    'iDetailstelephone': order['initialOrderDetails']['telephone'],
    'iDetailsalarmUser': order['initialOrderDetails']['alarmUser'],
    'iDetailslastName': order['initialOrderDetails']['lastName'],
    'iDetailstransactionId': order['initialOrderDetails']['transactionId'],
    'iDetailsemail': order['initialOrderDetails']['email'],
    'iDetailsinitialOrder': order['initialOrderDetails']['orderId'],
    'iDetailsbilling': order['initialOrderDetails']['billing'],
    'iDetailsshipping': order['initialOrderDetails']['shipping'],
    'iDetailsvatTotal': order['initialOrderDetails']['vatTotal'],
    'iDetailstotal': order['initialOrderDetails']['total'],
    'iDetailsnotes': order['initialOrderDetails']['notes'],
    'iDetailsInstallationAddress': order.initialOrderDetails.installationAddress,
    'ShippingMethod': order['initialOrderDispatchInformation']['ShippingMethod'],
    'sfOrderId': order['initialOrderDispatchInformation']['sfOrderId'],
    'trackingNo': order['initialOrderDispatchInformation']['trackingNo'],
    'cadOrderId': order['initialOrderDispatchInformation']['cadOrderId'],
    'iDetailsupgradeFallDetector': '',
    'iDetailsadditionalPendant': '',
    'iDetailskeysafe': '',
    'cancellationReason': order['cancellation']['cancellationReason'],
    'detailedCancellationReason': order['cancellation']['detailedCancellationReason'],
    'otherReason': order['cancellation']['otherReason'],
    'cancellationPersonReturning': order['cancellation']['personReturning'],
    'cancellationTelephoneNumber': order['cancellation']['telephoneNumber'],
    'cancellationEmail': order['cancellation']['cancellationEmail'],
    'setToCancellingBy':  order['cancellation']['setToCancellingBy'],
    'created': new Date(order['created']),
    'paymentMethod': order['initialOrderDetails']['payment'],
    'stripeCustomerId': order['accountDetails']['stripeCustomerId'],
    'stripeSubscriptionId': order['accountDetails']['stripeSubscriptionId'],
    'customerCalledFrom': order['initialOrderDetails']['customerCalledFrom'],
    'dispatchDate': '',
    'renewalDate': '',
    'dob': '',
    'paymentDueDate': '',
    'freemonths': '',
    'status': '',
    'statusDate': '',
    'statusSetBy': '',
    'backgroundAutoTestCall': '',
    'lastBoxActivation': '',
    'pendantOneLastActivation': '',
    'pendantTwoLastActivation': '',
    'lastMainsFail': '',
    'mainsFailCount': 0,
    'lastSmokeAlarmActivation': '',
    'lastCODetectorActivation': '',
    'lastHighUseDate': '',
    'highUseCount': 0,
    'lastGpsActivation': '',
    'lastRadioTestMissing': '',
    'lastEpilepsyAlert': '',
    'seconduserRelationship': '',
    'correspondenceFirstName': '',
    'correspondenceLastName': '',
    'correspondenceEmail': '',
    'correspondenceEmailMarketing': '',
    'correspondenceEmailMarketingUpdated': '',
    'correspondenceTelephone': '',
    'correspondenceMobile': '',
    'correspondenceMobileMarketing': '',
    'correspondenceMobileMarketingUpdated': '',
    'correspondenceAddressOne': '',
    'correspondenceAddressTwo': '',
    'correspondenceCity': '',
    'correspondenceCounty': '',
    'correspondencePostcode': '',
    'noteTime': [],
    'noteDateFormatted': [],
    'noteCategory': [],
    'notesContent': [],
    'notesUserName': [],
    'Tags': [],
    'tagExpiryDates': [],
    'outstandingActionsName': [],
    'outstandingActionsRenewalDateTaken': [],
    'outstandingActionsInitiatedDate': [],
    'vim': [],
    'planName': '',
    'planSerial': '',
    'planYearMade': '',
    'planStatus': '',
    'pendantType': '',
    'pendantSerial': '',
    'pendantYearMade': '',
    'pendantStatus': '',
    'planAddedAt': '',
    'AdditionalEquipmentName': '',
    'AdditionalEquipmentSerial': '',
    'additionalEquipmentYearMade': '',
    'AdditionalEquipmentStatus': '',
    'AdditionalEquipmentAddedAt': '',
    'ReplacementEquipmentSerial': '',
    'replacementEquipmentYearMade': '',
    'ReplacementEquipmentAddedAt': '',
    'ReplacementEquipmentName': '',
    'ReplacementEquipmentStatus': '',
    'serial': '',
    'keySafesTitle': '',
    'keySafesStatus': '',
    'keySafesAddedAt': '',
    'discounts': [],
    'goCardlessId': order.goCardlessId? order.goCardlessId: '',
    'goCardlessSubId': order.goCardlessSubId? order.goCardlessSubId: '',
    'pslid': order.pslid? order.pslid: '',
    'referencenumber': order.referencenumber? order.referencenumber: '',
    'serviceNames': '',
    'serviceStatuses': '',
    'servicesAddedAt': '',
    'legalCompany': order.legalCompany,
    'orderSource': order.initialOrderDetails.orderSource,
  };
  if (order['cancellation']['cancellationDate'] && (order['cancellation']['cancellationDate'] != '')) {
    newOrder.cancellationDate = new Date(order['cancellation']['cancellationDate']);
  } else {
    newOrder.cancellationDate = null;
  }
  if (order['cancellation']['returnDate'] && (order['cancellation']['returnDate'] != '')) {
    newOrder.returnDate = new Date(order['cancellation']['returnDate']);
  } else {
    newOrder.returnDate = null;
  }
  if (order['initialOrderDispatchInformation']['dispatchDate'] && (order['initialOrderDispatchInformation']['dispatchDate'] != '')) {
    newOrder.dispatchDate = moment(order['initialOrderDispatchInformation']['dispatchDate']).tz('Europe/London').format('DD/MM/YYYY').replace('Invalid date', '');
  }
  if (order['renewalInformation']['renewalDate'] && (order['renewalInformation']['renewalDate'] != '')) {
    newOrder.renewalDate = moment(order['renewalInformation']['renewalDate']).tz('Europe/London').format('DD/MM/YYYY').replace('Invalid date', '');
  }

  if (order['alarmUserDetails']['userAddress']['dob'] && (order['alarmUserDetails']['userAddress']['dob'] != '')) {
    newOrder.dob = moment(order['alarmUserDetails']['userAddress']['dob']).tz('Europe/London').format('DD/MM/YYYY').replace('Invalid date', '');
  }
  if (order['renewalInformation']['paymentDueDate'] && (order['renewalInformation']['paymentDueDate'] != '')) {
    newOrder.paymentDueDate = moment(order['renewalInformation']['paymentDueDate']).tz('Europe/London').format('DD/MM/YYYY').replace('Invalid date', '');
  }

  if (order['renewalInformation'] && order['renewalInformation']['freemonths']) {
    newOrder.freemonths = order['renewalInformation']['freemonths'];
  }
  if (order['status']) {
    if (order['status']['status']) {
      newOrder.status = order['status']['status'];
    }
    if (order['status']['date']) {
      newOrder.statusDate = order['status']['date'];
    }
    if (order['status']['setby']) {
      newOrder.statusSetBy = order['status']['setby'];
    }
    if (order['status']['backgroundAutoTestCall']) {
      newOrder.backgroundAutoTestCall = order['status']['backgroundAutoTestCall'];
    }
    if (order['status']['lastBoxActivation']) {
      newOrder.lastBoxActivation = order['status']['lastBoxActivation'];
    }
    if (order['status']['pendantOneLastActivation']) {
      newOrder.pendantOneLastActivation = order['status']['pendantOneLastActivation'];
    }
    if (order['status']['pendantTwoLastActivation']) {
      newOrder.pendantTwoLastActivation = order['status']['pendantTwoLastActivation'];
    }
    if (order['status']['lastMainsFail']) {
      newOrder.lastMainsFail = order['status']['lastMainsFail'];
    }
    if (order['status']['mainsFailCount']) {
      newOrder.mainsFailCount = order['status']['mainsFailCount'];
    }
    if (order['status']['lastSmokeAlarmActivation']) {
      newOrder.lastSmokeAlarmActivation = order['status']['lastSmokeAlarmActivation'];
    }
    if (order['status']['lastCODetectorActivation']) {
      newOrder.lastCODetectorActivation = order['status']['lastCODetectorActivation'];
    }
    if (order['status']['lastHighUseDate']) {
      newOrder.lastHighUseDate = order['status']['lastHighUseDate'];
    }
    if (order['status']['highUseCount']) {
      newOrder.highUseCount = order['status']['highUseCount'];
    }
    if (order['status']['lastGpsActivation']) {
      newOrder.lastGpsActivation = order['status']['lastGpsActivation'];
    }
    if (order.status.lastRadioTestMissing) {
      newOrder.lastRadioTestMissing = order.status.lastRadioTestMissing;
    }
    if (order.status.lastEpilepsyAlert) {
      newOrder.lastEpilepsyAlert = order.status.lastEpilepsyAlert;
    }
  }
  if (order['alarmUserDetails']['users'].length > 0) {
    newOrder.customerName += ' | ' + order['alarmUserDetails']['users'].map(user => {
      return user['firstName'] + ' ' + user['lastName'];
    }).join('|');
    newOrder.otherName += ' | ' + order['alarmUserDetails']['users'].map(user => {
      return user['otherName'];
    }).join('|');
    newOrder.preferredName += ' | ' + order['alarmUserDetails']['users'].map(user => {
      return user['preferredName'];
    }).join('|');
    newOrder.email += ' | ' + order['alarmUserDetails']['users'].map(user => {
      return user['email'];
    }).join('|');
    newOrder.emailMarketing += ' | ' + order['alarmUserDetails']['users'].map(user => {
      return user['emailMarketing'];
    }).join('|');
    newOrder.emailMarketingUpdated += ' | ' + order['alarmUserDetails']['users'].map(user => {
      return user.emailMarketingUpdated?
        moment(user.emailMarketingUpdated).tz('Europe/London').format('DD/MM/YYYY HH:mm'): '';
    }).join('|');
    newOrder.mobile += ' | ' + order['alarmUserDetails']['users'].map(user => {
      return user['mobile'];
    }).join('|');
    newOrder.mobileMarketing += ' | ' + order['alarmUserDetails']['users'].map(user => {
      return user['mobileMarketing'];
    }).join('|');
    newOrder.mobileMarketingUpdated += ' | ' + order['alarmUserDetails']['users'].map(user => {
      return user.mobileMarketingUpdated?
        moment(user.mobileMarketingUpdated).tz('Europe/London').format('DD/MM/YYYY HH:mm'): '';
    }).join('|');
    newOrder.seconduserRelationship = order['alarmUserDetails']['users'].map(user => {
      return user['relationship'];
    }).join('|');

  }

  if (order.alarmUserDetails.correspondenceDetails) {
    newOrder.correspondenceFirstName = order.alarmUserDetails.correspondenceDetails.firstName;
    newOrder.correspondenceLastName = order.alarmUserDetails.correspondenceDetails.lastName;
    newOrder.correspondenceEmail = order.alarmUserDetails.correspondenceDetails.email;
    newOrder.correspondenceEmailMarketing = order.alarmUserDetails.correspondenceDetails.emailMarketing;
    if (order.alarmUserDetails.correspondenceDetails.emailMarketingUpdated) {
      newOrder.correspondenceEmailMarketingUpdated =
        moment(order.alarmUserDetails.correspondenceDetails.emailMarketingUpdated).tz('Europe/London').format('DD/MM/YYYY HH:mm');
    }
    newOrder.correspondenceTelephone = order.alarmUserDetails.correspondenceDetails.telephone;
    newOrder.correspondenceMobile = order.alarmUserDetails.correspondenceDetails.mobile;
    newOrder.correspondenceMobileMarketing = order.alarmUserDetails.correspondenceDetails.mobileMarketing;
    if (order.alarmUserDetails.correspondenceDetails.mobileMarketingUpdated) {
      newOrder.correspondenceMobileMarketingUpdated =
        moment(order.alarmUserDetails.correspondenceDetails.mobileMarketingUpdated).tz('Europe/London').format('DD/MM/YYYY HH:mm');
    }
    newOrder.correspondenceAddressOne = order.alarmUserDetails.correspondenceDetails.addressOne;
    newOrder.correspondenceAddressTwo = order.alarmUserDetails.correspondenceDetails.addressTwo;
    newOrder.correspondenceCity = order.alarmUserDetails.correspondenceDetails.city;
    newOrder.correspondenceCounty = order.alarmUserDetails.correspondenceDetails.county;
    newOrder.correspondencePostcode = order.alarmUserDetails.correspondenceDetails.postcode;
  }

  order['outstandingActions'].sort((a: OutstandingAction, b: OutstandingAction) => {
    const bTime: number = new Date(b['renewalDateTaken']).getTime();
    const aTime: number = new Date(a['renewalDateTaken']).getTime();
    return aTime - bTime;
  });

  newOrder.iDetailsupgradeFallDetector = order['items'].filter(item =>
      (item['productId']) && ((item['productId']['category'] == 'lifetimePlan') || (item['productId']['category'] == 'quarterlyPlan')
        || (item['productId']['category'] == 'annualPlan'))
    ).map(item => item['productId']['title']).join('|');
  newOrder.iDetailsadditionalPendant = order['items'].filter(item =>
    (item['productId']) && (item['productId']['category'] == 'additionalEquipment')
  ).map(item => item['productId']['title']).join('|');
  newOrder.iDetailskeysafe = order['items'].filter(item =>
    (item['productId']) && (item['productId']['category'] == 'keySafe')
  ).map(item => item['productId']['title']).join('|');
  order['notes'].forEach((note: OrderNote) => {
    const noteMoment: moment.Moment = moment.tz(note.date, 'Europe/London');
    newOrder.noteTime.push((note.date && noteMoment.isValid())? noteMoment.toDate().getTime(): null);
    newOrder.noteDateFormatted.push((note.date && noteMoment.isValid())? noteMoment.format('DD/MM/YYYY'): '');
    newOrder.noteCategory.push(note.categories.join(';'));
    newOrder.notesContent.push(note.content);
    newOrder.notesUserName.push(note.userName);
  });

  newOrder.Tags = order['tags'].map(tag => {
    try {
      if (tag) {
        return tag['tagID']['tagName'];
      }
    // eslint-disable-next-line no-empty
    } catch (e) {
    }
    return '';
  });
  newOrder.tagExpiryDates = order['tags'].map(tag => {
    if (tag['expiryDate']) {
      return moment(tag['expiryDate']).tz('Europe/London').format('DD/MM/YYYY').replace('Invalid date', 'NO EXPIRY');
    }
    return 'NO EXPIRY';
  });

  if (order['outstandingActions'] && order['outstandingActions'].length > 0) {
    order['outstandingActions'] = [...order['outstandingActions'].filter(action => action.renewalDateTaken != null), ...order['outstandingActions'].filter(action => action.renewalDateTaken == null)]
  }
  newOrder.outstandingActionsName = order['outstandingActions'].map(outstandingActions => {
    return outstandingActions['outstandingName'];
  });
  newOrder.outstandingActionsRenewalDateTaken = order['outstandingActions'].map(outstandingActions => {
    if (outstandingActions['renewalDateTaken']) {
      return new Date(outstandingActions['renewalDateTaken']);
    } else {
      return null;
    }
  });
  newOrder.outstandingActionsInitiatedDate = order['outstandingActions'].map(outstandingActions => {
    if (outstandingActions['actionInitiatedDate']) {
      return new Date(outstandingActions['actionInitiatedDate']);
    } else {
      return null;
    }
  });
  newOrder.vim = order['vim'].map(vim => {
    return ('' + vim['content']).replace('\n', ' ');
  });

  // TODO start remove for hardware version
  newOrder.planName = order['plans'].map(element => {
    return element['equipment'];
  }).join('|');
  newOrder.planSerial = order['plans'].map(element => {
    // //console.log(element['serial']);
    serials.push(element['serial']);
    return element['serial'];
  }).join('|');
  newOrder.planYearMade = order['plans'].map((plan: Plan) => {
    return plan['baseUnitYearMade'];
  }).join('|');
  newOrder.planStatus = order['plans'].map((plan: Plan) => {
    return plan['status'];
  }).join('|');
  newOrder.pendantType = order['plans'].map((plan: Plan) => {
    return plan['typePendant'];
  }).join('|');
  newOrder.pendantSerial = order['plans'].map((plan: Plan) => {
    serials.push(plan['serialPendant']);
    return plan['serialPendant'];
  }).join('|');
  newOrder.pendantYearMade = order['plans'].map((plan: Plan) => {
    return plan['pendantYearMade'];
  }).join('|');
  newOrder.pendantStatus = order['plans'].map((plan: Plan) => {
    return plan['serialStatus'];
  }).join('|');
  newOrder.planAddedAt = order['plans'].map(e => {
    if (!e.added) {
      return '';
    }
    return moment(e.added).format('DD/MM/YYYY').replace('Invalid date', '');
  }).join('|');

  newOrder.AdditionalEquipmentName = order['additionalEquipment'].map((element: AdditionalOrReplacementEquipment) => {
    return element['equipment'];
  }).join('|');
  newOrder.AdditionalEquipmentSerial = order['additionalEquipment'].map((element: AdditionalOrReplacementEquipment) => {
    serials.push(element['serial']);
    return element['serial'];
  }).join('|');
  newOrder.additionalEquipmentYearMade = order['additionalEquipment'].map((element: AdditionalOrReplacementEquipment) => {
    return element['equipmentYearMade'];
  }).join('|');
  newOrder.AdditionalEquipmentStatus = order['additionalEquipment'].map((element: AdditionalOrReplacementEquipment) => {
    return element['status'];
  }).join('|');
  newOrder.AdditionalEquipmentAddedAt = order['additionalEquipment'].map((element: AdditionalOrReplacementEquipment) => {
    if (!element.added) {
      return '';
    }
    return moment(element.added).format('DD/MM/YYYY').replace('Invalid date', '');
  }).join('|')
  // Not all records have a replacementEquipment property
  newOrder.ReplacementEquipmentSerial = '';
  newOrder.ReplacementEquipmentAddedAt = '';
  newOrder.ReplacementEquipmentName = '';
  newOrder.ReplacementEquipmentStatus = '';
  try {
    if (order['replacementEquipment']) {
      newOrder.ReplacementEquipmentSerial = order['replacementEquipment'].map((element: AdditionalOrReplacementEquipment) => {
        serials.push(element['serial']);
        return element['serial'];
      }).join('|');
      newOrder.replacementEquipmentYearMade = order['replacementEquipment'].map((element: AdditionalOrReplacementEquipment) => {
        return element.equipmentYearMade;
      }).join('|');
      newOrder.ReplacementEquipmentAddedAt = order['replacementEquipment'].map((element: AdditionalOrReplacementEquipment) => {
        if (!element.added) {
          return '';
        }
        return moment(element.added).format('DD/MM/YYYY').replace('Invalid date', '');
      }).join('|');
      newOrder.ReplacementEquipmentName = order['replacementEquipment'].map((element: AdditionalOrReplacementEquipment) => {
        return element.equipment;
      }).join('|');
      newOrder.ReplacementEquipmentStatus = order['replacementEquipment'].map((element: AdditionalOrReplacementEquipment) => {
        return element.status;
      }).join('|');
    }
  // eslint-disable-next-line no-empty
  } catch (e) {
  }
  newOrder.serial = serials.filter((serial: string) => {
    if (serial) {
      return serial;
    }
    return '';
  }).join('|');
  // TODO end remove for hardware version
  // TODO add for hardware version
  /*
  let orderHardware: OrderHardware[] = [];
  if (order.hardwareSets) {
    order.hardwareSets.forEach((hardwareSet: OrderHardwareSet) => {
      orderHardware = orderHardware.concat(hardwareSet.hardwareInSet);
    });
    newOrder.hardwareSets = order.hardwareSets.map((hardwareSet: OrderHardwareSet) => {
      return hardwareSet.hardwareSetId.title;
    }).join('|');
  }
  if (order.hardware) {
    orderHardware = orderHardware.concat(order.hardware);
  }
  newOrder.hardwareId = orderHardware.map((hardware: OrderHardware) => {
    return hardware.hardwareId._id;
  }).join('|');
  newOrder.hardwareName = orderHardware.map((hardware: OrderHardware) => {
    return hardware.hardwareId.title;
  }).join('|');
  newOrder.hardwareSerial = orderHardware.map((hardware: OrderHardware) => {
    return hardware.serial;
  }).join('|');
  newOrder.hardwareStatus = orderHardware.map((hardware: OrderHardware) => {
    return hardware.status;
  }).join('|');
  newOrder.hardwareAddedAt = orderHardware.map((hardware: OrderHardware) => {
    return moment(hardware.added).format('DD/MM/YYYY').replace('Invalid date', '');
  }).join('|');
  */

  const keySafes: KeySafe[] = order['keySafes'] || [];
  newOrder.keySafesTitle = keySafes.map((keySafe: KeySafe) => {
    return keySafe.equipment;
  }).join('|');
  newOrder.keySafesStatus = keySafes.map((keySafe: KeySafe) => {
    return keySafe.status;
  }).join('|');
  newOrder.keySafesAddedAt = keySafes.map((keySafe: KeySafe) => {
    if (!keySafe.added) {
      return '';
    }
    return moment(keySafe.added).format('DD/MM/YYYY').replace('Invalid date', '');
  }).join('|');
  if (!!order.renewalDiscounts) {
    newOrder.discounts = order.renewalDiscounts.map((renewalDiscount: RenewalDiscount) => {
      const expiryMoment: moment.Moment = moment.tz(renewalDiscount.discountExpiry, 'Europe/London');
      return (!!renewalDiscount.otherReasonText? renewalDiscount.otherReasonText: renewalDiscount.reason) + ': ' + renewalDiscount.discount.toFixed(2) +
        (expiryMoment.isValid()? expiryMoment.format('[ Expires: ]DD/MM/YYYY'): ' No Expiry') + '. Added By: ' + renewalDiscount.addedBy;
    });
  }

  const services: Service[] = order['services'] || [];
  newOrder.serviceNames = services.map((service: Service) => {
    return service.serviceId.title;
  }).join('|');
  newOrder.serviceStatuses = services.map((service: Service) => {
    return service.status;
  }).join('|');
  newOrder.servicesAddedAt = services.map((service: Service) => {
    if (!service.added) {
      return '';
    }
    return moment(service.added).format('DD/MM/YYYY').replace('Invalid date', '');
  }).join('|');

  return newOrder;
}

export {
  convertOrderToData,
}
