import {RoleService} from './role.service';
import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {permissions} from '../../lookups/rolesAndPermissions';
import {MultiSelectChangeEvent} from '../../models/primeng/multiSelectChangeEvent.model';
import {Role} from '../../models/role.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {UpdateRoleRequest} from '../../models/requests/updateRoleRequest.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {UsersService} from '../users/users.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-maintain-roles',
  templateUrl: './maintain-roles.component.html',
  styleUrls: ['./maintain-roles.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class MaintainRolesComponent implements OnInit {

  constructor(
    private roleService: RoleService,
    private messageService: MessageService,
    private usersService: UsersService,
    private confirmationService: ConfirmationService,
    private title: Title,
  ) { }

  userName: string;
  addRoleModal: boolean = false;
  cols = [
    { field: 'roleName', header: 'Role' },
    { field: 'permissions', header: 'Permissions' },
    { field: 'action', header: 'Actions' },
  ];
  roles: Role[] = [];
  originalRoles: Role[] = [];
  rolePermissions:  SelectItem<string>[] = permissions;
  
  ngOnInit(): void {
    this.userName = localStorage.getItem('userName');
    this.getRoles();
    this.title.setTitle('CRM Roles');
  }

  getRoles() {
    this.roleService.getRoles().subscribe((response: MultiRecordResponse<Role>) => {
      if (!response.success) {
        this.showErrorPopUp('Error getting roles', response.message);
        return;
      }
      this.roles = response.data;
      this.originalRoles = (response.data || []).map((role: Role) => {
        return {
          ...role
        }
      });
    }, (err: Error) => {
      console.log('Something went wrong :: ', err);
      this.showErrorPopUp('Error getting roles', err.message);
    })
  }

  closeAddRoleModal(event) {
    if (event.eventName == 'close') {
      this.addRoleModal = false;
    } else if (event.eventName == 'success') {
      this.addRoleModal = false;
      // TODO just update page with updated role, rather than reloading the whole list.
      this.getRoles();
      this.showSuccess();
    } else if(event.eventName == 'error') {
      this.addRoleModal = false;
      this.showErrorPopUp('Error adding role', 'Error adding role');
    }
  }

  updateRole(role: Role) {
    const params: UpdateRoleRequest = {
      roleId: role._id,
      permissions: role.permissions
    }
    this.roleService.updateRole(params).subscribe((response: SingleRecordResponse<Role>) => {
      if (!response.success) {
        this.showErrorPopUp('Error updating roles', response.message);
        // Replace the list with the original roles (which will have been updated with already applied changes)
        this.roles = this.originalRoles.map((aRole: Role) => {
          return {
            ...aRole
          }
        });
        return;
      }
      const oldRole: Role = this.originalRoles.find((aRole: Role) => aRole._id == role._id);
      // Swap out the user in the original list with the updated one
      this.originalRoles = this.originalRoles.map((aRole: Role) => {
        if (aRole._id != role._id) {
          return aRole;
        } else {
          return response.data;
        }
      });
      // Update the list being worked on
      this.roles = this.originalRoles.map((aRole: Role) => {
        return {
          ...aRole
        }
      });
      this.changeRightsAutoNotification(oldRole, role);
      this.showSuccess();
    }, (err: Error) => {
      console.log('Error on updating role :: ', err);
      this.showErrorPopUp('Error updating role', err.message);
    })
  }

  deleteRole(roleId: string) {
    this.roleService.deleteRole(roleId).subscribe((response: SingleRecordResponse<Role>) => {
      if (response.success) {
        this.getRoles();
        this.showSuccess();
      } else {
        this.showErrorPopUp('Error deleting roles', response.message);
      }
    }, (err: Error) => {
      console.log('Error on delete role :: ', err);
      this.showErrorPopUp('Error deleting roles', err.message);
    })
  }

  // MultSelectChangeEvent incorrectly has value as array of strings and itemValue as SelectItem<string> both should be the same type
  confirmPermissionChange(event: MultiSelectChangeEvent<any>, role: Role): void {
    if (!this.usersService.userHasPermission(event.itemValue.value)) {
      // Undo the change
      if (event.value.includes(event.itemValue.value)) {
        // The user has tried to add a permission
        role.permissions = role.permissions.filter((permission: string) => permission != event.itemValue.value);
      } else {
        // The user tried to remove a permission - add back in this way to refresh UI
        role.permissions = [...role.permissions, event.itemValue.value]
      }

      this.confirmationService.confirm({
        message: 'You are not allowed to add/remove a permission that you do not have yourself.',
        header: 'Permission Denied',
        icon: 'pi pi-info-circle',
        acceptVisible: true,
        acceptLabel: 'OK',
        rejectVisible: false,
        accept: () => {
        },
      });
    }
  }

  showSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success Updates!',
      detail: 'Changes Successfuly Applied. Users with this role will need to log out and in again to pick up changes.',
      life: 10000
    });
  }

  changeRightsAutoNotification(oldRole: Role, role: Role) {
    const oldRoleRights: string[] = oldRole.permissions.length? oldRole.permissions: ['None'];
    const newRoleRights: string[] = role.permissions.length? role.permissions: ['None'];
    this.roleService.changeRoleRightsAutoNotification({
      userName: this.userName, 
      rightsChangedForRole: role.roleName,
      oldRoleRights: oldRoleRights.join(),
      newRoleRights: newRoleRights.join(), 
    }).subscribe(response => {
      console.log('Response on changeAccessRight :: ', response);
    });
  }

  showErrorPopUp(header: string, message: string): void {
    this.showPopUp(header, message, 'pi pi-exclamation-triangle');
  }

  showPopUp(header: string, message: string, icon: string): void {
    this.confirmationService.confirm({
      message: message,
      header: header,
      rejectVisible: false,
      acceptLabel:'OK',
      icon: icon,
      accept: () => {
      },
      reject: () => {
      }
    });
  }
}
