<div class="formbar row no-gutters">
  <div class="col-6">
    Customer Feedback
  </div>
  <div class="col-5 check-container">
    <button name="addFeedback" type="button" (click)="addFeedback()" class="btn btn-primary" [disabled]="isAddFeedbackDisabled()">
      Add Feedback
    </button>
  </div>
  <div class="col-1">
    <button (click)="minimizeSection(pageSection)" type="button" class="btn minMax">
      <i *ngIf="!pageSection.minimized" class="pi pi-minus"></i>
      <i *ngIf="pageSection.minimized" class="pi pi-plus"></i>
    </button>
  </div>
</div>
<div [ngStyle]="{'display': pageSection.minimized? 'none': 'block'}">
  <div class="form-row formsect">
    <div class="col-6 px-1 py-2">
      <p-multiSelect [options]="feedbackTypes" placeholder="Types" appendTo="body" [filter]=true
          [maxSelectedLabels]=2 selectedItemsLabel="{0} selected" [(ngModel)]="selectedTypes" (ngModelChange)="changeTypeFilter()">
      </p-multiSelect>
    </div>
    <div class="col-6 px-1 py-2">
      <p-multiSelect [options]="feedbackStatuses" placeholder="Statuses" appendTo="body" [filter]=true
          selectedItemsLabel="{0} selected" [(ngModel)]="selectedStatuses" (ngModelChange)="changeStatusFilter()">
      </p-multiSelect>
    </div>
  </div>
  <div class="feedbackContainer">
    <ng-container *ngFor="let feedback of feedbackRecords | customerFeedbackFilter : selectedTypes : selectedStatuses; let feedbackIndex=index">
      <div class="formbar row">
        <div class="col-6 px-0"></div>
        <div class="col-6 px-0 check-container">
          <button type="button" class="btn btn-primary" (click)="editFeedback(feedback._id)">
            Edit
          </button>
          <div *ngIf="isCustFeedbackLocked(feedback._id)" >
            Locked By: {{custFeedbackLockedBy(feedback._id)}}
          </div>
        </div>
      </div>
      <div class="form-row formsect">
        <div class="col-3 form-group">
          <label for="feedbackType">Type:</label>
          <input type="text" class="form-control" name="feedbackType" value="{{feedback.feedbackType}}" readonly/>
        </div>
        <div class="col-3 form-group">
          <label for="priority">Priority:</label>
          <input type="text" class="form-control" name="priority" value="{{feedback.priority}}" readonly/>
        </div>
        <div class="col-6 form-group">
          <label for="mainReason">Main Reason:</label>
          <input type="text" class="form-control" name="mainReason" value="{{feedback.mainReason}}" readonly/>
        </div>
        <div class="col-3 form-group">
          <label for="dateLogged">Date Logged:</label>
          <input type="text" class="form-control" name="dateLogged" value="{{feedback.dateLogged | date:'dd/MM/y'}}" readonly/>
        </div>
        <div class="col-3 form-group">
          <label for="status">Status:</label>
          <input type="text" class="form-control" name="status" value="{{feedback.status}}" readonly/>
        </div>
        <div class="col-6 form-group">
          <label for="whoContactedUs">Who Contacted Us:</label>
          <input type="text" class="form-control" name="whoContactedUs" value="{{feedback.whoContactedUs}}" readonly/>
        </div>
        <div class="col-12 form-group">
          <label for="feedbackDetails">Feedback Details:</label>
          <textarea name="feedbackDetails" class="form-control" rows="3" readonly value="{{feedback.feedbackDetails}}">
          </textarea>
        </div>
        <div class="col-12 form-group">
          <label for="resolution">Resolution:</label>
          <textarea name="resolution" class="form-control" rows="3" readonly value="{{feedback.resolution}}">
          </textarea>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<app-add-customer-feedback *ngIf="showAddFeedbackDialog" [order]="order" [tags]="tags" (closeModal)="closeAddFeedback($event)">
</app-add-customer-feedback>
<app-edit-customer-feedback *ngIf="feedbackIdToEdit" [feedbackId]="feedbackIdToEdit" [fromOrder]="true" (closeModal)="closeEditFeedback($event)">
</app-edit-customer-feedback>