import {SelectItem} from 'primeng/api';

const pageOptions: SelectItem<string>[] = [
  { label: 'Order Page', value: 'Order Page' }
];

const orderPageSections: SelectItem<string>[] = [
  { label: 'Alarm User Details', value: 'Alarm User Details' },
  { label: 'Account Contacts', value: 'Account Contacts' },
  { label: 'General', value: 'General' },
];

export {
  pageOptions,
  orderPageSections
}