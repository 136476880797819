<div class="display-faulty-equipments">
  <p-table #dt [loading]="loading" [(selection)]="selectedRows" [value]="faultyEquipments" [columns]="cols"
      [paginator]="true" [rows]="10" [globalFilterFields]="searchableFields" dataKey="_id"
      sortField="dateTested" [sortOrder]="-1" sortMode="single" (sortFunction)="customSort($event)" [customSort]="true"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="PAGE {currentPage} OF {totalPages} - FAULTY EQUIPMENT {first} TO {last} OF {totalRecords}">
    <ng-template pTemplate="caption">
      <div class="row">
        <div class="col-md-4">
          <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
          <input type="search" pInputText size="50" placeholder="Global Filter"
              (input)="globalFilter($event, 'contains')" style="width:90%">
        </div>
        <div class="col-4" *ngIf="false">
          <input type="file" style="display: inline-block;" (change)="incomingfile($event)" placeholder="Upload file" accept=".xlsx"
            #returnsFileUpload>
          <button type="button" id="importSupplierReturnValues" aria-labelledby="importSupplierReturnValues" (click)="upload()" 
              [disabled]="!file" class="btn btn-primary">
            Import
          </button>
        </div>
        <div id="display-equipment-table-bar" class="col-md-2">
          <div ngbDropdown class="d-inline-flex" style="width: 100%;">
            <button type="button" id="exportSelectedRows" ngbDropdownToggle class="px-2 btn btn-primary">
              Process Selected Rows
            </button>
            <div ngbDropdownMenu aria-labelledby="exportSelectedRows" id="exportOptions">
              <ng-container *ngFor="let option of orderExportOptions">
                <button ngbDropdownItem type="button" (click)="exportSelectedRows(option)">{{option}}</button>
              </ng-container>
              <button ngbDropdownItem type="button" (click)="processRows('Flag as sent to supplier')">
                Flag as sent to supplier
              </button>
              <button ngbDropdownItem type="button" (click)="processRows('Flag as received from supplier')">
                Flag as received from supplier
              </button>
            </div>
          </div>
        </div>
        <div id="display-equipment-table-bar" class="col-md-1">
          <div *ngIf="false" ngbDropdown class="d-inline-flex" style="width: 100%;">
            <button  type="button" id="exportSelectedRowsUsingSerials" ngbDropdownToggle class="px-2 btn btn-primary">
              Scan Serials
            </button>
            <div ngbDropdownMenu aria-labelledby="exportSelectedRowsUsingSerials" id="exportOptionsUsingSerials">
              <button ngbDropdownItem type="button" (click)="processRows('Flag as sent to supplier using serial')">
                Flag as sent to supplier
              </button>
              <button ngbDropdownItem type="button" (click)="processRows('Flag as received from supplier using serial')">
                Flag as received from supplier
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-1">
          <button class="px-2 btn btn-primary" type="button" (click)="showFaultyEquipmentDetail(null, 'addNewFault')">Add Fault</button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th *ngFor="let col of columns" [ngSwitch]="col.field">
          <div *ngSwitchCase="'actions'">Actions</div>
          <div *ngSwitchDefault [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order"
                ariaLabelAsc="Activate to sort in ascending order">
            </p-sortIcon>
          </div>
        </th>
      </tr>
      <tr>
        <th><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
        <ng-container *ngFor="let col of columns">
          <th [ngSwitch]='col.field'>

            <input *ngSwitchCase="'orderId.alarmUserDetails.tdCode'" pInputText type="search"
              (input)="applyFilter($event,col.field,'equals')"
              [value]="getFilterValue(col.field)"
              style="width: 100%;">

            <input *ngSwitchCase="'serial'" pInputText type="search"
              (input)="applyFilter($event, col.field, 'contains')"
              [value]="getFilterValue(col.field)"
              style="width: 100%;">
              
            <p-multiSelect *ngSwitchCase="'equipmentType'" [options]="equipmentCategories" placeholder="Equipment type"
              [maxSelectedLabels]=0 selectedItemsLabel="{0} items selected" 
              (onChange)="dt.filter($event.value, col.field, 'in')" [style]="{'width': '100%'}">
            </p-multiSelect>

            <p-multiSelect *ngSwitchCase="'reportedFault'" [options]="allFaults" placeholder="Reported Fault"
              [maxSelectedLabels]=0 selectedItemsLabel="{0} items selected"
              (onChange)="dt.filter($event.value, col.field, 'in')" [style]="{'width': '100%'}">
            </p-multiSelect>

            <input *ngSwitchCase="'outcomeOfTest'" pInputText type="search"
              (input)="applyFilter($event,col.field,'contains')"
              [value]="getFilterValue(col.field)"
              style="width: 100%;">

            <p-dropdown *ngSwitchCase="'troubleShootingCorrect'" [options]="troubleshootingOptions" [showClear]="true"
              placeholder="Correct" (onChange)="dt.filter($event.value, col.field, 'equals')" [style]="{'width': '100%'}">
            </p-dropdown>
            <p-dropdown *ngSwitchCase="'shelfLocation'" [options]="shelfLocations" [showClear]="true"
              placeholder="Shelf Location" (onChange)="dt.filter($event.value, col.field, 'equals')" [style]="{'width':'100%'}">
            </p-dropdown>
            <p-calendar *ngSwitchCase="'reportedAt'" [(ngModel)]="dateReportedFilters" appendTo="body" selectionMode="range"
              [readonlyInput]="true" dateFormat="dd/mm/yy" (onSelect)="dt.filter($event, col.field, 'reportedAt')"
              [monthNavigator]=true [yearNavigator]=true  [showButtonBar]=true [keepInvalid]=true 
              [yearRange]="filterYearRange" [numberOfMonths]="1" [showOtherMonths]=true [selectOtherMonths]=true [showIcon]=true
              [inputStyle]="{maxWidth:'100px'}" [hideTransitionOptions]="'0ms'"
              (onClearClick)="clearTestDateFilters()">
            </p-calendar>
            <p-calendar *ngSwitchCase="'dateTested'" [(ngModel)]="dateTestedFilters" appendTo="body" selectionMode="range"
              [readonlyInput]="true" dateFormat="dd/mm/yy" (onSelect)="dt.filter($event, col.field, 'dateTested')"
              [monthNavigator]=true [yearNavigator]=true [showButtonBar]=true [keepInvalid]=true
              [yearRange]="filterYearRange" [numberOfMonths]="1" [showOtherMonths]=true [selectOtherMonths]=true [showIcon]=true
              [inputStyle]="{maxWidth:'100px'}" [hideTransitionOptions]="'0ms'"
              (onClearClick)="clearTestDateFilters()">
            </p-calendar>
            <input *ngSwitchCase="'trackingId'" pInputText type="search" (input)="applyFilter($event,col.field,'contains')"
              [value]="getFilterValue(col.field)" style="width: 100%;"/>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td>
          <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
          <div *ngSwitchCase="'orderId.alarmUserDetails.tdCode'">
            <a routerLink="/order/{{rowData.orderId?._id}}" target="_blank">
              {{rowData.orderId?.alarmUserDetails.tdCode}}
              <div *ngIf="isOrderLocked(rowData.orderId?._id)">
                {{orderLockedBy(rowData.orderId?._id)}}
              </div>
            </a>
          </div>
          <div *ngSwitchCase="'actions'" class="text-nowrap">
            <button type="button" class="btn btn-primary" (click)="showFaultyEquipmentDetail(rowData._id, 'update')">
              Update
            </button>
            <button type="button" class="btn btn-info ml-2" (click)="showFaultyEquipmentDetail(rowData._id, 'read-only')">
              View
            </button>
          </div>
          <div *ngSwitchCase="'troubleShootingCorrect'">
            {{rowData[col.field]}}
          </div>
          <div *ngSwitchCase="'reportedAt'">
            {{rowData[col.field] | date:'dd/MM/y'}}
          </div>
          <div *ngSwitchCase="'dateTested'">
            {{rowData[col.field] | date:'dd/MM/y'}}
          </div>
          <div *ngSwitchDefault>
            {{rowData[col.field]}}
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary" class="table-stats-holder">
      <table>
        <tbody class="table-stats">
          <tr>
            <td><span>NOT YET STARTED: {{ notYetTestedCount}}</span></td>
            <td><span>TROUBLESHOOTING CORRECT: {{ troubleshootingCorrectCount}}</span></td>
            <td><span>TROUBLESHOOTING INCORRECT: {{ troubleshootingIncorrectCount}}</span></td>
            <td><span>TOTAL: {{ faultyEquipments.length}}</span></td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </p-table>
  <p-toast key="faultyEquipmentList"></p-toast>
</div>
<p-confirmDialog></p-confirmDialog>
<app-detail-faulty-equipment *ngIf="isFaultyEquipmentVisible" 
  [faultyEquipmentDetailId]="faultyEquipmentDetailId"
  [openingMode]="isFaultyEquipmentDetailReadOnly" 
  (closeFaultyEquipmentDetailDialog)="closeFaultyEquipmentDetailDialog($event)">
</app-detail-faulty-equipment>

<div class="process-selected-rows">
  <p-dialog [header]="rowsProcessFlag" [(visible)]="showRowsProcessing" [modal]="true" [style]="{width: '50vw'}" [draggable]="false"
    [resizable]="false" [blockScroll]="true" (onHide)="closeSelectedRowsProcess()">
    <ng-container *ngIf="rowsProcessFlag && rowsProcessFlag.startsWith('Flag as sent to supplier')">
      <div class="row">
        <div class="form-group col-6 col-md-6 col-lg-6">
          <label for="trackingId-to-update">Tracking Id</label>
          <input type="text" id="trackingId-to-update" name="trackingIdToUpdate" [(ngModel)]="trackingIdToUpdate" 
            class="form-control" required/>
        </div>
        <div class="form-group col-md-6">
          <label for="dispatch-date">Dispatch date</label>
          <input id="dispatch-date" class="form-control" type="date" name="dispatchDate" [(ngModel)]="dispatchDateToUpdate" onkeydown="return false"
           onkeydown="return false" min="{{today}}" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="rowsProcessFlag && rowsProcessFlag.startsWith('Flag as received from supplier')">
      <!-- <p>Received from supplier</p> -->
      <div class="row">
        <div class="form-group col-12 col-md-12 col-lg-12">
          <label for="receivedFromSupplerDate">Return Date</label>
          <input type="date" id="receivedFromSupplier" class="form-control" name="receivedFromSupplierDate" 
          [(ngModel)]="receivedFromSupplierDate" min="{{today}}" />
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="rowsProcessFlag && rowsProcessFlag.includes('serial')">
      <div class="row">
        <div class="form-group col-6 col-md-6 col-lg-6">
          <label for="serial">Serial</label>
          <textarea pInputText type="text" rows="10" placeholder="Serial" [(ngModel)]="serial" required></textarea>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="rowsProcessFlag && rowsProcessFlag.startsWith('Flag as sent to supplier')">
      <div class="row">
        <div class="form-group col-12 col-md-6 col-lg-6">
          <button type="button" class="btn btn-primary" 
            [disabled]="!okToSendToSupplier()"
            (click)="updateSelectedRows()"
          >Confirm</button>
          <button type="button" class="btn btn-warning ml-2" (click)="closeSelectedRowsProcess()">Cancel</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="rowsProcessFlag === 'Flag as received from supplier'">
      <div class="row">
        <div class="form-group col-12 col-md-6 col-lg-6">
          <button type="button" class="btn btn-primary" (click)="updateSelectedRows()">Confirm</button>
          <button type="button" class="btn btn-warning ml-2" (click)="closeSelectedRowsProcess()">Cancel</button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="rowsProcessFlag === 'Flag as received from supplier using serial'">
      <div class="row">
        <div class="form-group col-12 col-md-6 col-lg-6">
          <button type="button" class="btn btn-primary" (click)="generateReturnPage()" [disabled]="!serial">Confirm</button>
          <button type="button" class="btn btn-warning ml-2" (click)="closeSelectedRowsProcess()">Cancel</button>
        </div>
      </div>
    </ng-container>
  </p-dialog>
</div>
<p-dialog header="Fill In Return Details" [(visible)]="showReturnDialog" [modal]="true" [draggable]="false"
    [resizable]="false" [blockScroll]="true" (onHide)="closeReturnsPage()">         
  <form [formGroup]="returnFormsGroup">
    <p-table #returns [(selection)]="selectedReturnsForms" [value]="returnFormsArray.controls" [columns]="returnsColumns" styleClass="returnsTable">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th></th>
          <th></th>
          <th>
            <p-dropdown [options]="yesNoOptions" placeholder="Warranty" [showClear]="true" [(ngModel)]="selectedWarrantyOption" appendTo="body"
              [ngModelOptions]="{standalone: true}">
            </p-dropdown>
          </th>
          <th>
            <p-dropdown [options]="yesNoOptions" placeholder="Fixed" [showClear]="true" [(ngModel)]="selectedFixedOption" appendTo="body"
              [ngModelOptions]="{standalone: true}">
            </p-dropdown>
          </th>
          <th>
            <p-dropdown [options]="notFixedReasonsForBulkUpdate" placeholder="Reason Not Fixed" [showClear]="true" [(ngModel)]="selectedReasonOption" 
              appendTo="body" [ngModelOptions]="{standalone: true}">
            </p-dropdown>
          </th>
          <th>
            <button type="button" class="btn btn-primary ml-2 my-2" (click)="applyChangesToSelected()" [disabled]="!selectedReturnsForms || (selectedReturnsForms.length === 0)">
              Update selected records
            </button>
          </th>
        </tr>
        <tr>
          <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of returnsColumns">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="returnsColumns">
        <ng-container formArrayName="returnForms">
          <tr [formGroupName]="rowIndex" [pSelectableRow]="rowData">
            <td>
              <p-tableCheckbox [value]="rowData" ngDefaultControl=""></p-tableCheckbox>
            </td>
            <td *ngFor="let col of returnsColumns" [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'serial'">
                <input pInputText type="text" formControlName="serial" >
              </ng-container>
              <ng-container *ngSwitchCase="'warranty'">
                <p-checkbox class="center-checkbox" name="warranty" [binary]="true"
                            formControlName="warranty">
                        </p-checkbox>
              </ng-container>
              <ng-container *ngSwitchCase="'fixed'">
                <p-checkbox class="center-checkbox" name="fixed" [binary]="true"
                            formControlName="fixed">
                        </p-checkbox>
              </ng-container>
              <ng-container *ngSwitchCase="'reason'" [style]="{'width':'100%'}">
                <p-dropdown [options]="notFixedReasons" placeholder="No reason selected" [showClear]="true" appendTo="body" formControlName="reason">
                </p-dropdown>
              </ng-container>
              <ng-container *ngSwitchCase="'returnDate'">
                <input pInputText type="date" formControlName="returnDate" >
              </ng-container>
            </td>
          </tr>
          <tr [formGroupName]="rowIndex">
            <td colspan="6" class="pb-2">
              <label *ngIf="returnFormsArray.at(rowIndex).value.failureReason" class="alert alert-danger w-100">
                {{returnFormsArray.at(rowIndex).value.failureReason}}
              </label>
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>
  </form>
  <p-footer>
    <button type="button" class="btn btn-warning ml-2" (click)="closeReturnsPage()">Cancel</button>
    <button type="button" class="btn btn-primary ml-2" (click)="updateRecords()">Submit</button>
  </p-footer>
</p-dialog>