<p-dialog (onHide)="onHideDialog()" header="Change Free Months" [(visible)]="display" [modal]="true"
    [style]="{width: '50vw',height:'auto'}" [maximizable]="false" [minY]="70" [baseZIndex]="3100"
    id="freeMonthsDialog" (onShow)="removeScrollBlock()">
  <div id="freeMonthsDialogBody">
    <form [formGroup]="freeMonthsForm" (ngSubmit)="submitData()">
      <div class="row">
        <div class="col-md-6 form-group">
          <p-dropdown (onChange)="freeMonthReasonChange()" [options]="changeReasons" formControlName="selectedChangeReason"
            placeholder="Why are you changing the Free Months?" [style]="{width:'100%'}" [showClear]="true"
            name="selectedChangeReason" [required]=true>
          </p-dropdown>
          <div *ngIf="selectedChangeReason.touched && selectedChangeReason.invalid" class="alert alert-danger">
            The reason you are changing the free months is required
          </div>
        </div>
        <div class="col-md-6 form-group">
          <ng-container *ngIf="selectedChangeReason.value" [ngSwitch]="selectedChangeReason.value">
            <ng-container *ngSwitchCase="'Remove Free Months'">
              <!-- 
                specifying optionLabel even though label is the default value for this makes the form control
                hold the object, not just the value, which for months we want
              -->
              <p-dropdown [options]="removeFreeMonthOptions" optionLabel="label" formControlName="freeMonths"
                placeholder="How many free months should be removed?" [style]="{width:'100%'}" [showClear]="true"
                name="removedMonths" [required]=true>
              </p-dropdown>
              <div *ngIf="freeMonths.touched && freeMonths.invalid" class="alert alert-danger">
                The number of free months to remove is required
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'Account on Hold'">
              <p-dropdown [options]="addFreeMonthsOptions" optionLabel="label" formControlName="freeMonths"
                placeholder="How long the account is on hold for?" [style]="{width:'100%'}" [showClear]="true"
                name="addedMonths" [required]=true>
              </p-dropdown>
              <div *ngIf="freeMonths.touched && freeMonths.invalid" class="alert alert-danger">
                The length of time the acount is on hold for is required
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <p-dropdown [options]="addFreeMonthsOptions" optionLabel="label" formControlName="freeMonths"
                placeholder="How many free months should be added?" [style]="{width:'100%'}" [showClear]="true"
                name="addedMonths" [required]=true>
              </p-dropdown>
              <div *ngIf="freeMonths.touched && freeMonths.invalid" class="alert alert-danger">
                The number of free months to add is required
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngIf="selectedChangeReason.value === 'Account on Hold'">
        <div class="row">
          <div *ngIf="freeMonths.value" class="col-md-6 form-group">
            <p-dropdown (onChange)="holdReasonChange()" [options]="accountOnHoldReasons" formControlName="selectedReasonForAccountOnHold"
              placeholder="Why account is on hold?" [style]="{width:'100%'}" [showClear]="true"
              name="selectedReasonForAccountOnHold" [required]=true>
            </p-dropdown>
            <div *ngIf="selectedReasonForAccountOnHold.touched && selectedReasonForAccountOnHold.invalid"
                class="alert alert-danger">
              The reason the account is on hold is required
            </div>
          </div>
          <div *ngIf="selectedReasonForAccountOnHold.value === 'Other'" class="col-md-6 form-group">
            <span class="p-float-label" width="100%">
              <input id="float-hold" type="text" style="width: 100%;" pInputText formControlName="reasonText"
                name="otherReasonForAccountOnHold" required>
              <label for="float-hold" width="100%">
                Enter Reason For Account On Hold
              </label>
            </span>
            <div *ngIf="reasonText.touched && reasonText.invalid" class="alert alert-danger">
              The reason the account is on hold is required
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(selectedChangeReason.value === 'Remove Free Months') || selectedChangeReason.value === 'Other'" class="row">
        <div class="col-md-12 form-group">
          <span class="p-float-label" width="100%">
            <input id="float-free" type="text" style="width: 100%;" pInputText formControlName="reasonText"
              name="reasonForChange" required>
            <label for="float-free" width="100%">
              Enter Reason for changing free months
            </label>
          </span>
          <div *ngIf="reasonText.touched && reasonText.invalid" class="alert alert-danger">
            The reason for changing free months is required
          </div>
        </div>
      </div>
      <div class="row" *ngIf="selectedChangeReason.value">
        <div class="col-md-12 form-group">
          <h6>Additional Note</h6>
          <textarea [rows]="3" [cols]="30" id="float-textarea" style="width: 100%;" name="additionaNote" pInputTextarea
            formControlName="additionalNote"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <button type="submit" class="btn btn-primary" [disabled]="!freeMonthsForm.valid || isSubmitButtonDisabled">Submit</button>
          <button type="button" class="btn btn-warning ml-2" (click)="onHideDialog()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>