import {SelectItem} from 'primeng/api';

interface WhatToSend extends SelectItem<string> {
  class?: string
}

interface SendReason extends SelectItem<string> {
  tooltip?: string
}

let whatToSendOptions: WhatToSend[];

function getWhatToSendOptions(): WhatToSend[] {
  if (!whatToSendOptions) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('post: what to send')) {
      return [];
    }
    whatToSendOptions = JSON.parse(localStorage.getItem('post: what to send')).filter((what: WhatToSend) => !!what.class) || [];
  }
  return whatToSendOptions;
}

let sendReasonOptions: SendReason[];

function getSendReasonOptions(): SendReason[] {
  if (!sendReasonOptions) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('post: reason to send')) {
      return [];
    }
    sendReasonOptions = JSON.parse(localStorage.getItem('post: reason to send')) || [];
  }
  return sendReasonOptions;
}
let infoPackWhatToSendOptions: WhatToSend[];

function getInfoPackWhatToSendOptions(): WhatToSend[] {
  if (!infoPackWhatToSendOptions) {
    // Handle requests to get details before login is complete
    if (!localStorage.getItem('post: what to send')) {
      return [];
    }
    infoPackWhatToSendOptions = JSON.parse(localStorage.getItem('post: what to send')).filter((what: WhatToSend) => !what.class) || [];
  }
  return infoPackWhatToSendOptions;
}
export {
  WhatToSend,
  SendReason,
  getWhatToSendOptions,
  getSendReasonOptions,
  getInfoPackWhatToSendOptions,
}