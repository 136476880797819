import { Pipe, PipeTransform } from '@angular/core';
import {getBrandsOrTypesForPlanCode} from '../lookups/hardwareBrandsOrTypes';

@Pipe({
  name: 'planCodeHasTypes'
})
export class PlanCodeHasTypesPipe implements PipeTransform {

  transform(planCode: string): boolean {
    if (!planCode) {
      return false;
    }
    // Single item matches are just used for manual lookups, there aren't multiple types to select from
    return (getBrandsOrTypesForPlanCode(planCode).length > 1);
  }

}
