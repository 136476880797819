import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment}  from '../../../environments/environment';
import {Observable} from 'rxjs';
import {SimpleResponse} from '../../models/responses/simpleResponse.model';
import {MultiRecordResponse} from '../../models/responses/multiRecordResponse.model';
import {Tooltip} from '../../models/tooltip.model';
@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  API_URL = environment.protocol + environment.IPAddress + '/api/tooltips';
  
  constructor(
    private http:HttpClient
  ) { }

  getTooltips(): Observable<MultiRecordResponse<Tooltip>> {
    return this.http.get<MultiRecordResponse<Tooltip>>(`${this.API_URL}`);
  }

  updateTooltip(params): Observable<SimpleResponse> {
    return this.http.put<SimpleResponse>(`${this.API_URL}`, params);
  }
}
