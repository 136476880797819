<div class="row no-gutters">
    <div class="col-6 col-md-6 col-lg-6">
        <button type="button" class="btn btn-primary m-2" (click)="actionDetail={};actionOpenMode='add'">Add New Action</button>
    </div>
</div>
<div class="row no-gutters">
    <div class="actions-list">
        <p-table #actionsTable [value]="actions" [columns]="columns" [paginator]="true" [rows]="15" [sortOrder]="1" sortMode="single" sortField="actionName">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field">
                        <div *ngSwitchCase="'actions'">{{col.header}}</div>
                        <div *ngSwitchDefault [pSortableColumn]="col.field">
                            {{col.header}}
                            <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                ariaLabelDesc="Activate to sort in descending order"
                                ariaLabelAsc="Activate to sort in ascending order">
                            </p-sortIcon>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field">
                        <div *ngSwitchCase="'actionName'">
                            <input pInputText type="search" (input)="applyFilter($event, col.field, 'contains')" />
                        </div>
                        <div *ngSwitchCase="'board'">
                            <input pInputText type="search" (input)="applyFilter($event, col.field, 'contains')" />
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns" [ngSwitch]="col['field']">
                        <div *ngSwitchCase="'actions'">
                            <button type="button" class="btn btn-primary" (click)="actionDetail=rowData;actionOpenMode='edit'">
                              Edit
                            </button>
                            <button type="button" class="btn btn-info ml-2" (click)="actionDetail=rowData;actionOpenMode='view'">
                              View
                            </button>
                        </div>
                        <div *ngSwitchCase="'background'">
                            <div class="brandBar"
                                [ngStyle]="{'background-color': rowData['background'], 'color': rowData['color']}">
                                {{rowData['background']}}
                            </div>
                        </div>
                        <div *ngSwitchCase="'color'">
                            <div class="brandBar" [ngStyle]="{'background': rowData['color'], 'color': (rowData['color'] === '#000000'? '#FFFFFF': '#000000')}">
                                {{rowData['color']}}
                            </div>
                        </div>
                        <div *ngSwitchCase="'usersCanAdd'">
                            {{rowData['usersCanAdd']?'Yes':'No'}}
                        </div>
                        <div *ngSwitchDefault>
                            {{rowData[col.field]}}
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<p-toast></p-toast>
<app-add-action *ngIf="actionDetail" [openMode]="actionOpenMode" [actionDetail]="actionDetail" (closeAddAction)="closeAddNewAction($event)">
</app-add-action>